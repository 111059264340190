/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import { useReactToPrint } from 'react-to-print';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import { scaleLinear } from 'd3-scale';
import { colors, fontFamily } from '../../constants';
import { ComponentToPrint } from '../component-to-print';
import FloatingHoverButton from '../floating-hover-button';
import DateUserTitle from '../print-components/DateUserTitle';
import ChoosenFilters from '../print-components/ChoosenFilters';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyTableHeatmap = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  dynamicColors,
  style = { marginLeft: '29px', marginRight: '31px' },
  usePrint = false,
}) => {
  const { analysDataDefault } = useSelector((state) => state.analys);
  const { user } = useSelector((state) => state.auth);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const divstyle = {
    overflow: 'auto',
    // fontFamily: fontFamily.PRIMARY,
    ...style,
  };

  return (
    <>
      <div style={divstyle}>
        <ComponentToPrint ref={componentRef}>
          <div className="hide-when-printing">
            <PivotTableTitle title={title} />
          </div>
          <div className="show-when-printing">
            <DateUserTitle user={user} title={title} />
          </div>
          <ReactPivotTable
            data={analysDataDefault}
            rows={preSelectedRows}
            cols={preSelectedCols}
            aggregatorName={aggregatorName}
            vals={vals}
            rendererName="Table Heatmap"
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            tableColorScaleGenerator={(values) => {
              var redColorAverage = colors.pivottable.RED;
              var lightRedColorAverage = colors.pivottable.LIGHT_RED;
              // var lightRedColorAverage = scaleLinear()
              //   .domain([2.5, 2.99])
              //   .range([colors.pivottable.RED, colors.pivottable.LIGHT_RED]);

              var yellow = colors.pivottable.YELLOW;

              var greenColorAverage = colors.pivottable.GREEN;
              var lightGreenColorAverage = colors.pivottable.LIGHT_GREEN;
              // var lightGreenColorAverage = scaleLinear()
              //   .domain([3.7, 3.4])
              //   .range([
              //     colors.pivottable.GREEN,
              //     colors.pivottable.LIGHT_GREEN,
              //   ]);

              return (x) => {
                const dynamicColor = x !== null ? '#dce9eb' : '#FFF';

                const backgroundColor = dynamicColors
                  ? dynamicColor
                  : x === null
                  ? colors.WHITE
                  : x < 2.5
                  ? redColorAverage
                  : x >= 2.5 && x < 3
                  ? lightRedColorAverage
                  : x >= 3 && x < 3.4
                  ? yellow
                  : x >= 3.4 && x < 3.7
                  ? lightGreenColorAverage
                  : greenColorAverage;

                return { backgroundColor };
              };
            }}
            tableOptions={{}}
            valueFilter={data ? data.valueFilter : {}}
          />
          <div className="page-break" />
          <div className="show-when-printing d-felx align-items-center justify-content-center">
            <ChoosenFilters
              data={data ? data.valueFilter : {}}
              defaultData={analysDataDefault}
            />
          </div>
        </ComponentToPrint>
      </div>
      {usePrint && (
        <>
          <FloatingHoverButton
            hoverText="Skriv ut graf"
            icon="print"
            onClick={() => handlePrint()}
            top="82vh"
          />
        </>
      )}
    </>
  );
};

export default PlotlyTableHeatmap;
