import React from 'react';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';

const InactivateConfirmation = ({
  showModal,
  hideModal,
  confirmInactivate,
  message,
}) => {
  return (
    <>
      <MDBModal show={showModal} animationDirection="" staticBackdrop>
        <MDBModalDialog size="sm" centered>
          <MDBModalContent className="bg-white">
            <MDBModalBody className="d-flex align-items-center justify-content-center flex-column">
              <div className="alert alert-white">{message}</div>
              <div className="d-flex align-selft-start justify-content-between flex-row  w-100 mt-0">
                <p
                  className=" text-secondary"
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  onClick={hideModal}
                >
                  Avbryt
                </p>

                <p
                  className="text-secondary"
                  style={{
                    marginRight: 5,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  onClick={confirmInactivate}
                >
                  Inaktivera
                </p>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* <MDBModal show={showModal} animationDirection="right">
      <MDBModalDialog size="lg" centered>
        <MDBModalContent className="bg-light">
          <MDBModalHeader>
            <MDBBtn
              tag="a"
              color="none"
              className="mt-0 align-items-center  text-muted d-flex flex-row"
              onClick={hideModal}
            >
              <MDBIcon fas icon="chevron-left " size="lg" color="primary" />

              <p className="mt-3 text-primary" style={{ marginLeft: 5 }}>
                tillbaka
              </p>
            </MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="alert alert-warning">{message}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <p className="text-warning" onClick={confirmInactivate}>
              inaktivera
            </p>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal> */}
    </>
  );
};

export default InactivateConfirmation;
