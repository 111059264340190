export const CSVHeadersFromResponse = [
  { label: 'Projekt', key: 'project' },
  { label: 'Projektnummer', key: 'project_number' },
  { label: 'Projektnamn', key: 'project_name' },
  { label: 'Projektstart', key: 'project_start' },
  { label: 'Projek aktiv', key: 'project_active' },
  { label: 'Kund', key: 'customer' },
  { label: 'Kund aktiv', key: 'customer_active' },
  { label: 'Användare', key: 'user' },
  { label: 'Användarkonto', key: 'user_account' },
  { label: 'Användarkonto internal', key: 'user_account_internal' },
  { label: 'Rapportör', key: 'reporter' },
  { label: 'Rapportör aktiv', key: 'reporter_active' },
  { label: 'Fråga', key: 'question' },
  { label: 'Fråga aktiv', key: 'question_active' },
  { label: 'Respons timestamp', key: 'response_timestamp' },
  { label: 'Responsdatum', key: 'response_date' },
  { label: 'Respons år', key: 'response_year' },
  { label: 'Respons vecka', key: 'response_week' },
  { label: 'Respons kvartal', key: 'response_quarter' },
  { label: 'Respons månad', key: 'response_month' },
  { label: 'Respons dag på året', key: 'response_day_of_year' },
  { label: 'Respons dag i vecka', key: 'response_day_of_week' },
  { label: 'Respons år/mån', key: 'response_yearmonth' },
  { label: 'Respons år/kvartal', key: 'response_yearquarter' },
  { label: 'Respons år/vecka', key: 'response_yearweek' },
  {
    label: 'Respons dagar från projektstart',
    key: 'response_days_from_project_start',
  },
  {
    label: 'Respons veckor från projektstart',
    key: 'response_weeks_from_project_start',
  },
  { label: 'Respons', key: 'response' },
];
