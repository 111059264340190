import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const prevent = (Component) => {
  class Prevent extends React.Component {
    render() {
      return this.props.auth.isAuth ? (
        this.props.auth.user.profile.profile_role === 1 ? (
          <Redirect to="/admin-home" />
        ) : this.props.auth.user.profile.profile_role === 3 ? (
          <Redirect to="/analys-overview" />
        ) : (
          <Redirect to="/home" />
        )
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = (state) => {
    return { auth: state.auth };
  };

  return connect(mapStateToProps)(Prevent);
};

export default prevent;
