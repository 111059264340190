import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import ModalIcon from '../modal-icon';
import { icons } from '../../constants';

const AddList = ({ columns, rows, listKey, mobile }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-column w-100">
        <ModalIcon icon={icons.CONNECT_LIST} />
        <div style={{ background: 'white', padding: 10, width: '100%' }}>
          <MDBDataTableV5
            className="w-100"
            btn
            small
            info={false}
            searching={false}
            displayEntries={false}
            searchLabel="sök..."
            entriesLabel="per sida"
            barReverse
            searchTop
            searchBottom={false}
            responsive={mobile ? true : false}
            hover
            entriesOptions={[10, 20, 30]}
            entries={100}
            pagesAmount={4}
            data={{
              columns: columns,
              rows: rows,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddList;
