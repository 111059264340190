/* eslint-disable import/no-anonymous-default-export */
import {
  CREATE_SURVEY,
  GET_SURVEYS,
  CHOOSE_SURVEY_TO_USE,
  GET_ACTIVE_SURVEY,
  DELETE_SURVEY,
  EDIT_SURVEY,
  SET_ACTIVE_SURVEY,
  SURVEY_PROGRESS,
  GET_SURVEY_QUESTIONS,
  INACTIVATE_SURVEY,
  ACTIVATE_SURVEY,
} from '../types';

const INITIAL_STATE = {
  survey: null,
  surveys: null,
  surveyProgress: 0,
  surveyQuestions: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SURVEY:
      return { ...state };
    case GET_SURVEYS:
      return { ...state, surveys: action.payload };
    case GET_SURVEY_QUESTIONS:
      return { ...state, surveyQuestions: action.payload };
    case EDIT_SURVEY:
      return { ...state };
    case INACTIVATE_SURVEY:
      return { ...state };
    case ACTIVATE_SURVEY:
      return { ...state };
    case DELETE_SURVEY:
      return { ...state };
    case GET_ACTIVE_SURVEY:
      return { survey: action.payload };
    case CHOOSE_SURVEY_TO_USE:
      return { ...state, survey: action.payload };
    case SET_ACTIVE_SURVEY:
      return { ...state };
    case SURVEY_PROGRESS:
      return { surveyProgress: action.payload };
    default:
      return state;
  }
}
