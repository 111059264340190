/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addManagedProject,
  getResources,
  getProjects,
} from '../../../redux/store/actions';
import CanRunSurveyList from './CanRunSurveyList';
import Select from 'react-select';

const AddManagedProjectModal = ({ handleClose, show }) => {
  const [resourcesWithAccount, setresourcesWithAccount] = useState([]);
  const [managedProject, setManagedProject] = useState({
    project: {
      active: true,
      projectNumber: '',
      projectName: '',
      projectID: '',
      projectStart: '',
      customer: '',
    },
    projectLeader: '',
    resources: [],
    canRunSurvey: [],
  });

  const dispatch = useDispatch();

  const { resources } = useSelector((state) => state.resources);
  const { projects } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);

  let projectLeaders;
  let workers;

  useEffect(() => {
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
    if (!projects && user) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, [resources, projects, dispatch]);

  useEffect(() => {
    countresourcesWithAccount();
  }, [managedProject]);

  const handleAddManagedProject = () => {
    if (managedProject.project.projectID === '') {
      const mProject = {
        ...managedProject,
        project: projects[0],
      };
      dispatch(addManagedProject(mProject));
      handleClose();
    } else {
      dispatch(addManagedProject(managedProject));
      handleClose();
    }

    setManagedProject({
      project: {
        active: true,
        projectNumber: '',
        projectName: '',
        projectID: '',
        projectStart: '',
        customer: '',
      },
      projectLeader: '',
      resources: [],
    });
  };

  const handleSetSelectedProjects = (selectedresourcesWithAccount) => {
    setManagedProject({
      ...managedProject,
      canRunSurvey: selectedresourcesWithAccount,
    });
  };

  const countresourcesWithAccount = () => {
    let temp = [];

    if (managedProject.projectLeader) {
      if (managedProject.projectLeader.value.haveAccount) {
        temp.push(managedProject.projectLeader.value);
      }
    }

    if (managedProject.resources[0]) {
      const withAccount = managedProject.resources.filter(
        (resource) => resource.value.haveAccount === true
      );

      withAccount.forEach((resource) => {
        temp.push(resource.value);
      });
    }

    setresourcesWithAccount(temp);
  };

  if (resources) {
    const withAccount = resources.filter(
      (resource) => resource.haveAccount === true && resource.role === 2
    );
    const withoutAccount = resources.filter(
      (resource) => resource.haveAccount !== true
    );

    projectLeaders = withAccount;
    workers = withoutAccount;
  }

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>projekt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resources && projects ? (
          <Form>
            <Row>
              <Col xs={7}>
                <Form.Label className="text-secondary">välj projekt</Form.Label>
                <Form.Select
                  defaultValue={projects[0]}
                  onChange={(value) => {
                    const {
                      projectNumber,
                      projectName,
                      projectID,
                      active,
                      customer,
                      projectStart,
                    } = projects[value.target.value];
                    setManagedProject({
                      ...managedProject,
                      project: {
                        active: active,
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectID: projectID,
                        customer: customer,
                        projectStart: projectStart,
                      },
                    });
                  }}
                  aria-label="Default select example"
                >
                  {projects.map((project, index) => {
                    return (
                      <option key={project.projectNumber} value={index}>
                        {project.projectName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <br />
            <Row>
              <Form.Label className="text-secondary">
                välj projektledare
              </Form.Label>
              {projectLeaders ? (
                <Select
                  placeholder="Välj projektledare..."
                  value={managedProject.projectLeader}
                  onChange={(value) => {
                    setManagedProject({
                      ...managedProject,
                      projectLeader: value,
                    });
                  }}
                  name="colors"
                  options={projectLeaders.map((projectLeader) => {
                    return {
                      value: projectLeader,
                      label: projectLeader.name,
                    };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              ) : null}
            </Row>
            <br />

            <Row>
              <Form.Label className="text-secondary">
                lägg till team medlemmar (valfritt)
              </Form.Label>
              {workers ? (
                <Select
                  placeholder="Välj team medlemmar..."
                  onChange={(value) => {
                    setManagedProject({ ...managedProject, resources: value });
                  }}
                  isMulti
                  options={workers.map((worker) => {
                    return {
                      value: worker,
                      label: worker.name,
                    };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              ) : null}
            </Row>

            <br />
            <br />
            {resourcesWithAccount[0] && (
              <Row>
                <Form.Label className="text-secondary">
                  ska lämna respons
                </Form.Label>
                <CanRunSurveyList
                  resourcesWithAccount={resourcesWithAccount}
                  selectedresourcesWithAccount={handleSetSelectedProjects}
                />
              </Row>
            )}

            <br />
            <br />
          </Form>
        ) : (
          <Spinner animation="border" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          stäng
        </Button>
        <Button variant="primary" onClick={handleAddManagedProject}>
          skapa
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManagedProjectModal;
