/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ModalList from '../../../components/modal-list';
import { MDBIcon, MDBCheckbox } from 'mdb-react-ui-kit';

const TeammemberList = ({
  selectedTeammembers,
  connectProjectLeaders,
  removeTeammember,
  add,
}) => {
  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resource',
    },
    // {
    //   label: <p style={{ cursor: 'pointer', float: 'left' }}>e-post</p>,
    //   field: 'email',
    // },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Projektledare</p>,
      field: 'projectLeader',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  useEffect(() => {
    setTimeout(function () {
      selects();
    }, 500);
  }, []);

  useEffect(() => {
    handleConnectReporters();
  }, [checked]);

  const selects = () => {
    var selec = document.getElementsByName('selectedTeammembers');

    const tempCheck = [];

    for (var i = 0; i < selec.length; i++) {
      if (selec[i].checked === true) tempCheck.push(selec[i].value);
    }

    setChecked(tempCheck);
  };

  const handleConnectReporters = () => {
    const reportersToAdd = selectedTeammembers.filter((x) =>
      checked.includes(x.resource_id)
    );

    connectProjectLeaders(reportersToAdd);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <>
      {selectedTeammembers && (
        <ModalList
          listKey={'TeammemberList'}
          add={add}
          columns={columns}
          rows={selectedTeammembers.map((teammember) => {
            const {
              resource_firstname,
              resource_lastname,
              resource_email,
              resource_id,
              projectLeader,
            } = teammember;
            return {
              resource: `${resource_firstname} ${resource_lastname}`,
              email: resource_email,
              projectLeader: (
                <MDBCheckbox
                  key={resource_email}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    marginRight: '40px',
                  }}
                  name="selectedTeammembers"
                  defaultChecked={projectLeader}
                  value={resource_id}
                  className="text-primary"
                  onChange={handleCheck}
                />
              ),
              remove: (
                <MDBIcon
                  key={resource_id}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    marginRight: '13px',
                  }}
                  icon="minus-circle"
                  className="text-primary"
                  size="lg"
                  onClick={() => {
                    removeTeammember(resource_id);
                  }}
                />
              ),
            };
          })}
        />
      )}
    </>
  );
};

export default TeammemberList;
