import { supabase } from '../utils/supabase';
import { sendToast } from '../components/toast';
import moment from 'moment';

const addOperation = async (
  { operationName, description, status },
  digitualsClientID
) => {
  try {
    const { data, error } = await supabase.from('operation').insert([
      {
        operation_name: operationName,
        operation_description: description,
        is_active: status,
        digituals_client_id: digitualsClientID,
      },
    ]);

    if (data) {
      sendToast(`Avdelning ${operationName} skapad!`, 1);
    }
    if (error) {
      console.log('addOperation error: ', error);
    }
  } catch (error) {
    console.log('addOperation error', error, error.message);
  }
};

const getOperation = async (digitualsClientID) => {
  try {
    let { data: operation, error } = await supabase
      .from('operation')
      .select(
        `
    *,
    resource (
      * 
    )
  `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (operation) {
      return operation;
    }
    if (error) {
      console.log('getOperation error: ', error);
    }
  } catch (error) {
    console.log('getOperation error', error, error.message);
  }
};

const editOperation = async (
  { operationName, description, status, operation_id },
  digitualsClientID
) => {
  try {
    const { data, error } = await supabase
      .from('operation')
      .update({
        operation_name: operationName,
        operation_description: description,
        operation_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        is_active: status,
      })
      .eq('operation_id', operation_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`Avdelning ${operationName} redigerad!`, 1);
    }
    if (error) {
      console.log('editOperation error: ', error);
    }
  } catch (error) {
    console.log('editOperation error', error, error.message);
  }
};

const activateOperation = async (operation, digitualsClientID) => {
  const { operation_id, operation_name } = operation;
  try {
    const { data, error } = await supabase
      .from('operation')
      .update({ is_active: true })
      .eq('operation_id', operation_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      const toastText = `Avdelning ${operation_name} aktiverad!`;

      sendToast(toastText, 1);
    }
    if (error) {
      console.log('activateOperation error: ', error);
    }
  } catch (error) {
    console.log('activateOperation error', error, error.message);
  }
};
const inactivateOperation = async (operation, digitualsClientID) => {
  const { operation_id, operation_name } = operation;
  try {
    const { data, error } = await supabase
      .from('operation')
      .update({ is_active: false })
      .eq('operation_id', operation_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      const toastText = `Avdelning ${operation_name} inaktiverad!`;

      sendToast(toastText, 2);
    }
    if (error) {
      console.log('inactivateOperation error: ', error);
    }
  } catch (error) {
    console.log('inactivateOperation error', error, error.message);
  }
};

const deleteOperation = async (operation, digitualsClientID) => {
  const { operation_id, operation_name } = operation;
  try {
    const { data: resourceData, error: resourceError } = await supabase
      .from('resource')
      .select('*')
      .eq('operation_id', operation_id)
      .eq('digituals_client_id', digitualsClientID);

    if (resourceError) {
      console.log('deleteOperation resourceError', resourceError);
    }
    if (resourceData) {
      if (resourceData.length > 0) {
        return { connected: true };
      } else {
        const { data: opData, error: opError } = await supabase
          .from('operation')
          .delete()
          .eq('operation_id', operation_id)
          .eq('digituals_client_id', digitualsClientID);
        if (opData) {
          const toastText = `${operation_name} borttagen!`;

          sendToast(toastText, 4);
        }
        if (opError) {
          console.log('deleteOperation error: ', opError);
        }
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

export {
  addOperation,
  getOperation,
  editOperation,
  deleteOperation,
  activateOperation,
  inactivateOperation,
};
