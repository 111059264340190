import React from 'react';
import { Button } from 'react-bootstrap';

import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBIcon,
  MDBContainer,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { pixels } from '../../constants';

const AddModal = ({
  handleClose,
  show,
  fullscreen,
  done,
  children,
  submitForm,
  centered,
  header,
  minHeight = '80vh',
}) => {
  return (
    <MDBModal show={show} animationDirection="right" staticBackdrop closeOnEsc>
      <MDBModalDialog
        size={fullscreen ? 'fullscreen' : 'lg'}
        centered={centered ? true : false}
        position={centered ? '' : 'top'}
      >
        <MDBModalContent className="bg-light text-secondary">
          <MDBModalHeader className="d-flex align-items-center justify-content-between border-0">
            <div
              style={{ width: '30%', marginLeft: 20 }}
              className="d-flex align-items-center justify-content-start flex-row"
            >
              {submitForm ? (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  type="reset"
                  form={submitForm}
                >
                  <MDBIcon
                    fas
                    icon="chevron-left "
                    size="sm"
                    color="secondary"
                    style={{ marginLeft: -25 }}
                  />

                  <p
                    className=" text-secondary"
                    style={{ marginLeft: 5, fontSize: 16, marginTop: 15 }}
                  >
                    Tillbaka
                  </p>
                </Button>
              ) : (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  onClick={handleClose}
                >
                  <MDBIcon
                    fas
                    icon="chevron-left "
                    size="sm"
                    color="secondary"
                    style={{ marginLeft: -25 }}
                  />

                  <p
                    className=" text-secondary"
                    style={{ marginLeft: 5, fontSize: 16, marginTop: 15 }}
                  >
                    Tillbaka
                  </p>
                </Button>
              )}
            </div>

            {/* <p
              className="mt-3 text-secondary"
              style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 14 }}
            >
              {header}
            </p> */}

            {fullscreen ? null : (
              <MDBTypography variant="h5" style={{ marginTop: 10 }}>
                {header}
              </MDBTypography>
            )}

            <div
              style={{ width: '30%', marginRight: 20 }}
              className="d-flex align-items-center justify-content-end"
            >
              {submitForm ? (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  type="submit"
                  form={submitForm}
                >
                  <p
                    className=" text-secondary"
                    style={{ fontSize: 16, marginTop: 15, marginRight: -25 }}
                  >
                    Spara
                  </p>
                </Button>
              ) : (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  onClick={done}
                >
                  <p
                    className=" text-secondary"
                    style={{ fontSize: 16, marginTop: 15, marginRight: -25 }}
                  >
                    Spara
                  </p>
                </Button>
              )}
            </div>
          </MDBModalHeader>
          <MDBModalBody
            className="d-flex flex-column align-items-center justify-content-start "
            style={{ minHeight: minHeight }}
          >
            <MDBContainer
              className="mb-5 bg-transparent"
              style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
            >
              {children}
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default AddModal;
