/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { colors } from '../../constants';

const MultiSelectCheckboxes = ({
  options,
  buttonLabel,
  setValue,
  setActive,
  resetFilter,
  menuWidth = 230,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const customStyles = {
    menu: (provided, state) => {
      return {
        ...provided,
        width: menuWidth,
        padding: 0,
        boxShadow: state.isFocused ? `0 0 0 0.1rem ${colors.PRIMARY}` : '',
      };
    },
    option: (provided, state) => ({
      ...provided,
      width: 'auto',
      background: state.isSelected ? colors.DASHBOARD : 'white',
      fontSize: 14,
      color: colors.TEXT,
      padding: 0,
      marginLeft: -15,
      fontWeight: 'normal',
      '&:hover': {
        background: colors.PRIMARY,
        color: 'white',
      },
    }),
    control: (provided, state) => {
      return {
        ...provided,
        width: 'auto',
        borderColor: state.isFocused ? colors.PRIMARY : 'white',
        margin: 0,
        boxShadow: state.isFocused ? `0 0 0 0.1rem ${colors.PRIMARY}` : '',

        '&:hover': {
          borderColor: colors.PRIMARY,
        },
        '&:focus': {
          borderColor: colors.PRIMARY,
        },
      };
    },
    input: (provided, state) => {
      return {
        ...provided,
        borderColor: state.isSelected ? colors.PRIMARY : 'white',
        margin: 0,
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
    container: (provided, state) => {
      return {
        width: 'auto',
        background: 'white',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 5,
      };
    },
  };

  const opt = setActive ? options : [{ label: 'Alla', value: '*' }, ...options];
  useEffect(() => {
    setSelectedOptions(handleSetSelectedOptionsInit());
  }, [options]);

  useEffect(() => {
    reset();
  }, [resetFilter]);

  function reset() {
    setSelectedOptions([]);
  }

  function handleSetSelectedOptionsInit() {
    const optionsToSet = [{ label: 'Alla', value: '*' }];
    const selOptions = options.filter((option) => option.isDisabled === false);

    optionsToSet.push(...selOptions);
    return [];
  }
  function handleSetSelectedOptions() {
    const optionsToSet = [{ label: 'Alla', value: '*' }];

    optionsToSet.push(...options);

    return optionsToSet;
  }

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Alla`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} valda`;
    }
  }

  function onChange(value, event) {
    if (event.action === 'select-option' && event.option.value === '*') {
      this.setState(handleSetSelectedOptions());
      setValue(setActive ? '' : handleSetSelectedOptions());
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === '*'
    ) {
      this.setState([]);
      setValue(setActive ? '' : []);
    } else if (event.action === 'deselect-option') {
      this.setState(value.filter((o) => o.value !== '*'));

      const activeStatus =
        value[0]?.value === 1 ? true : value[0]?.value === 2 ? false : '';

      setValue(setActive ? activeStatus : value.filter((o) => o.value !== '*'));
    } else if (value.length === this.options.length - 1 && !setActive) {
      setValue(this.options);
      this.setState(this.options);
    } else if (value.length === this.options.length && setActive) {
      setValue('');
      this.setState(this.options);
    } else {
      const activeStatus = value[0]?.value === 1 ? true : false;

      this.setState(value);

      setValue(setActive ? activeStatus : value);
    }
  }

  return (
    <ReactMultiSelectCheckboxes
      options={opt}
      placeholderButtonLabel={buttonLabel}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
      setState={setSelectedOptions}
      styles={customStyles}
      isOptionDisabled={(option) => option.isDisabled}
      placeholder="Sök..."
      isSearchable={true}
      classNamePrefix="text-white"
      color={colors.PRIMARY}
      backgroundColor={colors.DASHBOARD}
    />
  );
};

export default MultiSelectCheckboxes;
