/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { interpolateYlGn } from 'd3-scale-chromatic';

import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { useDispatch } from 'react-redux';
import { setAnalysDataDefault } from '../../redux/store/actions';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivotTable = ({ data }) => {
  const [state, setState] = useState();
  const [tableData, setTableData] = useState([]);

  const preSelectedRows = ['Projekt', 'Kund'];
  const preSelectedCols = ['Fråga', 'Respons'];

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setTableData(
        data.map((value) => ({
          Projekt: value.project,
          ProjektAktivt: value.project_active,
          ProjektStart: value.project_start,
          Kund: value.customer,
          KundAktiv: value.customer_active,
          AnvKonto: value.user_account,
          AnvKontoAktivt: value.user_account_active,
          Rapportör: value.reporter,
          RapportörAktiv: value.reporter_active,
          Fråga: value.question,
          FrågaAktiv: value.question_active,
          Responsdatum: value.response_date,
          Respons_År: value.response_year,
          Respons_Vecka: value.response_week,
          Respons_Kvartal: value.response_quarter,
          Respons_Månad: value.response_month,
          ProjektStart_År: value.projectstart_year,
          ProjektStart_Vecka: value.projectstart_week,
          ProjektStart_Kvartal: value.projectstart_quarter,
          ProjektStart_Månad: value.projectstart_month,
          ProjektStart_ÅrVecka: value.projectstart_yearweek,
          ProjektStart_ÅrKvartal: value.projectstart_yearquarter,
          ProjektStart_ÅrMånad: value.projectstart_yearmonth,
          Respons_ÅrMånad: value.response_yearmonth,
          Respons_ÅrKvartal: value.response_yearquarter,
          Respons_ÅrVecka: value.response_yearweek,
          DgrFrånProjektstart: value.response_days_from_project_start,
          VeckorFrånProjektstart: value.response_weeks_from_project_start,
          Respons: value.response,
        }))
      );
      dispatch(
        setAnalysDataDefault({
          data: data.map((value) => ({
            Projekt: value.project,
            ProjektAktivt: value.project_active,
            ProjektStart: value.project_start,
            Kund: value.customer,
            KundAktiv: value.customer_active,
            AnvKonto: value.user_account,
            AnvKontoAktivt: value.user_account_active,
            Rapportör: value.reporter,
            RapportörAktiv: value.reporter_active,
            Fråga: value.question,
            FrågaAktiv: value.question_active,
            Responsdatum: value.response_date,
            Respons_År: value.response_year,
            Respons_Vecka: value.response_week,
            Respons_Kvartal: value.response_quarter,
            Respons_Månad: value.response_month,
            ProjektStart_År: value.projectstart_year,
            ProjektStart_Vecka: value.projectstart_week,
            ProjektStart_Kvartal: value.projectstart_quarter,
            ProjektStart_Månad: value.projectstart_month,
            ProjektStart_ÅrVecka: value.projectstart_yearweek,
            ProjektStart_ÅrKvartal: value.projectstart_yearquarter,
            ProjektStart_ÅrMånad: value.projectstart_yearmonth,
            Respons_ÅrMånad: value.response_yearmonth,
            Respons_ÅrKvartal: value.response_yearquarter,
            Respons_ÅrVecka: value.response_yearweek,
            DgrFrånProjektstart: value.response_days_from_project_start,
            VeckorFrånProjektstart: value.response_weeks_from_project_start,
            Respons: value.response,
          })),
          preSelectedRows: preSelectedRows,
          preSelectedCols: preSelectedCols,
        })
      );
    }
  }, [data]);
  useEffect(() => {
    if (state) {
      // dispatch(setAnalysData(state));
    }
  }, [state]);

  return (
    <div style={{ overflow: 'auto' }}>
      <div className="">
        <PivotTableUI
          data={tableData}
          aggregatorName="Average"
          vals={['Respons']}
          onChange={(s) => setState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          tableColorScaleGenerator={(values) => {
            const min = Math.min.apply(Math, values);
            const max = Math.max.apply(Math, values);
            const range = max - min;

            return (x) => {
              const progress = (max - x) / range;
              const backgroundColor = interpolateYlGn(1 - progress);

              return { backgroundColor };
            };
          }}
          {...state}
        />
        {/* <PivotTableUI
          data={tableData}
          showUI={false}
          // onRefresh
          // rows={preSelectedRows}
          // cols={preSelectedCols}
          // style={{
          //   width: '10px',
          //   height: '10%',
          // }}
          // hiddenFromDragDrop={hiddenFromDragDrop}
          onChange={(s) => setState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          tableColorScaleGenerator={(values) => {
           
            const min = Math.min.apply(Math, values);
            const max = Math.max.apply(Math, values);
            const range = max - min;

            return (x) => {
              const progress = (max - x) / range;
              const backgroundColor = interpolateYlGn(1 - progress);
            
              return { backgroundColor };
            };
          }}
          // rendererOptions={{
          //   'Table Heatmap': {
          //     colorScaleGenerator: function (values) {
          //       return Plot.d3.scale
          //         .linear()
          //         .domain([-35, 0, 35])
          //         .range(['#77F', '#FFF', '#F77']);
          //     },
          //   },
          // }}
          //   rendererOptions={{
          //     c3: {
          //       data: {
          //         colors: {
          //           Rapportör: '#77F',
          //         },
          //       },
          //     },
          //   }}
          {...state}
        /> */}
      </div>
    </div>
  );
};

export default PivotTable;
