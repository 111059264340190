/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ModalList from '../../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';
import AddResourceToProjectModal from '../add-resource-to-project-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getResources,
  getProjects,
  removeResourceToProject,
} from '../../../../redux/store/actions';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';

const ResourceProjectList = ({ resourceToEdit, mobile }) => {
  const [resource, setResource] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentProjects, setCurrentProjects] = useState('');
  const [availableProjects, setAvailableProjects] = useState('');
  const [rows, setRows] = useState([]);
  const [projectToRemove, setProjectToRemove] = useState('');
  const [activeProjects, setActiveProjects] = useState('');

  const confirmDeleteMessage =
    resourceToEdit && projectToRemove
      ? `Är du säker att du vill ta bort kopplingen mellan resurs  ${resourceToEdit.resource_firstname} ${resourceToEdit.resource_lastname} och projekt ${projectToRemove.project_name}?`
      : '';

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Projekt</p>,
      field: 'project',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Projektnummer</p>,
      field: 'projectNumber',
    },

    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (resourceToEdit) {
      setResource(resourceToEdit);
    }
  }, [resourceToEdit]);

  useEffect(() => {
    if (!projects && user) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, [resourceToEdit]);

  useEffect(() => {
    if (projects) {
      setActiveProjects(projects.filter((value) => value.is_active === true));
    }
  }, [projects]);

  useEffect(() => {
    if (resource && activeProjects) {
      createRowsForList(resource);
    }
  }, [resource, activeProjects]);

  const createRowsForList = (e) => {
    const { team } = e;
    const projectIDs = [];

    // const actProjects = projects.filter((value) => value.is_active === true);

    if (team.length > 0) {
      const rows = team.map((t) => {
        const { is_active, project_name, project_id, project_number } =
          t.project;

        if (is_active === true) {
          projectIDs.push(project_id);

          return {
            project: project_name,
            projectNumber: project_number,
            remove: (
              <MDBIcon
                key={project_id}
                style={{
                  cursor: 'pointer',
                  float: 'right',
                  marginRight: '13px',
                }}
                icon="minus-circle"
                className="text-primary"
                size="lg"
                onClick={() => {
                  setShowDeleteModal(true);
                  setProjectToRemove(t.project);
                }}
              />
            ),
          };
        } else {
          return;
        }
      });

      setRows(rows);
      setCurrentProjects(projectIDs);
    } else {
      setRows([]);
    }

    if (projectIDs.length === 0) {
      setAvailableProjects(activeProjects);
    } else {
      const availableP = activeProjects.filter(
        (x) => !projectIDs.includes(x.project_id)
      );
      setAvailableProjects(availableP);
    }
  };

  const handleRemoveResourceFromProject = (project, resource) => {
    dispatch(
      removeResourceToProject(project, resource, user.digituals_client_id)
    ).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id)).then(({ payload }) => {
          const findResourceToEdit = payload.filter(
            (e) => e.resource_id === resourceToEdit.resource_id
          );

          if (findResourceToEdit[0]) {
            // createRowsForList(findResourceToEdit[0]);

            setResource(findResourceToEdit[0]);
          }
        });
      }, 500)
    );

    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setTimeout(function () {
      dispatch(getResources(user.digituals_client_id)).then(({ payload }) => {
        const findResourceToEdit = payload.filter(
          (e) => e.resource_id === resourceToEdit.resource_id
        );

        if (findResourceToEdit[0]) {
          // createRowsForList(findResourceToEdit[0]);

          setResource(findResourceToEdit[0]);
        }
      });
    }, 500);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleDone = () => {};

  return (
    <>
      <DeleteConfirmation
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() =>
          handleRemoveResourceFromProject(projectToRemove, resourceToEdit)
        }
        message={confirmDeleteMessage}
      />
      {availableProjects && (
        <AddResourceToProjectModal
          availableProjects={availableProjects}
          currentProjects={currentProjects}
          resourceToEdit={resourceToEdit}
          show={showModal}
          handleClose={handleClose}
          done={handleDone}
          fullscreen={mobile ? true : false}
          mobile={mobile}
        />
      )}

      <ModalList
        columns={columns}
        rows={rows}
        add={handleOpen}
        mobile={mobile}
      />
    </>
  );
};

export default ResourceProjectList;
