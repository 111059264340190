import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import { useLocation, Link } from 'react-router-dom';

import { ADMIN_HOME, PROJECTS, USERS, RESPONSE } from './constants';

const MobileSizeNav = () => {
  const location = useLocation();
  const [currentPathLocation, setCurrentPathLocation] = useState(
    location.pathname
  );

  useEffect(() => {
    setCurrentPathLocation(location.pathname);
  }, [location]);

  // const handleReloadApp = () => {
  //   window.location.reload(false);
  //   window.location.reload(true);
  // };

  return (
    <>
      <Navbar
        fixed="bottom"
        className="w-100 pb-3"
        bg="light"
        variant="light"
        style={{
          maxHeight: '100px',
        }}
      >
        <Container className="d-flex justify-content-between">
          <MobileNavbarLink
            to={ADMIN_HOME}
            activeIcon="bi bi-house-fill"
            inactiveIcon="bi bi-house"
            text="Hem"
            currentPathLocation={currentPathLocation}
          />
          <MobileNavbarLink
            to={RESPONSE}
            activeIcon="bi bi-bar-chart-fill"
            inactiveIcon="bi bi-bar-chart"
            text="Frågeformulär"
            currentPathLocation={currentPathLocation}
          />
          <MobileNavbarLink
            to={PROJECTS}
            activeIcon="bi bi-calendar-week-fill"
            inactiveIcon="bi bi-calendar-week"
            text="Projekt"
            currentPathLocation={currentPathLocation}
          />
          <MobileNavbarLink
            to={USERS}
            activeIcon="bi bi-people-fill"
            inactiveIcon="bi bi-people"
            text="Resurser"
            currentPathLocation={currentPathLocation}
          />
        </Container>
      </Navbar>
    </>
  );
};

export default MobileSizeNav;

export function MobileNavbarLink({
  to,
  currentPathLocation,
  activeIcon,
  inactiveIcon,
  text,
}) {
  const isActive = to === currentPathLocation;

  return (
    <Link
      style={{ width: '25%' }}
      to={to}
      className={
        isActive
          ? 'd-flex flex-column justify-content-center align-items-center text-white'
          : 'd-flex flex-column justify-content-center align-items-center text-muted'
      }
    >
      <MDBIcon icon={isActive ? activeIcon : inactiveIcon} size="lg" />
      <p style={{ fontSize: 11 }}>{text}</p>
    </Link>
  );
}
