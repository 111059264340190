/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { MDBContainer } from 'mdb-react-ui-kit';
import {
  getOperation,
  editResource,
  getResources,
  updateUserPassword,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import ResourceProjectList from './ResourceProjectList';
import CollapseSection from '../../../../components/collapse-section';
import ModalIcon from '../../../../components/modal-icon';
import { icons, status } from '../../../../constants';

const EditResourceForm = ({ handleClose, resourceToEdit, mobile }) => {
  const [showAccount, setShowAccount] = useState(false);
  const [showConnectedProjects, setShowConnectedProjects] = useState(false);
  const [activeField, setActiveField] = useState('');
  const [initialValues, setInitialValues] = useState('');
  const [createAcc, setCreateAcc] = useState(false);
  const [createAccByInvite, setCreateAccByInvite] = useState(false);

  const [enableValidate, setEnableValidate] = useState(false);

  const [activeOperations, setActiveOperations] = useState('');

  const schema = Yup.object().shape({
    email: Yup.string().required('Ange e-post.'),
    name: Yup.string().required('Ange namn'),
    lastname: Yup.string().required('Ange efternamn'),
    operation: Yup.string().required('Välj avdelning'),
    password: createAcc
      ? Yup.string().min(6, 'Minst 6 tecken!').required('Ange lösenord!')
      : Yup.string().min(6, 'Minst 6 tecken!').notRequired(),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const internalOptions = [
    {
      value: true,
      label: 'Intern',
    },
    {
      value: false,
      label: 'Extern',
    },
  ];

  const dispatch = useDispatch();

  const { operations } = useSelector((state) => state.operation);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (operations) {
      setActiveOperations(operations.filter((o) => o.is_active === true));
    }
  }, [operations]);

  useEffect(() => {
    if (resourceToEdit) {
      const {
        profile_id,
        profile,
        operation_id,
        resource_firstname,
        resource_lastname,
        resource_email,
        resource_internal,
        role,
        haveAccount,
      } = resourceToEdit;

      setInitialValues({
        email: resource_email,
        name: resource_firstname,
        lastname: resource_lastname,
        role: role,
        operation: resourceToEdit.operation?.is_active ? operation_id : '',
        haveAccount: haveAccount,
        changeEmail: false,
        newEmail: '',
        createAccount: false,
        createAccountByInvite: false,
        status: resourceToEdit.is_active ? status.ACTIVE : status.INACTIVE,
        emailForAccount: profile_id ? profile.profile_email : resource_email,
        internalResource: resource_internal,
        password: '',
      });
    }

    setShowAccount(false);
    setShowConnectedProjects(false);
  }, [resourceToEdit]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const empCopy = {
      ...values,
      changeEmail: values.newEmail !== '' ? true : false,
      resource_id: resourceToEdit.resource_id,
      profile_id: resourceToEdit.profile_id,

      status: status.ACTIVE === values.status ? true : false,
    };

    if (values.password && values.haveAccount) {
      const userValues = {
        email: values.emailForAccount,
        profileID: resourceToEdit.profile_id,
        password: values.password,
      };

      dispatch(updateUserPassword(userValues));
    }

    dispatch(editResource(empCopy, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getOperation(user.digituals_client_id));
      }, 500)
    );

    handleResetForm(resetForm);
    handleClose();
  };

  const handleFocus = (e) => {
    setActiveField(e.target.name);
  };

  const toggleShowAccount = () => setShowAccount(!showAccount);
  const toggleShowConnectedProjects = () =>
    setShowConnectedProjects(!showConnectedProjects);

  return (
    <>
      <MDBContainer>
        {initialValues !== '' ? (
          <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            initialValues={initialValues}
            validateOnChange={enableValidate}
            validateOnBlur={enableValidate}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => {
              return (
                <Form
                  noValidate
                  onSubmit={(e) => {
                    setEnableValidate(true);
                    e.preventDefault();
                    return handleSubmit();
                  }}
                  id="EditResourceForm"
                  className="text-secondary"
                >
                  <ModalIcon
                    icon={
                      values.haveAccount
                        ? icons.RESOURCE_WITH_ACCOUNT
                        : icons.RESOURCE
                    }
                    style={{ marginTop: '0%' }}
                  />

                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="validationFormik01">
                      <Form.Label className="text-secondary">
                        Förnamn
                      </Form.Label>
                      <Form.Control
                        placeholder="Förnamn..."
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isValid={touched.name && !errors.name}
                        isInvalid={!!errors.name}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="text-secondary">
                        Efternamn
                      </Form.Label>
                      <Form.Control
                        placeholder="Efternamn..."
                        type="text"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        isValid={touched.lastname && !errors.lastname}
                        isInvalid={!!errors.lastname}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.lastname}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label className="text-secondary">E-post</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="E-post..."
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                        autoComplete="new-password"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label className="text-secondary">
                        Avdelning
                      </Form.Label>

                      {activeOperations && (
                        <Field
                          component={CustomSelect}
                          placeholder="Välj avdelning..."
                          name="operation"
                          options={activeOperations.map((op) => {
                            return {
                              value: op.operation_id,
                              label: op.operation_name,
                            };
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isValid={touched.operation && !errors.operation}
                          isInvalid={!!errors.operation}
                        />
                      )}

                      <Form.Control.Feedback type="invalid">
                        {errors.operation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-4">
                    {internalOptions && (
                      <Form.Group as={Col}>
                        <Form.Label className="text-secondary">
                          Intern / extern
                        </Form.Label>

                        <Field
                          component={CustomSelect}
                          placeholder="Välj intern/extern..."
                          name="internalResource"
                          options={internalOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isValid={
                            touched.internalResource && !errors.internalResource
                          }
                          isInvalid={!!errors.internalResource}
                          isClearable
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.internalResource}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Row>

                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label className="text-secondary">Status</Form.Label>

                      {statusOptions && (
                        <Field
                          component={CustomSelect}
                          placeholder="Välj status..."
                          name="status"
                          options={statusOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isValid={touched.status && !errors.status}
                          isInvalid={!!errors.status}
                        />
                      )}

                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <CollapseSection
                    isOpen={showAccount}
                    label="Konto"
                    onClick={toggleShowAccount}
                    check={values.haveAccount}
                  >
                    {values.haveAccount ? (
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="validationFormik07">
                          <Form.Label className="text-text">
                            E-post för konto
                          </Form.Label>

                          <Form.Control
                            readOnly={values.haveAccount}
                            type="email"
                            placeholder="E-post..."
                            name="emailForAccount"
                            value={
                              activeField === 'newEmail' || values.newEmail
                                ? values.newEmail
                                : values.emailForAccount
                            }
                            onChange={handleChange}
                            isValid={
                              touched.emailForAccount && !errors.emailForAccount
                            }
                            isInvalid={!!errors.emailForAccount}
                            onFocus={handleFocus}
                            autoComplete="new-password"
                          />
                        </Form.Group>
                      </Row>
                    ) : (
                      <>
                        <Row className="mb-4 text-text">
                          <Form.Group as={Col}>
                            <Form.Check
                              label="Skapa användare med lösenord"
                              name="createAccount"
                              checked={values.createAccount}
                              value={values.createAccount}
                              onChange={handleChange}
                              type="checkbox"
                              onClick={() => {
                                values.createAccountByInvite = false;
                                setCreateAccByInvite(false);
                                return setCreateAcc(!createAcc);
                              }}
                            />
                          </Form.Group>
                        </Row>
                        {createAcc && (
                          <>
                            <Row className="mb-4">
                              <Form.Group as={Col}>
                                <Form.Label className="text-text">
                                  E-post för konto
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="E-post..."
                                  name="newEmail"
                                  value={
                                    activeField === 'newEmail' ||
                                    values.newEmail
                                      ? values.newEmail
                                      : values.email
                                  }
                                  onChange={handleChange}
                                  isValid={touched.newEmail && !errors.newEmail}
                                  isInvalid={!!errors.newEmail}
                                  onFocus={handleFocus}
                                  autoComplete="new-password"
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.newEmail}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-4 text-text">
                              <Form.Group as={Col}>
                                <Form.Label className="text-text">
                                  Lösenord för konto
                                </Form.Label>
                                <Form.Control
                                  placeholder="Lösenord..."
                                  type="password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  isValid={touched.password && !errors.password}
                                  isInvalid={!!errors.password}
                                  autoComplete="new-password"
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                          </>
                        )}
                        <Row className="mb-4 text-text">
                          <Form.Group as={Col}>
                            <Form.Check
                              label="Skapa användare via epostinbjudan"
                              name="createAccountByInvite"
                              checked={values.createAccountByInvite}
                              value={values.createAccountByInvite}
                              onChange={handleChange}
                              type="checkbox"
                              onClick={() => {
                                values.createAccount = false;
                                setCreateAccByInvite(!createAccByInvite);
                                return setCreateAcc(false);
                              }}
                            />
                          </Form.Group>
                        </Row>
                        {createAccByInvite && (
                          <Row className="mb-4">
                            <Form.Group as={Col}>
                              <Form.Label className="text-text">
                                Inbjudan skickas till
                              </Form.Label>
                              <Form.Control
                                readOnly
                                placeholder="E-post..."
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                // isValid={touched.password && !errors.password}
                                // isInvalid={!!errors.password}
                                // autoComplete="new-password"
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        )}
                      </>
                    )}
                  </CollapseSection>
                </Form>
              );
            }}
          </Formik>
        ) : null}

        {resourceToEdit && (
          <>
            <CollapseSection
              isOpen={showConnectedProjects}
              label="Kopplade projekt"
              onClick={toggleShowConnectedProjects}
            >
              <Row className="mb-4">
                <MDBContainer className="bg-none">
                  <ResourceProjectList
                    resourceToEdit={resourceToEdit}
                    mobile={mobile}
                  />
                </MDBContainer>
              </Row>
            </CollapseSection>
          </>
        )}
      </MDBContainer>
    </>
  );
};

export default EditResourceForm;
