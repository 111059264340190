/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../../redux/store/actions';

import { customerColumns, customerColumnsMobile } from './dataColumns';
import EditCustomerModal from './edit-customer-modal/EditCustomerModal';
import AddCustomerModal from './add-customer-modal/AddCustomerModal';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import moment from 'moment';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const CustomerTable = ({ mobile }) => {
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState('');
  const [rowData, setRowData] = useState([]);

  const [showInactiveCustomers, setShowInactiveCustomers] = useState(false);

  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getCustomer(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (customers) {
      createRowsForDatasets();
    }
  }, [customers, showInactiveCustomers]);

  const createRowsForDatasets = () => {
    const filtredCustomers = customers.filter(
      ({ is_active }) => is_active === true
    );

    const customersToMap = !showInactiveCustomers
      ? filtredCustomers
      : customers;

    const rows = customersToMap.map((customer) => {
      const {
        customer_name,
        is_active,
        customer_created_at,
        project,
        customer_description,
        customer_updated_at,
      } = customer;
      return {
        clickEvent: () => handleClickRow(customer),
        customerName: customer_name,
        status: is_active ? 'Aktiv' : 'Inaktiv',
        description: customer_description
          ? customer_description
          : 'Ingen beskrivning',
        numberOfProjects: project.length,
        createdAt: moment(customer_created_at).format('YYYY-MM-DD'),
        updatedAt: moment(customer_updated_at).format('YYYY-MM-DD'),
      };
    });

    setRowData(rows);
  };

  const handleClickRow = (c) => {
    setCustomerToEdit(c);
    setShowEditCustomerModal(true);
  };

  const handleToggleShowActive = () =>
    setShowInactiveCustomers(!showInactiveCustomers);

  if (customers) {
    customers.sort(function (a, b) {
      var nameA = a.customer_name.toUpperCase();
      var nameB = b.customer_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <div className="mt-0 mb-5">
      {rowData ? (
        <>
          {!mobile && (
            <ShowOrHideInactiveButton
              onClick={handleToggleShowActive}
              text={
                showInactiveCustomers
                  ? 'Göm inaktiva kunder'
                  : 'Visa inaktiva kunder'
              }
            />
          )}

          <ActiveDatatable
            mobile={mobile ? true : false}
            columns={mobile ? customerColumnsMobile : customerColumns}
            rows={rowData}
            add={() => setShowAddCustomerModal(true)}
            listKey={'CustomerTable'}
          />
        </>
      ) : (
        <Spinner animation="border" />
      )}

      <EditCustomerModal
        mobile={mobile ? true : false}
        show={showEditCustomerModal}
        customerToEdit={customerToEdit}
        handleClose={() => {
          setShowEditCustomerModal(false);
          setCustomerToEdit('');
        }}
      />
      <AddCustomerModal
        mobile={mobile ? true : false}
        handleClose={() => setShowAddCustomerModal(false)}
        show={showAddCustomerModal}
      />
    </div>
  );
};

export default CustomerTable;
