import React, { useRef } from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import { useReactToPrint } from 'react-to-print';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import { colors, pixels } from '../../constants';
import ChoosenFilters from '../print-components/ChoosenFilters';
import FloatingHoverButton from '../floating-hover-button';
import { ComponentToPrint } from '../component-to-print';
import DateUserTitle from '../print-components/DateUserTitle';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyLineChartAnalys = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  showlegend,
  dtick = 5,
  rangeValues = [0.9, 4.1],
  style = { marginLeft: '31px' },
  usePrint = false,
}) => {
  const { analysDataDefault } = useSelector((state) => state.analys);
  const { user } = useSelector((state) => state.auth);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={style}>
        <ComponentToPrint ref={componentRef}>
          <div className="justify-content-center flex-column align-items-center  rounded ">
            <div className="hide-when-printing">
              <PivotTableTitle title={title} />
              <div style={{ overflow: 'auto' }}>
                <ReactPivotTable
                  data={analysDataDefault}
                  rows={preSelectedRows}
                  cols={preSelectedCols}
                  aggregatorName={aggregatorName}
                  vals={vals}
                  rendererName="Line Chart"
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                  plotlyOptions={{
                    width: pixels.ANALYS_PLOTLY_WIDTH,
                    colorway: colors.colorway,
                    minHeight: 500,

                    title: '',
                    hoverlabel: {
                      bgcolor: '#FFF',
                      namelength: 1000,
                      font: {
                        color: '#000',
                      },
                    },

                    showlegend: showlegend ? true : false,
                    yaxis: {
                      title: '',
                      autorange: true,
                      // range: rangeValues,
                      // fixedrange: true,
                      gridwidth: 2,
                      gridcolor: 'transparent',
                      dtick: 0.2,
                      showline: false,
                      linewidth: 20,
                      linecolor: 'rgb(204,204,204)',
                    },
                    grid: {
                      xgap: 0,
                      pattern: 'independent',
                    },

                    xaxis: {
                      gridwidth: 0,
                      automargin: 'center',
                      gridcolor: 'transparent',
                      autorange: true,
                      // range: [1, 10],

                      rangeslider: {
                        range: [1, 2],
                        bgcolor: colors.DASHBOARD,
                        thickness: 0.02,
                        borderwidth: 2,
                        bordercolor: colors.PRIMARY,
                      },
                      type: 'category',
                    },
                  }}
                  plotlyConfig={{ responsive: true }}
                  valueFilter={data ? data.valueFilter : {}}
                />
              </div>
            </div>
            <div className="show-when-printing">
              <DateUserTitle user={user} title={title} />
              <ReactPivotTable
                data={analysDataDefault}
                rows={preSelectedRows}
                cols={preSelectedCols}
                aggregatorName={aggregatorName}
                vals={vals}
                rendererName="Line Chart"
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                plotlyOptions={{
                  width: pixels.ANALYS_PLOTLY_WIDTH,
                  colorway: [
                    '#60909a',
                    '#b5cfd5',
                    '#558089',
                    '#d2e2e6',
                    '#4a7078',

                    '#c3d9dd',
                    '#88b3bc',
                    '#406067',
                    '#a6c6cd',
                    '#355056',
                    '#97bcc4',

                    '#6ba0ac',
                    '#79a9b4',
                  ],
                  minHeight: 500,

                  title: '',

                  showlegend: showlegend ? true : false,
                  yaxis: {
                    title: '',
                    autorange: true,
                    // range: rangeValues,
                    // fixedrange: true,
                    gridwidth: 2,
                    gridcolor: 'transparent',
                    dtick: 0.2,
                    showline: false,
                    linewidth: 20,
                    linecolor: 'rgb(204,204,204)',
                  },
                  grid: {
                    xgap: 0,
                    pattern: 'independent',
                  },

                  xaxis: {
                    gridwidth: 0,
                    automargin: 'center',
                    gridcolor: 'transparent',
                    autorange: true,
                  },
                }}
                plotlyConfig={{ responsive: true }}
                valueFilter={data ? data.valueFilter : {}}
              />
            </div>
          </div>
          <div className="page-break" />
          <div className="show-when-printing d-felx align-items-center justify-content-center">
            <ChoosenFilters
              data={data ? data.valueFilter : {}}
              defaultData={analysDataDefault}
            />
          </div>
        </ComponentToPrint>
      </div>
      {usePrint && (
        <>
          <FloatingHoverButton
            hoverText="Skriv ut graf"
            icon="print"
            onClick={() => handlePrint()}
            top="82vh"
          />
        </>
      )}
    </>
  );
};

export default PlotlyLineChartAnalys;
