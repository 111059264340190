import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as WebDataRocksReact from 'react-webdatarocks';
import 'webdatarocks/webdatarocks.css';
import { colors, fontFamily, pivotFilter } from '../../constants';
import AddModal from '../add-modal';
import LoadingPage from '../loading';
import SaveDataForm from './SaveDataForm';

export default function WebDataRocksPivot({ data }) {
  const [tableData, setTableData] = useState([]);
  const [saveDataModalOpen, setSaveDataModalOpen] = useState(false);

  const webdatarockRef = useRef();

  const {
    PROJECT,
    PROJECT_ACTIVE,
    PROJECT_START,
    CUSTOMER,
    CUSTOMER_ACTIVE,
    USER_ACCOUNT,
    USER_ACCOUNT_ACTIVE,
    REPORTER,
    REPORTER_ACTIVE,
    QUESTION,
    QUESTION_ACTIVE,
    RESPONSE_DATE,
    RESPONSE_YEAR,
    RESPONSE_WEEK,
    RESPONSE_QUARTER,
    RESPONSE_MONTH,
    PROJECT_START_YEAR,
    PROJECT_START_WEEK,
    PROJECT_START_QUARTER,
    PROJECT_START_MONTH,
    PROJECT_START_YEAR_WEEK,
    PROJECT_START_YEAR_QUARTER,
    PROJECT_START_YEAR_MONTH,
    RESPONSE_YEAR_MONTH,
    RESPONSE_YEAR_QUARTER,
    RESPONSE_YEAR_WEEK,
    DAYS_FROM_PROJECT_START,
    WEEKS_FROM_PROJECT_START,
    RESPONSE,
  } = pivotFilter;

  useEffect(() => {
    if (data.length > 0) {
      data.forEach((val) => {
        valuesForPivot.push({
          [PROJECT]: val.project,
          [PROJECT_ACTIVE]: val.project_active,
          [PROJECT_START]: val.project_start,
          [CUSTOMER]: val.customer,
          [CUSTOMER_ACTIVE]: val.customer_active,
          [USER_ACCOUNT]: val.user_account,
          [USER_ACCOUNT_ACTIVE]: val.user_account_active,
          [REPORTER]: val.reporter,
          [REPORTER_ACTIVE]: val.reporter_active,
          [QUESTION]: val.question,
          [QUESTION_ACTIVE]: val.question_active,
          [RESPONSE_DATE]: val.response_date,
          [RESPONSE_YEAR]: val.response_year,
          [RESPONSE_WEEK]: val.response_week,
          [RESPONSE_QUARTER]: val.response_quarter,
          [RESPONSE_MONTH]: val.response_month,
          [PROJECT_START_YEAR]: val.projectstart_year,
          [PROJECT_START_WEEK]: val.projectstart_week,
          [PROJECT_START_QUARTER]: val.projectstart_quarter,
          [PROJECT_START_MONTH]: val.projectstart_month,
          [PROJECT_START_YEAR_WEEK]: val.projectstart_yearweek,
          [PROJECT_START_YEAR_QUARTER]: val.projectstart_yearquarter,
          [PROJECT_START_YEAR_MONTH]: val.projectstart_yearmonth,
          [RESPONSE_YEAR_MONTH]: val.response_yearmonth,
          [RESPONSE_YEAR_QUARTER]: val.response_yearquarter,
          [RESPONSE_YEAR_WEEK]: val.response_yearweek,
          [DAYS_FROM_PROJECT_START]: val.response_days_from_project_start,
          [WEEKS_FROM_PROJECT_START]: val.response_weeks_from_project_start,
          [RESPONSE]: val.response,
        });
      });

      setTableData(valuesForPivot);
    }
  }, [data]);

  function customizeToolbar(toolbar) {
    var tabs = toolbar.getTabs(); // get all tabs from the toolbar

    const savedTabs = tabs.filter((tab) => tab.id !== 'wdr-tab-connect');

    const exportMenu = savedTabs[2].menu.filter(
      (menu) => menu.id !== 'wdr-tab-export-html'
    );

    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1]; // delete the first tab
      delete tabs[2]; // delete the first tab
      delete tabs[3]; // delete the first tab
      delete tabs[4]; // delete the first tab
      delete tabs[5]; // delete the first tab
      delete tabs[6]; // delete the first tab
      delete tabs[7]; // delete the first tab

      tabs.unshift(
        {
          id: savedTabs[0].id,
          // title: 'Öppna',
          title: savedTabs[0].title,
          handler: savedTabs[0].menu[0].handler,
          icon: savedTabs[0].icon,
        },
        {
          id: savedTabs[1].id,
          // title: 'Spara',
          title: savedTabs[1].title,
          handler: handleSaveTab,
          icon: savedTabs[1].icon,
        },
        {
          id: savedTabs[2].id,
          // title: 'Exportera',
          title: savedTabs[2].title,
          menu: exportMenu,
          mobile: savedTabs[2].mobile,
          icon: savedTabs[2].icon,
        },
        {
          id: savedTabs[3].id,
          // title: 'Format',
          title: savedTabs[3].title,
          menu: savedTabs[3].menu,
          icon: savedTabs[3].icon,
          rightGroup: savedTabs[3].rightGroup,
        },
        {
          id: savedTabs[4].id,
          // title: 'Inställningar',
          title: savedTabs[4].title,
          handler: savedTabs[4].handler,
          icon: savedTabs[4].icon,
          rightGroup: savedTabs[4].rightGroup,
        },
        {
          id: savedTabs[5].id,
          // title: 'Fält',
          title: savedTabs[5].title,
          handler: savedTabs[5].handler,
          icon: savedTabs[5].icon,
          rightGroup: savedTabs[5].rightGroup,
        },
        {
          id: savedTabs[6].id,
          // title: 'Helskärm',
          title: savedTabs[6].title,
          handler: savedTabs[6].handler,
          icon: savedTabs[6].icon,
          rightGroup: savedTabs[6].rightGroup,
        }
      );

      return tabs;
    };
  }

  function handleSaveTab() {
    webdatarockRef.current.webdatarocks.save({
      filename: `analysvy-${moment().format()}.json`,
    });
    // setSaveDataModalOpen(true);
  }

  function handeOnSubmit(filename) {
    // webdatarockRef.current.webdatarocks.save({
    //   filename: `${filename}.json`,
    // });
  }

  return (
    <div>
      <AddModal
        show={saveDataModalOpen}
        handleClose={() => setSaveDataModalOpen(false)}
        fullscreen={false}
        submitForm={'SaveDataForm'}
        header="Ange filnamn"
        minHeight="20vh"
        centered={true}
      >
        <SaveDataForm
          handleClose={() => setSaveDataModalOpen(false)}
          onSubmit={handeOnSubmit}
        />
      </AddModal>

      {tableData.length > 0 ? (
        <WebDataRocksReact.Pivot
          ref={webdatarockRef}
          beforetoolbarcreated={customizeToolbar}
          componentFolder=""
          toolbar={true}
          width="100%"
          report={{
            dataSource: {
              dataSourceType: 'json',
              data: tableData,
            },

            slice: {
              reportFilters: [
                {
                  uniqueName: RESPONSE_YEAR_QUARTER,
                },
              ],
              rows: [
                {
                  uniqueName: CUSTOMER,
                },
                {
                  uniqueName: PROJECT,
                },
                {
                  uniqueName: QUESTION,
                },
              ],
              columns: [
                {
                  uniqueName: 'Measures',
                },
                {
                  uniqueName: RESPONSE_YEAR_WEEK,
                },
              ],
              measures: [
                {
                  uniqueName: RESPONSE,
                  aggregation: 'average',
                  format: '56amlh62',
                },
              ],
            },
            options: {
              grid: {
                showTotals: 'off',
                showGrandTotals: 'off',
              },
            },
            conditions: [
              {
                formula: '#value < 2.5',
                format: {
                  backgroundColor: colors.pivottable.RED,
                  color: '#000000',
                  fontFamily: fontFamily.PRIMARY,
                  fontSize: '12px',
                },
              },
              {
                formula: 'AND(#value > 2.5, #value < 2.99)',
                format: {
                  backgroundColor: colors.pivottable.LIGHT_RED,
                  color: '#000000',
                  fontFamily: fontFamily.PRIMARY,
                  fontSize: '12px',
                },
              },
              {
                formula: 'AND(#value > 2.99, #value < 3.4)',
                format: {
                  backgroundColor: colors.pivottable.YELLOW,
                  color: '#000000',
                  fontFamily: fontFamily.PRIMARY,
                  fontSize: '12px',
                },
              },
              {
                formula: 'AND(#value > 3.399, #value < 3.7)',
                format: {
                  backgroundColor: colors.pivottable.LIGHT_GREEN,
                  color: '#000000',
                  fontFamily: fontFamily.PRIMARY,
                  fontSize: '12px',
                },
              },
              {
                formula: '#value > 3.699',
                format: {
                  backgroundColor: colors.pivottable.GREEN,
                  color: '#000000',
                  fontFamily: fontFamily.PRIMARY,
                  fontSize: '12px',
                },
              },
            ],

            formats: [
              {
                name: '',
                maxDecimalPlaces: 2,
                textAlign: 'right',
              },
              {
                name: '56amlh62',
                thousandsSeparator: ' ',
                decimalSeparator: '.',
                decimalPlaces: 2,
                currencySymbol: '',
                currencySymbolAlign: 'left',
                nullValue: '',
                textAlign: 'right',
                isPercent: false,
              },
            ],
          }}
        />
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

const valuesForPivot = [
  {
    [pivotFilter.PROJECT]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_ACTIVE]: {
      type: 'boolean',
    },
    [pivotFilter.PROJECT_START]: {
      type: 'date',
    },
    [pivotFilter.CUSTOMER]: {
      type: 'string',
    },
    [pivotFilter.CUSTOMER_ACTIVE]: {
      type: 'boolean',
    },
    [pivotFilter.USER_ACCOUNT]: {
      type: 'string',
    },

    [pivotFilter.REPORTER]: {
      type: 'string',
    },
    [pivotFilter.REPORTER_ACTIVE]: {
      type: 'boolean',
    },
    [pivotFilter.QUESTION]: {
      type: 'string',
    },
    [pivotFilter.QUESTION_ACTIVE]: {
      type: 'boolean',
    },

    [pivotFilter.RESPONSE_DATE]: {
      type: 'date',
    },
    [pivotFilter.RESPONSE_YEAR]: {
      type: 'string',
    },
    [pivotFilter.RESPONSE_MONTH]: {
      type: 'string',
    },
    [pivotFilter.RESPONSE_WEEK]: {
      type: 'string',
    },
    [pivotFilter.RESPONSE_QUARTER]: {
      type: 'string',
    },

    [pivotFilter.PROJECT_START_YEAR]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_WEEK]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_QUARTER]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_MONTH]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_YEAR_WEEK]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_YEAR_QUARTER]: {
      type: 'string',
    },
    [pivotFilter.PROJECT_START_YEAR_MONTH]: {
      type: 'string',
    },

    [pivotFilter.RESPONSE_YEAR_MONTH]: {
      type: 'string',
    },

    [pivotFilter.RESPONSE_YEAR_QUARTER]: {
      type: 'string',
    },

    [pivotFilter.RESPONSE_YEAR_WEEK]: {
      type: 'string',
    },

    [pivotFilter.DAYS_FROM_PROJECT_START]: {
      type: 'number',
    },

    [pivotFilter.WEEKS_FROM_PROJECT_START]: {
      type: 'number',
    },

    [pivotFilter.RESPONSE]: {
      type: 'number',
    },
  },
];
