import { sendToast } from '../components/toast';
import { supabase } from '../utils/supabase';
import Parse from '../utils/parse';
import toast from 'react-hot-toast';
// import onAuthStateChange from '../utils/authFunctions';

const registerUser = async ({ email, password, name, lastname }) => {
  try {
    const { data: createUserData, error: createUserError } =
      await Parse.Cloud.run('createUser', {
        email: email,
        password: password,
        SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
      });

    if (createUserData) {
    }

    if (createUserError) {
      console.log('createUserError: ', createUserError);
    }

    // let { user, error } = await supabase.auth.signUp({
    //   email: email,
    //   password: password,
    // });

    // if (user) {
    //   console.log('user: ', user);
    // }

    // if (error) {
    //   console.log('error: ', error);
    // }

    // return { isAuth: true, user: userProfile };
  } catch (error) {
    console.log('error: ', error);
    return { error: error.message };
  }
};

const loginUser = async ({ email, password }) => {
  try {
    const notification = toast.loading('Loggar in...');
    let { user, error } = await supabase.auth.signIn({
      email: email,
      password: password,
    });

    if (user) {
      let { data: resource } = await supabase
        .from('resource')
        .select(
          `
      *,
      operation (
        * 
      ),
      profile (
        *
      )
    `
        )
        .eq('profile_id', user.id);

      if (resource[0]) {
        const { is_active, profile_reporter, profile_role } =
          resource[0].profile;

        if (
          (is_active === true &&
            profile_reporter === true &&
            profile_role === 2) ||
          (is_active === true && profile_role === 1) ||
          profile_role === 3
        ) {
          let { data: digitualsClient, error: digitualsClientError } =
            await supabase
              .from('_digituals_client')
              .select('*')
              .eq('digituals_client_id', resource[0].digituals_client_id);

          if (digitualsClientError) {
            console.log('digitualsClientError: ', digitualsClientError);
          }
          if (digitualsClient) {
            const clientUser = {
              ...resource[0],
              _digituals_client: digitualsClient[0],
            };

            console.log('error: ', error);
            toast.success(`Loggas in som ${email}!`, {
              id: notification,
            });

            return { isAuth: true, user: clientUser };
          } else {
            toast.error('Fel vid inloggning', {
              id: notification,
            });
            logoutUser();

            return { isAuth: false, user: null };
          }
        } else {
          logoutUser();

          const toastText = 'Ditt konto är inaktiverat!';

          toast.error(toastText, {
            id: notification,
          });

          return { isAuth: false, user: null };
        }
      }
    }

    if (error) {
      console.log('error: ', error);
      const { status } = error;

      if (status === 400) {
        const toastText = 'Fel inloggningsuppgifter vänligen försök igen!';
        toast.error(toastText, {
          id: notification,
        });
      }
      if (status === 422) {
        const toastText = 'Invalid e-post vänligen försök igen!';

        toast.error(toastText, {
          id: notification,
        });
      }
      if (status === 429) {
        const toastText = 'För många försök, kontot är låst i 60 sekunder!';

        toast.error(toastText, {
          id: notification,
        });
      }

      return { isAuth: false, user: null };
    }
  } catch (error) {
    console.log('error: ', error);
    return { error: error.message };
  }
};

// export function onAuthStateChange(client, callback(event, session)) {
//   document.addEventListener('DOMContentLoaded', function() {
//       if (client.auth.session()) {
//           callback('SIGNED_IN', client.auth.session());
//       }
//   });

//   client.auth.onAuthStateChange((event, session) => {
//       callback(event, session);
//   });
// }

const autoSignIn = (session) =>
  new Promise(async (resolve, reject) => {
    const session = supabase.auth.session();

    if (session) {
      let { data: resource, error } = await supabase
        .from('resource')
        .select(
          `
      *,
      operation (
        * 
      ),
      profile (
        *
      )

    
        
    `
        )
        .eq('profile_id', session.user.id);

      if (resource[0]) {
        let { data: digitualsClient, error: digitualsClientError } =
          await supabase
            .from('_digituals_client')
            .select('*')
            .eq('digituals_client_id', resource[0].digituals_client_id);

        if (digitualsClientError) {
          console.log('digitualsClientError: ', digitualsClientError);
        }
        if (digitualsClient) {
          const clientUser = {
            ...resource[0],
            _digituals_client: digitualsClient[0],
          };

          console.log('error: ', error);
          resolve({ isAuth: true, user: clientUser });
        } else {
          resolve({ isAuth: false, user: null });
        }
      }
    } else {
      resolve({ isAuth: false, user: null });
    }
  });
// const autoSignIn = () =>
//   new Promise(async (resolve, reject) => {
//     const session = supabase.auth.session();

//     if (session) {
//       let { data: resource, error } = await supabase
//         .from('resource')
//         .select(
//           `
//       *,
//       operation (
//         *
//       ),
//       profile (
//         *
//       )

//     `
//         )
//         .eq('profile_id', session.user.id);

//       if (resource[0]) {
//         let { data: digitualsClient, error: digitualsClientError } =
//           await supabase
//             .from('_digituals_client')
//             .select('*')
//             .eq('digituals_client_id', resource[0].digituals_client_id);

//         if (digitualsClientError) {
//           console.log('digitualsClientError: ', digitualsClientError);
//         }
//         if (digitualsClient) {
//           const clientUser = {
//             ...resource[0],
//             _digituals_client: digitualsClient[0],
//           };

//           console.log('error: ', error);
//           resolve({ isAuth: true, user: clientUser });
//         } else {
//           resolve({ isAuth: false, user: null });
//         }
//       }
//     } else {
//       resolve({ isAuth: false, user: null });
//     }
//   });

const logoutUser = async () => {
  try {
    let { error } = await supabase.auth.signOut();

    console.log('error: ', error);
  } catch (error) {
    console.log('error: ', error);
  }
};

const updateProfile = async (user, isEmailChanged, digitualsClientID) => {
  const { name, lastname, password, email, resource_id, profile_id } = user;

  const userToReturn = {
    ...user,
    resource_firstname: name,
    resource_lastname: lastname,
    resource_email: email,
  };

  try {
    const { data: resourceData, error: resourceError } = await supabase
      .from('resource')
      .update({
        resource_firstname: name,
        resource_lastname: lastname,
        resource_email: email,
      })
      .eq('resource_id', resource_id)
      .eq('digituals_client_id', digitualsClientID);

    if (resourceError) {
      console.log('updateProfileError:', resourceError);
    }

    if (isEmailChanged === true) {
      const { user: userData, error: userError } = await supabase.auth.update(
        password
          ? {
              email: email,
              password: password,
            }
          : {
              email: email,
            }
      );

      if (userData) {
        const { error } = await supabase
          .from('profile')
          .update({ profile_email: email })
          .eq('profile_id', profile_id)
          .eq('digituals_client_id', digitualsClientID);

        if (error) {
          console.log('updateProfileError:', error);
        }

        const toastText = `E-post för konto ${name} ${lastname} ändrad, bekräftelse mejl skickat till ${email} ! `;

        sendToast(toastText, 1);
      }
      if (userError) {
        const { message } = userError;
        console.log('userError:', userError);

        const toastText = `${message}!`;

        sendToast(toastText, 2);
      }

      return userToReturn;
    } else {
      if (password) {
        const { error: userError } = await supabase.auth.update({
          password: password,
        });

        if (userError) {
          const { message } = userError;
          console.log('userError:', userError);

          const toastText = `${message}!`;

          sendToast(toastText, 2);
        }
      }

      if (resourceData) {
        const toastText = `${name} ${lastname}  redigerad!`;

        let { data: resource } = await supabase
          .from('resource')
          .select(
            `
      *,
      operation (
        * 
      ),
      profile (
        *
      )
    `
          )
          .eq('profile_id', resourceData[0].profile_id);

        sendToast(toastText, 1);

        return resource[0];
      }
    }
  } catch (error) {
    console.log('error:', error);
  }
};

const resetPassword = async (email) => {
  const notification = toast.loading('Skickar e-post....');

  try {
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email
      // {
      //   redirectTo: 'http://localhost:3000/',
      // }
    );

    if (data) {
      const toastText = `Återställing av lösenord skickad till e-post:${email}!`;

      toast.success(toastText, {
        id: notification,
      });
    }
    if (error) {
      console.log('error:', error);
      toast.error(error.message, {
        id: notification,
      });
    }
  } catch (error) {
    console.log('error:', error);
  }
};

const updateUserPassword = async ({ email, profileID, password }) => {
  try {
    const { data: updateUserPasswordData, error: updateUserPasswordError } =
      await Parse.Cloud.run('updateUserPassword', {
        profileID: profileID,
        password: password,
        SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
      });

    if (updateUserPasswordData) {
      const toastText = `Lösenord ändrat för e-post:${email}!`;

      sendToast(toastText, 1);
    }
    if (updateUserPasswordError) {
      console.log('error:', updateUserPasswordError);
      const { status } = updateUserPasswordError;

      if (status === 422) {
        const toastText = `Lösenordet måsta vara minst 6 tecken!`;

        sendToast(toastText, 2);
      }
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export {
  registerUser,
  loginUser,
  autoSignIn,
  logoutUser,
  updateProfile,
  resetPassword,
  updateUserPassword,
};
