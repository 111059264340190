/* eslint-disable import/no-anonymous-default-export */
import {
  POST_RESPONSE,
  GET_RESPONSE,
  DELETE_RESPONSE,
  EDIT_RESPONSE,
} from '../types';

const INITIAL_STATE = {
  response: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_RESPONSE:
      return { ...state };
    case GET_RESPONSE:
      return { ...state, response: action.payload };
    case DELETE_RESPONSE:
      return { ...state };
    case EDIT_RESPONSE:
      return { ...state };
    default:
      return state;
  }
}
