import { MDBTypography } from 'mdbreact';
import React from 'react';
import Plot from 'react-plotly.js';
import { colors, fontFamily } from '../../constants';

const SIZE = 170;

function PlotlyPieChart({
  label = 'Label',
  avrageValue = 'AvgValue',
  values = [1, 1, 1, 1],
  style,
}) {
  return (
    <div
      className="d-flex align-items-center justify-content-start flex-column"
      style={{ height: SIZE, width: SIZE, ...style }}
    >
      <Plot
        data={[
          {
            hoverinfo: 'none',
            type: 'pie',
            values: values,
            automargin: true,
            showlegend: false,
            textinfo: 'none',
            marker: {
              // colors: ['#F79B85', '#FACDC8', '#A1ECAF', '#44935A'],
              colors: [
                colors.pivottable.RED,
                colors.pivottable.LIGHT_RED,
                colors.pivottable.LIGHT_GREEN,
                colors.pivottable.GREEN,
              ],
            },
          },
        ]}
        layout={{
          height: SIZE,
          width: SIZE,
          title: {
            font: {
              family: fontFamily.PRIMARY,
              size: 40,

              color: 'black',
            },
            text: avrageValue || '-',

            y: 0.65,
            x: 0.5,
            xanchor: 'center',
            yanchor: 'middle',
            showlegend: false,
          },

          paper_bgcolor: 'transparent',
          margin: { t: 0, b: 0, l: 0, r: 0 },
        }}
        config={{ displayModeBar: false }}
      />
      <MDBTypography
        style={{ marginTop: -85, fontSize: 14, zIndex: 100 }}
        className="d-flex  text-black text-center mx-3 flex-column"
      >
        {label.split('\n').map((paragraph) => {
          return <div>{paragraph}</div>;
        })}
      </MDBTypography>
    </div>
  );
}

export default PlotlyPieChart;
