import React from 'react';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';
import FAB from '../../../components/floating-action-button';

const ActiveDatatable = ({ columns, rows, add, mobile, noAddBtn, listKey }) => {
  return (
    <>
      {/* <div style={{ overflow: 'auto' }}> */}
      <MDBDataTableV5
        key={listKey}
        btn
        // small
        small={mobile ? true : false}
        // style={{ marginBottom: 40 }}
        searchLabel="Sök..."
        entriesLabel="Per sida"
        infoLabel={['', '-', 'av', '']}
        barReverse
        searchTop
        searchBottom={false}
        responsive
        hover
        entriesOptions={[5, 10, 20, 30, 50, 100]}
        entries={10}
        pagesAmount={4}
        data={{
          columns: columns,
          rows: rows,
        }}
      />
      {mobile ? (
        <>{noAddBtn ? null : <FAB icon={'plus'} onClick={add} />}</>
      ) : (
        <div style={{ cursor: 'pointer', marginLeft: 22, marginTop: -55 }}>
          {noAddBtn ? null : (
            <MDBIcon
              icon="plus-circle"
              className="align-self-start text-primary"
              onClick={add}
              size="2x"
            />
          )}
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default ActiveDatatable;
