import React from 'react';

import QuestionsTable from './QuestionsTable';

const AdminQuestions = () => {
  // const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);

  // const handleOpenQuestionModal = (bool) => {
  //   setShowAddQuestionModal(bool);
  // };

  return (
    <>
      {/* <NavigationBar /> */}
      <div className=" ">
        {/* <div className="d-flex align-items-center  justify-content-center flex-column mb-5"> */}
        <QuestionsTable />
      </div>
    </>
  );
};

export default AdminQuestions;
