import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { Provider } from 'react-redux';

import RoutesComponent from './routes';

import ReduxStore from './redux/store/index';
import { Toaster } from 'react-hot-toast';

const App = () => {
  // const [currentTheme, setCurrentTheme] = useState('client-theme-skin');

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  return (
    <>
      <Provider store={ReduxStore()}>
        {/* <MDBContainer fluid className={currentTheme}> */}
        <RoutesComponent />
        {/* </MDBContainer> */}
        {/* <ToastsComponent /> */}
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              iconTheme: {
                primary: '#3e8d63',
                secondary: 'white',
              },
            },
            error: {
              iconTheme: {
                primary: '#d62518',
                secondary: 'white',
              },
            },
          }}
        />
      </Provider>

      {/* <div className="text-center">
        <MDBBtn
          color="light"
          onClick={() => setCurrentTheme('client-theme-skin')}
        >
          Light theme
        </MDBBtn>
        <MDBBtn color="dark" onClick={() => setCurrentTheme('my-theme-skin')}>
          Dark theme
        </MDBBtn>
      </div> */}
    </>
  );
};

export default App;
