import React, { useState, useEffect } from 'react';

import NavigationBar from '../../../components/navbar';
import ResourcesTable from './ResourcesTable';
import AdminResourcesMobile from './resources-mobile';
import OperationTable from '../operation/OperationTable';
import AccountTable from '../account/AccountTable';

import TopTabNavsDesktop from '../../../components/top-tabs-navs-desktop';

const AdminResources = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  const tabs = [
    {
      name: 'Resurser',
      content: <ResourcesTable />,
    },

    {
      name: 'Avdelningar',
      content: (
        <>
          <OperationTable />
        </>
      ),
    },
    {
      name: 'Konton',
      content: (
        <>
          <AccountTable />
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <NavigationBar />

      {currentWindowSize < pixelBreakPoint ? (
        <AdminResourcesMobile />
      ) : (
        <TopTabNavsDesktop tabs={tabs} />
      )}
    </>
  );
};

export default AdminResources;
