import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editOperation,
  deleteOperation,
  inactivateOperation,
  getOperation,
} from '../../../../redux/store/actions';

import { MDBContainer } from 'mdb-react-ui-kit';
import EditModal from '../../../../components/edit-modal';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditOperationForm from './EditOperationForm';
import ConnectedResourceList from './ConnectedResourceList';
import CollapseSection from '../../../../components/collapse-section';
import { pixels } from '../../../../constants';

const EditOperationModal = ({ handleClose, show, operationToEdit, mobile }) => {
  const [showConnectedResources, setShowConnectedResources] = useState(false);
  const [operation, setOperation] = useState('');
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [operationToDelete, setOperationToDelete] = useState('');
  const [operationToInactivate, setOperationToInactivate] = useState('');

  const [fromDelete, setFromDelete] = useState(false);

  const confirmDeleteTitle = 'ta bort fråga';
  const confirmDeleteMessage = operationToDelete
    ? `Är du säker att du vill ta bort avdelning ${operationToDelete.operation_name}?`
    : '';
  const confirmInactivationTitle = 'inaktivera avdelning';
  const confirmInactivationMessage = operationToInactivate
    ? `Är du säker att du vill inaktivera avdelning ${operationToInactivate.operation_name}?`
    : '';
  const confirmInactivationMessageFromDelete = operationToInactivate
    ? `Avdelning ${operationToInactivate.operation_name} har kopplingar till annan data och går inte att ta bort, vill du inaktivera avdelningen istället?`
    : '';

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setOperation(operationToEdit);
  }, [operationToEdit]);

  const handleEditOperation = () => {
    dispatch(editOperation(operation, user.digituals_client_id)).then(() =>
      dispatch(getOperation(user.digituals_client_id))
    );
    handleClose();
  };

  const handleDeleteOperation = (c) => {
    dispatch(deleteOperation(c, user.digituals_client_id)).then(
      ({ payload }) => {
        dispatch(getOperation(user.digituals_client_id));
        if (!payload) return;
        const { connected } = payload;
        if (connected) {
          setFromDelete(true);

          setShowInactivateModal(true);
        }
      }
    );
    setShowDeleteModal(false);
  };
  const handleInactivateOperation = (c) => {
    dispatch(inactivateOperation(c, user.digituals_client_id)).then(() =>
      dispatch(getOperation(user.digituals_client_id))
    );
    setShowInactivateModal(false);
  };

  const toggleShowConnectedResources = () =>
    setShowConnectedResources(!showConnectedResources);

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteOperation(operationToDelete)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() =>
          handleInactivateOperation(operationToInactivate)
        }
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />

      <EditModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        done={handleEditOperation}
        inactivate={() => {
          setOperationToInactivate(operation);
          setFromDelete(false);
          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setOperationToInactivate(operation);
          setOperationToDelete(operation);
          setShowDeleteModal(true);
        }}
        submitForm={'EditOperationForm'}
        header="Redigera avdelning"
      >
        <MDBContainer
          className="mb-5 bg-transparent"
          style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
        >
          {operation ? (
            <>
              <EditOperationForm
                handleClose={handleClose}
                opertaionToEdit={operation}
              />

              <CollapseSection
                isOpen={showConnectedResources}
                label="Länkade resurser"
                onClick={toggleShowConnectedResources}
              >
                <ConnectedResourceList
                  operationToEdit={operation}
                  mobile={mobile}
                />
              </CollapseSection>
            </>
          ) : null}
        </MDBContainer>
      </EditModal>
    </>
  );
};

export default EditOperationModal;
