/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getReporters } from '../../../redux/store/actions';
import { projectStatusColumns } from './dataColumns';

import moment from 'moment';
import ActiveDatatable from '../../../components/datatables/active-datatable';

import 'moment/locale/sv';
import 'moment-timezone';

import momentTime from '../../../utils/moment';

const ProjectStatusTable = ({ mobile }) => {
  const [activeReporters, setActiveReporters] = useState('');

  const [rows, setRows] = useState('');

  const dispatch = useDispatch();

  const { reporters } = useSelector((state) => state.resources);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getReporters(user.digituals_client_id));
    }

    // data();
  }, []);

  useEffect(() => {
    if (reporters) {
      setActiveReporters(
        reporters.filter((rep) => rep.project_setup.project.is_active === true)
      );
    }
  }, [reporters]);

  useEffect(() => {
    if (activeReporters) {
      createRowsForDatasets();
    }
  }, [activeReporters]);

  // const data = async () => {
  //   let {
  //     data: glbCurrentQuestionnaireData,
  //     error: glbCurrentQuestionnaireError,
  //   } = await supabase.from('v_output_dashboard').select('*');
  //   // // .eq('digituals_client_id', user.digituals_client_id);

  //   if (glbCurrentQuestionnaireError) {
  //     console.log(glbCurrentQuestionnaireError);
  //   }
  //   if (glbCurrentQuestionnaireData) {
  //     console.log('glbCurrentQuestionnaireData:', glbCurrentQuestionnaireData);
  //   }
  // };

  const createRowsForDatasets = () => {
    const rows = activeReporters.map((reporter) => {
      const { response } = reporter;
      const { project_name, project_number } = reporter.project_setup.project;
      const { resource_firstname, resource_lastname, resource_email } =
        reporter.profile.resource[0];

      const sortedResponse = response.sort(function (a, b) {
        const date1 = new Date(a.response_created_at);
        const date2 = new Date(b.response_created_at);

        return date2 - date1;
      });

      let daysFromLastResponse = '';

      if (sortedResponse.length > 0) {
        const today = moment().format();
        const lastResponseDate = sortedResponse[0].response_created_at;

        const todayFormat = moment(today, 'YYYY-MM-DD');
        const responseDate = moment(lastResponseDate, 'YYYY-MM-DD');

        const days = moment.duration(todayFormat.diff(responseDate)).asDays();

        daysFromLastResponse = parseInt(days.toFixed(0));
      }

      return {
        projectName: project_name,
        projectNumber: project_number,
        reporter: `${resource_firstname} ${resource_lastname} (${resource_email})`,
        daysFromLastResponse: daysFromLastResponse,
        time: sortedResponse[0]
          ? momentTime(sortedResponse[0].response_created_at, 'LLL')
          : 'Ingen respons lämnad',

        numberOfResponses: sortedResponse
          ? sortedResponse.length
          : 'Ingen respons lämnad',
        timestamp: sortedResponse[0]
          ? sortedResponse[0].response_created_at
          : '',
      };
    });

    rows.sort(
      (a, b) =>
        (b.daysFromLastResponse !== '' ? b.daysFromLastResponse : -1) -
        (a.daysFromLastResponse !== '' ? a.daysFromLastResponse : -1)
    );

    setRows(rows);
  };

  return (
    <>
      <div>
        {rows ? (
          <ActiveDatatable
            mobile={mobile ? true : false}
            columns={projectStatusColumns}
            listKey={'ProjectStatusTable'}
            rows={rows}
            noAddBtn
          />
        ) : (
          <Spinner animation="border" />
        )}
      </div>
    </>
  );
};

export default ProjectStatusTable;
