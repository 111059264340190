import { MDBIcon } from 'mdbreact';
import React from 'react';

const AnalysComponentIconButton = ({ icon = 'chart-line', onClick, name }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-items-center cursor-pointer"
      // style={{ maxWidth: 150 }}
      onClick={onClick}
    >
      <MDBIcon icon={icon} size="3x" className="mt-3 mr-1 text-primary" />

      <div className="mt-4">
        {name.split('\n').map((paragraph) => {
          return (
            <p
              className="text-center"
              style={{ fontSize: 14, marginTop: -10, maxWidth: 300 }}
            >
              {paragraph}
            </p>
          );
        })}
        {/* <p
          className="text-center text-primary"
          style={{ fontSize: 14, maxWidth: 200 }}
        >
          {name}
        </p> */}
      </div>
    </div>
  );
};

export default AnalysComponentIconButton;
