import React, { useEffect, useState } from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import { colors, fontFamily, pixels } from '../../constants';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyLineChart = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  showlegend,
  width = '',
  height = '',
  padding = 4,
  displayModeBar = true,
  useData = false,
  bgColor = false,
  dynamicWidth = false,

  style = { marginLeft: '31px' },
  grapthTitle = null,
}) => {
  const [dWidth, setDWidth] = useState(800);
  const { analysDataDefault } = useSelector((state) => state.analys);

  return (
    <>
      {/* <div className="justify-content-center flex-column align-items-center p-4 bg-dashboard rounded m-1"> */}
      <div
        style={style}
        className={`justify-content-center flex-column align-items-center p-${padding}  ${
          bgColor ? 'bg-dashboard' : null
        } rounded `}
      >
        {title && <PivotTableTitle title={title} />}

        <div style={{ overflow: 'auto' }}>
          <ReactPivotTable
            data={useData ? data : analysDataDefault || []}
            rows={preSelectedRows}
            cols={preSelectedCols}
            aggregatorName={aggregatorName}
            vals={vals}
            rendererName="Line Chart"
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            dataOptions={{
              line: { color: 'red', width: 3, shape: 'spline' },
              mode: 'lines+markers',
            }}
            plotlyOptions={{
              // data: [{ line: { color: 'red', width: 3, shape: 'spline' } }],
              data: {
                line: { color: 'red', width: 3, shape: 'spline' },
                mode: 'lines+markers',
                showlegend: true,
              },
              width: pixels.ANALYS_PLOTLY_WIDTH,

              // minWidth: 1280,

              // paper_bgcolor: bgColor ? '#dfe6e7ad' : 'transparent',
              // plot_bgcolor: bgColor ? '#dfe6e7ad' : 'transparent',

              colorway: [
                '#60909a',
                '#b5cfd5',
                '#558089',
                '#d2e2e6',
                '#4a7078',

                '#c3d9dd',
                '#88b3bc',
                '#406067',
                '#a6c6cd',
                '#355056',
                '#97bcc4',

                '#6ba0ac',
                '#79a9b4',
              ],

              mode: 'lines+markers',
              height: height,
              title: '',
              showlegend: showlegend ? true : false,
              yaxis: {
                title: '',
                range: [0.8, 4.2],
                fixedrange: false,
                gridwidth: 2,
                gridcolor: 'transparent',
                dtick: 1,
              },
              grid: {
                xgap: 0,
                pattern: 'independent',
              },
              xaxis: {
                ticklabelposition: 'center',
                tickangle: 0,
                side: 'left',
                showticklabels: true,
                gridwidth: 0,
                dtick: 2,
                automargin: 'center',
                gridcolor: 'transparent',
                range: 'auto',

                // color: 'yellow',
                x: 0.5,
                zeroline: true,
                // rangeSelector: {
                //   x: 0.5,
                //   y: 1.2,
                //   xanchor: 'right',
                //   showgrid: false,
                // },
              },
              annotations: grapthTitle
                ? [
                    {
                      xref: 'paper',
                      yref: 'paper',
                      x: 0.35,
                      y: 1.03,
                      xanchor: 'left',
                      yanchor: 'bottom',
                      text: grapthTitle,

                      font: {
                        family: fontFamily.PRIMARY,
                        // family: 'Arial',

                        size: 14,
                        fontWeight: 'bold',

                        color: 'rgb(37,37,37)',
                      },
                      showarrow: false,
                    },
                  ]
                : null,
            }}
            plotlyConfig={{ responsive: true, displayModeBar: displayModeBar }}
            valueFilter={data ? data.valueFilter : {}}
          />
        </div>
      </div>
    </>
  );
};

export default PlotlyLineChart;
