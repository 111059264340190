export const surveyColumns = [
  {
    label: '',
    field: '',
  },
  {
    label: 'Formulärnamn',
    field: 'surveyName',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Antal frågor',
    field: 'numberOfQuestions',
  },
  {
    label: 'Antal projekt',
    field: 'numberOfProjects',
  },
  {
    label: 'Skapad',
    field: 'createdAt',
  },
  {
    label: 'Uppdaterad',
    field: 'updatedAt',
  },
  {
    label: '',
    field: 'last',
  },
];
export const inactiveSurveyColumns = [
  {
    label: 'formulärnamn',
    field: 'surveyName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    // width: 100,
  },
];

export const surveyColumnsMobile = [
  {
    label: 'Formulärnamn',
    field: 'surveyName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  // {
  //   label: 'status',
  //   field: 'active',
  //   sort: 'disabled',

  //   // width: 150,
  // },
];
export const inactiveSurveyColumnsMobile = [
  {
    label: 'formulärnamn',
    field: 'surveyName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'aktivera',
    field: 'activate',
    // width: 100,
  },
];
