/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  inactivateResource,
  deleteResource,
  activateResource,
  getResources,
} from '../../../../redux/store/actions';

import EditModal from '../../../../components/edit-modal';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditResourceForm from './EditResourceForm';
import ActivateConfirmation from '../../../../components/confirm-modal/ActivateConfirmation';

const EditResourceModal = ({ handleClose, show, resourceToEdit, mobile }) => {
  const [resource, setResource] = useState(resourceToEdit);

  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);

  const [fromDelete, setFromDelete] = useState(false);

  const [resourceToDelete, setResourceToDelete] = useState('');

  const confirmDeleteTitle = 'ta bort fråga';
  const confirmDeleteMessage = resource
    ? `Är du säker att du vill ta bort resursen ${resource.resource_firstname} ${resource.resource_lastname}?`
    : '';
  const confirmInactivationTitle = 'inaktivera projektresurs';
  const confirmInactivationMessage = resource
    ? `Är du säker att du vill inaktivera resursen ${resource.resource_firstname} ${resource.resource_lastname}?`
    : '';

  const confirmActivateTitle = 'aktivera projektresurs';
  const confirmActivateMessage = resource
    ? `Är du säker att du vill aktivera ${resource.resource_firstname} ${resource.resource_lastname}?`
    : '';

  const confirmInactivationMessageFromDelete = resource
    ? `Resurs ${resource.resource_firstname} ${resource.resource_lastname} har kopplingar till annan data och går inte att ta bort, vill du inaktivera ${resource.resource_firstname} ${resource.resource_lastname} istället?`
    : '';

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (resourceToEdit) {
      setResource({
        ...resourceToEdit,
        createAccount: false,
        haveAccount: resourceToEdit.profile?.is_active ? true : false,
        operation_id: resourceToEdit.operation_id
          ? resourceToEdit.operation_id
          : '',
        reporter:
          resourceToEdit.profile?.profile_reporter === false ? false : true,
        role: resourceToEdit.profile?.profile_role
          ? resourceToEdit.profile?.profile_role
          : 2,
      });
    }
  }, [resourceToEdit]);

  const handleDeleteResource = (e) => {
    dispatch(deleteResource(e, user.digituals_client_id)).then(
      ({ payload }) => {
        dispatch(getResources(user.digituals_client_id));
        if (!payload) return;
        const { connected } = payload;
        if (connected) {
          setFromDelete(true);
          setShowInactivateModal(true);
        }
      }
    );
    setShowDeleteModal(false);
  };
  const handleInactivateResource = (e) => {
    dispatch(inactivateResource(e, user.digituals_client_id)).then(() =>
      dispatch(getResources(user.digituals_client_id))
    );
    setShowInactivateModal(false);
  };

  const handleActivateResource = (emp) => {
    dispatch(activateResource(emp, user.digituals_client_id)).then(() =>
      dispatch(getResources(user.digituals_client_id))
    );
    setShowActivationModal(false);
  };

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteResource(resourceToDelete)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() => handleInactivateResource(resource)}
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />

      <ActivateConfirmation
        title={confirmActivateTitle}
        showModal={showActivationModal}
        hideModal={() => setShowActivationModal(false)}
        confirmActivate={() => handleActivateResource(resource)}
        message={confirmActivateMessage}
      />

      <EditModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        active={resource.is_active ? false : true}
        inactivate={() => {
          setFromDelete(false);
          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setResourceToDelete(resource);
          setShowDeleteModal(true);
        }}
        activate={() => {
          setShowActivationModal(true);
        }}
        submitForm={'EditResourceForm'}
        header="Redigera resurs"
      >
        <>
          {resource && (
            <EditResourceForm
              resourceToEdit={resource}
              mobile={mobile}
              handleClose={() => {
                handleClose();
              }}
            />
          )}
        </>
      </EditModal>
    </>
  );
};

export default EditResourceModal;
