import { cols } from '../columns';

const s = {
  fontSize: 14,
  marginTop: 18,
  fontWeight: 'normal',
};

export const columns = [
  cols.customer,
  cols.project,
  cols.reporter,
  // cols.projectStart,
  cols.daysFromLastResponse,
  cols.lowestResponse,
  cols.averageResponse,
  cols.highestResponse,
  cols.firstResponse,
  cols.lastResponse,
  cols.numberOfResponses,
];
export const columnsProjectQuestion = [
  cols.project,
  cols.question,
  // cols.projectStart,
  cols.daysFromLastResponse,
  cols.lowestResponse,
  cols.averageResponse,
  cols.highestResponse,
  cols.firstResponse,
  cols.lastResponse,
  cols.numberOfResponses,
];
export const columnsCustomer = [
  cols.customer,
  cols.lowestResponse,
  cols.averageResponse,
  cols.highestResponse,
  cols.firstResponse,
  cols.lastResponse,
  cols.numberOfResponses,
  cols.numberOfReporters,
  cols.numberOfProjects,
];
export const columnsProjects = [
  cols.project,
  cols.projectStart,
  cols.lowestResponse,
  cols.averageResponse,
  cols.highestResponse,
  cols.firstResponse,
  cols.lastResponse,
  cols.numberOfResponses,
  cols.numberOfReporters,
];

export const columnsQuestion = [
  cols.question,
  cols.lowestResponse,
  cols.averageResponse,
  cols.highestResponse,
  cols.firstResponse,
  cols.lastResponse,
  cols.numberOfResponses,
  cols.numberOfReporters,
  cols.numberOfCustomers,
  cols.numberOfProjects,
];

export const columnsResponse = [
  cols.project,
  cols.customer,
  cols.reporter,
  cols.question,
];
export const columnsOverview = [
  cols.customer,
  cols.project,
  cols.reporter,
  cols.daysFromLastResponse,
];
