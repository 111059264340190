/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions } from '../../../redux/store/actions';

import EditQuestionModal from './edit-question-modal/EditQuestionModal';
import { questionColumns, questionColumnsMobile } from './dataColumns';
import ActivateConfirmation from '../../../components/confirm-modal/ActivateConfirmation';
import AddQuestionModal from './add-question-modal/AddQuestionModal';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import moment from 'moment';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const QuestionsTable = ({ mobile }) => {
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState('');
  const [showActivationModal, setShowActivationModal] = useState(false);

  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);

  const [showInactiveQuestions, setShowInactiveQuestions] = useState(false);

  const [rows, setRows] = useState('');

  const confirmActivationTitle = 'aktivera kund';
  const confirmActivationMessage = questionToEdit
    ? `Är du säker att du vill aktivera fråga ${questionToEdit.question}`
    : '';

  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!questions && user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (questions) {
      createRowsForDatasets();
    }
  }, [questions, showInactiveQuestions]);

  const createRowsForDatasets = () => {
    const filtredQuestions = questions.filter(
      ({ is_active }) => is_active === true
    );

    const questionsToMap = !showInactiveQuestions
      ? filtredQuestions
      : questions;

    const rows = questionsToMap.map((q) => {
      const {
        question,
        is_active,
        question_description,
        question_created_at,
        question_updated_at,
        questionnaire_setup,
      } = q;

      const activeQ =
        questionnaire_setup.length > 0
          ? questionnaire_setup.filter(
              (q) => q.questionnaire.is_active === true
            )
          : [];

      return {
        clickEvent: () => handleClickRow(q),
        question: question,
        status: is_active ? 'Aktiv' : 'Inaktiv',
        description: question_description
          ? question_description
          : 'Ingen beskrivning...',
        numberOfQuestionnaires: activeQ.length,
        createdAt: moment(question_created_at).format('YYYY-MM-DD'),
        updatedAt: moment(question_updated_at).format('YYYY-MM-DD'),
      };
    });

    setRows(rows);
  };

  const handleOpenQuestionModal = () => {
    setShowAddQuestionModal(true);
  };

  const handleClickRow = (q) => {
    setQuestionToEdit(q);
    setShowEditQuestionModal(true);
  };

  const handleClose = () => {
    setQuestionToEdit('');
    setShowEditQuestionModal(false);
  };

  const handleToggleShowActive = () =>
    setShowInactiveQuestions(!showInactiveQuestions);

  if (questions) {
    questions.sort(function (a, b) {
      var nameA = a.question.toUpperCase();
      var nameB = b.question.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <div className="mt-0 mb-5">
      <ActivateConfirmation
        title={confirmActivationTitle}
        showModal={showActivationModal}
        hideModal={() => setShowActivationModal(false)}
        // confirmActivate={() => handleActivateQuestion(questionToActivate)}
        message={confirmActivationMessage}
      />
      {rows ? (
        <>
          {!mobile && (
            <ShowOrHideInactiveButton
              onClick={handleToggleShowActive}
              text={
                showInactiveQuestions
                  ? 'Göm inaktiva frågor'
                  : 'Visa inaktiva frågor'
              }
            />
          )}

          <ActiveDatatable
            listKey={'questionTable'}
            columns={mobile ? questionColumnsMobile : questionColumns}
            rows={rows}
            add={handleOpenQuestionModal}
            mobile={mobile}
          />
        </>
      ) : (
        <Spinner animation="border" />
      )}

      <EditQuestionModal
        show={showEditQuestionModal}
        questionToEdit={questionToEdit}
        handleClose={handleClose}
        mobile={mobile}
      />
      <AddQuestionModal
        handleClose={() => setShowAddQuestionModal(false)}
        show={showAddQuestionModal}
        mobile={mobile}
      />
    </div>
  );
};

export default QuestionsTable;
