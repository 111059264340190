/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions } from '../../../../redux/store/actions';

const AddQuestionToSurveyModal = ({
  connectQuestions,
  handleClose,
  show,
  fullscreen,
  selectedQuestions,
}) => {
  const [availableQuestions, setAvailableQuestions] = useState('');
  const [selectedQ, setSelectedQ] = useState('');

  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Fråga</p>,
      field: 'question',
    },

    {
      label: '',

      field: 'add',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!questions && user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (questions) {
      const activeQuestions = questions.filter((q) => q.is_active === true);

      if (selectedQuestions) {
        const selectedQIDs = selectedQuestions.map(
          (value) => value.question_id
        );

        setSelectedQ(selectedQIDs);

        setAvailableQuestions(
          activeQuestions.filter((x) => !selectedQIDs.includes(x.question_id))
        );
      } else {
        setAvailableQuestions(activeQuestions);
        setSelectedQ('');
      }
    }
  }, [selectedQuestions, questions]);

  const handleConnectQuestions = () => {
    const questionsToAdd = questions.filter(
      (x) =>
        checked.includes(x.question_id) || selectedQ.includes(x.question_id)
    );

    const mappedQuestions = questionsToAdd?.map((value, index) => ({
      question_sort_order: index + 1,
      question_id: value.question_id,
      question: value.question,
      is_active: value.is_active,
    }));

    connectQuestions(mappedQuestions ? mappedQuestions : questionsToAdd);
    setChecked([]);
    setAvailableQuestions('');
    setSelectedQ('');
    handleClose();
  };

  // const handleClickRow = (resource) => {
  //   setSelectedResource(resource);
  // };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const hClose = () => {
    setAvailableQuestions('');
    setChecked([]);
    setSelectedQ('');
    handleClose();
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={hClose}
        fullscreen={fullscreen}
        done={handleConnectQuestions}
        centered={true}
        header="Välj frågor"
      >
        {/* <MDBContainer
        
           style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
        > */}
        {availableQuestions.length > 0 ? (
          <AddList
            columns={columns}
            rows={availableQuestions.map((q) => {
              const { question, question_id } = q;

              return {
                question: question,

                add: (
                  <MDBCheckbox
                    key={question_id}
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      marginRight: 8,
                    }}
                    name="selectedProjects"
                    value={question_id}
                    className="text-primary"
                    onChange={handleCheck}
                  />
                ),
              };
            })}
          />
        ) : null}
        {/* </MDBContainer> */}
      </AddModal>
    </>
  );
};

export default AddQuestionToSurveyModal;
