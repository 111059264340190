/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalysNavbar from '../../../components/navbar/AnalysNavbar';
import PlotlyAreaChart from '../../../components/plotly/PlotlyAreaChart';
import PlotlyTable from '../../../components/plotly/PlotlyTable';
import PlotlyLineChart from '../../../components/plotly/PlotlyLineChart';
import PlotlyTableHeatmap from '../../../components/plotly/PlotlyTableHeatmap';
import TopTabNavsAnalys from '../../../components/top-tab-navs-analys';
import { getDataFromDB } from '../calculate-functions';
import PlotlyGroupedBarChart from '../../../components/plotly/PlotlyGroupedBarChart';
import PivotFilter from '../../../components/pivot-table/PivotFilter';
import { analysFilterData, analysIcons, pivotFilter } from '../../../constants';
import { setAnalysDataDefault } from '../../../redux/store/actions';
import PlotlyGroupedColumnChart from '../../../components/plotly/PlotlyGroupedColumnChart';
import DubbleTableRow from '../../../components/dubble-table-row';
import NumOfRespOverResponseValue from './bar-charts/NumOfRespOverResponseValue';
import { MDBIcon } from 'mdbreact';
import AnalysComponentIconButton from '../../../components/analys-component-icon-button';
import { analysIconsList } from './analysIcons';
import PivotTable from '../../../components/pivot-table';
import WebDataRocksPivot from '../../../components/web-data-rocks-pivot';
import LoadingPage from '../../../components/loading';
import { Spinner } from 'react-bootstrap';
import SectionHeader from '../../../components/section-header';
import PlotlyLineChartAnalys from '../../../components/plotly/PlotltLineChartAnalys';
import { ComponentToPrint } from '../../../components/component-to-print';
import FloatingHoverButton from '../../../components/floating-hover-button';

const MARGIN_TOP = 110;
const MARGIN_BOTTOM = -55;

const s = {
  marginBottom: MARGIN_BOTTOM,
  zIndex: 1000,
};

const iconWidthStyle = {
  width: 'calc(100% / 6)',
};

const outerDiv =
  'bg-white d-flex align-items-start justify-content-center flex-column w-100';
const iconDiv =
  'bg-white d-flex flex-wrap align-items-start justify-content-start  mx-0 w-100';

const AnalysDashboard = () => {
  const [dbData, setDbData] = useState([]);
  const [defaultAnalysData, setDefaultAnalysData] = useState([]);

  const [activeDashboard, setActiveDashboard] = useState(0);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {
    analysData,
    analysDataDefault,
    analysDataTime,
    analysDataReporter,
    analysDataQuestion,
    analysDataProjectCustomer,
  } = useSelector((state) => state.analys);

  const {
    PROJECT,
    PROJECT_ACTIVE,
    PROJECT_START,
    CUSTOMER,
    CUSTOMER_ACTIVE,
    USER_ACCOUNT,
    USER_ACCOUNT_ACTIVE,
    REPORTER,
    REPORTER_ACTIVE,
    QUESTION,
    QUESTION_ACTIVE,
    RESPONSE_DATE,
    RESPONSE_YEAR,
    RESPONSE_WEEK,
    RESPONSE_QUARTER,
    RESPONSE_MONTH,
    PROJECT_START_YEAR,
    PROJECT_START_WEEK,
    PROJECT_START_QUARTER,
    PROJECT_START_MONTH,
    PROJECT_START_YEAR_WEEK,
    PROJECT_START_YEAR_QUARTER,
    PROJECT_START_YEAR_MONTH,
    RESPONSE_YEAR_MONTH,
    RESPONSE_YEAR_QUARTER,
    RESPONSE_YEAR_WEEK,
    DAYS_FROM_PROJECT_START,
    WEEKS_FROM_PROJECT_START,
    RESPONSE,
  } = pivotFilter;

  useEffect(() => {
    getDataFromDB(user).then((value) => {
      setDefaultAnalysData(
        value.data.map((val) => ({
          [PROJECT]: val.project,
          [PROJECT_ACTIVE]: val.project_active,
          [PROJECT_START]: val.project_start,
          [CUSTOMER]: val.customer,
          [CUSTOMER_ACTIVE]: val.customer_active,
          [USER_ACCOUNT]: val.user_account,
          [USER_ACCOUNT_ACTIVE]: val.user_account_active,
          [REPORTER]: val.reporter,
          [REPORTER_ACTIVE]: val.reporter_active,
          [QUESTION]: val.question,
          [QUESTION_ACTIVE]: val.question_active,
          [RESPONSE_DATE]: val.response_date,
          [RESPONSE_YEAR]: val.response_year,
          [RESPONSE_WEEK]: val.response_week,
          [RESPONSE_QUARTER]: val.response_quarter,
          [RESPONSE_MONTH]: val.response_month,
          [PROJECT_START_YEAR]: val.projectstart_year,
          [PROJECT_START_WEEK]: val.projectstart_week,
          [PROJECT_START_QUARTER]: val.projectstart_quarter,
          [PROJECT_START_MONTH]: val.projectstart_month,
          [PROJECT_START_YEAR_WEEK]: val.projectstart_yearweek,
          [PROJECT_START_YEAR_QUARTER]: val.projectstart_yearquarter,
          [PROJECT_START_YEAR_MONTH]: val.projectstart_yearmonth,
          [RESPONSE_YEAR_MONTH]: val.response_yearmonth,
          [RESPONSE_YEAR_QUARTER]: val.response_yearquarter,
          [RESPONSE_YEAR_WEEK]: val.response_yearweek,
          [DAYS_FROM_PROJECT_START]: val.response_days_from_project_start,
          [WEEKS_FROM_PROJECT_START]: val.response_weeks_from_project_start,
          [RESPONSE]: val.response,
        }))
      );

      setDbData(value.data);
    });
  }, []);

  useEffect(() => {
    if (defaultAnalysData) {
      dispatch(setAnalysDataDefault(defaultAnalysData));
    }
  }, [defaultAnalysData]);

  return (
    <>
      <AnalysNavbar />

      {dbData ? (
        <div
          className=" align-items-center justify-content-center flex-column  "
          style={{
            marginTop: '120px',
          }}
        >
          <TopTabNavsAnalys
            tabClick={() => setActiveDashboard(0)}
            tabs={[
              {
                name: 'Skapa analysvy',
                content: (
                  <div
                    style={{
                      marginTop: '200px',
                      marginRight: '34px',
                      marginLeft: '34px',
                    }}
                  >
                    <WebDataRocksPivot data={dbData} />
                  </div>
                ),
              },
              {
                name: 'Färdiga analysvyer',
                content: (
                  <>
                    {analysDataDefault && (
                      <>
                        <PivotFilter
                          data={analysDataDefault}
                          hide={activeDashboard === 0 ? true : false}
                          hiddenFromDragDrop={[
                            RESPONSE,
                            RESPONSE_WEEK,
                            RESPONSE_QUARTER,
                            RESPONSE_MONTH,
                            PROJECT_START,
                            DAYS_FROM_PROJECT_START,
                            WEEKS_FROM_PROJECT_START,
                            PROJECT_START_QUARTER,
                            PROJECT_START_MONTH,
                            PROJECT_START_WEEK,
                          ]}
                          filter={analysFilterData.OVERVIEW}
                        />

                        <div
                          style={{ marginTop: '200px', marginBottom: '200px' }}
                        >
                          {activeDashboard === 0 ? (
                            <div
                            // className="bg-white d-flex flex-wrap align-items-start justify-content-start"
                            // style={{ marginTop: '-78px' }}
                            >
                              {/* CUSTOMER */}
                              <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Kund"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 1 ||
                                        row.number === 2 ||
                                        row.number === 3 ||
                                        row.number === 4 ||
                                        row.number === 5 ||
                                        row.number === 6
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5  bg-white"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          {/* <p className="text-center">
                                            {number}
                                          </p> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/* CUSTOMER */}

                              {/* PROJECT */}
                              <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Projekt"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 7 ||
                                        row.number === 8 ||
                                        row.number === 9 ||
                                        row.number === 10 ||
                                        row.number === 11 ||
                                        row.number === 12
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          {/* <p className="text-center">
                                            {number}
                                          </p> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/* PROJECT */}

                              {/* REPORTER */}
                              <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Rapportör"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 13 ||
                                        row.number === 14 ||
                                        row.number === 15 ||
                                        row.number === 16 ||
                                        row.number === 17 ||
                                        row.number === 18
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          {/* <p className="text-center">
                                            {number}
                                          </p> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/* REPORTER */}

                              {/* QUESTION */}
                              <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Fråga"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 19 ||
                                        row.number === 20 ||
                                        row.number === 21 ||
                                        row.number === 22 ||
                                        row.number === 23 ||
                                        row.number === 24
                                      // row.number === 25
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          {/* <p className="text-center">
                                            {number}
                                          </p> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/* QUESTION */}

                              {/* TIME */}
                              <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Översikt Respons"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 26 ||
                                        row.number === 27 ||
                                        row.number === 28 ||
                                        row.number === 29 ||
                                        row.number === 30 ||
                                        row.number === 31 ||
                                        row.number === 32 ||
                                        row.number === 33 ||
                                        row.number === 34
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          {/* <p className="text-center">
                                            {number}
                                          </p> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/* TIME */}

                              {/* RESPONSEVALUE */}
                              {/* <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Responsvärde"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 1 ||
                                        row.number === 4 ||
                                        row.number === 5 ||
                                        row.number === 9
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          <p className="text-center">
                                            {number}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div> */}
                              {/* RESPONSEVALUE */}

                              {/* OVERVIEW */}
                              {/* <div
                                className={outerDiv}
                                style={{ marginTop: MARGIN_TOP }}
                              >
                                <SectionHeader
                                  title="Övrigt"
                                  style={s}
                                  className="align-self-center"
                                />
                                <div className={iconDiv}>
                                  {analysIconsList
                                    .filter(
                                      (row) =>
                                        row.number === 3 ||
                                        row.number === 6 ||
                                        row.number === 1 ||
                                        row.number === 4 ||
                                        row.number === 5 ||
                                        row.number === 9 ||
                                        row.number === 2
                                    )
                                    .map(({ icon, name, number }) => {
                                      return (
                                        <div
                                          className=" mt-5"
                                          style={iconWidthStyle}
                                          key={`AnalysComponentIconButton-${number}-${name}`}
                                        >
                                          <AnalysComponentIconButton
                                            // key={name}
                                            icon={icon}
                                            name={name}
                                            onClick={() =>
                                              setActiveDashboard(number)
                                            }
                                          />
                                          <p className="text-center">
                                            {number}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div> */}
                              {/* OVERVIEW */}
                            </div>
                          ) : activeDashboard === 1 ? (
                            <PlotlyGroupedBarChart
                              preSelectedRows={[CUSTOMER]}
                              preSelectedCols={[]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title="Medelrespons per Kund"
                              fixedrange={true}
                              range={[1, 4]}
                              usePrint
                            />
                          ) : activeDashboard === 2 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={'Medelrespons per Kund/Kalendervecka'}
                              usePrint
                            />
                          ) : activeDashboard === 3 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_MONTH]}
                              aggregatorName={['Average']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Fråga/Kalendermånad'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 4 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={['Average']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Fråga/Kalendervecka'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 5 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Vecka från projektstart'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 6 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER, QUESTION]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Fråga/Vecka från projektstart'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 7 ? (
                            <PlotlyGroupedBarChart
                              preSelectedRows={[PROJECT]}
                              preSelectedCols={[]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title="Medelrespons per Projekt"
                              fixedrange={true}
                              range={[1, 4]}
                              usePrint
                            />
                          ) : activeDashboard === 8 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER, PROJECT]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Projekt/Kalendervecka'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 9 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[PROJECT, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_MONTH]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Projekt/Fråga/Kalendermånad'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 10 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[PROJECT, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Projekt/Fråga/Kalendervecka'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 11 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[CUSTOMER, PROJECT]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Kund/Projekt/Vecka från projektstart'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 12 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[PROJECT, QUESTION]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Medelrespons per Projekt/Fråga/Vecka från projektstart'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 13 ? (
                            <PlotlyGroupedBarChart
                              preSelectedRows={[REPORTER]}
                              preSelectedCols={[]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              showlegend={false}
                              data={analysData}
                              title="Medelrespons per Rapportör"
                              fixedrange={true}
                              range={[1, 4]}
                              usePrint
                            />
                          ) : activeDashboard === 14 ? (
                            <PlotlyTableHeatmap
                              title="Medelrespons per Projekt/Rapportör/Kalendervecka"
                              preSelectedRows={[PROJECT, REPORTER]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              usePrint
                            />
                          ) : activeDashboard === 15 ? (
                            <PlotlyTableHeatmap
                              title="Medelrespons per Projekt/Rapportör/Fråga/Kalendermånad"
                              preSelectedRows={[PROJECT, REPORTER, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_MONTH]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              usePrint
                            />
                          ) : activeDashboard === 16 ? (
                            <PlotlyTableHeatmap
                              title="Medelrespons per Projekt/Rapportör/Fråga/Kalendervecka"
                              preSelectedRows={[PROJECT, REPORTER, QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              usePrint
                            />
                          ) : activeDashboard === 17 ? (
                            <PlotlyTableHeatmap
                              title="Medelrespons per Projekt/Rapportör/Vecka från projektstart"
                              preSelectedRows={[PROJECT, REPORTER]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              usePrint
                            />
                          ) : activeDashboard === 18 ? (
                            <PlotlyTableHeatmap
                              title="Medelrespons per Projekt/Rapportör/Fråga/Vecka från projektstart"
                              preSelectedRows={[PROJECT, REPORTER, QUESTION]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              usePrint
                            />
                          ) : activeDashboard === 19 ? (
                            <PlotlyGroupedBarChart
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              title="Medelrespons per Fråga"
                              data={analysData}
                              showlegend={false}
                              fixedrange={true}
                              range={[1, 4]}
                              usePrint
                            />
                          ) : activeDashboard === 20 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title="Medelrespons per Fråga/Kalendervecka"
                              usePrint
                            />
                          ) : activeDashboard === 21 ? (
                            <PlotlyLineChartAnalys
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              title="Medelrespons per Fråga/Kalendervecka"
                              data={analysData}
                              showlegend={true}
                              dtick={5}
                              usePrint
                            />
                          ) : activeDashboard === 22 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_MONTH]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title="Medelrespons per Fråga/Kalendermånad"
                              usePrint
                            />
                          ) : activeDashboard === 23 ? (
                            <PlotlyLineChartAnalys
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[RESPONSE_YEAR_MONTH]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              title="Medelrespons per Fråga/Kalendermånad"
                              data={analysData}
                              showlegend={true}
                              dtick={1}
                              usePrint
                            />
                          ) : activeDashboard === 24 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title="Medelrespons per Fråga/Vecka från projektstart"
                              usePrint
                            />
                          ) : activeDashboard === 25 ? (
                            <PlotlyLineChartAnalys
                              preSelectedRows={[QUESTION]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              title="Medelrespons per Fråga/Vecka från projektstart"
                              data={analysData}
                              showlegend={true}
                              dtick={2}
                              usePrint
                            />
                          ) : activeDashboard === 26 ? (
                            <PlotlyGroupedColumnChart
                              preSelectedRows={[]}
                              preSelectedCols={[RESPONSE]}
                              aggregatorName={'Count'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={'Antal responser per Responsvärde'}
                              fixedRange={true}
                              usePrint
                            />
                          ) : activeDashboard === 27 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[USER_ACCOUNT, REPORTER]}
                              preSelectedCols={[RESPONSE]}
                              aggregatorName={['Count']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Antal responser per Responsvärde/Användarkonto/Rapportör'
                              }
                              dynamicColors={true}
                              usePrint
                            />
                          ) : activeDashboard === 28 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[PROJECT]}
                              preSelectedCols={[RESPONSE]}
                              aggregatorName={['Count']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={'Antal responser per Responsvärde/Projekt'}
                              dynamicColors={true}
                              usePrint
                            />
                          ) : activeDashboard === 29 ? (
                            <PlotlyAreaChart
                              preSelectedRows={[RESPONSE]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={['Count as Fraction of Columns']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Andel av respektive responsvärde per Kalendervecka'
                              }
                              dtick={1}
                              tickangle={90}
                              usePrint
                            />
                          ) : activeDashboard === 30 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[RESPONSE]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={['Count']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Antal responser per Responsvärde/Kalendervecka'
                              }
                              dynamicColors={true}
                              usePrint
                            />
                          ) : activeDashboard === 31 ? (
                            <PlotlyLineChartAnalys
                              preSelectedRows={[]}
                              preSelectedCols={[RESPONSE_YEAR_WEEK]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={'Medelrespons per Kalendervecka'}
                              dtick={3}
                              usePrint
                            />
                          ) : activeDashboard === 32 ? (
                            <PlotlyAreaChart
                              preSelectedRows={[RESPONSE]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={['Count as Fraction of Columns']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Andel av respektive responsvärde per Vecka från projektstart'
                              }
                              usePrint
                            />
                          ) : activeDashboard === 33 ? (
                            <PlotlyTableHeatmap
                              preSelectedRows={[RESPONSE]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={['Count']}
                              vals={[RESPONSE]}
                              data={analysData}
                              title={
                                'Antal responser per Responsvärde/Vecka från projektstart'
                              }
                              dynamicColors={true}
                              usePrint
                            />
                          ) : activeDashboard === 34 ? (
                            <PlotlyLineChartAnalys
                              preSelectedRows={[]}
                              preSelectedCols={[WEEKS_FROM_PROJECT_START]}
                              aggregatorName={'Average'}
                              vals={[RESPONSE]}
                              title={'Medelrespons per Vecka från projektstart'}
                              data={analysData}
                              dtick={3}
                              usePrint
                            />
                          ) : null}
                        </div>
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </div>
      ) : (
        'Ingen data...'
      )}
    </>
  );
};

export default AnalysDashboard;
