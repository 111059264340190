import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AnalysDatatable from '../../../components/datatables/analys-datatable';
import AnalysNavbar from '../../../components/navbar/AnalysNavbar';
import LineChartOverview from '../../../components/plotly/LineChartOverview';
import PlotlyLineChart from '../../../components/plotly/PlotlyLineChart';
import PlotlyPieChart from '../../../components/plotly/PlotlyPieChart';
import ValueCircle from '../../../components/value-circle';
import { analysDatatableOptions } from '../../../constants';
import { columnsOverview } from '../analys-status/dataColumns';
import {
  getDataFromDB,
  calculateRowsForStatusTable,
  calculateAvrageDaysFromLastResponse,
  calculateRowsForOverviewTable,
  calculateAvrageResponseFromProjects,
  calculateAvrageResponseTotal,
} from '../calculate-functions';

const AnalysOverview = () => {
  const [dbData, setDbData] = useState([]);
  const [overViewRows, setOverViewRows] = useState([]);
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [numberOfReporters, setNumberOfReporters] = useState(0);
  const [avrageResponseValue, setAvrageResponseValue] = useState(0);
  const [averageResponseValueTotal, setAverageResponseValueTotal] = useState(0);
  const [averageResponsCustomWeeks, setAverageResponsCustomWeeks] = useState(0);
  const [responseValues, setResponseValues] = useState([1, 1, 1, 1]);
  const [responseValuesTotal, setResponseValuesTotal] = useState([1, 1, 1, 1]);
  const [responseValuesCustomWeeks, setResponseValuesCustomWeeks] = useState([
    1, 1, 1, 1,
  ]);
  const [avrageResponsLineChartData, setAvrageResponsLineChartData] = useState(
    []
  );
  const [lineChartData, setLineChartData] = useState([]);
  const [avrageDaysLastResponse, setAvrageDaysLastResponse] = useState('-');

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getDataFromDB(user).then((value) => {
      setDbData(value.data);
      setNumberOfProjects(
        value.uniqueProjects.filter((val) => val.project_active === true).length
      );
      setNumberOfReporters(
        value.uniqueReporters.filter((val) => val.reporter_active === true)
          .length
      );

      const resData = calculateResponseFromFiveWeeksAgo(value.data);

      setOverViewRows(resData.overViewRows);
      setResponseValues(resData.responseValues);
      setResponseValuesCustomWeeks(resData.responseValuesCustomWeeks);
      setAvrageResponseValue(resData.avrageResponseValue);
      setAverageResponsCustomWeeks(resData.avrageResponseValueCustomWeek);

      setAverageResponseValueTotal(resData.avrageResponseValueTotal);
      setResponseValuesTotal(resData.responseValuesTotal);
    });
  }, []);

  function calculateResponseFromFiveWeeksAgo(responseData) {
    const activeProjects = responseData.filter(
      (value) => value.project_active === true
    );
    const fiveWeeksAgo = moment().subtract(14, 'days').format();
    const seventWeeksAgo = moment().subtract(21, 'days').format();

    const resFromFiveWeeksAgo = responseData.filter(
      (response) => response.response_timestamp > fiveWeeksAgo
    );
    const resFromSevenWeeksAgo = responseData.filter(
      (response) => response.response_timestamp > seventWeeksAgo
    );
    const filtredTableData = responseData.filter(
      (row) =>
        row.project_active === true &&
        row.reporter_active === true &&
        row.customer_active === true
    );

    const overViewRows = calculateRowsForOverviewTable(responseData);

    setLineChartData(resFromSevenWeeksAgo);

    setAvrageResponsLineChartData(
      resFromFiveWeeksAgo.map((val) => ({
        Projekt: val.project,
        ProjektAktivt: val.project_active,
        ProjektStart: val.project_start,
        Kund: val.customer,
        KundAktiv: val.customer_active,
        AnvKonto: val.user_account,
        AnvKontoAktivt: val.user_account_active,
        Rapportör: val.reporter,
        RapportörAktiv: val.reporter_active,
        Fråga: val.question,
        FrågaAktiv: val.question_active,
        Responsdatum: val.response_date,
        Respons_År: val.response_year,
        Respons_Vecka: val.response_week,
        Respons_Kvartal: val.response_quarter,
        Respons_Månad: val.response_month,
        ProjektStart_År: val.projectstart_year,
        ProjektStart_Vecka: val.projectstart_week,
        ProjektStart_Kvartal: val.projectstart_quarter,
        ProjektStart_Månad: val.projectstart_month,
        ProjektStart_ÅrVecka: val.projectstart_yearweek,
        ProjektStart_ÅrKvartal: val.projectstart_yearquarter,
        ProjektStart_ÅrMånad: val.projectstart_yearmonth,
        Respons_ÅrMånad: val.response_yearmonth,
        Respons_ÅrKvartal: val.response_yearquarter,
        Respons_ÅrVecka: val.response_yearweek,
        DgrFrånProjektstart: val.response_days_from_project_start,
        VeckorFrånProjektstart: val.response_weeks_from_project_start,
        Respons: val.response,
      }))
    );

    const avrageDaysFromLastResponse =
      calculateAvrageDaysFromLastResponse(filtredTableData);

    setAvrageDaysLastResponse(avrageDaysFromLastResponse);

    const responseValues = [1, 1, 1, 1];
    const responseValuesCustomWeeks = [1, 1, 1, 1];
    const responseValuesTotalResponse = [1, 1, 1, 1];

    responseData.forEach((value) => {
      if (value.response === 1) {
        responseValuesTotalResponse[0] += 1;
      } else if (value.response === 2) {
        responseValuesTotalResponse[1] += 1;
      } else if (value.response === 3) {
        responseValuesTotalResponse[2] += 1;
      } else {
        responseValuesTotalResponse[3] += 1;
      }
    });
    activeProjects.forEach((value) => {
      if (value.response === 1) {
        responseValues[0] += 1;
      } else if (value.response === 2) {
        responseValues[1] += 1;
      } else if (value.response === 3) {
        responseValues[2] += 1;
      } else {
        responseValues[3] += 1;
      }
    });
    resFromFiveWeeksAgo.forEach((value) => {
      if (value.response === 1) {
        responseValuesCustomWeeks[0] += 1;
      } else if (value.response === 2) {
        responseValuesCustomWeeks[1] += 1;
      } else if (value.response === 3) {
        responseValuesCustomWeeks[2] += 1;
      } else {
        responseValuesCustomWeeks[3] += 1;
      }
    });

    const avrageResponseValue =
      calculateAvrageResponseFromProjects(responseData);

    const avrageResponseValueCustomWeek =
      calculateAvrageResponseFromProjects(resFromFiveWeeksAgo);

    const avrageResponseValueTotal = calculateAvrageResponseTotal(responseData);

    return {
      responseValues: responseValues,
      responseValuesCustomWeeks: responseValuesCustomWeeks,
      responseValuesTotal: responseValuesTotalResponse,
      avrageResponseValue: avrageResponseValue,
      avrageResponseValueCustomWeek: avrageResponseValueCustomWeek,
      overViewRows: overViewRows,
      avrageResponseValueTotal: avrageResponseValueTotal,
    };
  }

  return (
    <>
      <AnalysNavbar />
      <div
        className="d-flex flex-column bg-white  w-100  justify-content-between "
        style={{ marginTop: '74px', minHeight: '80vh' }}
      >
        <div
          className="d-flex flex-row justify-content-between  align-items-center mt-5 "
          style={{ overflow: 'auto', marginLeft: '50px', marginRight: '50px' }}
        >
          <PlotlyPieChart
            avrageValue={averageResponseValueTotal}
            values={responseValuesTotal}
            label="Medelvärde all respons"
          />
          <PlotlyPieChart
            avrageValue={avrageResponseValue}
            values={responseValues}
            label="Medelvärde aktiva projekts snittrespons"
          />
          <PlotlyPieChart
            avrageValue={averageResponsCustomWeeks}
            values={responseValuesCustomWeeks}
            // label="Medelvärde aktiva projekt senaste \n 14 dgr"
            label={`Medelvärde aktiva projekt senaste \n 14 dgr`}
          />
          <ValueCircle value={numberOfProjects} label="Aktiva projekt" />

          <ValueCircle value={numberOfReporters} label="Aktiva rapportörer" />
          <ValueCircle
            // value={1}
            value={avrageDaysLastResponse}
            label="Snitt antal dagar sedan senaste respons"
            interpolateColor
          />
        </div>
        <div
          className="d-flex flex-row justify-content-between  align-items-start  mx-0 mt-5"
          style={{ overflow: 'auto' }}
        >
          <LineChartOverview
            lineChartData={lineChartData}
            width={800}
            height={460}
            title="Trend för alla projekts snittrespons"
            // title="Medelvärde alla projekts snittrespons per kalendervecka"
            style={{ marginTop: -28, marginLeft: -44, marginRight: 20 }}
          />

          {/* <PlotlyLineChart
            width={800}
            height={550}
            padding={0}
            displayModeBar={false}
            data={avrageResponsLineChartData}
            preSelectedCols={['Respons_ÅrVecka']}
            aggregatorName={'Average'}
            vals={['Respons']}
            useData
            bgColor={false}
            grapthTitle="Medelvärde alla projekt"
            // grapthTitle="Medelvärde respons per vecka"
            style={{ marginTop: -30 }}
          /> */}

          <AnalysDatatable
            sortField=""
            mobile={false}
            columns={columnsOverview}
            rows={overViewRows.sort(
              (a, b) => b.daysFromLastResponse - a.daysFromLastResponse
            )}
            mx={0}
            entries={1000}
            maxHeight={350}
            fixedHeader
            style={{ marginRight: 50 }}
          />
        </div>
        {/* <div style={{ height: 50 }} /> */}
      </div>
    </>
  );
};

export default AnalysOverview;
