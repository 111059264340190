/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  getOperation,
  editAccount,
  getResources,
  getAccounts,
  updateUserPassword,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { icons, status } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';

const EditAccountForm = ({ handleClose, accountToEdit }) => {
  const [enableValidate, setEnableValidate] = useState(false);

  const initialValues = {
    email: accountToEdit.profile.profile_email,
    role: accountToEdit.profile.profile_role,
    name: `${accountToEdit.resource_firstname} ${accountToEdit.resource_lastname}`,
    status: accountToEdit.profile.is_active ? status.ACTIVE : status.INACTIVE,
    password: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string().required('Ange e-post!'),
  });

  const roleOptions = [
    {
      value: 2,
      label: 'Användare',
    },
    {
      value: 1,
      label: 'Admin',
    },
  ];

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const dispatch = useDispatch();

  const { operations } = useSelector((state) => state.operation);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const v = {
      values: values,
      resource: accountToEdit,
      status: status.ACTIVE === values.status ? true : false,
    };

    if (values.password) {
      const userValues = {
        email: values.email,
        profileID: accountToEdit.profile_id,
        password: values.password,
      };

      dispatch(updateUserPassword(userValues));
    }

    dispatch(editAccount(v, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getAccounts(user.digituals_client_id));
      }, 500)
    );

    handleResetForm(resetForm);
    handleClose();
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      initialValues={initialValues}
      validateOnChange={enableValidate}
      validateOnBlur={enableValidate}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            setEnableValidate(true);
            e.preventDefault();
            return handleSubmit();
          }}
          id="EditAccountForm"
        >
          <ModalIcon icon={icons.ACCOUNT} />

          <Row className="mb-4 ">
            <Form.Group as={Col} className="bg-light">
              <Form.Label className="text-secondary">Resurs</Form.Label>
              <Form.Control
                type="text"
                placeholder="Resurs..."
                name="name"
                value={values.name}
                readOnly
                // plaintext
                className="bg-white"
              />
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">
                E-post för konto
              </Form.Label>
              <Form.Control
                type="email"
                readOnly
                // plaintext
                className="bg-white"
                placeholder="E-post..."
                name="email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">Användartyp</Form.Label>

              {roleOptions && (
                <Field
                  component={CustomSelect}
                  placeholder="Välj användartyp..."
                  name="role"
                  options={roleOptions}
                  className="basic-multi-select mb-4"
                  classNamePrefix="select"
                  isValid={touched.role && !errors.role}
                  isInvalid={!!errors.role}
                />
              )}

              <Form.Control.Feedback type="invalid">
                {errors.role}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">Status</Form.Label>

              {statusOptions && (
                <Field
                  component={CustomSelect}
                  placeholder="Välj status..."
                  name="status"
                  options={statusOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isValid={touched.status && !errors.status}
                  isInvalid={!!errors.status}
                />
              )}

              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EditAccountForm;
