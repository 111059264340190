import React from 'react';
import { MDBIcon, MDBBtn } from 'mdbreact';
import { Form } from 'react-bootstrap';

const AddFromModalButton = ({ onClick, label, disabled }) => {
  return (
    <div
      className="d-flex align-items-center  justify-content-center "
      style={{
        cursor: 'pointer',
      }}
    >
      <MDBBtn
        disabled={disabled ? true : false}
        floating
        tag="a"
        size="md"
        onClick={onClick}
        className={disabled ? 'bg-dark' : 'bg-primary'}
      >
        <MDBIcon
          fas
          icon="plus"
          size="lg"
          color="light"
          className="text-white"
        />
      </MDBBtn>
      <Form.Label className="m-2 text-secondary">{label}</Form.Label>
    </div>
  );
};

export default AddFromModalButton;
