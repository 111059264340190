import React from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from 'mdb-react-ui-kit';

const ActivateConfirmation = ({
  showModal,
  hideModal,
  confirmActivate,
  message,
}) => {
  return (
    <MDBModal show={showModal} animationDirection="right" staticBackdrop>
      <MDBModalDialog size="lg" centered>
        <MDBModalContent className="bg-light">
          <MDBModalHeader>
            <MDBBtn
              tag="a"
              color="none"
              className="mt-0 align-items-center  text-muted d-flex flex-row"
              onClick={hideModal}
            >
              <MDBIcon fas icon="chevron-left " size="lg" color="primary" />

              <p className="mt-3 text-primary" style={{ marginLeft: 5 }}>
                tillbaka
              </p>
            </MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="alert alert-success">{message}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <p className="text-success" onClick={confirmActivate}>
              aktivera
            </p>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ActivateConfirmation;
