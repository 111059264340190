/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_OPERATION,
  GET_OPERATION,
  ACTIVATE_OPERATION,
  INACTIVATE_OPERATION,
  EDIT_OPERATION,
  DELETE_OPERATION,
} from '../types';

const INITIAL_STATE = {
  operations: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_OPERATION:
      return { ...state };
    case GET_OPERATION:
      return { operations: action.payload };
    case ACTIVATE_OPERATION:
      return { ...state };
    case INACTIVATE_OPERATION:
      return { ...state };
    case DELETE_OPERATION:
      return { ...state };
    case EDIT_OPERATION:
      return { ...state };
    default:
      return state;
  }
}
