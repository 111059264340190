import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { MDBTypography, MDBBtn } from 'mdbreact';

import { Container } from 'react-bootstrap';

import background from '../../image/background.png';
import LogoutConfirmation from '../confirm-modal/LogoutConfirmation';
import UserModal from '../user-modal';
import { colors } from '../../constants';

const TopTabNavs = ({ tabs }) => {
  const [basicActive, setBasicActive] = useState(0);

  const [showUserModal, setShowUserModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <LogoutConfirmation
        showModal={showLogoutModal}
        hideModal={() => setShowLogoutModal(false)}
      />
      <UserModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        logout={() => setShowLogoutModal(true)}
        fullscreen={true}
      />

      <div className="fixed-top  bg-dark">
        <div
          className="d-flex justify-content-between align-items-end"
          style={{
            // backgroundImage: background,
            // backgroundImage: `url(${background})`,
            background: colors.PRIMARY,
            height: '10vh',
            minHeight: '70px',

            // fontSize: '50px',
            backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            width: '105%',
            marginTop: '-10px',
            marginLeft: '-10px',
          }}
          // style={{
          //   // backgroundImage: background,
          //   backgroundImage: `url(${background})`,
          //   height: '10vh',
          //   marginTop: '0px',
          //   fontSize: '50px',
          //   backgroundSize: 'cover',
          //   backgroundRepeat: 'no-repeat',
          // }}
        >
          <MDBTypography
            tag="h2"
            variant="h2-responsive"
            className=" text-white "
            style={{ marginLeft: '5%', marginBottom: '1%' }}
          >
            {tabs[basicActive].name}
          </MDBTypography>
          <MDBBtn
            floating
            className=" mt-3 bg-white align-self-center"
            style={{ marginRight: '5%', marginBottom: '0%' }}
            onClick={(e) => {
              e.preventDefault();
              setShowUserModal(true);
            }}
          >
            <MDBIcon
              icon="user"
              className="text-primary"
              style={{ cursor: 'pointer' }}
              size="2x"
            />
          </MDBBtn>
        </div>
        <MDBTabs className="mb-0  bg-white">
          {tabs.map(({ name }, index) => {
            // const { name } = tab;

            return (
              <MDBTabsItem className=" ">
                <MDBTabsLink
                  className=" "
                  onClick={() => handleBasicClick(index)}
                  active={basicActive === index}
                >
                  {name}
                </MDBTabsLink>
              </MDBTabsItem>
            );
          })}
        </MDBTabs>
      </div>
      <div style={{ marginTop: '20vh' }}>
        <MDBTabsContent>
          {tabs.map(({ content }, index) => {
            return (
              <MDBTabsPane show={basicActive === index}>{content}</MDBTabsPane>
            );
          })}
        </MDBTabsContent>
      </div>
    </>
  );
};

export default TopTabNavs;
