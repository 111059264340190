/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getResources } from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const ConnectResourceModal = ({
  connectResource,
  handleClose,
  show,
  fullscreen,
}) => {
  const [selectedResource, setSelectedResource] = useState('');
  const [availableRecources, setAvailableRecources] = useState('');

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resorce',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',

      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
  ];

  const dispatch = useDispatch();
  const { resources } = useSelector((state) => state.resources);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources) {
      const resourcesWithoutAccount = resources.filter(
        (res) => res.profile_id === null && res.is_active === true
      );

      setAvailableRecources(resourcesWithoutAccount);
    }
  }, [resources]);

  const handleConnectResource = () => {
    connectResource(selectedResource);
    handleClose();
  };

  const handleClickRow = (resource) => {
    setSelectedResource(resource);
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleConnectResource}
        centered={true}
        header="Lägg till resurs"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {availableRecources ? (
            <AddList
              listKey={'ConnectResourceModal'}
              columns={columns}
              rows={availableRecources.map((resource) => {
                const {
                  resource_email,
                  resource_firstname,
                  resource_lastname,
                  resource_id,
                } = resource;

                const selected = selectedResource.resource_id === resource_id;

                return {
                  clickEvent: () => handleClickRow(resource),
                  resorce: `${resource_firstname} ${resource_lastname}`,
                  email: resource_email,
                  add: (
                    <MDBIcon
                      key={resource_id}
                      style={{
                        cursor: 'pointer',
                      }}
                      icon="check-circle"
                      className={selected ? 'text-primary' : 'text-background'}
                      size="lg"
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default ConnectResourceModal;
