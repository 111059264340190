import moment from 'moment';
import { supabase } from '../../../utils/supabase';

const getUniqueRespons = (arr) => {
  let uniqueIDs = [];

  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.Respons)) {
      uniqueIDs.push(value.Respons);
    }
  });

  return uniqueIDs;
};
const getUniqueProjects = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.project_id)) {
      uniqueIDs.push(value.project_id);
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};
const getUniqueWeeks = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    const weeknumber = moment(value.response_timestamp).isoWeek();
    if (!uniqueIDs.includes(weeknumber)) {
      uniqueIDs.push(weeknumber);
      uniqueVal.push(weeknumber);
    }
  });

  return uniqueVal;
};
const getUniqueProjectQuestion = (arr) => {
  let uniqueVal = [];
  arr.forEach((value) => {
    const isFound = uniqueVal.some((uniqueValue) => {
      if (
        uniqueValue.project_id === value.project_id &&
        uniqueValue.question_id === value.question_id
      ) {
        return true;
      }

      return false;
    });

    if (!isFound) {
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};

const getUniqueCustomers = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.customer_id)) {
      uniqueIDs.push(value.customer_id);
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};

const getUniqueReporters = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.reporter)) {
      uniqueIDs.push(value.reporter);
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};
const getUniqueReporterOnProject = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.reporter)) {
      uniqueIDs.push(value.reporter);
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};

const getUniqueQuestions = (arr) => {
  let uniqueIDs = [];
  let uniqueVal = [];
  arr.forEach((value) => {
    if (!uniqueIDs.includes(value.question)) {
      uniqueIDs.push(value.question);
      uniqueVal.push(value);
    }
  });

  return uniqueVal;
};

const getResponseInfo = (arr) => {
  const sortedResponse = arr.sort(function (a, b) {
    const date1 = new Date(a.response_date);
    const date2 = new Date(b.response_date);

    return date2 - date1;
  });

  let daysFromLastResponse = '';
  let lastResponseDate = '';
  let firstResponseDate = '';

  if (sortedResponse.length > 0) {
    const today = moment().format();
    lastResponseDate = sortedResponse[0].response_date;
    firstResponseDate = sortedResponse[sortedResponse.length - 1].response_date;
    const todayFormat = moment(today, 'YYYY-MM-DD');
    const responseDate = moment(lastResponseDate, 'YYYY-MM-DD');

    const days = moment.duration(todayFormat.diff(responseDate)).asDays();

    daysFromLastResponse = parseInt(days.toFixed(0));
  }

  return { daysFromLastResponse, lastResponseDate, firstResponseDate };
};

const calculateAverageResponse = (data) => {
  let sum = 0;

  data.forEach((row) => {
    sum += row.response;
  });

  return sum / data.length;
};

const calculateTotalData = (data, firstColumnName) => {
  const { daysFromLastResponse, lastResponseDate, firstResponseDate } =
    getResponseInfo(data);

  const averageResponse = calculateAverageResponse(data);
  const numberOfResponses = data.length;
  let highestResponse = '';
  let lowestResponse = '';

  data.forEach((row) => {
    if (row.response > highestResponse || highestResponse === '') {
      highestResponse = row.response;
    }
    if (row.response < lowestResponse || lowestResponse === '') {
      lowestResponse = row.response;
    }
  });

  const row = {
    [firstColumnName]: 'Totalt',
    averageResponse: parseFloat(averageResponse.toFixed(2)),
    highestResponse: highestResponse,
    lowestResponse: lowestResponse,
    numberOfResponses: numberOfResponses,
    daysFromLastResponse: daysFromLastResponse,
    firstResponse: firstResponseDate,
    lastResponse: lastResponseDate,
  };

  return row;
};

const calculateRowsForProjectQuestionTable = (sortedData) => {
  const uniqueProjectQuestions = getUniqueProjectQuestion(sortedData);

  const rowData = [];

  uniqueProjectQuestions.forEach((uniqueRow) => {
    const rows = sortedData.filter(
      (row) =>
        row.project_id === uniqueRow.project_id &&
        row.question_id === uniqueRow.question_id
    );

    const { daysFromLastResponse, lastResponseDate, firstResponseDate } =
      getResponseInfo(rows);

    let project = '';
    let question = '';
    let projectStart = '';

    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      project = row.project;
      question = row.question;
      projectStart = row.project_start;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      project: project,
      question: question,
      projectStart: projectStart,
      daysFromLastResponse: daysFromLastResponse,
      lowestResponse: lowestResponse,
      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'customer');

  // rowData.push(totalRow);

  return rowData;
};

const calculateRowsForStatusTable = (sortedData) => {
  const reporters = getUniqueReporterOnProject(sortedData);

  const rowData = [];

  reporters.forEach((reporter) => {
    const rows = sortedData.filter((row) => row.reporter === reporter.reporter);

    const { daysFromLastResponse, lastResponseDate, firstResponseDate } =
      getResponseInfo(rows);

    let customer = '';
    let project = '';
    let projectStart = '';
    let reporterName = '';

    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      customer = row.customer;
      project = row.project;
      projectStart = row.project_start;
      reporterName = row.reporter;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      customer: customer,
      project: project,
      projectStart: projectStart,
      reporter: reporterName,
      daysFromLastResponse: daysFromLastResponse,
      lowestResponse: lowestResponse,
      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'customer');

  // rowData.push(totalRow);

  return rowData;
};
const calculateRowsForOverviewTable = (sortedData) => {
  const reporters = getUniqueReporterOnProject(sortedData);
  const filtredReporters = reporters.filter(
    (row) =>
      row.project_active === true &&
      row.reporter_active === true &&
      row.customer_active === true
  );

  const rowData = [];

  filtredReporters.forEach((reporter) => {
    const rows = sortedData.filter((row) => row.reporter === reporter.reporter);

    const { daysFromLastResponse, lastResponseDate, firstResponseDate } =
      getResponseInfo(rows);

    let customer = '';
    let project = '';
    let projectStart = '';
    let reporterName = '';

    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      customer = row.customer;
      project = row.project;
      projectStart = row.project_start;
      reporterName = row.reporter;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      customer: customer,
      project: project,
      projectStart: projectStart,
      reporter: reporterName,
      daysFromLastResponse: daysFromLastResponse,
      lowestResponse: lowestResponse,
      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'customer');

  // rowData.push(totalRow);

  return rowData;
};
const calculateRowsForCustomerTable = (sortedData, unFiltredData) => {
  const customers = getUniqueCustomers(sortedData);

  const rowData = [];

  customers.forEach((cus) => {
    const rows = sortedData.filter(
      (row) => row.customer_id === cus.customer_id
    );

    const { lastResponseDate, firstResponseDate } = getResponseInfo(rows);

    let customer = '';

    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      customer = row.customer;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      customer: customer,
      lowestResponse: lowestResponse,
      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
      numberOfReporters: getUniqueReporters(rows).length,
      numberOfProjects: getUniqueProjects(rows).length,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'customer');

  // rowData.push(totalRow);

  return rowData;
};
const calculateRowsForProjectTable = (sortedData, unFiltredData) => {
  const projects = getUniqueProjects(sortedData);

  const rowData = [];

  projects.forEach((proj) => {
    const rows = sortedData.filter((row) => row.project_id === proj.project_id);

    const { lastResponseDate, firstResponseDate } = getResponseInfo(rows);

    let project = '';
    let projectStart = '';
    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      project = row.project;
      projectStart = row.project_start;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      project: project,
      projectStart: projectStart,
      lowestResponse: lowestResponse,
      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
      numberOfReporters: getUniqueReporters(rows).length,
      numberOfCustomers: getUniqueCustomers(rows).length,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'customer');

  // rowData.push(totalRow);

  return rowData;
};
const calculateRowsForQuestionTable = (sortedData) => {
  const questions = getUniqueQuestions(sortedData);

  const rowData = [];

  questions.forEach((question) => {
    const rows = sortedData.filter((row) => row.question === question.question);
    const reporters = getUniqueReporters(rows);
    const customers = getUniqueCustomers(rows);
    const projects = getUniqueProjects(rows);

    const { lastResponseDate, firstResponseDate } = getResponseInfo(rows);

    let questionName = '';
    let lowestResponse = '';
    let averageResponse = calculateAverageResponse(rows);
    let highestResponse = '';

    let numberOfResponses = rows.length;

    rows.forEach((row) => {
      questionName = row.question;

      if (row.response < lowestResponse || lowestResponse === '') {
        lowestResponse = row.response;
      }

      if (row.response > highestResponse || highestResponse === '') {
        highestResponse = row.response;
      }
    });

    const avrRes = parseFloat(averageResponse.toFixed(2));

    rowData.push({
      question: questionName,
      lowestResponse: lowestResponse,

      averageResponse: avrRes.toFixed(2),
      highestResponse: highestResponse,
      firstResponse: firstResponseDate,
      lastResponse: lastResponseDate,
      numberOfResponses: numberOfResponses,
      numberOfReporters: reporters.length,
      numberOfCustomers: customers.length,
      numberOfProjects: projects.length,
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'question');

  // rowData.push(totalRow);

  return rowData;
};
const calculateRowsForExportTable = (data) => {
  const rowData = [];

  data.forEach((row) => {
    rowData.push({
      customer: row.customer,
      project: row.project_name,
      question: row.question,
      response: row.response,
      responseCreated: moment(row.response_date).format('YYYY-MM-DD'),
      reporter: row.reporter,
      reporterEmail: row.user_account,
      reporterActive: row.reporter_active ? 'Ja' : 'Nej',
      projectActive: row.project_active ? 'Ja' : 'Nej',
      numberOfProjectMembers: 'ingen data...',
    });
  });

  // const totalRow = calculateTotalData(sortedData, 'question');

  // rowData.push(totalRow);

  return rowData;
};

const getDataFromDB = async () => {
  let {
    data: glbCurrentQuestionnaireData,
    error: glbCurrentQuestionnaireError,
  } = await supabase.from('v_output_dashboard').select('*');

  if (glbCurrentQuestionnaireError) {
    console.log(glbCurrentQuestionnaireError);
  }
  if (glbCurrentQuestionnaireData) {
    const sortProj = getUniqueProjects(glbCurrentQuestionnaireData);
    const sortReporters = getUniqueReporters(glbCurrentQuestionnaireData);
    const sortQuestions = getUniqueQuestions(glbCurrentQuestionnaireData);
    const sortCustomers = getUniqueCustomers(glbCurrentQuestionnaireData);

    return {
      data: glbCurrentQuestionnaireData,
      uniqueProjects: sortProj,
      uniqueReporters: sortReporters,
      uniqueQuestions: sortQuestions,
      uniqueCustomers: sortCustomers,
    };
  }
};
const getFiltredDataFromDB = async ({
  showActiveProjects,
  showActiveReporters,
  selectedProjects,
  selectedReporters,
  overviewDataSelectedQuestions,
  overviewDataActiveQuestions,
  overviewDataSelectedCustomers,
  overviewDataActiveCustomers,
  overviewDataSetSelectedDate,
}) => {
  let query = supabase.from('v_output_dashboard').select('*');

  if (showActiveProjects === false || showActiveProjects === true) {
    query = query.eq('project_active', showActiveProjects);
  }
  if (showActiveReporters === false || showActiveReporters === true) {
    query = query.eq('reporter_active', showActiveReporters);
  }
  if (
    overviewDataActiveQuestions === false ||
    overviewDataActiveQuestions === true
  ) {
    query = query.eq('question_active', overviewDataActiveQuestions);
  }
  if (
    overviewDataActiveCustomers === false ||
    overviewDataActiveCustomers === true
  ) {
    query = query.eq('customer_active', overviewDataActiveCustomers);
  }
  if (selectedProjects) {
    if (selectedProjects.length > 0) {
      query = query.filter('project_id', 'in', `(${selectedProjects})`);
    }
  }
  if (selectedReporters) {
    if (selectedReporters.length > 0) {
      query = query.filter('reporter_id', 'in', `(${selectedReporters})`);
    }
  }
  if (overviewDataSelectedQuestions) {
    if (overviewDataSelectedQuestions.length > 0) {
      query = query.filter(
        'question_id',
        'in',
        `(${overviewDataSelectedQuestions})`
      );
    }
  }

  if (overviewDataSelectedCustomers) {
    if (overviewDataSelectedCustomers.length > 0) {
      query = query.filter(
        'customer_id',
        'in',
        `(${overviewDataSelectedCustomers})`
      );
    }
  }

  if (overviewDataSetSelectedDate) {
    query = query.gte('response_timestamp', overviewDataSetSelectedDate);
  }

  // console.log(
  //   'showActiveProjects',
  //   showActiveProjects,
  //   'showActiveReporters',
  //   showActiveReporters,
  //   'selectedProjects',
  //   selectedProjects,
  //   'selectedReporters',
  //   selectedReporters,
  //   'overviewDataSelectedQuestions',
  //   overviewDataSelectedQuestions,
  //   'overviewDataActiveQuestions',
  //   overviewDataActiveQuestions,
  //   'overviewDataSelectedCustomers',
  //   overviewDataSelectedCustomers,
  //   'overviewDataActiveCustomers',
  //   overviewDataActiveCustomers,
  //   'overviewDataSetSelectedDate',
  //   overviewDataSetSelectedDate
  // );

  const {
    data: glbCurrentQuestionnaireData,
    error: glbCurrentQuestionnaireError,
  } = await query;

  if (glbCurrentQuestionnaireError) {
    console.log(glbCurrentQuestionnaireError);
  }
  if (glbCurrentQuestionnaireData) {
    const sortProj = getUniqueProjects(glbCurrentQuestionnaireData);
    const sortReporters = getUniqueReporters(glbCurrentQuestionnaireData);
    const sortQuestions = getUniqueQuestions(glbCurrentQuestionnaireData);
    const sortCustomers = getUniqueCustomers(glbCurrentQuestionnaireData);

    return {
      data: glbCurrentQuestionnaireData,
      uniqueProjects: sortProj,
      uniqueReporters: sortReporters,
      uniqueQuestions: sortQuestions,
      uniqueCustomers: sortCustomers,
    };
  }
};

function calculateAvrageDaysFromLastResponse(responseData) {
  const daysFromLastResponse = [];
  let totalDaysFromLastResponse = 0;

  const data = calculateRowsForStatusTable(responseData);

  data.forEach((value) => {
    daysFromLastResponse.push(value.daysFromLastResponse);
    totalDaysFromLastResponse += value.daysFromLastResponse;
  });

  const avrageDaysFromLastResponse = totalDaysFromLastResponse / data.length;

  return avrageDaysFromLastResponse
    ? avrageDaysFromLastResponse.toFixed(0)
    : '-';
}

function calculateAvrageResponseTotal(responseData) {
  let totalResponse = 0;
  let totalResponseCount = 0;

  responseData.forEach((value) => {
    totalResponse += value.response;
    totalResponseCount++;
  });

  const avrageResponseTotal = totalResponse / totalResponseCount;

  return avrageResponseTotal ? avrageResponseTotal.toFixed(2) : '-';
}
function calculateAvrageResponseFromProjects(responseData) {
  const uniqueProjects = getUniqueProjects(responseData);
  const activeUniqueProjects = uniqueProjects.filter(
    (project) => project.project_active === true
  );

  let projectsTotalValues = activeUniqueProjects.map((project) => ({
    id: project.project_id,
    totalResponse: 0,
    numberOfResponses: 0,
  }));

  responseData.forEach((value) => {
    const foundProject = projectsTotalValues.find(
      (project) => project.id === value.project_id
    );

    if (foundProject) {
      foundProject.totalResponse += value.response;
      foundProject.numberOfResponses += 1;
    }
  });

  let avgRes = [];

  projectsTotalValues.forEach((value) => {
    avgRes.push(value.totalResponse / value.numberOfResponses);
  });

  let totalAvg = 0;

  avgRes.forEach((value) => {
    totalAvg += value;
  });

  const sum = totalAvg / avgRes.length;

  return sum ? sum.toFixed(2) : '-';
}

function calculateAvrageResponseFromProjectsOverWeeks(responseData) {
  const xData = getUniqueWeeks(responseData).sort((a, b) => a - b);

  let yData = [];
  let yDataResponse = [];

  xData.forEach((week) => {
    let avgRes = [];
    let toaltResponseValue = 0;
    let numberOfResponses = 0;

    const filtredResData = responseData.filter(
      (res) => moment(res.response_timestamp).isoWeek() === week
    );

    const uniqueProjects = getUniqueProjects(filtredResData).map((project) => ({
      id: project.project_id,
      totalResponse: 0,
      numberOfResponses: 0,
    }));

    filtredResData.forEach((value) => {
      toaltResponseValue += value.response;
      numberOfResponses += 1;

      const foundProject = uniqueProjects.find(
        (project) => project.id === value.project_id
      );

      if (foundProject) {
        foundProject.totalResponse += value.response;
        foundProject.numberOfResponses += 1;
      }
    });

    uniqueProjects.forEach((value) => {
      avgRes.push(value.totalResponse / value.numberOfResponses);
    });

    let totalAvg = 0;

    avgRes.forEach((value) => {
      totalAvg += value;
    });

    const sum = totalAvg / avgRes.length;
    const sumResponse = toaltResponseValue / numberOfResponses;

    yDataResponse.push(parseFloat(sumResponse.toFixed(2)));
    yData.push(parseFloat(sum.toFixed(2)));
  });

  return { yData, xData, yDataResponse };
}

//2022-07-07

export {
  getUniqueRespons,
  getUniqueProjects,
  getDataFromDB,
  getUniqueQuestions,
  getUniqueReporters,
  getUniqueCustomers,
  getUniqueProjectQuestion,
  calculateRowsForStatusTable,
  calculateRowsForQuestionTable,
  getFiltredDataFromDB,
  getResponseInfo,
  calculateAvrageDaysFromLastResponse,
  calculateRowsForCustomerTable,
  calculateRowsForProjectTable,
  calculateRowsForExportTable,
  calculateRowsForProjectQuestionTable,
  calculateRowsForOverviewTable,
  calculateAvrageResponseFromProjects,
  calculateAvrageResponseFromProjectsOverWeeks,
  calculateAvrageResponseTotal,
};
