/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  getOperation,
  addResource,
  getResources,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { icons } from '../../../../constants';
import CollapseSection from '../../../../components/collapse-section';
import ModalIcon from '../../../../components/modal-icon';

const AddResourceForm = ({ handleClose }) => {
  const [activeField, setActiveField] = useState('');

  const [enableValidate, setEnableValidate] = useState(false);

  const [showCreateAccount, setShowCreateAccount] = useState(false);

  const [activeOperations, setActiveOperations] = useState('');

  const [createAcc, setCreateAcc] = useState(false);
  const [createAccByInvite, setCreateAccByInvite] = useState(false);

  const internalOptions = [
    {
      value: true,
      label: 'Intern',
    },
    {
      value: false,
      label: 'Extern',
    },
  ];

  const initialValues = {
    email: '',
    name: '',
    lastname: '',
    operation: '',
    changeEmail: false,
    newEmail: '',
    createAccount: false,
    createAccountByInvite: false,
    internalResource: true,
    password: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string().required('Ange e-post!'),
    name: Yup.string().required('Ange namn!'),
    lastname: Yup.string().required('Ange efternamn!'),
    operation: Yup.string().required('Välj avdelning!'),
    password: createAcc
      ? Yup.string().min(6, 'Minst 6 tecken!').required('Ange lösenord!')
      : Yup.string().notRequired(),
  });

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { operations } = useSelector((state) => state.operation);

  useEffect(() => {
    if (!operations) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (operations) {
      setActiveOperations(operations.filter((o) => o.is_active === true));
    }
  }, [operations]);

  const handleResetForm = (resetForm) => {
    resetForm(initialValues);
  };

  const handleSubmit = (values, resetForm) => {
    // const f = {
    //   email: 'glb.analys@glbbygg.se',
    //   password: 'glbanalys',
    //   name: 'analysuser',
    //   lastname: 'glb',
    //   role: 3,
    //   createAccount: true,
    //   operation: 'e2948760-5475-4882-bcb3-dea2591b41d2',
    //   internalResource: true,
    // };
    // const digitualsClientID = 'a018c706-21e2-4c12-b604-9b9ff717a6fd';
    // dispatch(addResource(f, digitualsClientID));
    const resourceValue = {
      ...values,
      changeEmail: values.newEmail !== '' ? true : false,
    };
    dispatch(addResource(resourceValue, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getOperation(user.digituals_client_id));
      }, 500)
    );
    setShowCreateAccount(false);
    handleResetForm(resetForm);
    handleClose();
  };

  const handleGoBack = (resetForm) => {
    setShowCreateAccount(false);
    resetForm();
    handleClose();
  };

  const handleFocus = (e) => {
    setActiveField(e.target.name);
  };

  const toggleCollapse = () => {
    setShowCreateAccount(!showCreateAccount);
  };

  return (
    <>
      {initialValues !== '' && (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            return handleSubmit(values, resetForm);
          }}
          initialValues={initialValues}
          validateOnChange={enableValidate}
          validateOnBlur={enableValidate}
          enableReinitialize
        >
          {({
            handleReset,
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            resetForm,
          }) => {
            return (
              <Form
                onReset={(e) => {
                  e.preventDefault();
                  return handleGoBack(resetForm);
                }}
                noValidate
                onSubmit={(e) => {
                  setEnableValidate(true);
                  e.preventDefault();
                  return handleSubmit();
                }}
                id="AddResourceForm"
              >
                <ModalIcon icon={icons.RESOURCE} style={{ marginTop: '0%' }} />

                <Row className="mb-4">
                  <Form.Group as={Col} controlId="validationFormik01">
                    <Form.Label className="text-secondary">Förnamn</Form.Label>
                    <Form.Control
                      placeholder="Förnamn..."
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">
                      Efternamn
                    </Form.Label>
                    <Form.Control
                      placeholder="Efternamn..."
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      isValid={touched.lastname && !errors.lastname}
                      isInvalid={!!errors.lastname}
                      // onFocus={handleFocus}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">E-post</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E-post..."
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                      autoComplete="new-password"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  {activeOperations && (
                    <Form.Group as={Col} controlId="validationFormik05">
                      <Form.Label className="text-secondary">
                        Avdelning
                      </Form.Label>

                      <Field
                        component={CustomSelect}
                        placeholder="Välj avdelning..."
                        name="operation"
                        options={activeOperations.map((op) => {
                          return {
                            value: op.operation_id,
                            label: op.operation_name,
                          };
                        })}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isValid={touched.operation && !errors.operation}
                        isInvalid={!!errors.operation}
                        isClearable
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.operation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Row>
                <Row className="mb-4">
                  {internalOptions && (
                    <Form.Group as={Col}>
                      <Form.Label className="text-secondary">
                        Intern / extern
                      </Form.Label>

                      <Field
                        component={CustomSelect}
                        placeholder="Välj intern/extern..."
                        name="internalResource"
                        options={internalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isValid={
                          touched.internalResource && !errors.internalResource
                        }
                        isInvalid={!!errors.internalResource}
                        isClearable
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.internalResource}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Row>

                <CollapseSection
                  isOpen={showCreateAccount}
                  onClick={toggleCollapse}
                  label="Skapa konto"
                >
                  <Row className="mb-4 text-text">
                    <Form.Group as={Col}>
                      <Form.Check
                        label="Skapa användare med lösenord"
                        name="createAccount"
                        checked={values.createAccount}
                        value={values.createAccount}
                        onChange={handleChange}
                        type="checkbox"
                        onClick={() => {
                          values.createAccountByInvite = false;
                          setCreateAccByInvite(false);
                          return setCreateAcc(!createAcc);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  {createAcc && (
                    <>
                      <Row className="mb-4 text-text">
                        <Form.Group as={Col}>
                          <Form.Label className="text-text ">
                            E-post för konto
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="E-post..."
                            name="newEmail"
                            value={
                              activeField === 'newEmail' || values.newEmail
                                ? values.newEmail
                                : values.email
                            }
                            onChange={handleChange}
                            isValid={touched.newEmail && !errors.newEmail}
                            isInvalid={!!errors.newEmail}
                            onFocus={handleFocus}
                            autoComplete="new-password"
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.newEmail}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4 text-text">
                        <Form.Group as={Col}>
                          <Form.Label className="text-text ">
                            Lösenord för konto
                          </Form.Label>
                          <Form.Control
                            placeholder="Lösenord..."
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                            autoComplete="new-password"
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </>
                  )}
                  <Row className="mb-4 text-text">
                    <Form.Group as={Col}>
                      <Form.Check
                        label="Skapa användare via epostinbjudan"
                        name="createAccountByInvite"
                        checked={values.createAccountByInvite}
                        value={values.createAccountByInvite}
                        onChange={handleChange}
                        type="checkbox"
                        onClick={() => {
                          values.createAccount = false;
                          setCreateAccByInvite(!createAccByInvite);
                          return setCreateAcc(false);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  {createAccByInvite && (
                    <Row className="mb-4 text-text">
                      <Form.Group as={Col}>
                        <Form.Label className="text-text ">
                          Inbjudan skickas till
                        </Form.Label>
                        <Form.Control
                          readOnly
                          placeholder="E-post..."
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          // isValid={touched.password && !errors.password}
                          // isInvalid={!!errors.password}
                          // autoComplete="new-password"
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  )}
                </CollapseSection>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AddResourceForm;
