import React from 'react';
import { MDBCollapse, MDBCollapseHeader, MDBCardBody, MDBIcon } from 'mdbreact';

const CollapseSection = ({ isOpen, children, label, onClick, check }) => {
  return (
    <div>
      <MDBCollapseHeader
        tagClassName="d-flex justify-content-between bg-light"
        // onClick={() => toggleCollapse(id)}
        onClick={onClick}
        tag="h6"
      >
        {/* <div
          className="d-flex justify-content-center align-items-center mr-4"
          style={{ backgroundColor: '#fff', minWidth: '100px' }}
        >
          <MDBIcon icon="cloud" size="2x" className="m-3 black-text" />
        </div> */}
        {/* <p></p> */}
        <div style={{ marginLeft: -22, fontWeight: 'normal' }}>{label}</div>
        <div className="justify-self-end">
          {check && (
            <MDBIcon
              icon="check-circle"
              className="text-primary "
              size="lg"
              style={{ marginRight: 10 }}
            />
          )}
          <MDBIcon
            style={{ cursor: 'pointer' }}
            icon={isOpen ? 'angle-up' : 'angle-down'}
          />
        </div>
      </MDBCollapseHeader>
      <MDBCollapse isOpen={isOpen} className="bg-white">
        <MDBCardBody className="">{children}</MDBCardBody>
      </MDBCollapse>
    </div>
  );
};

export default CollapseSection;
