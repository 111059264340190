/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ModalList from '../../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';

const SurveyToConnectList = ({
  selectedSurveys,
  removeSurvey,
  add,
  setPostionValue,
  fixedPosition,
}) => {
  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Formulär</p>,
      field: 'surveyName',
    },
    // {
    //   label: <p style={{ cursor: 'pointer', float: 'right' }}>Position</p>,
    //   field: 'position',
    // },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  // const positionOptions = selectedSurveys.map((_, index) => ({
  //   value: index + 1,
  //   label: index + 1,
  // }));

  const positionOptionsQuestion = [];

  for (let index = 0; index < 10; index++) {
    positionOptionsQuestion.push({
      value: index + 1,
      label: index + 1,
    });
  }

  // const handleSetPostionValue = (value, index) => {
  //   let selectedQuestionsCopy = [...selectedSurveys];

  //   selectedQuestionsCopy[index].question_sort_order = value;
  //   setPostionValue(selectedQuestionsCopy);
  // };

  if (selectedSurveys) {
    selectedSurveys.sort(function (a, b) {
      var nameA = a.questionnaire_name.toUpperCase();
      var nameB = b.questionnaire_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  // if (surveys) {
  //   surveys.sort(function (a, b) {
  //     var nameA = a.questionnaire_name.toUpperCase();
  //     var nameB = b.questionnaire_name.toUpperCase();
  //     if (nameA < nameB) {
  //       return -1;
  //     }
  //     if (nameA > nameB) {
  //       return 1;
  //     }

  //     return 0;
  //   });
  // }

  return (
    <>
      {selectedSurveys && (
        <ModalList
          listKey={'TeammemberList'}
          add={add}
          columns={columns}
          rows={selectedSurveys.map((value, index) => {
            const {
              questionnaire_name,
              questionnaire_id,
              // question_sort_order,
            } = value;
            return {
              surveyName: questionnaire_name,

              // position: (
              //   <div style={{ float: 'right' }}>
              //     <PositionSelect
              //       options={
              //         fixedPosition ? positionOptionsQuestion : positionOptions
              //       }
              //       placeholder="-"
              //       value={question_sort_order ? question_sort_order : 1}
              //       setValue={(value) => {
              //         return handleSetPostionValue(value, index);
              //       }}
              //     />
              //   </div>
              // ),

              remove: (
                <MDBIcon
                  key={questionnaire_id}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    marginRight: '13px',
                  }}
                  icon="minus-circle"
                  className="text-primary"
                  size="lg"
                  onClick={() => removeSurvey(questionnaire_id)}
                />
              ),
            };
          })}
        />
      )}
    </>
  );
};

export default SurveyToConnectList;
