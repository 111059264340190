import React from 'react';

export default function SectionHeader({
  title = 'title',
  className = '',
  style,
}) {
  return (
    <div
      //   className=""
      className={className}
      style={style}
    >
      <p
        style={{
          fontSize: 30,
          fontWeight: 'bold',
        }}
      >
        {title}
      </p>
    </div>
  );
}
