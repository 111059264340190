/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { history } from '../../utils/history';

import { sendToast } from '../../components/toast';

import { MDBBtn, MDBIcon, MDBTypography } from 'mdbreact';

import background from '../../image/background.png';
import eqpuls from '../../image/eqpuls_logo.png';

import LogoutConfirmation from '../../components/confirm-modal/LogoutConfirmation';
import UserModal from '../../components/user-modal';
import ProjectCardList from './ProjectCardList';
import { colors } from '../../constants';

const Home = () => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [showUserModal, setShowUserModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleSetSelectedProjects = (selectedProjects) => {
    setSelectedProjects(selectedProjects);
  };

  const refresh = () => window.location.reload();

  const handleStartSurvey = () => {
    if (selectedProjects[0]) {
      history.push('/survey', {
        state: {
          selectedProjects: selectedProjects,
          selectedDate: selectedDate,
        },
      });
    } else {
    }
  };

  return (
    <>
      <LogoutConfirmation
        showModal={showLogoutModal}
        hideModal={() => setShowLogoutModal(false)}
        mobile={true}
      />
      <UserModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        logout={() => setShowLogoutModal(true)}
        fullscreen={true}
      />
      <div
        className="d-flex justify-content-between align-items-center  fixed-top"
        style={{
          // backgroundImage: background,
          // backgroundImage: `url(${background})`,
          backgroundColor: colors.PRIMARY,
          height: '70px',
          width: '105%',
          marginTop: '-10px',
          marginLeft: '-10px',
          backgroundSize: 'cover',
          minHeight: '70px',
          // fontSize: '50px',
          // backgroundSize: 'auto',
          // backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          alt=""
          src={eqpuls}
          width="120"
          height="auto"
          className="text-white mt-3"
          style={{ marginLeft: 3 }}
        />

        <MDBBtn
          floating
          color="white"
          className=" mt-2 bg-white"
          style={{ marginRight: '5%' }}
          onClick={(e) => {
            e.preventDefault();
            setShowUserModal(true);
          }}
        >
          <MDBIcon
            icon="user"
            className="text-primary"
            style={{ cursor: 'pointer' }}
            size="2x"
          />
        </MDBBtn>
      </div>

      <Container
        className="d-flex align-items-center   justify-content-center flex-column"
        style={{ marginTop: '70px' }}
      >
        <div
          className="d-flex align-self-start   justify-self-start flex-column text-primary"
          style={{ marginLeft: 0, marginTop: '0vh', cursor: 'pointer' }}
          onClick={refresh}
        >
          <MDBIcon
            fas
            icon="redo"
            className=" text-primary"
            size="sm"
            color="secondary"
            style={{ marginLeft: 0, marginBottom: -20 }}
          />
        </div>

        <div
          style={{ marginTop: -4 }}
          className="d-flex align-items-center   justify-content-center flex-column text-primary"
        >
          <MDBTypography tag="h5">Projekt</MDBTypography>
          <p>Välj projekt att ge feedback</p>
        </div>
        {/* <MDBScrollbar
          suppressScrollX
          style={{
            position: 'relative',
            width: '70vw',
            height: '500vh',
            marginTop: '3vh',
          }}
        > */}
        {/* </MDBScrollbar> */}
        <div
          style={{
            marginTop: '3vh',
            height: '350px',
            // overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <div className="mt-4 mb-4">
            <ProjectCardList
              selectedProjectsToParent={handleSetSelectedProjects}
            />
          </div>
        </div>
        {/* <ProjectListDataTable
          selectedProjectsToParent={handleSetSelectedProjects}
        /> */}
        <br />

        {/* <Form.Control
          type="date"
          name="duedate"
          placeholder="Due date"
          value={selectedDate}
          onChange={(value) => {
            setSelectedDate(value.target.value);
          }}
        /> */}

        <Button
          className="mt-0 mb-5"
          onClick={() =>
            selectedProjects[0]
              ? handleStartSurvey()
              : sendToast('Välj ett projekt för att starta!', 2, 2000, true)
          }
        >
          Start
        </Button>
      </Container>
    </>
  );
};

export default Home;
