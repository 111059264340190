/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import prevent from '../../hoc/prevent';
import logo from '../../image/logga.png';

import puls from '../../image/puls_login.png';
import LoginForm from './LoginForm';

const Login = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={
        currentWindowSize < pixelBreakPoint
          ? {
              background: `url(${puls})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom',
              backgroundSize: '100%',
            }
          : null
      }
      // style={{
      //   background:
      //     'linear-gradient(rgb(81, 126, 135), rgb(107, 160, 172)) ',
      //     'linear-gradient(rgb(0, 96, 100), rgb(0, 131, 143),rgb(0, 151, 167)) ',
      //   'linear-gradient(rgb(19,44,90), rgb(32,68,132), rgb(40,82,158))',
      //   'linear-gradient(rgb(132,60,12), rgb(197,90,17), rgb(244,177,131))',
      // }}
      className="d-felx flex-column align-items-center container-fluid d-flex justify-content-center vh-100 bg-primary "
    >
      <div
        style={
          {
            // backgroundImage: `url(${puls})`,
            // // marginTop: '50%',
            // // height: '50%',
            // // backgroundSize: 'contain',
            // backgroundSize: '120%',
            // backgroundRepeat: 'no-repeat',
            // marginLeft: '-10px',
            // marginRight: '-10px',
            // backgroundPosition: 'center bottom',
          }
        }
        className="d-felx flex-column align-items-center container-fluid d-flex justify-content-center vh-100 "
      >
        <img alt="" src={logo} width="200" height="auto" className="mb-5" />
        <LoginForm />
      </div>
      <p style={{ fontSize: 12 }} className="text-white">
        Copyright ⓒ dividuals AB all rights reserved
      </p>
    </div>
  );
};

export default prevent(Login);
