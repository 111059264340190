import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  editQuestion,
  getQuestions,
  getSurveys,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { status, icons } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';
import CollapseSection from '../../../../components/collapse-section';
import ConnectedSurveyList from './ConnectedSurveyList';

const EditQuestionForm = ({ handleClose, questionToEdit, mobile }) => {
  const [showConnectedSurveyList, setShowConnectedSurveyList] = useState(false);

  const [enableValidate, setEnableValidate] = useState(false);

  const [initialValues, setInitialValues] = useState('');

  const [question, setQuestion] = useState('');

  // const initialValues = {
  //   question: questionToEdit.question,
  //   description: questionToEdit.question_description,
  //   status: questionToEdit.is_active ? status.ACTIVE : status.INACTIVE,
  // };

  const schema = Yup.object().shape({
    question: Yup.string().max(55, 'Max 55 tecken!').notRequired(),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (questionToEdit) {
      setInitialValues({
        question: questionToEdit.question,
        description: questionToEdit.question_description,
        status: questionToEdit.is_active ? status.ACTIVE : status.INACTIVE,
      });
    }
    setQuestion(questionToEdit);
    setShowConnectedSurveyList(false);
  }, [questionToEdit]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const v = {
      ...values,
      status: status.ACTIVE === values.status ? true : false,
      question_id: questionToEdit.question_id,
    };

    dispatch(editQuestion(v, user.digituals_client_id)).then(() => {
      dispatch(getSurveys(user.digituals_client_id));
      dispatch(getQuestions(user.digituals_client_id));
    });
    handleResetForm(resetForm);
    handleClose();
  };

  const handleSetQuestion = (value) => {
    setQuestion(value);
  };

  return (
    <>
      {initialValues !== '' && (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          initialValues={initialValues}
          validateOnChange={enableValidate}
          validateOnBlur={enableValidate}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                setEnableValidate(true);
                e.preventDefault();
                return handleSubmit();
              }}
              id="EditQuestionForm"
            >
              <ModalIcon icon={icons.QUESTION} style={{ marginTop: '0%' }} />

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Fråga</Form.Label>
                  <Form.Control
                    style={{ height: '100px' }}
                    placeholder="Skriv fråga..."
                    as={'textarea'}
                    type="text"
                    name="question"
                    value={values.question}
                    onChange={handleChange}
                    isValid={touched.question && !errors.question}
                    isInvalid={!!errors.question}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.question}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Beskrivning
                  </Form.Label>
                  <Form.Control
                    as={'textarea'}
                    style={{ height: '100px' }}
                    placeholder="Skriv beskrivning..."
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isValid={touched.description && !errors.description}
                    isInvalid={!!errors.description}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Status</Form.Label>

                  {statusOptions && (
                    <Field
                      component={CustomSelect}
                      placeholder="Välj status..."
                      name="status"
                      options={statusOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.status && !errors.status}
                      isInvalid={!!errors.status}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <CollapseSection
                isOpen={showConnectedSurveyList}
                label="Länkade  formulär"
                onClick={() =>
                  setShowConnectedSurveyList(!showConnectedSurveyList)
                }
              >
                {question && (
                  <ConnectedSurveyList
                    questionToEdit={question}
                    mobile={mobile}
                    setQuestion={handleSetQuestion}
                  />
                )}
              </CollapseSection>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditQuestionForm;
