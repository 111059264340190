/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import { getQuestions } from '../../../../redux/store/actions';

import AddModal from '../../../../components/add-modal';
import AddSurveyForm from './AddSurveyForm';

const AddSurveyModal = ({ handleClose, show, mobile }) => {
  const dispatch = useDispatch();

  const { questions } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  return (
    <AddModal
      show={show}
      handleClose={handleClose}
      fullscreen={mobile ? true : false}
      submitForm={'AddSurveyForm'}
      header="Skapa formulär"
    >
      {questions ? (
        <>
          <AddSurveyForm handleClose={handleClose} />
        </>
      ) : (
        <Spinner animation="border" />
      )}
    </AddModal>
  );
};

export default AddSurveyModal;
