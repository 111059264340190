import { combineReducers } from 'redux';
import auth from './auth';
import analys from './analys';
import survey from './survey';
import projects from './project';
import questions from './question';
import resources from './resource';
import managedProjects from './projectManagement';
import response from './response';
import operation from './operation';
import customer from './customer';

const appReducers = combineReducers({
  auth,
  analys,
  survey,
  projects,
  questions,
  resources,
  managedProjects,
  response,
  operation,
  customer,
});

export default appReducers;
