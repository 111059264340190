/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { MDBIcon } from 'mdb-react-ui-kit';

import {
  getOperation,
  createAccount,
  getResources,
  getAccounts,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { sendToast } from '../../../../components/toast';

const AddAccountForm = ({
  handleClose,
  disabled,
  connectedResource,
  handleRemoveResource,
}) => {
  const [enableValidate, setEnableValidate] = useState(false);

  const [createAccByInvite, setCreateAccByInvite] = useState(false);

  const [createAcc, setCreateAcc] = useState(false);

  // const [activeOperations, setActiveOperations] = useState('');

  const initialValues = {
    email: connectedResource.resource_email,
    role: 2,
    name: `${connectedResource.resource_firstname} ${connectedResource.resource_lastname}`,
    password: '',
    createAccountByInvite: false,
    createAccount: false,
  };

  const schema = Yup.object().shape({
    email: Yup.string().required('Ange e-post!'),
    password: createAcc
      ? Yup.string().min(6, 'Minst 6 tecken!').required('Ange lösenord!')
      : Yup.string().notRequired(),
  });

  const roleOptions = [
    {
      value: 2,
      label: 'Användare',
    },
    {
      value: 1,
      label: 'Admin',
    },
  ];

  const dispatch = useDispatch();

  const { operations } = useSelector((state) => state.operation);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  // useEffect(() => {
  //   if (operations) {
  //     setActiveOperations(operations.filter((c) => c.is_active === true));
  //   }
  // }, [operations]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    if (!values.createAccByInvite && !values.createAccount) {
      return sendToast(`Vänligen välj ett alternativ för att skapa konto!`, 4);
    }

    const v = {
      ...values,
      resource: connectedResource,
    };

    dispatch(createAccount(v, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getAccounts(user.digituals_client_id));
      }, 500)
    );
    handleResetForm(resetForm);
    handleClose();
  };

  const handleGoBack = (resetForm) => {
    resetForm();
    handleClose();
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      initialValues={initialValues}
      validateOnChange={enableValidate}
      validateOnBlur={enableValidate}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        resetForm,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            setEnableValidate(true);
            e.preventDefault();
            return handleSubmit();
          }}
          id="AddAccountForm"
          onReset={(e) => {
            e.preventDefault();
            return handleGoBack(resetForm);
          }}
        >
          <Row className="mb-4">
            <Form.Group as={Col} md={10}>
              <Form.Label className="text-secondary">Resurs</Form.Label>
              <Form.Control
                type="text"
                placeholder="Resurs..."
                name="name"
                value={values.name}
                readOnly
                // plaintext
                className="bg-white"
              />
            </Form.Group>
            <Col className="d-flex align-items-center  justify-content-end ">
              <MDBIcon
                style={{ cursor: 'pointer', float: 'right', marginTop: 30 }}
                icon="minus-circle"
                className="text-white"
                size="lg"
                onClick={handleRemoveResource}
              />
            </Col>
          </Row>
          {/* START */}
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Check
                label="Skapa användare med lösenord"
                name="createAccount"
                checked={values.createAccount}
                value={values.createAccount}
                onChange={handleChange}
                type="checkbox"
                onClick={() => {
                  values.createAccountByInvite = false;
                  setCreateAcc(!createAcc);
                  setCreateAccByInvite(false);
                }}
              />
            </Form.Group>
          </Row>
          {createAcc && (
            <>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    E-post för konto
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-post..."
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.newEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Lösenord för konto
                  </Form.Label>
                  <Form.Control
                    placeholder="Lösenord..."
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={!!errors.password}
                    autoComplete="new-password"
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </>
          )}
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Check
                label="Skapa användare via epostinbjudan"
                name="createAccountByInvite"
                checked={values.createAccountByInvite}
                value={values.createAccountByInvite}
                onChange={handleChange}
                type="checkbox"
                onClick={() => {
                  values.createAccount = false;
                  setCreateAccByInvite(!createAccByInvite);
                  setCreateAcc(false);
                }}
              />
            </Form.Group>
          </Row>
          {createAccByInvite && (
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">
                  Inbjudan skickas till
                </Form.Label>
                <Form.Control
                  readOnly
                  placeholder="E-post..."
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  // isValid={touched.password && !errors.password}
                  // isInvalid={!!errors.password}
                  // autoComplete="new-password"
                />

                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}
          {/* END */}
          {/* <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">
                E-post för konto
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="E-post..."
                name="email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">
                Lösenord för konto
              </Form.Label>
              <Form.Control
                placeholder="Lösenord..."
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                isInvalid={!!errors.password}
                autoComplete="new-password"
              />

              <Form.Control.Feedback type="invalid">
                {errors.newEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Row> */}

          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">Användartyp</Form.Label>

              {roleOptions && (
                <Field
                  component={CustomSelect}
                  placeholder="Välj användartyp..."
                  name="role"
                  options={roleOptions}
                  className="basic-multi-select mb-4"
                  classNamePrefix="select"
                  isValid={touched.role && !errors.role}
                  isInvalid={!!errors.role}
                />
              )}

              <Form.Control.Feedback type="invalid">
                {errors.role}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AddAccountForm;
