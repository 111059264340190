/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  addResourceToProject,
  getProjects,
} from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddResourceToProjectModal = ({
  availableProjects,
  currentProjects,
  resourceToEdit,
  handleClose,
  show,
  fullscreen,
  done,
  mobile,
}) => {
  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: (
        <p
          key={'AddResourceToProjectModalPColumn'}
          style={{ cursor: 'pointer', float: 'left' }}
        >
          Projekt
        </p>
      ),
      field: 'name',
    },

    {
      label: (
        <p
          key={'AddResourceToProjectModalPNColumn'}
          style={{ cursor: 'pointer', float: 'left' }}
        >
          Projektnummer
        </p>
      ),
      field: 'projectNumber',
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',

      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
  ];

  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!projects) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, []);

  // useEffect(() => {
  //   if (projects) {
  //     createRowsForList();
  //   }
  // }, [projects, currentProjects]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // const selects = () => {
  //   var selec = document.getElementsByName('selectedProjects');
  //   for (var i = 0; i < selec.length; i++) {
  //     if (selec[i].type === 'checkbox') selec[i].checked = true;
  //   }

  //   setSelectedProjects(availableProjects);
  // };
  // const deSelect = () => {
  //   var selec = document.getElementsByName('selectedProjects');
  //   for (var i = 0; i < selec.length; i++) {
  //     if (selec[i].type === 'checkbox') selec[i].checked = false;
  //   }
  //   setSelectedProjects([]);
  // };

  // const createRowsForList = () => {
  //   const activeP = projects.filter((p) => p.is_active === true);

  //   setAvailableProjects(
  //     activeP.filter((x) => !currentProjects.includes(x.project_id))
  //   );
  // };

  const handleAddResourceToProject = () => {
    const projToAdd = projects.filter(
      (x) =>
        checked.includes(x.project_id) || currentProjects.includes(x.project_id)
    );

    dispatch(
      addResourceToProject(projToAdd, resourceToEdit, user.digituals_client_id)
    );
    setChecked([]);
    handleClose();
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleAddResourceToProject}
        centered={true}
        header="Koppla projekt"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {availableProjects.length > 0 ? (
            <AddList
              mobile={mobile}
              columns={columns}
              rows={availableProjects.map((proj) => {
                const { project_name, project_number, project_id } = proj;

                return {
                  name: project_name,

                  project: project_name,
                  projectNumber: project_number,
                  add: (
                    <MDBCheckbox
                      key={project_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={project_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : (
            'Inga formulär...'
          )}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddResourceToProjectModal;
