import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';

import AddModal from '../../../../components/add-modal';
import AddQuestionForm from './AddQuestionForm';

const AddQuestionModal = ({ handleClose, show, mobile }) => {
  const questionHandleClose = () => {
    handleClose();
  };

  return (
    <AddModal
      show={show}
      handleClose={handleClose}
      fullscreen={mobile ? true : false}
      submitForm={'AddQuestionForm'}
      header="Skapa fråga"
    >
      <MDBContainer className="mb-5 bg-transparent">
        <AddQuestionForm handleClose={questionHandleClose} mobile={mobile} />
      </MDBContainer>
    </AddModal>
  );
};

export default AddQuestionModal;
