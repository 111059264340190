/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MDBDatepicker } from 'mdb-react-ui-kit';
import { MDBTypography } from 'mdbreact';
import moment from 'moment';
import { TrendingUpRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { overviewDataSetSelectedDate } from '../../redux/store/actions';
import { colors } from '../../constants';

export default function CustomDatePicker({ label, setValue, options }) {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const dispatch = useDispatch();

  const { overviewDataSelectedDate } = useSelector((state) => state.analys);

  useEffect(() => {
    if (options.length > 0) {
      options.sort(function (a, b) {
        return dateComparison(a, b);
      });
      const d = moment(new Date(options[0])).format('DD/MM/YYYY');

      handleSetDate(options[0]);
    }
  }, [options]);

  // useEffect(() => {
  //   if (overviewDataSelectedDate !== null) {
  //     const d = moment(overviewDataSelectedDate).format('DD/MM/YYYY');

  //     if (date !== d) {
  //       setDate(d);
  //     }
  //   }
  // }, [overviewDataSelectedDate]);

  const handleSetSelectedDate = (value) => {
    dispatch(overviewDataSetSelectedDate(value));
  };

  const handleSetDate = (date, init = false) => {
    const [year, month, day] = date.split('-');
    // const [day, month, year] = date.split('-');

    const d = new Date(+year, month - 1, +day);
    // const d = new Date(+year, month - 1, +day);

    const timestamp = moment(d).format();

    if (init === true) {
      setDate(date);
    }
    if (init === false) {
    }
    setDate(moment(date).format('YYYY-MM-DD'));
    handleSetSelectedDate(timestamp);
  };

  function dateComparison(a, b) {
    const date1 = new Date(a);
    const date2 = new Date(b);

    return date1 - date2;
  }

  return (
    <div className="text-text">
      {/* <MDBTypography
        tag="h6"
        variant="h4-responsive"
        className="d-flex justify-content-start text-white "
      >
        {label}
      </MDBTypography> */}

      <MDBDatepicker
        format="yyyy-mm-dd"
        title=""
        weekdaysNarrow={['S', 'M', 'T', 'O', 'T', 'F', 'L']}
        startDay={1}
        inputLabel={label}
        onChange={handleSetDate}
        value={
          overviewDataSelectedDate
            ? moment(overviewDataSelectedDate).format('YYYY-MM-DD')
            : moment(date).format('YYYY-MM-DD')
        }
        // value={
        //   overviewDataSelectedDate
        //     ? moment(overviewDataSelectedDate).format('DD/MM/YYYY')
        //     : date
        // }
        className="text-text px-4"
        monthsFull={[
          'Januari',
          'February',
          'Mars',
          'April',
          'Maj',
          'Juni',
          'Juli',
          'Augusti',
          'September',
          'Oktober',
          'November',
          'December',
        ]}
        monthsShort={[
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Maj',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dec',
        ]}
        weekdaysFull={[
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ]}
        weekdaysShort={['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör']}
        okBtnText="Ok"
        clearBtnText="Rensa"
        cancelBtnText="Avbryt"
      />
    </div>
  );
}
