import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAccount,
  inactivateAccount,
  getResources,
  getAccounts,
} from '../../../../redux/store/actions';
import { MDBContainer } from 'mdb-react-ui-kit';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditModal from '../../../../components/edit-modal';
import EditAccountForm from './EditAccountForm';
import { pixels } from '../../../../constants';

const EditAccountModal = ({ handleClose, show, accountToEdit, mobile }) => {
  const [account, setAccount] = useState('');
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [fromDelete, setFromDelete] = useState(false);

  const [accountToDelete, setAccountToDelete] = useState('');

  const confirmDeleteTitle = 'ta bort fråga';
  const confirmDeleteMessage = accountToDelete
    ? `Är du säker att du vill ta bort ${accountToDelete.resource_firstname} ${accountToDelete.resource_lastname}  ?`
    : '';
  const confirmInactivationTitle = 'inaktivera konto';
  const confirmInactivationMessage = accountToDelete
    ? `Är du säker att du vill inaktivera konto ${accountToDelete.profile.profile_email}?`
    : '';
  const confirmInactivationMessageFromDelete = accountToDelete
    ? `Konto ${accountToDelete.profile.profile_email} har kopplingar till annan data och går inte att ta bort, vill du inaktivera kontot istället?`
    : '';

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setAccount(accountToEdit);
  }, [accountToEdit]);

  const handleDeleteAccount = (a) => {
    dispatch(deleteAccount(a)).then(({ payload }) => {
      dispatch(getResources(user.digituals_client_id));
      dispatch(getAccounts(user.digituals_client_id));

      if (payload.connected) {
        setFromDelete(true);

        setShowInactivateModal(true);
      }
    });
    setShowDeleteModal(false);
  };
  const handleInactivateAccount = (a) => {
    dispatch(inactivateAccount(a, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getAccounts(user.digituals_client_id));
      }, 500)
    );
    setShowInactivateModal(false);
  };

  const handleOnClose = () => {
    setAccount('');
    handleClose();
  };

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteAccount(accountToDelete)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() => handleInactivateAccount(accountToDelete)}
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />

      <EditModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        inactivate={() => {
          setFromDelete(false);
          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setAccountToDelete(account);
          setShowDeleteModal(true);
        }}
        submitForm={'EditAccountForm'}
        header="Redigera konto"
      >
        <MDBContainer
          className="mb-0 bg-transparent"
          style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
        >
          {account ? (
            <EditAccountForm
              accountToEdit={account}
              handleClose={handleOnClose}
            />
          ) : null}
        </MDBContainer>
      </EditModal>
    </>
  );
};

export default EditAccountModal;
