/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  editSurvey,
  getQuestions,
  inactivateSurvey,
  deleteSurvey,
  getSurveys,
} from '../../../../redux/store/actions';

import EditModal from '../../../../components/edit-modal';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditSurveyForm from './EditSurveyForm';

const EditSurveyModal = ({ handleClose, show, surveyToEdit, mobile }) => {
  const [selectedQuestions, setSelectedQuestions] = useState('');
  const [survey, setSurvey] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState('');
  const [surveyToInactivatee, setSurveyToInactivatee] = useState('');
  const [fromDelete, setFromDelete] = useState(false);

  const confirmDeleteTitle = 'ta bort formulär';
  const confirmDeleteMessage = surveyToDelete
    ? `Är du säker att du vill ta bort formulär ${surveyToDelete.questionnaire_name}?`
    : '';

  const confirmInactivationTitle = 'inaktivera formulär';
  const confirmInactivationMessage = surveyToInactivatee
    ? `Är du säker att du vill inaktivera formulär ${surveyToInactivatee.questionnaire_name}?`
    : '';
  const confirmInactivationMessageFromDelete = surveyToInactivatee
    ? `Formulär ${surveyToInactivatee.questionnaire_name} har kopplingar till annan data och går inte att ta bort, vill du inaktivera frågan istället?`
    : '';

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (surveyToEdit) {
      handleSetSelectedQuestions();
      setSurvey(surveyToEdit);
    }
  }, [surveyToEdit]);

  const handleSetSelectedQuestions = () => {
    // const { questionnaire_setup } = surveyToEdit;
    // const tempArr = [];
    // questionnaire_setup.forEach((qSetuo) => {
    //   const { question, question_id, is_active } = qSetuo.question;
    //   if (question && question_id && is_active) {
    //     tempArr.push({
    //       value: question_id,
    //       label: question,
    //     });
    //   }
    // });
    // setSelectedQuestions(tempArr);
  };

  const handleEditProject = () => {
    dispatch(editSurvey(survey, selectedQuestions, user.digituals_client_id));
    handleClose();
  };

  const handleDeleteSurvey = (s) => {
    dispatch(deleteSurvey(s, user.digituals_client_id)).then(({ payload }) => {
      dispatch(getSurveys(user.digituals_client_id));
      if (!payload) return;

      const { connected } = payload;

      if (connected) {
        setFromDelete(true);
        setSurveyToInactivatee(s);
        setShowInactivateModal(true);
      }
    });
    setShowDeleteModal(false);
  };

  const handleInactivateSurvey = (s) => {
    dispatch(inactivateSurvey(s, user.digituals_client_id)).then(() =>
      dispatch(getSurveys(user.digituals_client_id))
    );
    setShowInactivateModal(false);
  };

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteSurvey(surveyToDelete)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() => handleInactivateSurvey(surveyToInactivatee)}
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />
      <EditModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        inactivate={() => {
          setFromDelete(false);
          setSurveyToInactivatee(survey);
          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setSurveyToDelete(survey);
          setShowDeleteModal(true);
        }}
        // done={handleEditProject}
        header="Redigera formulär"
        submitForm="EditSurveyForm"
      >
        {survey !== '' && (
          <EditSurveyForm
            surveyToEdit={survey}
            handleClose={handleClose}
            mobile={mobile}
          />
        )}
      </EditModal>
    </>
  );
};

export default EditSurveyModal;
