/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyManagedProjects } from '../../redux/store/actions';
import { supabase } from '../../utils/supabase';

import moment from 'moment';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardFooter,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import { icons } from '../../constants';
import { sendToast } from '../../components/toast';

const ProjectCardList = ({ selectedProjectsToParent }) => {
  const [checked, setChecked] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [activeProjects, setActiveProjects] = useState([]);

  const dispatch = useDispatch();

  const { myManagedProjects } = useSelector((state) => state.managedProjects);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    supabase
      .from('reporter')
      .on('*', (payload) => {
        setTimeout(function () {
          dispatch(
            getMyManagedProjects(user.profile_id, user.digituals_client_id)
          );
        }, 500);
      })
      .subscribe();
    supabase
      .from('project')
      .on('*', (payload) => {
        setTimeout(function () {
          dispatch(
            getMyManagedProjects(user.profile_id, user.digituals_client_id)
          );
        }, 500);
      })
      .subscribe();
    supabase
      .from('project_setup')
      .on('*', (payload) => {
        setTimeout(function () {
          dispatch(
            getMyManagedProjects(user.profile_id, user.digituals_client_id)
          );
        }, 500);
      })
      .subscribe();
    supabase
      .from('questionnaire')
      .on('*', (payload) => {
        setTimeout(function () {
          dispatch(
            getMyManagedProjects(user.profile_id, user.digituals_client_id)
          );
        }, 500);
      })
      .subscribe();
    supabase
      .from('customer')
      .on('*', (payload) => {
        setTimeout(function () {
          dispatch(
            getMyManagedProjects(user.profile_id, user.digituals_client_id)
          );
        }, 500);
      })
      .subscribe();

    if (!myManagedProjects && user) {
      dispatch(getMyManagedProjects(user.profile_id, user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (myManagedProjects) {
      setActiveProjects(
        myManagedProjects.filter(
          (s) =>
            s.project_setup.project.is_active === true &&
            s.project_setup.questionnaire.is_active === true
        )
      );
    }
  }, [myManagedProjects]);

  const handleToggle = (proj, checkboxChecked) => {
    const { project } = proj.project_setup;
    const { project_id } = project;

    const newSelectedProjects = [...selectedProjects];

    if (checkboxChecked === true) {
      newSelectedProjects.push(proj);

      setSelectedProjects(newSelectedProjects);
      selectedProjectsToParent(newSelectedProjects);
    } else {
      const filteredSelectedProjects = selectedProjects.filter(
        (selectedProj) => {
          return selectedProj.project_setup.project.project_id !== project_id;
        }
      );
      setSelectedProjects(filteredSelectedProjects);
      selectedProjectsToParent(filteredSelectedProjects);
    }
  };

  const handleClickRow = (project, index) => {
    const sameQuestionnaire =
      selectedProjects[0]?.project_setup.questionnaire_id ===
      project.project_setup.questionnaire_id
        ? true
        : false;

    if (sameQuestionnaire || selectedProjects.length === 0) {
      let temp = [...checked];
      temp[index] = !checked[index];
      setChecked(temp);

      handleToggle(project, temp[index]);
    } else {
      sendToast(`Projekten har inte samma formulär!`, 2, 2000, true);
    }
  };

  return (
    <>
      {activeProjects.length > 0 ? (
        //  <MDBRow className="row-cols-1 row-cols-2 g-4">

        <MDBRow className="d-flex align-items-center justify-content-between flex-row g-4">
          {activeProjects.map((mangedProject, index) => {
            const { project } = mangedProject.project_setup;
            const { response } = mangedProject;
            const { project_name, project_number, project_id } = project;
            const { customer_name } = project.customer;

            let daysFromLastResponse = 'ingen respons...';

            // const sameQuestionnaire =
            //   selectedProjects[0]?.project_setup.questionnaire_id ===
            //   questionnaire_id
            //     ? true
            //     : false;

            if (response.length > 0) {
              const sortedResponse = response.sort(function (x, y) {
                return (
                  moment(y.response_created_at).format('YYYYMMDD') -
                  moment(x.response_created_at).format('YYYYMMDD')
                );
              });
              daysFromLastResponse = moment(
                sortedResponse[0].response_created_at
              ).format('YYYY-MM-DD');
            }

            return (
              <MDBCol
                className="d-flex align-items-center justify-content-center"
                key={project_id}
              >
                <MDBCard
                  className={
                    checked[index]
                      ? 'd-flex align-items-center   justify-content-center flex-column bg-primary'
                      : 'd-flex align-items-center   justify-content-center flex-column'
                  }
                  style={{ width: '150px', cursor: 'pointer' }}
                  onClick={() => handleClickRow(mangedProject, index)}
                >
                  <MDBCardImage
                    src={checked[index] ? icons.PROJECT_REVERT : icons.PROJECT}
                    alt="..."
                    position="top"
                    className="d-flex align-self-center mt-3"
                    style={{ maxWidth: '70px', maxHeight: '70px' }}
                  />
                  <MDBCardBody
                    className={
                      checked[index]
                        ? 'd-flex align-items-center   justify-content-center flex-column text-white'
                        : 'd-flex align-items-center   justify-content-center flex-column text-primary'
                    }
                  >
                    <MDBCardTitle
                      tag="h6"
                      className="text-center"
                      style={{ maxWidth: '150px', height: '40px' }}
                    >
                      {project_name}
                    </MDBCardTitle>

                    <p
                      style={{
                        maxWidth: '150px',
                        height: '20px',
                        fontSize: 16,
                      }}
                      className="text-center"
                    >
                      {project_number}
                    </p>
                    <p
                      style={{
                        maxWidth: '150px',
                        height: '20px',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                      className="text-center"
                    >
                      {project.customer.is_active
                        ? customer_name
                        : 'Ingen kund...'}
                    </p>
                    {/* <MDBTypography tag="h6" style={{ fontWeight: 'normal' }}>
                      {project.customer.is_active
                        ? customer_name
                        : 'Ingen kund...'}
                    </MDBTypography> */}
                  </MDBCardBody>
                  <MDBCardFooter
                    className="d-flex align-items-center   justify-content-center flex-column w-100 bg-white "
                    style={{ marginTop: -10 }}
                  >
                    <small className="d-flex align-self-center text-primary">
                      {daysFromLastResponse}
                    </small>
                  </MDBCardFooter>
                </MDBCard>
              </MDBCol>
            );
          })}
        </MDBRow>
      ) : (
        'Inga projekt kopplade...'
      )}
    </>
  );
};

export default ProjectCardList;
