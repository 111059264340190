import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  addQuestion,
  getQuestions,
  getSurveys,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { status, icons } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';
import CollapseSection from '../../../../components/collapse-section';
import AddFromModalButton from '../../../../components/add-from-modal-button';
import ConnectQuestionToSurveyModal from '../connect-question-to-survey-modal';
import SurveyToConnectList from './SurveyToConnectList';

const AddQuestionForm = ({ handleClose, mobile }) => {
  const [enableValidate, setEnableValidate] = useState(false);

  const [showSurveyList, setShowSurveyList] = useState(false);
  const [surveysToConnect, setSurveysToConnect] = useState([]);

  const [
    showConnectQuestionToSurveyModal,
    setShowConnectQuestionToSurveyModal,
  ] = useState(false);

  const initialValues = {
    question: '',
    description: '',
    status: status.ACTIVE,
  };

  const schema = Yup.object().shape({
    question: Yup.string().max(55, 'Max 55 tecken!').required('Ange fråga!'),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setShowSurveyList(false);
    setSurveysToConnect([]);
  }, [handleClose]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const v = {
      ...values,
      status: status.ACTIVE === values.status ? true : false,
      surveys: surveysToConnect,
    };

    dispatch(addQuestion(v, user.digituals_client_id)).then(() => {
      dispatch(getQuestions(user.digituals_client_id));
      dispatch(getSurveys(user.digituals_client_id));
    });
    handleResetForm(resetForm);
    handleClose();
  };

  const handleGoBack = (resetForm) => {
    resetForm();
    handleClose();
  };

  const handleConnectSurveys = (selectedSurveys) => {
    setSurveysToConnect(selectedSurveys);
  };
  const handleRemoveSurveys = (surveyID) => {
    const newReporterList = surveysToConnect.filter(
      (r) => r.questionnaire_id !== surveyID
    );

    setSurveysToConnect(newReporterList.length > 0 ? newReporterList : []);
  };

  const toggleShowSurveyList = () => setShowSurveyList(!showSurveyList);

  const handleSetPostionValue = (value) => {
    setSurveysToConnect(value);
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        initialValues={initialValues}
        validateOnChange={enableValidate}
        validateOnBlur={enableValidate}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          resetForm,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              setEnableValidate(true);
              e.preventDefault();
              return handleSubmit();
            }}
            onReset={(e) => {
              e.preventDefault();
              return handleGoBack(resetForm);
            }}
            id="AddQuestionForm"
          >
            <ModalIcon icon={icons.QUESTION} style={{ marginTop: '0%' }} />

            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Fråga</Form.Label>
                <Form.Control
                  style={{ height: '100px' }}
                  placeholder="Skriv fråga..."
                  as={'textarea'}
                  type="text"
                  name="question"
                  value={values.question}
                  onChange={handleChange}
                  isValid={touched.question && !errors.question}
                  isInvalid={!!errors.question}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.question}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Beskrivning</Form.Label>
                <Form.Control
                  as={'textarea'}
                  style={{ height: '100px' }}
                  placeholder="Skriv beskrivning..."
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isValid={touched.description && !errors.description}
                  isInvalid={!!errors.description}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Status</Form.Label>

                {statusOptions && (
                  <Field
                    component={CustomSelect}
                    placeholder="Välj status..."
                    name="status"
                    options={statusOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isValid={touched.status && !errors.status}
                    isInvalid={!!errors.status}
                  />
                )}

                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <CollapseSection
              isOpen={showSurveyList}
              label="Koppla fråga till formulär"
              onClick={toggleShowSurveyList}
            >
              {surveysToConnect.length > 0 ? (
                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <SurveyToConnectList
                      selectedSurveys={surveysToConnect}
                      removeSurvey={handleRemoveSurveys}
                      add={() => setShowConnectQuestionToSurveyModal(true)}
                      setPostionValue={handleSetPostionValue}
                      fixedPosition={true}
                    />
                  </Form.Group>
                </Row>
              ) : (
                <Row className=" mb-4">
                  <Col className="d-flex align-items-center  justify-content-start ">
                    <AddFromModalButton
                      label={'Välj formulär'}
                      onClick={() => setShowConnectQuestionToSurveyModal(true)}
                    />
                  </Col>
                </Row>
              )}
            </CollapseSection>
          </Form>
        )}
      </Formik>

      <ConnectQuestionToSurveyModal
        show={showConnectQuestionToSurveyModal}
        handleClose={() => setShowConnectQuestionToSurveyModal(false)}
        fullscreen={mobile}
        connectSurveys={handleConnectSurveys}
        connectedSurveys={surveysToConnect}
      />
    </>
  );
};

export default AddQuestionForm;
