import React from 'react';
import { Button } from 'react-bootstrap';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBContainer,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { pixels } from '../../constants';

const EditModal = ({
  handleClose,
  show,
  fullscreen,
  done,
  children,
  inactivate,
  activate,
  deleteItem,
  submitForm,
  active,
  centered,
  header,
}) => {
  return (
    <MDBModal show={show} animationDirection="right" staticBackdrop>
      <MDBModalDialog
        size={fullscreen ? 'fullscreen' : 'lg'}
        // scrollable
        centered={centered ? true : false}
        position={centered ? '' : 'top'}
      >
        <MDBModalContent className="bg-light text-secondary">
          <MDBModalHeader className="d-flex align-items-center justify-content-between border-0">
            <div style={{ width: '30%', marginLeft: 20 }}>
              <Button
                style={{ background: 'transparent' }}
                color="transparent"
                className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                onClick={handleClose}
              >
                <MDBIcon
                  fas
                  icon="chevron-left "
                  size="sm"
                  color="secondary"
                  style={{ marginLeft: -25 }}
                />

                <p
                  className=" text-secondary"
                  style={{ marginLeft: 5, fontSize: 16, marginTop: 16 }}
                >
                  Tillbaka
                </p>
              </Button>
            </div>
            {fullscreen ? null : (
              <MDBTypography variant="h5" style={{ marginTop: 10 }}>
                {header}
              </MDBTypography>
            )}

            <div
              style={{ width: '30%', marginRight: 20 }}
              className="d-flex align-items-center justify-content-end"
            >
              {submitForm ? (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  type="submit"
                  form={submitForm}
                >
                  <p
                    className=" text-secondary"
                    style={{ fontSize: 16, marginTop: 15, marginRight: -25 }}
                  >
                    Spara
                  </p>
                </Button>
              ) : (
                <Button
                  style={{ background: 'transparent' }}
                  color="transparent"
                  className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                  onClick={done}
                >
                  <p
                    className=" text-secondary"
                    style={{ fontSize: 16, marginTop: 15, marginRight: -25 }}
                  >
                    Spara
                  </p>
                </Button>
              )}
            </div>
          </MDBModalHeader>
          <MDBModalBody
            className="d-flex flex-column align-items-center justify-content-start "
            style={{ minHeight: '73vh' }}
          >
            <MDBContainer
              className="mb-5 bg-transparent text-secondary "
              style={{ maxWidth: pixels.MODAL_LIST_WIDTH, marginTop: '0px' }}
            >
              {children}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              style={{ background: 'transparent', marginRight: 20 }}
              color="transparent"
              // className=" align-items-center  text-muted d-flex flex-row"
              className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-center"
              onClick={() => {
                deleteItem();
                handleClose();
              }}
            >
              <p
                style={{
                  marginRight: -25,
                  fontSize: 16,
                }}
                className="text-secondary"
              >
                Ta bort
              </p>
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default EditModal;
