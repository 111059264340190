import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from 'mdb-react-ui-kit';

const TopTabNavsAnalys = ({ tabs, tabClick }) => {
  const [basicActive, setBasicActive] = useState(0);

  const handleBasicClick = (value) => {
    tabClick();
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <div className="fixed-top" style={{ marginTop: '75px' }}>
        <MDBTabs className="mb-0  bg-white">
          {tabs.map((tab, index) => {
            const { name } = tab;

            return (
              <MDBTabsItem
                style={{ marginLeft: 3 }}
                key={`tabs-item${index}${name}`}
              >
                <MDBTabsLink
                  key={`tabs-link${index}${name}`}
                  onClick={() => handleBasicClick(index)}
                  active={basicActive === index}
                  style={{ fontSize: 14, fontWeight: 'normal' }}
                >
                  {name}
                </MDBTabsLink>
              </MDBTabsItem>
            );
          })}
        </MDBTabs>
      </div>
      <div
        style={{
          marginTop: '5vh',
        }}
      >
        <MDBTabsContent>
          {tabs.map(({ content }, index) => {
            return (
              <MDBTabsPane
                key={`tabs-pane${index}`}
                show={basicActive === index}
              >
                {content}
              </MDBTabsPane>
            );
          })}
        </MDBTabsContent>
      </div>
    </>
  );
};

export default TopTabNavsAnalys;
