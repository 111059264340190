/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getResources,
  activateAccount,
  getAccounts,
} from '../../../redux/store/actions';

import { accountColumns, accountColumnsMobile } from './dataColumns';

import { MDBIcon } from 'mdbreact';
import ActivateConfirmation from '../../../components/confirm-modal/ActivateConfirmation';

import ActiveDatatable from '../../../components/datatables/active-datatable';

import moment from 'moment';
import EditAccountModal from './edit-account-modal/EditAccountModal';
import AddAccountModal from './add-account-modal/AddAccountModal';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const AccountTable = ({ mobile }) => {
  const [loading, setLoading] = useState(true);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState('');
  const [accountToActivate, setAccountToActivate] = useState('');
  const [rowData, setRowData] = useState([]);

  const [showInactiveAccounts, setShowInactiveAccounts] = useState(false);

  const confirmActivateTitle = 'aktivera projektresurs';
  const confirmActivateMessage = accountToActivate
    ? `Är du säker att du vill aktivera ${accountToActivate.resource_firstname} ${accountToActivate.resource_lastname}?`
    : '';

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { resources } = useSelector((state) => state.resources);
  const { accounts } = useSelector((state) => state.resources);

  useEffect(() => {
    if (user) {
      dispatch(getResources(user.digituals_client_id));
    }
    if (user) {
      dispatch(getAccounts(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources && accounts) {
      createRowsForDataset();
    }
  }, [resources, accounts, showInactiveAccounts]);

  const handleActivateAccount = (emp) => {
    dispatch(activateAccount(emp, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getAccounts(user.digituals_client_id));
      }, 500)
    );
    setShowActivationModal(false);
  };

  const handleClickRow = (e) => {
    setAccountToEdit(e);
    setShowEditAccountModal(true);
  };

  const handleCloseAddAccountModal = () => {
    setShowAddAccountModal(false);
  };

  const handleEditAccountModalOnClose = () => {
    setAccountToEdit('');
    setShowEditAccountModal(false);
  };

  const createRowsForDataset = () => {
    const filtredResources = resources.filter(
      ({ is_active }) => is_active === true
    );

    const resourcesToMap = !showInactiveAccounts ? filtredResources : resources;

    let rows = [];

    resourcesToMap.forEach((resource) => {
      const { profile_id, profile } = resource;

      if (!profile_id) return;

      const findAccount = accounts.find((acc) => acc.id === profile_id);

      const res = {
        ...resource,
        account: findAccount,
      };

      const row = {
        clickEvent: () => handleClickRow(res),

        email: profile?.profile_email,
        status: profile?.is_active ? 'Aktiv' : 'Inaktiv',
        createdAt: profile?.profile_created_at
          ? moment(profile?.profile_created_at).format('YYYY-MM-DD')
          : '',

        role: profile?.profile_role === 1 ? 'Admin' : 'Användare',
        inviteSent: moment(res.account?.created_at).format('YYYY-MM-DD'),
        inviteConfirmed: res.account?.email_confirmed_at
          ? moment(res.account?.email_confirmed_at).format('YYYY-MM-DD')
          : 'Ej bekräftad',

        activate: (
          <MDBIcon
            icon="check"
            className="text-success"
            size="lg"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setAccountToActivate(resource);
              setShowActivationModal(true);
            }}
          />
        ),
      };

      rows.push(row);
    });

    setRowData(rows);
    setLoading(false);
  };

  const handleToggleShowActive = () =>
    setShowInactiveAccounts(!showInactiveAccounts);

  if (resources) {
    resources.sort(function (a, b) {
      var nameA = a.resource_firstname.toUpperCase();
      var nameB = b.resource_firstname.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <>
      <div className="mb-5">
        <ActivateConfirmation
          title={confirmActivateTitle}
          showModal={showActivationModal}
          hideModal={() => setShowActivationModal(false)}
          confirmActivate={() => handleActivateAccount(accountToActivate)}
          message={confirmActivateMessage}
        />

        {!loading ? (
          <>
            {!mobile && (
              <ShowOrHideInactiveButton
                onClick={handleToggleShowActive}
                text={
                  showInactiveAccounts
                    ? 'Göm inaktiva konton'
                    : 'Visa inaktiva konton'
                }
              />
            )}

            <ActiveDatatable
              listKey={'AccountTable'}
              columns={mobile ? accountColumnsMobile : accountColumns}
              rows={rowData}
              mobile={mobile ? true : false}
              noAddBtn={false}
              add={() => setShowAddAccountModal(true)}
            />
          </>
        ) : (
          <Spinner animation="border" />
        )}

        <EditAccountModal
          show={showEditAccountModal}
          accountToEdit={accountToEdit}
          handleClose={handleEditAccountModalOnClose}
        />
        <AddAccountModal
          show={showAddAccountModal}
          handleClose={handleCloseAddAccountModal}
        />
      </div>
    </>
  );
};

export default AccountTable;
