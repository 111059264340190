/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AnalysNavbar from '../../../components/navbar/AnalysNavbar';

import TopTabNavsAnalys from '../../../components/top-tab-navs-analys';
import {
  calculateRowsForStatusTable,
  getDataFromDB,
  getFiltredDataFromDB,
  calculateRowsForQuestionTable,
  calculateAvrageDaysFromLastResponse,
  calculateRowsForCustomerTable,
  calculateRowsForProjectTable,
  calculateRowsForProjectQuestionTable,
  calculateRowsForExportTable,
} from '../calculate-functions';
import DashboardControls from './DashboardControls';
import {
  columns,
  columnsProjectQuestion,
  columnsResponse,
  columnsQuestion,
  columnsOverview,
  columnsCustomer,
  columnsProjects,
} from './dataColumns';
import PlotlyGauge from '../../../components/plotly/PlotlyGauge';
import AnalysDatatable from '../../../components/datatables/analys-datatable';
import {
  activeOptionsProjects,
  activeOptionsReporters,
  activeOptionsQuestions,
  activeOptionsCustomers,
  activeOptions,
} from './activeOptions';
import Moment from '../../../components/moment';
import { MDBDatatable } from 'mdb-react-ui-kit';
import AnalysExport from '../analys-export';
import { analysDatatableOptions } from '../../../constants';

// const MARGIN_TOP = -87;
const MARGIN_TOP = 50;
const MARGIN_X = 100;

const AnalysOverview = () => {
  const [dbData, setDbData] = useState([]);
  const [filtredData, setfiltredData] = useState([]);

  const [projects, setProjects] = useState([]);
  const [reporters, setReporters] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [dates, setDates] = useState([]);

  const [selectedReporters, setSelectedReporters] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(null);

  const [sortedReporters, setSortedReporters] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [sortedQuestions, setSortedQuestions] = useState([]);
  const [sortedCustomers, setSortedCustomers] = useState([]);

  const [showActiveProjects, setShowActiveProjects] = useState('');
  const [showActiveReporters, setShowActiveReporters] = useState('');

  const [dataToExport, setDataToExport] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const {
    overviewDataSelectedQuestions,
    overviewDataActiveQuestions,
    overviewDataSelectedCustomers,
    overviewDataActiveCustomers,
    overviewDataSelectedDate,
  } = useSelector((state) => state.analys);

  useEffect(() => {
    getDataFromDB(user).then((value) => {
      setDbData(value.data);
      setfiltredData(value.data);
      setSortedProjects(value.uniqueProjects);
      setSortedReporters(value.uniqueReporters);
      setSortedQuestions(value.uniqueQuestions);
      setSortedCustomers(value.uniqueCustomers);

      setQuestions(
        value.uniqueQuestions.map((value) => ({
          isDisabled: false,
          is_active: value.question_active,
          label: value.question,
          value: value.question_id,
          id: value.question_id,
        }))
      );

      setProjects(
        value.uniqueProjects.map((value) => ({
          isDisabled: false,
          is_active: value.project_active,
          label: value.project_name,
          value: value.project_id,
        }))
      );

      setReporters(
        value.uniqueReporters.map((value) => ({
          isDisabled: false,
          is_active: value.user_account_active,
          label: value.reporter,
          value: value.reporter_id,
        }))
      );

      setCustomers(
        value.uniqueCustomers.map((value) => ({
          isDisabled: false,
          is_active: value.customer_active,
          label: value.customer,
          value: value.customer_id,
        }))
      );

      setDates(
        value.data.map((value) =>
          Moment(value.response_timestamp, 'YYYY-MM-DD')
        )
      );
    });
  }, []);

  useEffect(() => {
    if (sortedProjects) {
      const filtredRows = filterDbData();

      setfiltredData(filtredRows);
      setDataToExport(filtredRows);
    }
  }, [
    showActiveProjects,
    showActiveReporters,
    selectedProjects,
    selectedReporters,
    overviewDataSelectedQuestions,
    overviewDataActiveQuestions,
    overviewDataSelectedCustomers,
    overviewDataActiveCustomers,
    overviewDataSelectedDate,
  ]);

  function filterDbData() {
    let data = [...dbData];

    if (showActiveProjects === false || showActiveProjects === true) {
      data = data.filter(
        (value) => value.project_active === showActiveProjects
      );
    }
    if (showActiveReporters === false || showActiveReporters === true) {
      data = data.filter(
        (value) => value.reporter_active === showActiveReporters
      );
    }
    if (
      overviewDataActiveQuestions === false ||
      overviewDataActiveQuestions === true
    ) {
      data = data.filter(
        (value) => value.question_active === overviewDataActiveQuestions
      );
    }
    if (
      overviewDataActiveCustomers === false ||
      overviewDataActiveCustomers === true
    ) {
      data = data.filter(
        (value) => value.customer_active === overviewDataActiveCustomers
      );
    }
    if (selectedProjects) {
      if (selectedProjects.length > 0) {
        data = data.filter((value) => {
          const inc = selectedProjects.includes(value.project_id);
          if (inc) {
            return value;
          } else {
            return null;
          }
        });
      }
    }
    if (selectedReporters) {
      if (selectedReporters.length > 0) {
        data = data.filter((value) => {
          const inc = selectedReporters.includes(value.reporter_id);
          if (inc) {
            return value;
          } else {
            return null;
          }
        });
      }
    }
    if (overviewDataSelectedQuestions) {
      if (overviewDataSelectedQuestions.length > 0) {
        data = data.filter((value) => {
          const inc = overviewDataSelectedQuestions.includes(value.question_id);
          if (inc) {
            return value;
          } else {
            return null;
          }
        });
      }
    }

    if (overviewDataSelectedCustomers) {
      if (overviewDataSelectedCustomers.length > 0) {
        data = data.filter((value) => {
          const inc = overviewDataSelectedCustomers.includes(value.customer_id);
          if (inc) {
            return value;
          } else {
            return null;
          }
        });
      }
    }

    if (overviewDataSelectedDate) {
      data = data.filter(
        (value) => value.response_timestamp >= overviewDataSelectedDate
      );
    }

    return data;
  }

  const handleSetValuesProject = (values) => {
    const filtredProjects = values.filter((o) => o.value !== '*');
    const selectedProjects = filtredProjects.map((project) => project.value);

    setSelectedProjects(selectedProjects);
  };
  const handleSetValuesRepoters = (values) => {
    const filtredReporters = values.filter((o) => o.value !== '*');
    const selectedReporters = filtredReporters.map(
      (reporter) => reporter.value
    );

    setSelectedReporters(selectedReporters);
  };

  const handleSetActiveProject = (value) => {
    setShowActiveProjects(value);
  };
  const handleSetActiveReporter = (value) => {
    setShowActiveReporters(value);
  };

  function handleResetFilter() {
    setfiltredData(dbData);
    setDataToExport(dbData);
  }

  return (
    <>
      <AnalysNavbar />
      {dbData ? (
        <div
          className="d-flex justify-content-center flex-column "
          style={{
            marginTop: '120px',
          }}
        >
          <DashboardControls
            projects={projects}
            reporters={reporters}
            questions={questions}
            customers={customers}
            dates={dates}
            activeOptionsCustomers={activeOptions}
            activeOptionsProjects={activeOptions}
            activeOptionsQuestions={activeOptions}
            activeOptionsReporters={activeOptions}
            handleSetValuesProject={handleSetValuesProject}
            handleSetValuesRepoters={handleSetValuesRepoters}
            handleSetActiveProject={handleSetActiveProject}
            handleSetActiveReporter={handleSetActiveReporter}
            handleResetFilter={handleResetFilter}
          />

          <TopTabNavsAnalys
            tabClick={() => console.log('tabClick')}
            tabs={[
              {
                name: 'Kund/Projekt/Rapportör',
                content: (
                  <>
                    <div
                      className="d-flex flex-column  overflow-hidden "
                      style={{
                        marginTop: MARGIN_TOP,
                        marginRight: MARGIN_X,
                        marginLeft: MARGIN_X,
                      }}
                    >
                      <AnalysDatatable
                        mobile={false}
                        columns={columns}
                        rows={calculateRowsForStatusTable(filtredData).sort(
                          function (a, b) {
                            const date1 = new Date(a.lastResponse);
                            const date2 = new Date(b.lastResponse);

                            return date2 - date1;
                          }
                        )}
                        search
                        entries={1000}
                        datePickerOptions={dates}
                        useDatepicker
                        fixedHeader
                        maxHeight={analysDatatableOptions.MAX_HEIGHT}
                        showPrint
                        printTitle="Kund/Projekt/Rapportör"
                      />
                    </div>
                  </>
                ),
              },
              {
                name: 'Projekt/Fråga',
                content: (
                  <>
                    <div
                      className="d-flex flex-column  overflow-hidden "
                      style={{
                        marginTop: MARGIN_TOP,
                        marginRight: MARGIN_X,
                        marginLeft: MARGIN_X,
                      }}
                    >
                      <AnalysDatatable
                        mobile={false}
                        columns={columnsProjectQuestion}
                        rows={calculateRowsForProjectQuestionTable(
                          filtredData
                        ).sort(function (a, b) {
                          const date1 = new Date(a.lastResponse);
                          const date2 = new Date(b.lastResponse);

                          return date2 - date1;
                        })}
                        search={true}
                        entries={1000}
                        datePickerOptions={dates}
                        useDatepicker
                        fixedHeader
                        maxHeight={analysDatatableOptions.MAX_HEIGHT}
                        showPrint
                        printTitle="Projekt/Fråga"
                      />
                    </div>
                  </>
                ),
              },
              {
                name: 'Kund',
                content: (
                  <>
                    <div
                      className="d-flex flex-column  overflow-hidden "
                      style={{
                        marginTop: MARGIN_TOP,
                        marginRight: MARGIN_X,
                        marginLeft: MARGIN_X,
                      }}
                    >
                      <AnalysDatatable
                        mobile={false}
                        columns={columnsCustomer}
                        rows={calculateRowsForCustomerTable(filtredData).sort(
                          function (a, b) {
                            const date1 = new Date(a.lastResponse);
                            const date2 = new Date(b.lastResponse);

                            return date2 - date1;
                          }
                        )}
                        entries={1000}
                        search={true}
                        datePickerOptions={dates}
                        useDatepicker
                        fixedHeader
                        maxHeight={analysDatatableOptions.MAX_HEIGHT}
                        showPrint
                        printTitle="Kund"
                      />
                    </div>
                  </>
                ),
              },
              {
                name: 'Projekt',
                content: (
                  <>
                    <div
                      className="d-flex flex-column  overflow-hidden "
                      style={{
                        marginTop: MARGIN_TOP,
                        marginRight: MARGIN_X,
                        marginLeft: MARGIN_X,
                      }}
                    >
                      <AnalysDatatable
                        mobile={false}
                        columns={columnsProjects}
                        rows={calculateRowsForProjectTable(filtredData).sort(
                          function (a, b) {
                            const date1 = new Date(a.lastResponse);
                            const date2 = new Date(b.lastResponse);

                            return date2 - date1;
                          }
                        )}
                        search={true}
                        entries={1000}
                        datePickerOptions={dates}
                        useDatepicker
                        fixedHeader
                        maxHeight={analysDatatableOptions.MAX_HEIGHT}
                        showPrint
                        printTitle="Projekt"
                      />
                    </div>
                  </>
                ),
              },
              {
                name: 'Fråga',
                content: (
                  <>
                    <div
                      className="d-flex flex-column  overflow-hidden "
                      style={{
                        marginTop: MARGIN_TOP,
                        marginRight: MARGIN_X,
                        marginLeft: MARGIN_X,
                      }}
                    >
                      <AnalysDatatable
                        mobile={false}
                        columns={columnsQuestion}
                        rows={calculateRowsForQuestionTable(filtredData).sort(
                          function (a, b) {
                            const date1 = new Date(a.lastResponse);
                            const date2 = new Date(b.lastResponse);

                            return date2 - date1;
                          }
                        )}
                        entries={1000}
                        search={true}
                        datePickerOptions={dates}
                        useDatepicker
                        fixedHeader
                        maxHeight={analysDatatableOptions.MAX_HEIGHT}
                        showPrint
                        printTitle="Fråga"
                      />
                    </div>
                  </>
                ),
              },
              {
                name: 'Respons',
                content: (
                  <>
                    <AnalysExport
                      rows={calculateRowsForExportTable(filtredData)}
                      dataToExport={dataToExport}
                      datePickerOptions={dates}
                      marginX={MARGIN_X}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      ) : (
        'Ingen data...'
      )}
    </>
  );
};

export default AnalysOverview;
