/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getResources, getOperation } from '../../../redux/store/actions';
import { resourceColumns, resourceColumnsMobile } from './dataColumns';
import EditResourceModal from './edit-resource-modal/EditResourceModal';
import AddResourceModal from './add-resource-modal/AddResourceModal';
import { MDBIcon } from 'mdbreact';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const ResourcesTable = ({ handleOpenResourceModal, mobile }) => {
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [showEditResourceModal, setShowEditResourceModal] = useState(false);
  const [resourceToEdit, setResourceToEdit] = useState('');

  const [showInactiveResources, setShowInactiveResources] = useState(false);

  const [rows, setRows] = useState('');

  const dispatch = useDispatch();

  const { resources } = useSelector((state) => state.resources);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getResources(user.digituals_client_id));
    }
    if (user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources) {
      createRowsForDatasets();
    }
  }, [resources, showInactiveResources]);

  const createRowsForDatasets = () => {
    const filtredResources = resources.filter(
      ({ is_active }) => is_active === true
    );

    const resourcesToMap = !showInactiveResources
      ? filtredResources
      : resources;

    const rows = resourcesToMap.map((resource) => {
      const {
        resource_firstname,
        resource_lastname,
        resource_id,
        resource_email,
        resource_internal,
        operation,
        is_active,
        profile,
        team,
      } = resource;

      let numberOfProjects = 0;

      if (team) {
        team.forEach((t) => {
          if (t.project.is_active) {
            numberOfProjects = numberOfProjects + 1;
          }
        });
      }

      return {
        clickEvent: () => handleClickRow(resource),
        name: resource_firstname,
        lastname: resource_lastname,
        operation:
          operation && operation.is_active === true
            ? operation.operation_name
            : 'Ingen avdelning vald...',
        email: resource_email,
        numberOfProjects: numberOfProjects,
        status: is_active ? 'Aktiv' : 'Inaktiv',
        internalResource: resource_internal ? 'Intern' : 'Extern',
        haveAccount: profile?.is_active ? (
          <MDBIcon
            icon="check-circle"
            className="text-primary"
            size="lg"
            key={`icon-${resource_id}`}
          />
        ) : (
          <MDBIcon
            icon="check-circle"
            className="text-background"
            size="lg"
            key={`icon-2-${resource_id}`}
          />
        ),
      };
    });

    setRows(rows);
  };

  const handleClickRow = (e) => {
    setResourceToEdit(e);
    setShowEditResourceModal(true);
  };

  const handleToggleShowActive = () =>
    setShowInactiveResources(!showInactiveResources);

  if (resources) {
    if (resources.length > 0) {
      resources.sort(function (a, b) {
        var nameA = a.resource_firstname.toUpperCase();
        var nameB = b.resource_firstname.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }
  }

  return (
    <>
      <div className="mb-5">
        {rows ? (
          <>
            {!mobile && (
              <ShowOrHideInactiveButton
                onClick={handleToggleShowActive}
                text={
                  showInactiveResources
                    ? 'Göm inaktiva resurser'
                    : 'Visa inaktiva resurser'
                }
              />
            )}

            <ActiveDatatable
              listKey={'ResourcesTable'}
              mobile={mobile}
              columns={mobile ? resourceColumnsMobile : resourceColumns}
              rows={rows}
              add={() => setShowAddResourceModal(true)}
            />
          </>
        ) : (
          <Spinner animation="border" />
        )}

        <>
          <AddResourceModal
            mobile={mobile}
            handleClose={() => setShowAddResourceModal(false)}
            show={showAddResourceModal}
          />
        </>

        <EditResourceModal
          mobile={mobile}
          show={showEditResourceModal}
          resourceToEdit={resourceToEdit}
          handleClose={() => {
            setShowEditResourceModal(false);
            setResourceToEdit('');
          }}
        />
      </div>
    </>
  );
};

export default ResourcesTable;
