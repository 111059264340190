/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { logoutUser } from '../../firebase-api/user';

const LogoutConfirmation = ({ showModal, hideModal, mobile }) => {
  const [currentUser, setCurrentUser] = useState('');
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    hideModal();
    window.location.reload();
  };

  return (
    <MDBModal show={showModal} animationDirection="" staticBackdrop>
      <MDBModalDialog centered size="sm">
        <MDBModalContent className="bg-light">
          <MDBModalBody className="d-flex align-items-center justify-content-center flex-column">
            {currentUser ? (
              <div className="alert alert-white text-secondary">
                Är du säker på att du vill logga ut{' '}
                {currentUser.resource_firstname} {currentUser.resource_lastname}
                ?
              </div>
            ) : null}

            <div className="d-flex align-selft-start justify-content-between flex-row  w-100 mt-0">
              <p
                className=" text-secondary"
                style={{ marginLeft: 5, fontWeight: 'bold', cursor: 'pointer' }}
                onClick={hideModal}
              >
                Avbryt
              </p>

              <p
                className=" text-secondary mb-4"
                onClick={handleLogout}
                style={{
                  cursor: 'pointer',
                  marginLeft: 20,
                  fontWeight: 'bold',
                }}
              >
                Logga ut
              </p>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
    // <MDBModal show={showModal} animationDirection="right">
    //   <MDBModalDialog size={mobile ? 'fullscreen' : 'lg'} centered>
    //     <MDBModalContent className="bg-light">
    //       <MDBModalHeader>
    //         <MDBBtn
    //           tag="a"
    //           color="none"
    //           className="mt-0 align-items-center  text-muted d-flex flex-row"
    //           onClick={hideModal}
    //         >
    //           <MDBIcon fas icon="chevron-left " size="lg" color="secondary" />

    //           <p className="mt-3 text-secondary" style={{ marginLeft: 5 }}>
    //             tillbaka
    //           </p>
    //         </MDBBtn>
    //       </MDBModalHeader>
    //       <MDBModalBody className="d-flex align-items-center justify-content-center">
    //         {currentUser ? (
    //           <div className="alert alert-white text-secondary">
    //             är du säker på att du vill logga ut{' '}
    //             {currentUser.resource_firstname} {currentUser.resource_lastname}
    //             ?
    //           </div>
    //         ) : null}
    //       </MDBModalBody>

    //       <MDBModalFooter className="d-flex flex-column align-items-start justify-content-center ">
    //         <p
    //           className=" text-danger mb-4"
    //           onClick={handleLogout}
    //           style={{ cursor: 'pointer', marginLeft: 20 }}
    //         >
    //           logga ut
    //         </p>
    //       </MDBModalFooter>
    //     </MDBModalContent>
    //   </MDBModalDialog>
    // </MDBModal>
  );
};

export default LogoutConfirmation;
