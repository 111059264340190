export const ADMIN_HOME = '/admin-home';
export const ANALYSIS = '/analysis';
export const PROJECTS = '/project';
export const QUESTIONS = '/questions';
export const USERS = '/users';
export const PROJECT_MANAGEMENT = '/project-management';
export const SURVEY_MANAGEMENT = '/survey-management';
export const RESPONSE = '/response';
export const OVERVIEW = '/analys-overview';
export const STATUS = '/analys-status';
export const ANALYS = '/analys-analys';
export const EXPORT = '/analys-export';
export const FONT_SIZE_NAVBAR = 14;
