/* eslint-disable import/no-anonymous-default-export */
import {
  AUTH_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
} from '../types';

const INITIAL_STATE = {
  isAuth: false,
  user: null,
  checkingAuth: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...action.payload, checkingAuth: true };

    case LOGOUT_USER:
      return { ...state, user: null, isAuth: false };
    case RESET_PASSWORD:
      return state;
    case UPDATE_USER_PASSWORD:
      return state;
    case UPDATE_USER:
      return { ...state, user: action.payload };

    default:
      return state;
  }
}
