import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from 'mdb-react-ui-kit';
import { Container } from 'react-bootstrap';

const TopTabNavsDesktop = ({ tabs }) => {
  const [basicActive, setBasicActive] = useState(0);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <div className="fixed-top" style={{ marginTop: '75px' }}>
        <MDBTabs className="mb-0  bg-white">
          {tabs.map((tab, index) => {
            const { name } = tab;

            return (
              <MDBTabsItem className="" key={`tabs-item${index}${name}`}>
                <MDBTabsLink
                  key={`tabs-link${index}${name}`}
                  onClick={() => handleBasicClick(index)}
                  active={basicActive === index}
                >
                  {name}
                </MDBTabsLink>
              </MDBTabsItem>
            );
          })}
        </MDBTabs>
      </div>
      <div style={{ marginTop: '20vh' }} className="mx-5">
        <MDBTabsContent>
          {tabs.map((tab, index) => {
            const { content } = tab;
            return (
              <MDBTabsPane
                key={`tabs-pane${index}`}
                show={basicActive === index}
              >
                {content}
              </MDBTabsPane>
            );
          })}
        </MDBTabsContent>
      </div>
    </>
  );
};

export default TopTabNavsDesktop;
