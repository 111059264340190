/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_CUSTOMER,
  GET_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,
  ACTIVATE_CUSTOMER,
  INACTIVATE_CUSTOMER,
} from '../types';

const INITIAL_STATE = {
  customers: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CUSTOMER:
      return { ...state };
    case ACTIVATE_CUSTOMER:
      return { ...state };
    case INACTIVATE_CUSTOMER:
      return { ...state };
    case GET_CUSTOMER:
      return { customers: action.payload };
    case EDIT_CUSTOMER:
      return { ...state };
    case DELETE_CUSTOMER:
      return { ...state };
    default:
      return state;
  }
}
