import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';
import { MDBTooltip } from 'mdb-react-ui-kit';

function FloatingHoverButton({
  icon = 'download',
  hoverText = 'hover text',
  onClick,
  top = '90vh',
}) {
  return (
    <div
      style={{
        position: 'fixed',
        right: '7px',
        top: top,
        marginTop: 5,
        zIndex: 2000,
      }}
    >
      <MDBTooltip
        wrapperProps={{
          color: 'primary',
        }}
        wrapperClass="bg-transparent text-white"
        className="bg-primary"
        placement="left"
        title={hoverText}
      >
        <MDBBtn
          floating
          onClick={onClick}
          tag="a"
          style={{
            cursor: 'pointer',
            zIndex: 2000,
          }}
          size="lg"
        >
          <MDBIcon fas icon={icon} size="lg" color="white" />
        </MDBBtn>
      </MDBTooltip>
    </div>
  );
}

export default FloatingHoverButton;
