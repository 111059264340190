import { supabase } from '../utils/supabase';
import { sendToast } from '../components/toast';
import moment from 'moment';

const addCustomer = async (
  { customer, description, status },
  digitualsClientID
) => {
  try {
    const { data, error } = await supabase.from('customer').insert([
      {
        customer_name: customer,
        customer_description: description,
        is_active: status,
        digituals_client_id: digitualsClientID,
      },
    ]);

    if (data) {
      sendToast(`${customer} lades till som kund`, 1);
    }
    if (error) {
      console.log('addCustomer error: ', error);
    }
  } catch (error) {
    console.log('addCustomer error', error, error.message);
  }
};

const getCustomer = async (digitualsClientID) => {
  try {
    let { data: customer, error } = await supabase
      .from('customer')
      .select(
        `
      *,
      project (
        *
      )
    `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (customer) {
      return customer;
    }
    if (error) {
      console.log('getCustomer error: ', error);
    }
  } catch (error) {
    console.log('getCustomer error', error, error.message);
  }
};
const editCustomer = async (c, digitualsClientID) => {
  const { customer, description, status, customer_id } = c;

  try {
    const { data, error } = await supabase
      .from('customer')
      .update({
        customer_name: customer,
        customer_description: description,
        customer_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        is_active: status,
      })
      .eq('customer_id', customer_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`${customer} redigerad`, 1);
    }
    if (error) {
      console.log('editCustomer error: ', error);
    }
  } catch (error) {
    console.log('editCustomer error', error, error.message);
  }
};

const activateCustomer = async (c, digitualsClientID) => {
  const { customer_name, customer_id } = c;

  try {
    const { data, error } = await supabase
      .from('customer')
      .update({ is_active: true })
      .eq('customer_id', customer_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`${customer_name} aktiverad`, 1);
    }
    if (error) {
      console.log('activateCustomer error: ', error);
    }
  } catch (error) {
    console.log('activateCustomer error', error, error.message);
  }
};

const inactivateCustomer = async (c, digitualsClientID) => {
  const { customer_name, customer_id } = c;

  try {
    const { data, error } = await supabase
      .from('customer')
      .update({ is_active: false })
      .eq('customer_id', customer_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`${customer_name} inaktiverad`, 3);
    }
    if (error) {
      console.log('inactivateCustomer error: ', error);
    }
  } catch (error) {
    console.log('inactivateCustomer error', error, error.message);
  }
};

const deleteCustomer = async (c, digitualsClientID) => {
  const { customer_name, customer_id } = c;
  try {
    const { data, error } = await supabase
      .from('customer')
      .delete()
      .eq('customer_id', customer_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`Kund ${customer_name} har tagits bort! `, 4);
    }
    if (error) {
      console.log('deleteCustomer error: ', error);
      const { code } = error;
      if (code === '23503') {
        return { connected: true };
      }
    }
  } catch (error) {
    console.log('deleteCustomer error', error, error.message);
  }
};

export {
  addCustomer,
  getCustomer,
  editCustomer,
  deleteCustomer,
  activateCustomer,
  inactivateCustomer,
};
