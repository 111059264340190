/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveys } from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const ConnectQuestionToSurveyModal = ({
  connectSurveys,
  handleClose,
  show,
  fullscreen,
  connectedSurveys,
}) => {
  const [available, setAvailable] = useState('');

  const [selected, setSelected] = useState('');

  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Formulär</p>,
      field: 'surveyName',
    },

    {
      label: '',

      field: 'add',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { surveys } = useSelector((state) => state.survey);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!surveys && user) {
      dispatch(getSurveys(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (surveys) {
      const activeResources = surveys.filter(
        (value) => value.is_active === true
      );

      if (connectedSurveys) {
        const selectedSurveyIDs = connectedSurveys.map(
          (value) => value.questionnaire_id
        );

        setSelected(selectedSurveyIDs);

        setAvailable(
          activeResources.filter(
            (x) => !selectedSurveyIDs.includes(x.questionnaire_id)
          )
        );
      } else {
        setAvailable(activeResources);
      }
    }
  }, [surveys, connectedSurveys]);

  const handleConnectSurveys = () => {
    const surveysToAdd = surveys.filter(
      (x) =>
        checked.includes(x.questionnaire_id) ||
        selected.includes(x.questionnaire_id)
    );

    const mappedSurveys = surveysToAdd?.map((value) => ({
      questionnaire_name: value.questionnaire_name,
      questionnaire_id: value.questionnaire_id,
      question_sort_order: 1,
    }));

    connectSurveys(mappedSurveys ? mappedSurveys : surveysToAdd);
    setChecked([]);
    handleClose();
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleConnectSurveys}
        centered={true}
        header="Välj formulär"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {available.length > 0 ? (
            <AddList
              columns={columns}
              rows={available.map((value) => {
                const { questionnaire_name, questionnaire_id } = value;

                return {
                  surveyName: questionnaire_name,

                  add: (
                    <MDBCheckbox
                      key={questionnaire_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={questionnaire_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default ConnectQuestionToSurveyModal;
