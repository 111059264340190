import React from 'react';

import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../redux/store/actions';

import NavigationBar from '../../../components/navbar';

const AdminAnalysis = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <>
      <NavigationBar />
      <div className="d-flex align-items-center vh-100 justify-content-center flex-column w-100">
        ADMIN
        <Button onClick={handleLogout}>LOG OUT</Button>
      </div>
    </>
  );
};

export default AdminAnalysis;
