/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getResources } from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddTeammemberToProjectModal = ({
  connectTeammember,
  handleClose,
  show,
  fullscreen,
  teammembers,
}) => {
  const [availableResources, setAvailableResources] = useState('');

  const [selectedQ, setSelectedQ] = useState('');

  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resorce',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { resources } = useSelector((state) => state.resources);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources) {
      const activeResources = resources.filter((res) => res.is_active === true);

      if (teammembers) {
        const selectedQIDs = teammembers.map((value) => value.resource_id);

        setSelectedQ(selectedQIDs);

        setAvailableResources(
          activeResources.filter((x) => !selectedQIDs.includes(x.resource_id))
        );
      } else {
        setAvailableResources(activeResources);
        setSelectedQ('');
      }
    }
  }, [resources, teammembers]);

  const handleConnectTeammembers = () => {
    const resourcesToAdd = resources.filter(
      (x) =>
        checked.includes(x.resource_id) || selectedQ.includes(x.resource_id)
    );

    connectTeammember(resourcesToAdd);
    setChecked([]);
    setAvailableResources('');
    setSelectedQ('');
    handleClose();
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleConnectTeammembers}
        centered={true}
        header="Välj teammedlemmar"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {availableResources.length > 0 ? (
            <AddList
              listKey={'AddTeammemberToProjectModal'}
              columns={columns}
              rows={availableResources.map((resource) => {
                const {
                  resource_email,
                  resource_firstname,
                  resource_lastname,
                  resource_id,
                } = resource;

                return {
                  resorce: `${resource_firstname} ${resource_lastname}`,
                  email: resource_email,
                  add: (
                    <MDBCheckbox
                      key={resource_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={resource_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddTeammemberToProjectModal;
