import toast from 'react-hot-toast';
import { logoutUser } from '../../firebase-api/user';

const sendToast = (
  text,
  toastAction = 'success' | 'warning' | 'info' | 'error',
  duration,
  center
) => {
  const style = {
    duration: duration ? duration : 3000,
    position: 'top-center',
    style: {
      marginTop: center ? '40vh' : '',
    },
  };

  switch (toastAction) {
    case 1:
      return toast.success(text, style);
    case 2:
      return toast.error(text, style);
    case 3:
      return toast.error(text, style);
    case 4:
      return toast.error(text, style);

    default:
      return null;
  }
};

const logout = (text) => {
  return toast(
    (t) => (
      <div>
        <span>{text}</span>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <p
            className=" text-danger "
            onClick={() => {
              logoutUser();
              toast.dismiss(t.id);
              window.location.reload();
            }}
            style={{ cursor: 'pointer', marginLeft: 0 }}
          >
            logga ut
          </p>
          <p
            className=" text-secondary "
            onClick={() => {
              toast.dismiss(t.id);
            }}
            style={{ cursor: 'pointer', marginLeft: 0 }}
          >
            avbryt
          </p>
        </div>
      </div>
    ),
    {
      duration: 100000000,
      position: 'top-center',
      style: {
        marginTop: '80vh',
      },
    }
  );
};

export { sendToast, logout };
