/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spinner, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../../redux/store/actions';
import EditProjectModal from './edit-project-modal/EditProjectModal';
import AddProjectModal from './add-project-modal/AddProjectModal';
import { projectColumns, projectColumnsMobile } from './dataColumns';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const ProjectsTable = ({ mobile }) => {
  const [showAddProjectModal, setshowAddProjectModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  const [showInactiveProjects, setShowInactiveProjects] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState('');

  const [rows, setRows] = useState('');

  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.projects);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (projects) {
      createRowsForDatasets();
    }
  }, [projects, showInactiveProjects]);

  const createRowsForDatasets = () => {
    const filtredProjects = projects.filter(
      ({ is_active }) => is_active === true
    );

    const projectToMap = !showInactiveProjects ? filtredProjects : projects;

    const rows = projectToMap.map((p) => {
      const {
        is_active,
        project_name,
        project_number,
        project_start,
        customer,
        team,
        project_setup,
      } = p;
      const { customer_name } = customer;

      const projectLeaders = team.filter((t) => t.team_projectleader === true);

      const reporters = project_setup[0]?.reporter
        ? project_setup[0]?.reporter.filter((rep) => rep.is_active)
        : '';

      return {
        clickEvent: () => handleClickRow(p),
        projectName: project_name,
        projectNumber: project_number,
        customer: customer.is_active ? customer_name : 'Ingen kund',
        reporters: reporters ? reporters.length : 'Inga rapportörer',
        projectLeader:
          projectLeaders.length > 0
            ? projectLeaders.map((pLeader) => {
                return `${pLeader.resource.resource_firstname} ${pLeader.resource.resource_lastname},`;
              })
            : 'Inga projektledare',
        projectMembers: team.length,

        survey: project_setup[0]?.is_active
          ? project_setup[0]?.questionnaire?.questionnaire_name
          : 'Inget formulär valt',
        status: is_active ? 'Aktiv' : 'Inaktiv',
        projectStart: project_start,
      };
    });

    setRows(rows);
  };

  const handleClickRow = (p) => {
    setProjectToEdit(p);
    setShowEditProjectModal(true);
  };

  const handleCloseAndReset = () => {
    setShowEditProjectModal(false);
    setProjectToEdit('');
  };

  if (projects) {
    projects.sort(function (a, b) {
      var nameB = b.project_name.toUpperCase();
      var nameA = a.project_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  const handleToggleShowActive = () =>
    setShowInactiveProjects(!showInactiveProjects);

  return (
    <>
      <div className="mt-0 ">
        <EditProjectModal
          show={showEditProjectModal}
          projectToEdit={projectToEdit}
          handleClose={handleCloseAndReset}
          mobile={mobile ? true : false}
        />

        {rows ? (
          <>
            {!mobile && (
              <ShowOrHideInactiveButton
                onClick={handleToggleShowActive}
                text={
                  showInactiveProjects
                    ? 'Göm inaktiva projekt'
                    : 'Visa inaktiva projekt'
                }
              />
            )}

            <ActiveDatatable
              mobile={mobile ? true : false}
              columns={mobile ? projectColumnsMobile : projectColumns}
              rows={rows}
              add={() => setshowAddProjectModal(true)}
            />
          </>
        ) : (
          <Spinner animation="border" />
        )}

        <AddProjectModal
          handleClose={() => setshowAddProjectModal(false)}
          show={showAddProjectModal}
          mobile={mobile ? true : false}
        />
      </div>
    </>
  );
};

export default ProjectsTable;
