import React from 'react';

import { Container } from 'react-bootstrap';
import TopTabNavs from '../../../../components/top-tab-navs';

import QuestionsTable from '../../questions/QuestionsTable';

import SurveyTable from '../../survey-management/SurveyTable';

const AdminResponseMobile = () => {
  const tabs = [
    {
      name: 'Frågor',
      content: <QuestionsTable mobile={true} />,
    },
    {
      name: 'Formulär',
      content: <SurveyTable mobile={true} />,
    },
    // {
    //   name: 'svar',
    //   content: <ResponseTableMobile />,
    // },
  ];
  return (
    <>
      <div className="d-flex justify-content-start align-items-start mt-0 mb-3  flex-column">
        <TopTabNavs tabs={tabs} />
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default AdminResponseMobile;
