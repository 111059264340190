import React from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment-timezone';

const Moment = (time, format) => {
  return moment(time).format(format);
};

export default Moment;
