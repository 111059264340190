import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {
  editOperation,
  getOperation,
  getResources,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { status, icons } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';

const EditOperationForm = ({ handleClose, opertaionToEdit }) => {
  const [initialValues, setInitialValues] = useState('');

  const [enableValidate, setEnableValidate] = useState(false);

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const schema = Yup.object().shape({
    operationName: Yup.string().required('Namn på avdelning'),
  });

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (opertaionToEdit) {
      const { is_active, operation_name, operation_description } =
        opertaionToEdit;

      setInitialValues({
        operationName: operation_name,
        description: operation_description ? operation_description : '',
        status: is_active === true ? status.ACTIVE : status.INACTIVE,
      });
    }
  }, [opertaionToEdit]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const operationToAdd = {
      operationName: values.operationName,
      description: values.description,
      status: values.status === status.ACTIVE ? true : false,
      operation_id: opertaionToEdit.operation_id,
    };

    dispatch(editOperation(operationToAdd, user.digituals_client_id)).then(
      () => {
        dispatch(getResources(user.digituals_client_id));
        dispatch(getOperation(user.digituals_client_id));
      }
    );

    handleResetForm(resetForm);
    handleClose();
  };

  return (
    <>
      {initialValues !== '' ? (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          initialValues={initialValues}
          validateOnChange={enableValidate}
          validateOnBlur={enableValidate}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                setEnableValidate(true);
                e.preventDefault();
                return handleSubmit();
              }}
              id="EditOperationForm"
            >
              <ModalIcon icon={icons.OPERATION} />

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Avdelning</Form.Label>
                  <Form.Control
                    placeholder="Avdelning..."
                    type="text"
                    name="operationName"
                    value={values.operationName}
                    onChange={handleChange}
                    isValid={touched.operationName && !errors.operationName}
                    isInvalid={!!errors.operationName}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.operationName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    beskrivning
                  </Form.Label>
                  <Form.Control
                    as={'textarea'}
                    style={{ height: '100px' }}
                    placeholder="Skriv beskrivning..."
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isValid={touched.description && !errors.description}
                    isInvalid={!!errors.description}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Status</Form.Label>

                  {statusOptions && (
                    <Field
                      component={CustomSelect}
                      placeholder="Välj avdelning..."
                      name="status"
                      options={statusOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.status && !errors.status}
                      isInvalid={!!errors.status}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export default EditOperationForm;
