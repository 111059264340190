/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { scaleLinear } from 'd3-scale';
import { MDBDatatable } from 'mdb-react-ui-kit';
import CustomDatePicker from '../../custom-date-picker';
import { colors } from '../../../constants';
import { ComponentToPrint } from '../../component-to-print';
import FloatingHoverButton from '../../floating-hover-button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DateUserTitle from '../../print-components/DateUserTitle';

const AnalysDatatable = ({
  columns,
  rows,
  mobile,
  maxWidth,
  mx = 2,
  style,
  className,
  overview = false,
  pagination = false,
  search = false,
  entries = 5,
  fixedHeader = false,
  maxHeight = null,
  color = 'transparent',
  sortField = '',
  sortOrder = 'asc',
  datePickerOptions = [],
  useDatepicker = false,
  showPrint = false,
  printTitle = 'TITLE',
}) => {
  const [loading, setLoading] = useState(false);

  const [mappedColumns, setMappedColumns] = useState(columns);
  const [lastDirectionAndColumn, setLastDirectionAndColumn] = useState({
    direction: '',
    field: '',
    toggle: false,
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { selectedDataFromFilter } = useSelector((state) => state.analys);
  const { overviewDataSelectedDate } = useSelector((state) => state.analys);
  const { user } = useSelector((state) => state.auth);

  const divStyle = {
    marginTop: '0px',
    width: maxWidth,
    ...style,
  };

  useEffect(() => {
    if (columns) {
      setMappedColumns(
        columns.map((column) => {
          return {
            label: showPrint ? (
              <p
                className={
                  column.field === 'lowestResponse' ||
                  column.field === 'highestResponse' ||
                  column.field === 'averageResponse' ||
                  column.field === 'numberOfProjects' ||
                  column.field === 'numberOfCustomers' ||
                  column.field === 'response' ||
                  column.field === 'numberOfReporters' ||
                  column.field === 'numberOfResponses'
                    ? 'text-end'
                    : ''
                }
                style={{
                  fontSize: 14,
                  marginTop: 18,
                  fontWeight: 'normal',
                }}
                onClick={() => {
                  return setLastDirectionAndColumn((prev) => ({
                    ...prev,
                    field: column.field,
                    toggle: !prev.toggle,
                  }));
                }}
              >
                {column.label}
              </p>
            ) : (
              <p
                className={
                  column.field === 'lowestResponse' ||
                  column.field === 'highestResponse' ||
                  column.field === 'averageResponse' ||
                  column.field === 'numberOfProjects' ||
                  column.field === 'numberOfCustomers' ||
                  column.field === 'response' ||
                  column.field === 'numberOfReporters' ||
                  column.field === 'numberOfResponses'
                    ? 'text-end'
                    : ''
                }
                style={{
                  fontSize: 14,
                  marginTop: 18,
                  fontWeight: 'normal',
                }}
              >
                {column.label}
              </p>
            ),
            field: column.field,
            sort: column.sort,
            width: column.width,
          };
        })
      );
    }
  }, [columns]);

  useEffect(() => {
    handleSetDirection(lastDirectionAndColumn.field);
  }, [lastDirectionAndColumn.toggle]);

  function handleSetDirection(field) {
    if (lastDirectionAndColumn.field && field !== '') {
      return lastDirectionAndColumn.field === field
        ? setLastDirectionAndColumn((prev) => ({
            ...prev,
            direction:
              lastDirectionAndColumn.direction === 'asc'
                ? ''
                : lastDirectionAndColumn.direction === ''
                ? 'desc'
                : 'asc',
            field: field,
          }))
        : setLastDirectionAndColumn((prev) => ({
            ...prev,
            direction: 'asc',
            field: field,
          }));
    }
  }

  function mapSelectedDataFromFilter(data) {
    const filterKeys = Object.keys(data ? data : {});

    const style = {
      title: {
        fontSize: 12,
        minWidth: 130,
        fontWeight: 'bold',
      },
      li: {
        fontSize: 12,
      },
    };

    return (
      <div className="d-flex align-items-center align-self-center flex-column mx-5">
        <div
          style={{
            marginTop: 0,
            marginBottom: 40,
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          Använda filter för rapporten
        </div>
        <div className="d-flex flex-column align-self-center">
          {overviewDataSelectedDate && (
            <div
              className="me-5 d-flex flex-row "
              key={overviewDataSelectedDate}
            >
              <p className="d-flex align-self-center" style={style.title}>
                Fr.o.m. datum:
              </p>
              <p
                key={`from-date${overviewDataSelectedDate}`}
                style={style.li}
                className="mx-3 d-flex align-self-center"
              >
                -{moment(overviewDataSelectedDate).format('YYYY-MM-DD')}
              </p>
            </div>
          )}

          {filterKeys.length > 0 && (
            <>
              {filterKeys.map((title) => {
                return title === 'Status Fråga' ||
                  title === 'Status Kund' ||
                  title === 'Status Projekt' ||
                  title === 'Status Rapportör' ? (
                  <>
                    {data[title] !== '' && (
                      <div className="me-5 d-flex flex-row " key={title}>
                        <p
                          className="d-flex align-self-start"
                          style={style.title}
                        >
                          {title}:
                        </p>
                        <div className="d-flex flex-row flex-wrap">
                          <p
                            key={data[title]}
                            style={style.li}
                            className="mx-3 d-flex align-self-center"
                          >
                            -{data[title] === true ? 'Aktiva' : 'Inaktiva'}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : data[title].length > 0 ? (
                  <div key={title} className="me-5 d-flex flex-row  ">
                    <p className="d-flex align-self-start" style={style.title}>
                      {title}:
                    </p>
                    <div className="d-flex flex-row flex-wrap">
                      {data[title].map((item) => {
                        return (
                          <p
                            key={item}
                            style={style.li}
                            className="mx-3 d-flex align-self-center"
                          >
                            -{item}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {useDatepicker && (
        <div
          className="d-flex align-self-end"
          style={{ marginBottom: -44, marginRight: -40 }}
        >
          <CustomDatePicker
            options={datePickerOptions}
            label="Fr.o.m responsdatum"
          />
        </div>
      )}

      <div style={divStyle}>
        <MDBDatatable
          sortField={sortField}
          sortOrder={sortOrder}
          style={overview ? { marginLeft: 40 } : null}
          loading={loading}
          color={color}
          search={search}
          className="p-1"
          sm
          format={(field, value) => {
            if (rows.length > 0 && field) {
              if (
                field === 'lowestResponse' ||
                field === 'highestResponse' ||
                field === 'numberOfProjects' ||
                field === 'numberOfCustomers' ||
                field === 'response' ||
                field === 'numberOfReporters' ||
                field === 'numberOfResponses'
              ) {
                return {
                  textAlign: 'right',
                  fontSize: 12,
                };
              }

              if (
                field === 'daysFromLastResponse' ||
                field === 'averageResponse'
              ) {
                const sortedNumberOfResponses = [...rows].sort(function (a, b) {
                  return a.numberOfResponses - b.numberOfResponses;
                });

                const highestNumberOfResponses =
                  sortedNumberOfResponses.length > 2
                    ? sortedNumberOfResponses[
                        sortedNumberOfResponses.length - 2
                      ].numberOfResponses
                    : sortedNumberOfResponses[
                        sortedNumberOfResponses.length - 1
                      ].numberOfResponses;

                const dynamicColors =
                  field === 'numberOfResponses' ? true : false;

                const dynamicColorsDaysFromLastResponse =
                  field === 'daysFromLastResponse' ? true : false;

                const dynamicColorsNumberOfResponses =
                  field === 'numberOfResponses' ? true : false;

                const min = dynamicColorsDaysFromLastResponse
                  ? 1
                  : dynamicColorsNumberOfResponses
                  ? 0
                  : 1;

                const max = dynamicColorsDaysFromLastResponse
                  ? 10
                  : dynamicColorsNumberOfResponses
                  ? highestNumberOfResponses
                  : 4;

                var redColor = scaleLinear()
                  .domain([9, 30])
                  .range([colors.WHITE, colors.pivottable.RED]);
                var greenColor = scaleLinear()
                  .domain([0, 12])
                  .range([colors.pivottable.GREEN, colors.WHITE]);

                var redColorAverage = colors.pivottable.RED;
                var lightRedColorAverage = colors.pivottable.LIGHT_RED;

                var yellow = colors.pivottable.YELLOW;

                var greenColorAverage = colors.pivottable.GREEN;
                var lightGreenColorAverage = colors.pivottable.LIGHT_GREEN;

                const colorToUse =
                  value < 12
                    ? greenColor(value > 0 ? value : 0)
                    : redColor(value < 31 ? value : 31);

                const range = max - min;
                var color = scaleLinear()
                  .domain([0, 1])
                  .range(['#88b3bc', '#e4ecee']);

                const progress = (max - value) / range;
                const progressValue = progress < 0 ? 0 : progress;
                const backgroundColor = dynamicColors
                  ? color(progressValue)
                  : dynamicColorsDaysFromLastResponse
                  ? colorToUse
                  : value < 2.5
                  ? redColorAverage
                  : value >= 2.5 && value < 3
                  ? lightRedColorAverage
                  : value >= 3 && value < 3.4
                  ? yellow
                  : value >= 3.4 && value < 3.7
                  ? lightGreenColorAverage
                  : value >= 3.7
                  ? greenColorAverage
                  : value === null
                  ? 'rgb(255,255,255)'
                  : redColorAverage;

                return {
                  fontSize: 12,
                  background: backgroundColor,
                  textAlign: 'right',
                  width: field === 'daysFromLastResponse' ? 10 : 'auto',
                };
              }
            }

            return {
              fontSize: 12,
            };
          }}
          searchBottom={false}
          hover
          entriesOptions={[10, 20, 30]}
          entries={entries}
          pagination={pagination}
          data={{
            columns: mappedColumns,
            rows: rows.length > 0 ? rows : [],
          }}
          advancedData
          fixedHeader={fixedHeader}
          maxHeight={maxHeight}
          searchInputProps={{ label: 'Sök' }}
          responsive={true}
        />
        {showPrint && (
          <>
            <FloatingHoverButton
              hoverText="Skriv ut tabell"
              icon="print"
              onClick={() => handlePrint()}
              top="82vh"
            />
            {/* <div> */}
            <div style={{ height: 0, width: 0 }}>
              <ComponentToPrint ref={componentRef}>
                <div className="d-flex align-items-center flex-column ">
                  <DateUserTitle user={user} title={printTitle} />
                  <div className="avoid-page-break">
                    <MDBDatatable
                      sortField={sortField}
                      sortOrder={sortOrder}
                      loading={loading}
                      color={color}
                      search={false}
                      className="p-1 "
                      sm
                      format={(field, value) => {
                        if (rows.length > 0 && field) {
                          if (
                            field === 'lowestResponse' ||
                            field === 'highestResponse' ||
                            field === 'numberOfProjects' ||
                            field === 'numberOfCustomers' ||
                            field === 'response' ||
                            field === 'numberOfReporters' ||
                            field === 'numberOfResponses'
                          ) {
                            return {
                              textAlign: 'right',
                            };
                          }

                          if (
                            field === 'daysFromLastResponse' ||
                            field === 'averageResponse'
                          ) {
                            const sortedNumberOfResponses = [...rows].sort(
                              function (a, b) {
                                return (
                                  a.numberOfResponses - b.numberOfResponses
                                );
                              }
                            );

                            const highestNumberOfResponses =
                              sortedNumberOfResponses.length > 2
                                ? sortedNumberOfResponses[
                                    sortedNumberOfResponses.length - 2
                                  ].numberOfResponses
                                : sortedNumberOfResponses[
                                    sortedNumberOfResponses.length - 1
                                  ].numberOfResponses;

                            const dynamicColors =
                              field === 'numberOfResponses' ? true : false;

                            const dynamicColorsDaysFromLastResponse =
                              field === 'daysFromLastResponse' ? true : false;

                            const dynamicColorsNumberOfResponses =
                              field === 'numberOfResponses' ? true : false;

                            const min = dynamicColorsDaysFromLastResponse
                              ? 1
                              : dynamicColorsNumberOfResponses
                              ? 0
                              : 1;

                            const max = dynamicColorsDaysFromLastResponse
                              ? 10
                              : dynamicColorsNumberOfResponses
                              ? highestNumberOfResponses
                              : 4;

                            var redColor = scaleLinear()
                              .domain([9, 30])
                              .range([colors.WHITE, colors.pivottable.RED]);
                            var greenColor = scaleLinear()
                              .domain([0, 12])
                              .range([colors.pivottable.GREEN, colors.WHITE]);

                            var redColorAverage = colors.pivottable.RED;
                            var lightRedColorAverage =
                              colors.pivottable.LIGHT_RED;

                            var yellow = colors.pivottable.YELLOW;

                            var greenColorAverage = colors.pivottable.GREEN;
                            var lightGreenColorAverage =
                              colors.pivottable.LIGHT_GREEN;

                            const colorToUse =
                              value < 12
                                ? greenColor(value > 0 ? value : 0)
                                : redColor(value < 31 ? value : 31);

                            const range = max - min;
                            var color = scaleLinear()
                              .domain([0, 1])
                              .range(['#88b3bc', '#e4ecee']);

                            const progress = (max - value) / range;
                            const progressValue = progress < 0 ? 0 : progress;
                            const backgroundColor = dynamicColors
                              ? color(progressValue)
                              : dynamicColorsDaysFromLastResponse
                              ? colorToUse
                              : value < 2.5
                              ? redColorAverage
                              : value >= 2.5 && value < 3
                              ? lightRedColorAverage
                              : value >= 3 && value < 3.4
                              ? yellow
                              : value >= 3.4 && value < 3.7
                              ? lightGreenColorAverage
                              : value >= 3.7
                              ? greenColorAverage
                              : value === null
                              ? 'rgb(255,255,255)'
                              : redColorAverage;

                            return {
                              fontSize: 14,
                              background: backgroundColor,
                              textAlign: 'right',
                              width:
                                field === 'daysFromLastResponse' ? 10 : 'auto',
                            };
                          }
                        }

                        return {
                          fontSize: 14,
                        };
                      }}
                      searchBottom={false}
                      hover
                      entriesOptions={[10, 20, 30]}
                      entries={entries}
                      pagination={pagination}
                      data={{
                        columns: columns,
                        rows:
                          rows.length > 0 &&
                          lastDirectionAndColumn.direction === ''
                            ? rows
                            : rows.length > 0 &&
                              lastDirectionAndColumn.direction === 'asc'
                            ? rows.sort((a, b) =>
                                a[lastDirectionAndColumn.field] <
                                b[lastDirectionAndColumn.field]
                                  ? 1
                                  : -1
                              )
                            : rows.length > 0 &&
                              lastDirectionAndColumn.direction === 'desc'
                            ? rows.sort((a, b) =>
                                a[lastDirectionAndColumn.field] >
                                b[lastDirectionAndColumn.field]
                                  ? 1
                                  : -1
                              )
                            : [],
                      }}
                      advancedData
                      searchInputProps={{ label: 'Sök' }}
                      responsive={true}
                    />
                  </div>
                </div>
                <div className="page-break" />

                {mapSelectedDataFromFilter(selectedDataFromFilter)}
              </ComponentToPrint>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnalysDatatable;
