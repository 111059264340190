/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBCheckbox } from 'mdb-react-ui-kit';

const Listbox = ({ values, setValues, className, label }) => {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    handleSetValues();
  }, [checked]);

  const handleSetValues = () => {
    const valuesToAdd = values.filter((x) => checked.includes(x.value));
    return setValues(valuesToAdd);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <div className={className}>
      <p className="text-center text-dark">{label ? label : ''}</p>
      <MDBListGroup style={{ minWidth: '22rem' }}>
        {values
          ? values.map((value) => (
              <MDBListGroupItem
                tag="label"
                key={value.value}
                disabled={value.disabled}
              >
                <MDBCheckbox
                  disabled={value.disabled}
                  label={value.label}
                  name="selectedTeammembers"
                  value={value.value}
                  className="text-primary"
                  onChange={handleCheck}
                />
              </MDBListGroupItem>
            ))
          : null}
      </MDBListGroup>
    </div>
  );
};

export default Listbox;
