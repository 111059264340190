/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AnalysDatatable from '../../../components/datatables/analys-datatable';
import { columnsExport } from './dataColumns';
import { CSVHeadersFromResponse } from './csv';
import moment from 'moment';
import FloatingExportButton from '../../../components/floating-export-button';
import { analysDatatableOptions } from '../../../constants';

const AnalysExport = ({ rows, dataToExport, datePickerOptions, marginX }) => {
  return (
    <>
      <div
        className="d-flex  flex-column "
        style={{
          marginTop: 50,
          marginRight: marginX,
          marginLeft: marginX,
        }}
      >
        <AnalysDatatable
          mobile={false}
          columns={columnsExport}
          rows={
            rows.sort(function (a, b) {
              const date1 = moment(a.responseCreated).format('YYYYMMDD');
              const date2 = moment(b.responseCreated).format('YYYYMMDD');

              return date2 - date1;
            }) || []
          }
          search
          entries={1000}
          fixedHeader
          maxHeight={analysDatatableOptions.MAX_HEIGHT}
          datePickerOptions={datePickerOptions}
          useDatepicker
        />
      </div>

      <FloatingExportButton
        dataToExport={dataToExport}
        headers={CSVHeadersFromResponse}
      />
    </>
  );
};

export default AnalysExport;
