const customer = {
  label: 'Kund',
  field: 'customer',
  width: 'auto',
  sort: true,
};
const project = {
  label: 'Projekt',
  field: 'project',
  width: 'auto',
  sort: true,
};
const reporter = {
  label: 'Rapportör',

  field: 'reporter',
  width: 'auto',
  sort: true,
};
const projectStart = {
  label: 'Projektstart',

  field: 'projectStart',
  width: 'auto',
  sort: true,
};
const daysFromLastResponse = {
  label: 'Dgr sedan respons',

  field: 'daysFromLastResponse',
  width: 10,
  sort: true,
};
const lowestResponse = {
  label: 'Lägsta',

  field: 'lowestResponse',
  width: 10,
  sort: true,
};
const averageResponse = {
  label: 'Medel',

  field: 'averageResponse',
  width: 10,
  sort: true,
};
const highestResponse = {
  label: 'Högsta',

  field: 'highestResponse',
  width: 10,
  sort: true,
};
const firstResponse = {
  label: 'Första',

  field: 'firstResponse',

  sort: true,
};
const lastResponse = {
  label: 'Senaste',

  field: 'lastResponse',
  sort: true,
};
const numberOfResponses = {
  label: 'Antal',
  field: 'numberOfResponses',
  width: 10,
  sort: true,
};
const question = {
  label: 'Fråga',
  field: 'question',
  width: 'auto',
  sort: true,
};
const response = {
  label: 'Respons',
  field: 'response',
};
const numberOfReporters = {
  label: 'Antal rapportörer',
  field: 'numberOfReporters',
  width: 10,
  sort: true,
};
const numberOfProjects = {
  label: 'Antal projekt',
  field: 'numberOfProjects',
  width: 10,
  sort: true,
};
const numberOfCustomers = {
  label: 'Antal kunder',
  field: 'numberOfCustomers',
  width: 10,
  sort: true,
};

export const cols = {
  customer: customer,
  project: project,
  reporter: reporter,
  projectStart: projectStart,
  daysFromLastResponse: daysFromLastResponse,
  lowestResponse: lowestResponse,
  averageResponse: averageResponse,
  highestResponse: highestResponse,
  firstResponse: firstResponse,
  lastResponse: lastResponse,
  numberOfResponses: numberOfResponses,
  question: question,
  response: response,
  numberOfReporters: numberOfReporters,
  numberOfProjects: numberOfProjects,
  numberOfCustomers: numberOfCustomers,
};
