import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { supabase } from '../../utils/supabase';
import * as Yup from 'yup';

import puls from '../../image/puls_login.png';
import logo from '../../image/logga.png';
import { history } from '../../utils/history';

function PasswordReset() {
  // const [password, setPassword] = useState(null);
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const [hash, setHash] = useState(null);

  const pixelBreakPoint = 770;

  const schema = Yup.object().shape({
    password: Yup.string().required('Ange lösenord!'),
  });

  const initialValues = {
    password: '',
  };

  useEffect(() => {
    setHash(window.location.hash);

    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    setPassword();

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  async function setPassword() {
    const newPassword = prompt('Skriv ditt lösenord, minst 6 tecken.');
    const { data, error } = await supabase.auth.update({
      password: newPassword,
    });

    if (data) {
      toast.success('Lösenord uppdaterat!');
      setTimeout(() => {
        history.push('/');
      }, 1200);
    }
    if (error) toast.error('Fel uppstod!');
  }

  const handleSubmit = async (e, resetForm) => {
    // e.preventDefault();

    const notification = toast.loading('Uppdaterar lösenord...');

    try {
      // if the user doesn't have accesstoken
      if (!hash) {
        return toast.error('Sorry, Invalid token', {
          id: notification,
        });
      } else if (hash) {
        const hashArr = hash
          .substring(1)
          .split('&')
          .map((param) => param.split('='));

        let type;
        let accessToken;
        for (const [key, value] of hashArr) {
          if (key === 'type') {
            type = value;
          } else if (key === 'access_token') {
            accessToken = value;
          }
        }

        // if (
        //   type !== 'recovery' ||
        //   !accessToken ||
        //   typeof accessToken === 'object'
        // ) {
        //   toast.error('Invalid access token or type', {
        //     id: notification,
        //   });
        //   return;
        // }

        //   now we will change the password
        const { data, error } = await supabase.auth.update({
          // password: password,
        });

        console.log('error', error);
        console.log('data', data);
        if (error) {
          toast.error(`Ett fel har uppstått: ${error.message}`, {
            id: notification,
          });
        } else if (!error) {
          toast.success('Lönenord ändrat!', {
            id: notification,
          });
        }
      }

      resetForm({});
      // setTimeout(() => {
      //   history.push('/');
      // }, 1500);
    } catch (error) {
      console.log(error);
      toast.error(`Ett fel har uppstått: ${error.message}`, {
        id: notification,
      });
    }
  };

  return (
    <div
      style={
        currentWindowSize < pixelBreakPoint
          ? {
              background: `url(${puls})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom',
              backgroundSize: '100%',
            }
          : null
      }
      className="d-felx flex-column align-items-center container-fluid d-flex justify-content-center vh-100 bg-primary "
    >
      {/* <div className="d-felx flex-column align-items-center container-fluid d-flex justify-content-center vh-100 ">
        <img alt="" src={logo} width="200" height="auto" className="mb-5" />

        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} id="ResetPasswordForm">
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-white">Lösenord</Form.Label>
                  <Form.Control
                    placeholder="Lösenord..."
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={!!errors.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group
                className="mb-3 d-flex d-flex justify-content-end"
                controlId="formBasicLastname"
              >
                <span
                  className="font-weight-bold text-white"
                  onClick={handleSubmit}
                  style={{ cursor: 'pointer' }}
                >
                  Uppdatera lösenord
                </span>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div> */}
      <p style={{ fontSize: 12 }} className="text-white">
        Copyright ⓒ dividuals AB all rights reserved
      </p>
    </div>
  );
}

export default PasswordReset;
