import React from 'react';
import { MDBTypography } from 'mdbreact';
import { scaleLinear } from 'd3-scale';
import { colors } from '../../constants';

const SIZE = 170;

export default function ValueCircle({
  value = 0,
  label = 'Label',
  style,
  interpolateColor = false,
}) {
  var redColor = scaleLinear()
    .domain([9, 30])
    .range([colors.WHITE, colors.pivottable.RED]);
  var greenColor = scaleLinear()
    .domain([0, 12])
    .range([colors.pivottable.GREEN, colors.WHITE]);

  const colorToUse =
    value < 12
      ? greenColor(value < 31 ? value : 31)
      : redColor(value < 31 ? value : 31);

  return (
    <div
      className="d-flex justify-content-start align-items-center flex-column"
      style={{ width: SIZE, height: SIZE, ...style }}
    >
      <div
        className={`d-flex ${
          interpolateColor ? '' : 'bg-dashboard'
        }  justify-content-center align-items-center rounded-circle`}
        style={{
          width: SIZE,
          height: SIZE,
          backgroundColor: interpolateColor ? colorToUse : '',
        }}
      >
        <p
          // className={`d-flex align-self-start ${
          //   interpolateColor ? 'text-black' : 'text-white'
          // }  text-center `}
          className={`d-flex align-self-start text-black  text-center `}
          style={{ fontSize: 40, marginTop: 30 }}
        >
          {value}
        </p>
      </div>
      <MDBTypography
        style={{ marginTop: -85, fontSize: 14 }}
        className={`d-flex justify-content-center mx-3 text-black  text-center `}
        // className={`d-flex justify-content-center mx-3 ${
        //   interpolateColor ? 'text-black' : 'text-white'
        // }  text-center `}
      >
        {label}
      </MDBTypography>
    </div>
  );
}
