import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const adminHoc = (Component) => {
  class AdminHoc extends React.Component {
    authCheck = () => {
      const auth = this.props.auth;

      if (auth.isAuth) {
        const { profile_role } = auth.user.profile;

        if (profile_role === 1) {
          return <Component {...this.props} />;
        }

        return <Redirect to="/home" />;
      } else {
        return <Redirect to="/" />;
      }
    };

    render() {
      return this.authCheck();
    }
  }

  const mapStateToProps = (state) => {
    return { auth: state.auth };
  };

  return connect(mapStateToProps)(AdminHoc);
};

export default adminHoc;
