import React from 'react';

import { Container } from 'react-bootstrap';
import TopTabNavs from '../../../../components/top-tab-navs';
import CustomerTable from '../../customer/CustomerTable';

import ProjectsTable from '../ProjectsTable';

const AdminProjectMobile = () => {
  const tabs = [
    {
      name: 'Projekt',
      content: <ProjectsTable mobile={true} />,
    },
    {
      name: 'Kund',
      content: <CustomerTable mobile={true} />,
    },
  ];
  return (
    <>
      <div className="d-flex justify-content-start align-items-start mt-0 mb-3  flex-column">
        <TopTabNavs tabs={tabs} />
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default AdminProjectMobile;
