/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getResources } from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddReporterToProjectModal = ({
  connectReporters,
  handleClose,
  show,
  fullscreen,
  reporters,
}) => {
  // const [selectedReporters, setSelectedReporters] = useState('');
  const [availableReporters, setAvailableReporters] = useState('');

  const [selectedQ, setSelectedQ] = useState('');

  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resorce',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',

      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
  ];

  const dispatch = useDispatch();
  const { resources } = useSelector((state) => state.resources);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources) {
      const resourcesWithAccount = resources.filter(
        (res) => res.profile?.is_active === true
      );

      if (reporters) {
        const selectedQIDs = reporters.map((value) => value.resource_id);

        setSelectedQ(selectedQIDs);

        setAvailableReporters(
          resourcesWithAccount.filter(
            (x) => !selectedQIDs.includes(x.resource_id)
          )
        );
      } else {
        setAvailableReporters(resourcesWithAccount);
        setSelectedQ('');
      }
    }
  }, [resources, reporters]);

  const handleConnectReporters = () => {
    const reportersToAdd = resources.filter(
      (x) =>
        checked.includes(x.resource_id) || selectedQ.includes(x.resource_id)
    );

    connectReporters(reportersToAdd);
    setAvailableReporters('');
    setSelectedQ('');
    setChecked([]);
    handleClose();
  };

  // const handleClickRow = (resource) => {
  //   setSelectedResource(resource);
  // };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleConnectReporters}
        centered={true}
        header="Välj rapportörer"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {availableReporters.length > 0 ? (
            <AddList
              listKey={'AddReporterToProjectModal'}
              columns={columns}
              rows={availableReporters.map((resource) => {
                const {
                  resource_email,
                  resource_firstname,
                  resource_lastname,
                  resource_id,
                } = resource;

                return {
                  // clickEvent: () => handleClickRow(resource),
                  resorce: `${resource_firstname} ${resource_lastname}`,
                  email: resource_email,
                  add: (
                    <MDBCheckbox
                      key={resource_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={resource_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddReporterToProjectModal;
