import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { postResponse, getSurveyQuestions } from '../../redux/store/actions';
import { history } from '../../utils/history';
import LoadingPage from '../../components/loading';
import { MDBContainer, MDBTypography, MDBIcon } from 'mdbreact';
import moment from 'moment';
import { sendToast } from '../../components/toast/index';

import { MDBBtn } from 'mdb-react-ui-kit';

import bad from '../../image/survey-bad.png';
import good from '../../image/survey-good.png';
import notGood from '../../image/survey-not-good.png';
import best from '../../image/survey-best.png';

import badGrey from '../../image/survey-bad-grey.png';
import goodGrey from '../../image/survey-good-grey.png';
import notGoodGrey from '../../image/survey-not-good-grey.png';
import bestGrey from '../../image/survey-best-grey.png';

import background from '../../image/background.png';
import eqpuls from '../../image/eqpuls_logo.png';
import { addTestdata } from './addTestdata';
import { colors } from '../../constants';

class SurveyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      allAnswersChosen: false,
      buttonDisable: false,
      progressNumber: 1,
      progress: 0,
      projects: [],
      activeSurvey: '',
      options: [4, 3, 2, 1],
      surveys: [],
      loading: true,
      questionNumber: 0,
      answers: [],
      formHorizontalRadios: [],
      user: {
        email: '',
        uid: '',
        lastname: '',
        name: '',
      },
      pixelBreakPoint: 600,
      currentWindowSize: window.innerWidth,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { selectedProjects, selectedDate } = history.location.state.state;

    const { user } = this.props.auth;
    const { questionnaire } = selectedProjects[0].project_setup;
    let numberOfAnswers = [];
    let buttonFocus = [];

    selectedProjects.forEach((project) => {
      numberOfAnswers.push([]);
      buttonFocus.push([false, false, false, false]);
    });

    this.props
      .dispatch(getSurveyQuestions(questionnaire, user.digituals_client_id))
      .then(({ payload }) => {
        if (payload) {
          payload.sort(function (a, b) {
            var numA = a.question_sort_order;
            var numB = b.question_sort_order;
            if (numA < numB) {
              return -1;
            }
            if (numA > numB) {
              return 1;
            }

            return 0;
          });
        }
        this.setState({
          loading: false,
          activeSurvey: payload,
          user: user,
          projects: selectedProjects,
          answers: numberOfAnswers,
          formHorizontalRadios: buttonFocus,
          selectedDate: selectedDate,
        });

        this.calculateProgress(1);
      });

    const handleResize = () => {
      this.setState({
        currentWindowSize: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }

  handleInputChange(event, index) {
    const target = event.target;
    const value = target.type === 'radio' ? target.checked : target.value;
    const name = target.name;

    let formHorizontalRadiosCopy = [false, false, false, false];

    formHorizontalRadiosCopy[index] = value;

    if (target.type === 'radio') {
      this.setState({
        formHorizontalRadios: formHorizontalRadiosCopy,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleSelectOption(
    question,
    answer,
    questionNumber,
    projectIndex,
    questionID,
    index
  ) {
    const numberOfProjects = this.state.formHorizontalRadios.length;
    let copyOfAnswerArray = [...this.state.answers];
    let buttonFocus = [...this.state.formHorizontalRadios];
    let allChosen = false;
    let count = 0;

    copyOfAnswerArray[projectIndex][questionNumber] = {
      question: question,
      questionID: questionID,
      answer: answer,
    };

    buttonFocus[projectIndex] = [false, false, false, false];
    buttonFocus[projectIndex][index] = true;

    buttonFocus.forEach((buttonFoc) => {
      buttonFoc.forEach((opt) => {
        if (opt) {
          count = count + 1;
        }
      });
    });

    if (count === numberOfProjects) {
      allChosen = true;
    } else {
      allChosen = false;
    }

    this.setState({
      answers: copyOfAnswerArray,
      formHorizontalRadios: buttonFocus,
      allAnswersChosen: allChosen,
    });
  }

  handleNextQuestion() {
    const selectedProjects = history.location.state.state.selectedProjects;

    let buttonFocus = [];

    selectedProjects.forEach((project) => {
      buttonFocus.push([false, false, false, false]);
    });

    if (this.state.questionNumber < this.state.activeSurvey.length - 1) {
      this.setState({
        progressNumber: this.state.progressNumber + 1,
        questionNumber: this.state.questionNumber + 1,
        formHorizontalRadios: buttonFocus,
        allAnswersChosen: false,
      });
    }

    this.calculateProgress(this.state.progressNumber + 1);
  }
  handlePrevQuestion() {
    const selectedProjects = history.location.state.state.selectedProjects;

    let buttonFocus = [];

    selectedProjects.forEach((project) => {
      buttonFocus.push([false, false, false, false]);
    });

    if (this.state.questionNumber > 0) {
      this.setState({
        progressNumber: this.state.progressNumber - 1,
        questionNumber: this.state.questionNumber - 1,
        formHorizontalRadios: buttonFocus,
        allAnswersChosen: false,
      });
    }

    this.calculateProgress(this.state.progressNumber - 1);
  }

  handlePostResponse() {
    const { projects, answers, user, selectedDate } = this.state;

    projects.forEach(async (p, index) => {
      const { project } = p.project_setup;
      const { project_start } = project;

      const today = moment().format();

      const startDate = moment(project_start, 'YYYY-MM-DD');
      // const responseDate = moment(selectedDate, 'YYYY-MM-DD');
      const responseDate = moment(today, 'YYYY-MM-DD');

      const days = moment.duration(responseDate.diff(startDate)).asDays();

      const response = {
        questions: this.state.activeSurvey,
        response: answers[index],
        project: p,
        user: user,
        createdAt: moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        daysFromStartDate: parseInt(days.toFixed(0)),
      };

      await this.props.dispatch(
        postResponse(response, user.digituals_client_id)
      );
      // addTestdata(p, this.state.activeSurvey, user, project_start);
    });

    this.setState({
      buttonDisable: true,
    });

    setTimeout(function () {
      history.push('/home');
    }, 3000);
  }

  calculateProgress(progressNumber) {
    if (this.state.activeSurvey) {
      const numberOfQuestions = this.state.activeSurvey.length;
      const percentage = 100 / numberOfQuestions;
      const number = progressNumber;
      const progress = percentage * number;

      this.setState({
        progress: progress,
      });
    }
  }

  handleGoToHome = () => {
    history.push('/home');
  };

  render() {
    const {
      loading,
      activeSurvey,
      questionNumber,
      progress,
      projects,
      options,
      formHorizontalRadios,
      allAnswersChosen,
    } = this.state;

    return (
      <>
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            <div
              className="d-flex justify-content-between align-items-center  fixed-top"
              style={{
                // backgroundImage: background,
                // backgroundImage: `url(${background})`,
                backgroundColor: colors.PRIMARY,
                height: '70px',
                width: '105%',
                marginTop: '-10px',
                marginLeft: '-10px',
                backgroundSize: 'cover',
                minHeight: '70px',
                // fontSize: '50px',
                // backgroundSize: 'auto',
                // backgroundRepeat: 'no-repeat',
              }}
            >
              {/* <Container
              className="d-flex justify-content-between align-items-center mt-0 mb-0 fixed-top"
              style={{
                backgroundImage: `url(${background})`,
                height: '10vh',
                marginTop: '0px',
                fontSize: '50px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            > */}
              <img
                alt=""
                src={eqpuls}
                width="120"
                height="auto"
                className="text-white mt-3"
              />

              <MDBBtn
                floating
                color="white"
                className=" mt-3 bg-white"
                style={{ marginRight: '5%' }}
                onClick={this.handleGoToHome}
              >
                <MDBIcon
                  icon="home"
                  className="text-primary"
                  style={{ cursor: 'pointer' }}
                  size="2x"
                />
              </MDBBtn>
              {/* </Container> */}
            </div>

            <MDBContainer
              className="d-flex align-items-center justify-content-start flex-column  w-100"
              style={{ marginTop: '13vh' }}
            >
              <MDBTypography tag="h6" className=" text-primary">{`${
                questionNumber + 1
              }/${activeSurvey.length}`}</MDBTypography>
              <ProgressBar
                className="w-100"
                now={progress}
                style={{ maxWidth: '400px' }}
              />

              <div
                className="d-flex align-items-center justify-content-start flex-column  w-100  "
                style={{ height: '7vh', marginTop: '3vh' }}
              >
                <MDBTypography tag="h5" className="text-primary text-center">
                  {activeSurvey[questionNumber].question.question}
                </MDBTypography>
              </div>

              {projects.map((project, projectIndex) => {
                const { project_name } = project.project_setup.project;

                return (
                  <>
                    <div
                      className="d-flex align-items-center justify-content-center flex-column  w-100  "
                      style={{ marginTop: '5%' }}
                    >
                      <MDBTypography
                        tag="h6"
                        className="mt-2 mb-3 text-primary"
                        style={{ maxWidth: '350px' }}
                      >
                        {project_name}
                      </MDBTypography>
                    </div>

                    <div
                      className="d-flex align-content-stretch flex-wrap  justify-content-center "
                      style={{ maxWidth: '350px' }}
                    >
                      {options.map((option, index) => {
                        const question =
                          activeSurvey[questionNumber].question.question;

                        const questionID =
                          activeSurvey[questionNumber].question.question_id;

                        const active =
                          formHorizontalRadios[projectIndex][index];

                        const bestLogo = active ? best : bestGrey;
                        const goodLogo = active ? good : goodGrey;
                        const notGoodLogo = active ? notGood : notGoodGrey;
                        const badLogo = active ? bad : badGrey;

                        const WIDTH = 120;
                        const MARGIN = 10;

                        return (
                          <MDBBtn
                            active={active}
                            style={{
                              width: WIDTH,
                              height: WIDTH,
                              padding: 0,
                              marginLeft: 0,
                              marginRight: 0,

                              margin: MARGIN,
                              borderRadius: 100,
                              borderWidth: 0,
                              borderColor: '#0000',
                            }}
                            size="lg"
                            color="transparent"
                            className="d-flex align-content-center   justify-content-center "
                            key={`button${projectIndex}${index}`}
                            onClick={() => {
                              this.handleSelectOption(
                                question,
                                option,
                                questionNumber,
                                projectIndex,
                                questionID,
                                index
                              );
                            }}
                          >
                            <img
                              style={{ width: WIDTH, height: WIDTH }}
                              alt=""
                              src={
                                index === 0
                                  ? bestLogo
                                  : index === 1
                                  ? goodLogo
                                  : index === 2
                                  ? notGoodLogo
                                  : index === 3
                                  ? badLogo
                                  : null
                              }
                              // width="200"
                              // height="200"
                            />
                          </MDBBtn>
                        );
                      })}
                    </div>
                  </>
                );
              })}

              {/* <Container className="d-flex align-items-center justify-content-around flex-row mt-2"> */}
              <MDBContainer
                className="d-flex align-content-center flex-wrap  justify-content-center "
                style={{ marginBottom: '100px', marginTop: '30px' }}
              >
                {/* <MDBBtn
                  disabled={this.state.questionNumber === 0 ? true : false}
                  style={{
                    width: 120,
                    marginRight: 10,
                    // maxWidth: '100px',
                  }}
                  color="primary"
                  onClick={() => {
                    this.handlePrevQuestion();
                  }}
                >
                  Tillbaka
                </MDBBtn>

                <MDBBtn
                  style={{
                    width: 120,
                    marginLeft: 10,
                    // maxWidth: '100px',
                  }}
                  color="primary"
                  onClick={() => {
                    if (questionNumber === this.state.activeSurvey.length - 1 ?) {
                      
                    }
                    if (
                      this.state.buttonDisable === false &&
                      allAnswersChosen === true
                    ) {
                      this.handlePostResponse();
                    } else {
                      sendToast('Välj ett alternativ!', 2, 4000, true);
                    }
                  }}
                >
                  Nästa
                </MDBBtn> */}

                <MDBBtn
                  disabled={this.state.questionNumber === 0 ? true : false}
                  style={{
                    width: 120,
                    marginRight: 10,
                    // maxWidth: '100px',
                  }}
                  color="primary"
                  onClick={() => {
                    this.handlePrevQuestion();
                  }}
                >
                  Tillbaka
                </MDBBtn>

                {questionNumber === this.state.activeSurvey.length - 1 ? (
                  <MDBBtn
                    style={{
                      width: 120,
                      marginLeft: 10,
                      // maxWidth: '100px',
                    }}
                    color="primary"
                    onClick={() => {
                      if (
                        this.state.buttonDisable === false &&
                        allAnswersChosen === true
                      ) {
                        this.handlePostResponse();
                      } else {
                        sendToast('Välj ett alternativ!', 2, 4000, true);
                      }
                    }}
                  >
                    Nästa
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    style={{
                      width: 120,
                      marginLeft: 10,
                      // maxWidth: '100px',
                    }}
                    color="primary"
                    onClick={() => {
                      if (
                        this.state.buttonDisable === false &&
                        allAnswersChosen === true
                      ) {
                        this.handleNextQuestion();
                      } else {
                        sendToast('Välj ett alternativ!', 2, 4000, true);
                      }
                    }}
                  >
                    Nästa
                  </MDBBtn>
                )}
                {/* </Container> */}
              </MDBContainer>
            </MDBContainer>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  surveys: state.survey,
  auth: state.auth,
  questions: state.questions,
});

export default connect(mapStateToProps)(SurveyComponent);
