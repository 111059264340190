import React from 'react';
import { Container } from 'react-bootstrap';

import SurveyComponent from './survey';

const Survey = () => {
  return (
    <>
      <Container style={{ marginTop: '70px' }}>
        <SurveyComponent />
      </Container>
    </>
  );
};

export default Survey;
