import React from 'react';

import { Container } from 'react-bootstrap';
import TopTabNavs from '../../../../components/top-tab-navs';

import ResourcesTable from '../ResourcesTable';
import OperationTable from '../../operation/OperationTable';
import AccountTable from '../../account/AccountTable';
const AdminresourcesMobile = () => {
  const tabs = [
    {
      name: 'Resurser',
      content: <ResourcesTable mobile={true} />,
    },
    {
      name: 'Avdelningar',
      content: <OperationTable mobile={true} />,
    },
    {
      name: 'Konton',
      content: <AccountTable mobile={true} />,
    },
  ];
  return (
    <>
      <div className="d-flex justify-content-start align-items-start mt-0 mb-3  flex-column">
        <TopTabNavs tabs={tabs} />
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default AdminresourcesMobile;
