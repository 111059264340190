/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_RESOURCE,
  GET_RESOURCES,
  GET_USERS,
  DELETE_RESOURCE,
  EDIT_RESOURCE,
  ADD_PROJECT_MANAGER,
  ACTIVATE_RESOURCE,
  INACTIVATE_RESOURCE,
  ADD_RESOURCE_TO_PROJECT,
  REMOVE_RESOURCE_TO_PROJECT,
  REMOVE_RESOURCE_FROM_OPERATION,
  ADD_RESOURCE_TO_OPERATION,
  ACTIVATE_ACCOUNT,
  INACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  CREATE_ACCOUNT,
  GET_REPORTERS,
  GET_ACCOUNTS,
} from '../types';

const INITIAL_STATE = {
  resources: null,
  users: null,
  reporters: null,
  accounts: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_RESOURCE:
      return { ...state };
    case ADD_PROJECT_MANAGER:
      return { ...state };
    case ACTIVATE_RESOURCE:
      return { ...state };
    case INACTIVATE_RESOURCE:
      return { ...state };
    case GET_RESOURCES:
      return { ...state, resources: action.payload };
    case GET_USERS:
      return { ...state, users: action.payload };
    case GET_REPORTERS:
      return { ...state, reporters: action.payload };
    case GET_ACCOUNTS:
      return { ...state, accounts: action.payload };
    case DELETE_RESOURCE:
      return { ...state };
    case EDIT_RESOURCE:
      return { ...state };
    case ADD_RESOURCE_TO_PROJECT:
      return { ...state };
    case REMOVE_RESOURCE_TO_PROJECT:
      return { ...state };
    case REMOVE_RESOURCE_FROM_OPERATION:
      return { ...state };
    case ADD_RESOURCE_TO_OPERATION:
      return { ...state };
    case ACTIVATE_ACCOUNT:
      return { ...state };
    case INACTIVATE_ACCOUNT:
      return { ...state };
    case DELETE_ACCOUNT:
      return { ...state };
    case EDIT_ACCOUNT:
      return { ...state };
    case CREATE_ACCOUNT:
      return { ...state };
    default:
      return state;
  }
}
