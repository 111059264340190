import customer from '../image/kund_ikon.png';
import question from '../image/question_ikon.png';
import questionnaire from '../image/questionnaire_ikon.png';
import rescourcesWithAccount from '../image/resurs_med_konto_ikon.png';
import resource from '../image/resurs_ikon.png';
import operation from '../image/avd_ikon.png';
import project from '../image/projekt_ikon.png';
import projectRevert from '../image/projekt_ikon_revert.png';
import connectListIcon from '../image/connect_list_icon.png';

import account from '../image/konto_ikon.png';

export const status = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const multiselectAllOptions = {
  SELECT_ALL: 'select-option',
  DESELECT_ALL: 'deselect-option',
};

export const analysIcons = {
  CHART_LINE: 'chart-line',
  TABLE: 'table',
  CHART_BAR: 'chart-bar',
  CHART_AREA: 'chart-area',
};

export const icons = {
  PROFILE:
    'https://www.nicepng.com/png/full/136-1366211_group-of-10-guys-login-user-icon-png.png',
  CUSTOMER: customer,
  QUESTION: question,
  QUESTIONNAIRE: questionnaire,
  RESOURCE_WITH_ACCOUNT: rescourcesWithAccount,
  RESOURCE: resource,
  OPERATION: operation,
  PROJECT: project,
  PROJECT_REVERT: projectRevert,
  ACCOUNT: account,
  CONNECT_LIST: connectListIcon,
};
export const pixels = {
  MODAL_LIST_WIDTH: '700px',
  ANALYS_PLOTLY_WIDTH: 1450,
};

export const analysFilterData = {
  OVERVIEW: 'overview',
  TIME: 'time',
  PROJECT_CUSTOMER: 'project_customer',
  REPORTER: 'reporter',
  QUESTION: 'question',
};

export const colors = {
  // PRIMARY: '#404040',
  PRIMARY: '#6ba0ac',
  // SECONDARY: '#cbc1c1',
  SECONDARY: '#595959',
  SUCCESS: ' #3e8d63',
  INFO: '#7854e4',
  WARNING: ' #def702',
  DANGER: '#d62518',
  // LIGHT: '#404040',
  LIGHT: '#f3f8f9',
  DARKER_LIGHT: '#d9d9d9',
  DARK: '#343a40',
  ACCENT: '#da6d25',
  DASHBOARD: '#dfe6e7ad',
  BACKGROUND: '#d9d9d9',
  GREY: '#595959',
  WHITE: '#ffffff',
  TEXT: '#000000',
  pivottable: {
    YELLOW: '#FFFDE7',
    GREEN: '#DCEDC8',
    LIGHT_GREEN: '#F1F8E9',
    RED: '#FFCDD2',
    LIGHT_RED: '#FFEBEE',
  },
  areachart: {
    YELLOW: '#FFFDE7',
    GREEN: '#44935A',
    LIGHT_GREEN: '#A1ECAF',
    RED: '#F79B85',
    LIGHT_RED: '#FACDC8',
  },
  colorway: [
    '#518E93', //5
    '#7F7678', //8
    '#6D98B4', //11
    '#585858', //9
    '#758A93', //2
    '#0E1313', //7
    '#89C2BF', //6
    '#AC949C', //10
    '#60909a', //1
    '#83A5A0', //3
    '#B8C0C9', //4
  ],
  // colorway: [
  //   '#60909a',
  //   '#b5cfd5',
  //   '#558089',
  //   '#d2e2e6',
  //   '#4a7078',

  //   '#c3d9dd',
  //   '#88b3bc',
  //   '#406067',
  //   '#a6c6cd',
  //   '#355056',
  //   '#97bcc4',

  //   '#6ba0ac',
  //   '#79a9b4',
  // ],
};

export const fontFamily = {
  PRIMARY: 'Roboto',
};

export const pivotFilter = {
  PROJECT: 'Projekt',
  PROJECT_ACTIVE: 'Aktivt Projekt',
  CUSTOMER: 'Kund',
  CUSTOMER_ACTIVE: 'Aktiv Kund',
  USER_ACCOUNT: 'Användarkonto',
  USER_ACCOUNT_ACTIVE: 'Aktivt Användarkonto',
  REPORTER: 'Rapportör',
  REPORTER_ACTIVE: 'Aktiv Rapportör',
  QUESTION: 'Fråga',
  QUESTION_ACTIVE: 'Aktiv Fråga',
  RESPONSE_DATE: 'Responsdatum',
  RESPONSE_YEAR: 'Kalenderår',
  RESPONSE_MONTH: 'Responsmånad',
  RESPONSE_WEEK: 'Responsvecka',
  RESPONSE_QUARTER: 'Responskvartal',
  PROJECT_START: 'Projektstart',
  PROJECT_START_YEAR: 'Projektstartsår',
  PROJECT_START_MONTH: 'Projektstart Månad',
  PROJECT_START_WEEK: 'Projektstart Vecka',
  PROJECT_START_QUARTER: 'Projektstart Kvartal',
  PROJECT_START_YEAR_WEEK: 'Projektstartsvecka',
  PROJECT_START_YEAR_QUARTER: 'Projektstartskvartal',
  PROJECT_START_YEAR_MONTH: 'Projektstartsmånad',
  RESPONSE_YEAR_WEEK: 'Kalendervecka',
  RESPONSE_YEAR_QUARTER: 'Kalenderkvartal',
  RESPONSE_YEAR_MONTH: 'Kalendermånad',
  DAYS_FROM_PROJECT_START: 'Dagar från projektstart',
  WEEKS_FROM_PROJECT_START: 'Vecka från projektstart',
  RESPONSE: 'Respons',
};

export const analysDatatableOptions = {
  MAX_HEIGHT: '507px',
};
