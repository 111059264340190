/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  editSurvey,
  getQuestions,
  getSurveys,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { status, icons } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';
import CollapseSection from '../../../../components/collapse-section';
import AddFromModalButton from '../../../../components/add-from-modal-button';
import QuestionList from '../QuestionList';
import AddQuestionToSurveyModal from '../add-question-to-survey-modal';
import ConnectedProjectList from './ConnectedProjectList';

const EditSurveyForm = ({ surveyToEdit, handleClose, mobile }) => {
  const [showAddQuestionList, setShowAddQuestionList] = useState(false);
  const [enableValidate, setEnableValidate] = useState(false);
  const [showConnectedProjectList, setShowConnectedProjectList] =
    useState(false);

  const [showAddQuestionToSurveyModal, setShowAddQuestionToSurveyModal] =
    useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState('');

  const [survey, setSurvey] = useState('');

  // const [initialValues, setInitialValues] = useState('');

  const initialValues = {
    surveyName: surveyToEdit.questionnaire_name,
    description: surveyToEdit.questionnaire_description,
    status: surveyToEdit.is_active ? status.ACTIVE : status.INACTIVE,
    questions: [],
    options: [1, 2, 3, 4],
  };

  const schema = Yup.object().shape({
    surveyName: Yup.string().required('Ange formulärnamn!'),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    setShowConnectedProjectList(false);
    setShowAddQuestionList(false);
    if (surveyToEdit) {
      setSurvey(surveyToEdit);
    }
  }, [surveyToEdit]);
  useEffect(() => {
    if (survey) {
      if (survey.questionnaire_setup.length > 0) {
        const q = survey.questionnaire_setup.map((question) => question);
        setSelectedQuestions(
          q.filter((value) => value.question.is_active === true)
        );
      }
      if (survey.questionnaire_setup.length > 0) {
        const q = survey.questionnaire_setup.map((question) => ({
          question_sort_order: question.question_sort_order,
          question_id: question.question_id,
          question: question.question.question,
          is_active: question.question.is_active,
        }));

        setSelectedQuestions(q.filter((value) => value.is_active === true));
      }
    }
  }, [survey]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const v = {
      ...surveyToEdit,
      ...values,
      status: status.ACTIVE === values.status ? true : false,
    };

    dispatch(editSurvey(v, selectedQuestions, user.digituals_client_id)).then(
      () =>
        setTimeout(function () {
          dispatch(getSurveys(user.digituals_client_id));
          dispatch(getQuestions(user.digituals_client_id));
        }, 1000)
    );
    handleResetForm(resetForm);
    setShowAddQuestionList(false);
    setShowConnectedProjectList(false);
    handleClose();
  };

  const toggleShowAddQuestionList = () =>
    setShowAddQuestionList(!showAddQuestionList);

  const toggleShowConnectedProjectList = () =>
    setShowConnectedProjectList(!showConnectedProjectList);

  const handleConnectQuestions = (questionsToConnect) => {
    setSelectedQuestions(questionsToConnect);
  };

  const handleRemoveQuestion = (questionID) => {
    const newQuestionList = selectedQuestions.filter(
      (q) => q.question_id !== questionID
    );

    setSelectedQuestions(newQuestionList.length > 0 ? newQuestionList : '');
  };

  const handleSetPostionValue = (value) => {
    setSelectedQuestions(value);
  };

  const handleSetSurvey = (value) => {
    setSurvey(value);
  };

  return (
    <>
      {initialValues !== '' && (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          initialValues={initialValues}
          validateOnChange={enableValidate}
          validateOnBlur={enableValidate}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                setEnableValidate(true);
                e.preventDefault();
                return handleSubmit();
              }}
              id="EditSurveyForm"
            >
              <ModalIcon
                icon={icons.QUESTIONNAIRE}
                style={{ marginTop: '0%' }}
              />

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Formulärnamn
                  </Form.Label>
                  <Form.Control
                    placeholder="Formulärnamn..."
                    type="text"
                    name="surveyName"
                    value={values.surveyName}
                    onChange={handleChange}
                    isValid={touched.surveyName && !errors.surveyName}
                    isInvalid={!!errors.surveyName}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.surveyName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Beskrivning
                  </Form.Label>
                  <Form.Control
                    as={'textarea'}
                    style={{ height: '100px' }}
                    placeholder="Skriv beskrivning..."
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isValid={touched.description && !errors.description}
                    isInvalid={!!errors.description}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Status</Form.Label>

                  {statusOptions && (
                    <Field
                      component={CustomSelect}
                      placeholder="Välj status..."
                      name="status"
                      options={statusOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.status && !errors.status}
                      isInvalid={!!errors.status}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <CollapseSection
                isOpen={showAddQuestionList}
                label="Välj frågor"
                onClick={toggleShowAddQuestionList}
              >
                {selectedQuestions ? (
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <QuestionList
                        add={() => setShowAddQuestionToSurveyModal(true)}
                        removeQuestion={handleRemoveQuestion}
                        selectedQuestions={selectedQuestions}
                        setPostionValue={handleSetPostionValue}
                      />
                    </Form.Group>
                  </Row>
                ) : (
                  <Row className=" mb-4">
                    <Col className="d-flex align-items-center  justify-content-start ">
                      <AddFromModalButton
                        label={'Välj frågor'}
                        onClick={() => setShowAddQuestionToSurveyModal(true)}
                      />
                    </Col>
                  </Row>
                )}
              </CollapseSection>

              <CollapseSection
                isOpen={showConnectedProjectList}
                label="Länkade  projekt"
                onClick={toggleShowConnectedProjectList}
              >
                {survey && (
                  <ConnectedProjectList
                    surveyToEdit={survey}
                    mobile={mobile}
                    setSurvey={handleSetSurvey}
                    handleClose={handleClose}
                  />
                )}
              </CollapseSection>
            </Form>
          )}
        </Formik>
      )}

      <AddQuestionToSurveyModal
        show={showAddQuestionToSurveyModal}
        handleClose={() => setShowAddQuestionToSurveyModal(false)}
        fullscreen={mobile}
        connectQuestions={handleConnectQuestions}
        selectedQuestions={selectedQuestions}
      />
    </>
  );
};

export default EditSurveyForm;
