/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AddModal from '../../../../components/add-modal';
import AddProjectForm from './AddProjectForm';

const AddProjectModal = ({ handleClose, show, mobile }) => {
  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        submitForm={'AddProjectForm'}
        header="Skapa projekt"
      >
        <AddProjectForm handleClose={handleClose} />
      </AddModal>
    </>
  );
};

export default AddProjectModal;
