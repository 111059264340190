export const projectColumns = [
  {
    label: '',
    field: '',
  },
  {
    label: 'Projektnamn',
    field: 'projectName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Projektnummer',
    field: 'projectNumber',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Kund',
    field: 'customer',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'Projektledare',
    field: 'projectLeader',
    // width: 270,
  },

  {
    label: 'Rapportörer',
    field: 'reporters',
    // width: 270,
  },
  {
    label: 'Teammedlemmar',
    field: 'projectMembers',
    // width: 270,
  },
  {
    label: 'Formulär',
    field: 'survey',
    // width: 270,
  },
  {
    label: 'Status',
    field: 'status',
    // width: 270,
  },
  {
    label: 'Startdatum',
    field: 'projectStart',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: '',
    field: 'last',
  },

  // {
  //   label: 'redigera',
  //   field: 'edit',
  //   // width: 100,
  // },
  // {
  //   label: 'ta bort',
  //   field: 'delete',
  //   sort: 'disabled',
  //   // width: 150,
  // },
];
export const inactiveProjectColumns = [
  {
    label: 'projektnamn',
    field: 'projectName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'projektnummer',
    field: 'projectNumber',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
export const projectColumnsMobile = [
  {
    label: 'Projektnamn',
    field: 'projectName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'Kund',
    field: 'customer',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];

export const inactiveProjectColumnsMobile = [
  {
    label: 'projektnamn',
    field: 'projectName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];

export const resourceColumns = [
  {
    label: 'förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'e-post',
    field: 'email',
    // width: 270,
  },
];
