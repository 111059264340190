/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ModalList from '../../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';

import { useDispatch, useSelector } from 'react-redux';
import {
  getSurveys,
  removeSurveyFromProject,
  getProjects,
} from '../../../../redux/store/actions';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import AddSurveyToProjectModal from '../add-survey-to-project-modal';
import AddFromModalButton from '../../../../components/add-from-modal-button';

const ConnectedProjectList = ({
  surveyToEdit,
  mobile,
  setSurvey,
  handleClose,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [availableProjects, setAvailableProjects] = useState('');
  const [currentProjects, setCurrentProjects] = useState('');
  const [rows, setRows] = useState('');
  const [projectToRemove, setProjectToRemove] = useState('');

  const confirmDeleteMessage =
    surveyToEdit && projectToRemove
      ? `Är du säker att du vill ta bort kopplingen mellan formulär  ${surveyToEdit.questionnaire_name} och projekt ${projectToRemove.project_name}?`
      : '';

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Projekt</p>,
      field: 'project',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!projects && user) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, []);
  useEffect(() => {
    setCurrentProjects('');
  }, [handleClose]);

  useEffect(() => {
    if (surveyToEdit && projects) {
      createRowsForList(surveyToEdit, projects);
    }
  }, [surveyToEdit, projects]);

  const createRowsForList = (s, p) => {
    const { project_setup } = s;
    const projectIDs = [];

    const activeProj = p.filter((value) => value.is_active === true);
    if (project_setup.length > 0) {
      const activeProjectSetups = project_setup.filter(
        (ps) => ps.is_active === true && ps.project.is_active
      );

      const rows = activeProjectSetups.map((t) => {
        const { project_name, project_id } = t.project;

        projectIDs.push(project_id);

        return {
          project: project_name,
          remove: (
            <MDBIcon
              key={project_id}
              style={{ cursor: 'pointer', float: 'right', marginRight: '13px' }}
              icon="minus-circle"
              className="text-primary"
              size="lg"
              onClick={() => {
                setShowDeleteModal(true);
                setProjectToRemove(t.project);
              }}
            />
          ),
        };
      });

      setRows(rows);
      setCurrentProjects(projectIDs);
    } else {
      setRows([]);
    }

    if (projectIDs.length === 0) {
      setAvailableProjects(activeProj);
    } else {
      setAvailableProjects(
        activeProj.filter((x) => !projectIDs.includes(x.project_id))
      );
    }
  };

  const handleRemoveSurveyFromProject = (project, survey) => {
    dispatch(
      removeSurveyFromProject(project, survey, user.digituals_client_id)
    ).then(() =>
      setTimeout(function () {
        dispatch(getSurveys(user.digituals_client_id)).then(({ payload }) => {
          const findServeyToEdit = payload.filter(
            (e) => e.questionnaire_id === surveyToEdit.questionnaire_id
          );

          if (findServeyToEdit[0]) {
            setSurvey(findServeyToEdit[0]);
          }
        });
      }, 500)
    );
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setTimeout(function () {
      dispatch(getSurveys(user.digituals_client_id)).then(({ payload }) => {
        const findServeyToEdit = payload.filter(
          (e) => e.questionnaire_id === surveyToEdit.questionnaire_id
        );

        if (findServeyToEdit[0]) {
          setSurvey(findServeyToEdit[0]);
        }
      });
    }, 500);

    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleDone = () => {};

  return (
    <>
      <DeleteConfirmation
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() =>
          handleRemoveSurveyFromProject(projectToRemove, surveyToEdit)
        }
        message={confirmDeleteMessage}
      />
      {availableProjects && (
        <AddSurveyToProjectModal
          availableProjects={availableProjects}
          currentProjects={currentProjects}
          surveyToEdit={surveyToEdit}
          show={showModal}
          handleClose={handleCloseModal}
          done={handleDone}
          fullscreen={mobile ? true : false}
        />
      )}

      {currentProjects ? (
        <ModalList
          listKey={'ConnectedProjectList'}
          columns={columns}
          rows={rows}
          add={handleOpen}
        />
      ) : (
        <Row className=" mb-4">
          <Col className="d-flex align-items-center  justify-content-start ">
            <AddFromModalButton label={'Koppla projekt'} onClick={handleOpen} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ConnectedProjectList;
