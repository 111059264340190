export const accountColumns = [
  // {
  //   label: 'förnamn',
  //   field: 'name',
  //   // width: 150,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Name',
  //   },
  // },
  // {
  //   label: 'efternamn',
  //   field: 'lastname',
  //   // width: 150,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Name',
  //   },
  // },
  // {
  //   label: 'avdelning',
  //   field: 'operation',
  //   // width: 270,
  // },
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'E-post',
    field: 'email',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Skapad',
    field: 'createdAt',
  },
  {
    label: 'Användartyp',
    field: 'role',
    // width: 270,
  },
  // {
  //   label: 'Inbjudan skickad',
  //   field: 'inviteSent',
  //   // width: 270,
  // },
  // {
  //   label: 'Inbjudan bekräftad',
  //   field: 'inviteConfirmed',
  //   // width: 270,
  // },
];
export const accountColumnsMobile = [
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'E-post',
    field: 'email',
  },

  {
    label: 'Användartyp',
    field: 'role',
    // width: 270,
  },
];

export const inactiveAccountColumns = [
  // {
  //   label: 'förnamn',
  //   field: 'name',
  //   // width: 150,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Name',
  //   },
  // },
  // {
  //   label: 'efternamn',
  //   field: 'lastname',
  //   // width: 150,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Name',
  //   },
  // },
  // {
  //   label: 'avdelning',
  //   field: 'operation',
  //   // width: 270,
  // },
  {
    label: 'användartyp',
    field: 'role',
    // width: 270,
  },
  {
    label: 'e-post',
    field: 'email',
    // width: 270,
  },

  {
    label: 'aktivera',
    field: 'activate',
    sort: 'disabled',
    // width: 150,
  },
];
export const resourceColumnsMobile = [
  {
    label: 'förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'rapportör',
    field: 'haveAccount',
    // width: 270,
  },
];
