export const questionColumns = [
  {
    label: '',
    field: '',
  },
  {
    label: 'Fråga',
    field: 'question',
  },
  {
    label: 'Beskrivning',
    field: 'description',
  },
  {
    label: 'Antal formulär',
    field: 'numberOfQuestionnaires',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Skapad',
    field: 'createdAt',
  },
  {
    label: 'Uppdaterad',
    field: 'updatedAt',
  },
  {
    label: '',
    field: 'last',
  },
];

export const inactiveQuestionColumns = [
  {
    label: 'fråga',
    field: 'question',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    sort: 'disabled',
    // width: 150,
  },
];
export const questionColumnsMobile = [
  {
    label: 'Fråga',
    field: 'question',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
export const inactiveQuestionColumnsMobile = [
  {
    label: 'fråga',
    field: 'question',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'aktivera',
    field: 'activate',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
