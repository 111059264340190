import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
// export const DIGITUALS_CLIENT_ID = '4cf8db60-0c3e-4aff-828a-3f282a1a2a9f';
export const DIGITUALS_CLIENT_ID = 'c7968b7c-845e-4f01-9028-8f9efb1bbc04'; //testdata_bygg
//export const DIGITUALS_CLIENT_ID = 'cd386a2c-2bbc-41f5-bd2c-39e39e085748'; //glb_bygg

// export const DIGITUALS_CLIENT_ID = '2af612b8-190c-42d1-a867-17e7e92323fb';
//   "mdb-react-ui-kit": "git+https://oauth2:c4NEjziAxxGoVZfoWzct@git.mdbootstrap.com/mdb/react/mdb5/prd/mdb5-react-ui-kit-pro-essential",
// "mdbreact": "git+https://oauth2:c4NEjziAxxGoVZfoWzct@git.mdbootstrap.com/mdb/react/re-pro.git",

// "mdb-react-ui-kit": "git+https://oauth2:c4NEjziAxxGoVZfoWzct@git.mdbootstrap.com/mdb/react/mdb5/prd/mdb5-react-ui-kit-pro-essential",
// "mdbreact": "git+https://oauth2:c4NEjziAxxGoVZfoWzct@git.mdbootstrap.com/mdb/react/re-pro.git",
