import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';

import { CSVLink } from 'react-csv';
import { MDBTooltip } from 'mdb-react-ui-kit';
import moment from 'moment';

function FloatingExportButton({ dataToExport, headers }) {
  return (
    <div
      style={{
        position: 'fixed',
        right: '7px',
        top: '82vh',
        marginTop: 5,
        zIndex: 2000,
      }}
    >
      <MDBTooltip
        wrapperProps={{ color: 'primary' }}
        wrapperClass="bg-transparent text-white"
        className="bg-primary"
        placement="left"
        title="Exportera till textfil"
      >
        <CSVLink
          className="d-flex  flex-row"
          data={dataToExport}
          headers={headers}
          filename={`respons-${moment().format()}.csv`}
          target="_blank"
          style={{ cursor: 'pointer', marginRight: 0, marginBottom: 0 }}
        >
          <MDBBtn
            floating
            tag="a"
            style={{
              cursor: 'pointer',
            }}
            size="lg"
          >
            <MDBIcon fas icon="download" size="lg" color="white" />
          </MDBBtn>
        </CSVLink>
      </MDBTooltip>
    </div>
  );
}

export default FloatingExportButton;
