/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';

import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { useDispatch } from 'react-redux';
import {
  setAnalysData,
  setAnalysDataProjectCustomer,
  setAnalysDataQuestion,
  setAnalysDataReporter,
  setAnalysDataTime,
} from '../../redux/store/actions';
import { analysFilterData } from '../../constants';
import FloatingHoverButton from '../floating-hover-button';
import { sortAs } from 'react-pivottable/Utilities';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivotFilter = ({ data, hiddenFromDragDrop, filter, hide = false }) => {
  const [state, setState] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      if (filter === analysFilterData.OVERVIEW) {
        dispatch(setAnalysData(state));
      }
      // if (filter === analysFilterData.TIME) {
      //   dispatch(setAnalysDataTime(state));
      // }
      // if (filter === analysFilterData.PROJECT_CUSTOMER) {
      //   dispatch(setAnalysDataProjectCustomer(state));
      // }
      // if (filter === analysFilterData.REPORTER) {
      //   dispatch(setAnalysDataReporter(state));
      // }
      // if (filter === analysFilterData.QUESTION) {
      //   dispatch(setAnalysDataQuestion(state));
      // }
    }
  }, [state]);

  function resetFilter() {
    dispatch(setAnalysData(data));
    setState(data);
  }

  return (
    <>
      <div
        className={`d-flex flex-column  fixed-top `}
        style={{
          overflow: 'visable',
          marginTop: hide ? '-1000px' : '121px',
          zIndex: hide ? 0 : 1000,
          marginLeft: '25px',
          marginRight: '31px',
        }}
      >
        <PivotTableUI
          data={data}
          onChange={(s) => setState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          hiddenFromDragDrop={hiddenFromDragDrop}
          unusedOrientationCutoff={1000}
          {...state}
        />
      </div>
      {!hide && (
        <FloatingHoverButton
          hoverText="Återställ filter"
          icon="redo"
          onClick={() => resetFilter()}
        />
      )}
    </>
  );
};

export default PivotFilter;
