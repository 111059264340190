import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

const SaveDataForm = ({ handleClose, onSubmit }) => {
  const [enableValidate, setEnableValidate] = useState(false);

  const initialValues = {
    fileName: '',
  };

  const schema = Yup.object().shape({
    fileName: Yup.string().required('Namnge fil'),
  });

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    onSubmit(values.fileName);
    handleResetForm(resetForm);
    handleClose();
  };

  const handleGoBack = (resetForm) => {
    resetForm();
    handleClose();
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      initialValues={initialValues}
      validateOnChange={enableValidate}
      validateOnBlur={enableValidate}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        resetForm,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            setEnableValidate(true);
            e.preventDefault();
            return handleSubmit();
          }}
          onReset={(e) => {
            e.preventDefault();
            return handleGoBack(resetForm);
          }}
          id="SaveDataForm"
        >
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-secondary">Filnamn</Form.Label>
              <Form.Control
                placeholder="Filnamn..."
                type="text"
                name="fileName"
                value={values.fileName}
                onChange={handleChange}
                isValid={touched.fileName && !errors.fileName}
                isInvalid={!!errors.fileName}
              />

              <Form.Control.Feedback type="invalid">
                {errors.fileName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SaveDataForm;
