/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ModalList from '../../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import {
  getQuestions,
  getSurveys,
  removeQuestionFromSurvey,
} from '../../../../redux/store/actions';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import AddQuestionToSurveysModal from '../add-question-to-surveys-modal';
import AddFromModalButton from '../../../../components/add-from-modal-button';

const ConnectedSurveyList = ({ questionToEdit, mobile, setQuestion }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentSurveys, setCurrentSurveys] = useState('');
  const [availableSurveys, setAvailableSurveys] = useState('');
  const [rows, setRows] = useState('');

  const [surveyToRemove, setSurveyToRemove] = useState('');

  const confirmDeleteMessage =
    questionToEdit && surveyToRemove
      ? `Är du säker att du vill ta bort kopplingen mellan fråga  ${questionToEdit.question} och formulär ${surveyToRemove.questionnaire_name} ?`
      : '';

  const columns = [
    {
      label: (
        <p style={{ cursor: 'pointer', float: 'left', marginLeft: 0 }}>
          Formulär
        </p>
      ),
      field: 'survey',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { surveys } = useSelector((state) => state.survey);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getSurveys(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (questionToEdit) {
      createRowsForList(questionToEdit);
    }
  }, [questionToEdit]);

  const createRowsForList = (q) => {
    const { questionnaire_setup } = q;
    const surveyIDs = [];
    const actServeys = surveys.filter((value) => value.is_active === true);

    if (questionnaire_setup.length > 0) {
      const activeSurveys = questionnaire_setup.filter(
        (value) => value.questionnaire.is_active === true
      );

      const rows = activeSurveys.map((qs) => {
        const { questionnaire_name, questionnaire_id } = qs.questionnaire;

        surveyIDs.push(questionnaire_id);

        return {
          survey: questionnaire_name,
          remove: (
            <MDBIcon
              key={questionnaire_id}
              style={{ cursor: 'pointer', float: 'right', marginRight: '13px' }}
              icon="minus-circle"
              className="text-primary"
              size="lg"
              onClick={() => {
                setSurveyToRemove(qs.questionnaire);
                setShowDeleteModal(true);
              }}
            />
          ),
        };
      });

      setRows(rows);
      setCurrentSurveys(surveyIDs);
    } else {
      setRows([]);
    }

    if (surveyIDs.length === 0) {
      setAvailableSurveys(actServeys);
    } else {
      if (actServeys) {
        setAvailableSurveys(
          actServeys.filter((x) => !surveyIDs.includes(x.questionnaire_id))
        );
      }
    }
  };

  const handleRemoveQuestionFromSurvey = (question, survey) => {
    dispatch(
      removeQuestionFromSurvey(question, survey, user.digituals_client_id)
    ).then(() =>
      setTimeout(function () {
        dispatch(getQuestions(user.digituals_client_id)).then(({ payload }) => {
          const findQuestionToEdit = payload.filter(
            (e) => e.question_id === questionToEdit.question_id
          );
          if (findQuestionToEdit[0]) {
            setQuestion(findQuestionToEdit[0]);
          }
        });
      }, 500)
    );

    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowModal(false);

    setTimeout(function () {
      dispatch(getQuestions(user.digituals_client_id)).then(({ payload }) => {
        const findQuestionToEdit = payload.filter(
          (e) => e.question_id === questionToEdit.question_id
        );
        if (findQuestionToEdit[0]) {
          setQuestion(findQuestionToEdit[0]);
        }
      });
    }, 500);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      {questionToEdit && surveyToRemove && (
        <DeleteConfirmation
          showModal={showDeleteModal}
          hideModal={() => setShowDeleteModal(false)}
          confirmModal={() =>
            handleRemoveQuestionFromSurvey(questionToEdit, surveyToRemove)
          }
          message={confirmDeleteMessage}
        />
      )}

      {availableSurveys && (
        <AddQuestionToSurveysModal
          currentSurveys={currentSurveys}
          availableSurveys={availableSurveys}
          questionToEdit={questionToEdit}
          show={showModal}
          handleClose={handleClose}
          fullscreen={mobile ? true : false}
        />
      )}

      {currentSurveys && rows.length > 0 ? (
        <ModalList columns={columns} rows={rows} add={handleOpen} />
      ) : (
        <Row className=" mb-4">
          <Col className="d-flex align-items-center  justify-content-start ">
            <AddFromModalButton
              label={'Koppla formulär'}
              onClick={handleOpen}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ConnectedSurveyList;
