import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { autoSignIn, logoutUser } from './redux/store/actions';

import AuthHoc from './hoc/authHoc';
import AdminHoc from './hoc/adminHoc';
import AnalysHoc from './hoc/analysHoc';

import Home from './pages/home';
import Login from './pages/login';
import PasswordReset from './pages/reset-password';
import Survey from './pages/survey';
import AdminAnalysis from './pages/admin/analysis';
import AdminProject from './pages/admin/project';
import AdminQuestions from './pages/admin/questions';
import AdminResources from './pages/admin/resources';
import AdminProjectManagement from './pages/admin/project-management';
import AdminSurveyManagement from './pages/admin/survey-management';
import AdminHome from './pages/admin/admin-home';
import AdminResponse from './pages/admin/response';
import AnalysDashboard from './pages/analys/analys-analys';
import AnalysOverview from './pages/analys/analys-overview';
import AnalysStatus from './pages/analys/analys-status';
import AnalysExport from './pages/analys/analys-export';
import Loading from './components/loading';
import { supabase } from './utils/supabase';
import toast from 'react-hot-toast';
import { history } from './utils/history';

class RoutesComponent extends Component {
  componentDidMount() {
    this.props.dispatch(autoSignIn());
    // this.props.dispatch(logoutUser());
    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('event', event, 'session', session);

      if (event === 'SIGNED_IN' && session) {
        if (session.user.user_metadata.have_password === false) {
          const newPassword = prompt('Skriv in lösenord, minst 6 tecken.');
          const { data, error } = await supabase.auth.update({
            password: newPassword,
            data: { have_password: true },
          });

          if (data) {
            toast.success('Lösenord uppdaterat!');
            setTimeout(() => {
              history.push('/');
            }, 1200);
          }
          if (error) toast.error('Fel uppstod!');
        }
      }

      if (event === 'PASSWORD_RECOVERY') {
        const newPassword = prompt('Skriv ditt nya lösenord, minst 6 tecken.');
        const { data, error } = await supabase.auth.update({
          password: newPassword,
        });

        if (data) {
          toast.success('Lösenord uppdaterat!');
          setTimeout(() => {
            history.push('/');
          }, 1200);
        }
        if (error) toast.error('Fel uppstod!');
      }
    });
  }

  app = () => (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/analys-analys" component={AnalysHoc(AnalysDashboard)} />
          <Route
            path="/analys-overview"
            component={AnalysHoc(AnalysOverview)}
          />
          <Route path="/analys-status" component={AnalysHoc(AnalysStatus)} />
          <Route path="/analys-export" component={AnalysHoc(AnalysExport)} />
          <Route path="/admin-home" component={AdminHoc(AdminHome)} />
          <Route path="/analysis" component={AdminHoc(AdminAnalysis)} />
          <Route path="/project" component={AdminHoc(AdminProject)} />
          <Route path="/questions" component={AdminHoc(AdminQuestions)} />
          <Route path="/users" component={AdminHoc(AdminResources)} />
          <Route path="/response" component={AdminHoc(AdminResponse)} />
          <Route
            path="/project-management"
            component={AdminHoc(AdminProjectManagement)}
          />
          <Route
            path="/survey-management"
            component={AdminHoc(AdminSurveyManagement)}
          />
          <Route path="/home" component={AuthHoc(Home)} />
          <Route path="/survey" component={AuthHoc(Survey)} />
          {/* <Route path="/reset-password" component={AuthHoc(PasswordReset)} /> */}
          {/* <Route path="/reset-password" component={AdminHoc(PasswordReset)} />
          <Route path="/reset-password" component={AnalysHoc(PasswordReset)} /> */}
          <Route path="/reset-password" component={PasswordReset} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
    </>
  );

  render() {
    const { auth } = this.props;

    return auth.checkingAuth ? this.app() : <Loading />;
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(RoutesComponent);
