/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveys } from '../../../redux/store/actions';
import EditSurveyModal from './edit-survey-modal/EditSurveyModal';
import { surveyColumns, surveyColumnsMobile } from './dataColumns';
import AddSurveyModal from './add-survey-modal/AddSurveyModal';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import moment from 'moment';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const SurveyTable = ({ mobile }) => {
  const [showEditSurveyModal, setShowEditSurveyModal] = useState(false);
  const [surveyToEdit, setSurveyToEdit] = useState('');
  const [openCreateSurveyModal, setOpenCreateSurveyModal] = useState(false);

  const [showInactiveSurveys, setShowInactiveSurveys] = useState(false);

  const [rows, setRows] = useState('');

  const dispatch = useDispatch();
  const { surveys } = useSelector((state) => state.survey);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getSurveys(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (surveys) {
      createRowsForDatasets();
    }
  }, [surveys, showInactiveSurveys]);

  const createRowsForDatasets = () => {
    const filtredSurveys = surveys.filter(
      ({ is_active }) => is_active === true
    );

    const surveysToMap = !showInactiveSurveys ? filtredSurveys : surveys;

    const rows = surveysToMap.map((survey) => {
      const {
        questionnaire_name,
        is_active,
        questionnaire_setup,
        questionnaire_created_at,
        questionnaire_updated_at,
        project_setup,
      } = survey;

      const activeProjectSetups = project_setup.filter(
        (ps) => ps.is_active === true && ps.project.is_active
      );

      const activeQ =
        questionnaire_setup.length > 0
          ? questionnaire_setup.filter((q) => q.question.is_active === true)
          : [];

      return {
        clickEvent: () => handleClickRow(survey),
        surveyName: questionnaire_name,
        status: is_active ? 'Aktiv' : 'Inaktiv',
        numberOfQuestions: activeQ.length,
        numberOfProjects: activeProjectSetups.length,
        createdAt: moment(questionnaire_created_at).format('YYYY-MM-DD'),
        updatedAt: moment(questionnaire_updated_at).format('YYYY-MM-DD'),
      };
    });

    setRows(rows);
  };

  const handleOpenSurveyManagementModal = () => {
    setOpenCreateSurveyModal(true);
  };

  const handleClickRow = (s) => {
    setSurveyToEdit(s);
    setShowEditSurveyModal(true);
  };

  const handleToggleShowActive = () =>
    setShowInactiveSurveys(!showInactiveSurveys);

  if (surveys) {
    surveys.sort(function (a, b) {
      var nameA = a.questionnaire_name.toUpperCase();
      var nameB = b.questionnaire_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <div className="mb-5">
      {rows ? (
        <>
          {!mobile && (
            <ShowOrHideInactiveButton
              onClick={handleToggleShowActive}
              text={
                showInactiveSurveys
                  ? 'Göm inaktiva frågeformulär'
                  : 'Visa inaktiva frågeformulär'
              }
            />
          )}

          <ActiveDatatable
            columns={mobile ? surveyColumnsMobile : surveyColumns}
            rows={rows}
            add={handleOpenSurveyManagementModal}
            mobile={mobile}
          />
        </>
      ) : (
        <Spinner animation="border" />
      )}
      {/* {surveyToEdit !== '' && (
        )} */}
      <EditSurveyModal
        show={showEditSurveyModal}
        surveyToEdit={surveyToEdit}
        handleClose={() => setShowEditSurveyModal(false)}
        mobile={mobile}
      />

      <AddSurveyModal
        handleClose={() => setOpenCreateSurveyModal(false)}
        show={openCreateSurveyModal}
        mobile={mobile}
      />
    </div>
  );
};

export default SurveyTable;
