import { FamilyRestroomOutlined } from '@mui/icons-material';
import React from 'react';
import { forwardRef } from 'react';

export const ComponentToPrint = forwardRef((props, ref) => {
  return (
    <div style={props.style} className={props.className} ref={ref}>
      {props.children}
    </div>
  );
});
