/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_QUESTION,
  GET_QUESTIONS,
  DELETE_QUESTION,
  EDIT_QUESTION,
  INACTIVATE_QUESTION,
  ACTIVATE_QUESTION,
  ADD_QUESTION_TO_SURVEYS,
  REMOVE_QUESTION_FROM_SURVEY,
} from '../types';

const INITIAL_STATE = {
  questions: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_QUESTION:
      return { ...state };
    case GET_QUESTIONS:
      return { questions: action.payload };
    case DELETE_QUESTION:
      return { ...state };
    case EDIT_QUESTION:
      return { ...state };
    case INACTIVATE_QUESTION:
      return { ...state };
    case ACTIVATE_QUESTION:
      return { ...state };
    case ADD_QUESTION_TO_SURVEYS:
      return { ...state };
    case REMOVE_QUESTION_FROM_SURVEY:
      return { ...state };
    default:
      return state;
  }
}
