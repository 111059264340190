import React from 'react';

import SurveyTable from './SurveyTable';

const AdminSurveyManagement = () => {
  return (
    <>
      {/* <NavigationBar /> */}
      <div className="">
        {/* <div className="d-flex align-items-center  justify-content-center flex-column mb-5"> */}
        <SurveyTable />
      </div>
    </>
  );
};

export default AdminSurveyManagement;
