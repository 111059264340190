import React from 'react';
import Plot from 'react-plotly.js';

function PlotlyGauge() {
  //   var data = [
  //     {
  //       domain: { x: [0, 1], y: [0, 1] },
  //       value: 450,
  //       title: { text: 'Speed' },
  //       type: 'indicator',
  //       mode: 'gauge+number',
  //       delta: { reference: 400 },
  //       gauge: { axis: { range: [null, 500] } },
  //     },
  //   ];

  //   var layout = { width: 600, height: 400 };
  //   const gauge = Plotly.newPlot('myDiv', data, layout);

  return (
    <div>
      <Plot
        data={[
          {
            x: [0, 1],
            y: [0, 1],
            type: 'indicator',
            mode: 'gauge+number',
            marker: { color: 'red' },
            value: 2.7,
            gauge: {
              axis: { range: [1, 4] },
              bar: { color: 'red' },
              shape: 'angular',
              bordercolor: 'blue',
              borderwidth: 2,
              steps: {
                range: [1, 2, 3, 4, 5],
                color: ['red', 'orange', 'yellow', 'green', 'blue'],
              },
            },
          },
          // { type: 'bar', x: [1, 2, 3], y: [2, 5, 3] },
        ]}
        layout={{ width: 320, height: 240, title: 'A Fancy Plot' }}
        config={{ displayModeBar: false }}
      />
    </div>
  );
}

export default PlotlyGauge;
