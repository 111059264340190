/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Spinner, Form } from 'react-bootstrap';

import { resourceWithAccountColumns } from './dataColumns';

import { MDBDataTableV5 } from 'mdbreact';

const CanRunSurveyList = ({
  selectedresourcesWithAccount,
  resourcesWithAccount,
  checkedresources,
}) => {
  const [resourceOnList, setresourceOnList] = useState(resourcesWithAccount);
  const [checked, setChecked] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedresources, setSelectedresources] = useState(
    checkedresources ? checkedresources : []
  );

  useEffect(() => {
    setresourceOnList(resourcesWithAccount);
  }, [resourcesWithAccount]);

  useEffect(() => {
    handleCreateColumns();
    countCheckboxes();
  }, [resourceOnList, selectAllChecked]);

  const countCheckboxes = () => {
    let temp = [];

    resourceOnList.forEach((resource, index) => {
      temp[index] = false;
      if (checkedresources) {
        checkedresources.forEach((checkedresource) => {
          if (resource.value === checkedresource.value) {
            temp[index] = true;
          }
        });
      } else {
        temp.push(false);
      }
    });

    setChecked(temp);
  };

  const handleToggle = (resource, index, checkboxChecked) => {
    const newSelectedresources = [...selectedresources];

    if (checkboxChecked === true) {
      newSelectedresources.push(resource);

      setSelectedresources(newSelectedresources);
      selectedresourcesWithAccount(newSelectedresources);
    } else {
      const filteredSelectedresources = selectedresources.filter(
        (selectedresource) => selectedresource.value !== resource.value
      );
      setSelectedresources(filteredSelectedresources);
      selectedresourcesWithAccount(filteredSelectedresources);
    }
  };

  const handleSelectAll = () => {
    const allEmp = [];

    resourceOnList.forEach((resourceWithAccount, i) => {
      allEmp.push(resourceWithAccount);
    });

    setSelectedresources(allEmp);
    selectedresourcesWithAccount(allEmp);

    let temp = [];
    resourceOnList.forEach((res, index) => {
      temp.push(true);
    });
    setChecked(temp);
  };
  const handleClear = () => {
    let temp = [];
    resourceOnList.forEach((res, index) => {
      temp.push(false);
    });
    setChecked(temp);
    setSelectedresources([]);
    selectedresourcesWithAccount([]);
  };

  const handleCreateColumns = () => {
    const dataColumns = [];

    resourceWithAccountColumns.forEach((column) => {
      dataColumns.push(column);
    });

    dataColumns.push({
      label: [
        <Form.Check
          className="text-center"
          key={'index.toString()'}
          checked={selectAllChecked}
          onClick={() => {
            selectAllChecked ? handleClear() : handleSelectAll();
            setSelectAllChecked(!selectAllChecked);
          }}
          type="checkbox"
        />,
      ],
      field: 'checkbox',
      sort: 'disabled',
    });

    setColumnData(dataColumns);
  };

  return (
    <>
      <Container className="">
        {resourceOnList ? (
          <MDBDataTableV5
            className="bg-white"
            responsive
            small
            hover
            searching={false}
            entriesOptions={[5, 20, 25, 50, 100]}
            entries={5}
            pagesAmount={4}
            data={{
              columns: columnData,

              rows: resourceOnList.map((resource, index) => {
                const { label } = resource;

                return {
                  name: label,
                  // name: `${resource.resource_firstname} ${resource.lastname}`,
                  checkbox: (
                    <Form.Check
                      className="text-center"
                      key={index.toString()}
                      checked={checked[index]}
                      onClick={() => {
                        let temp = [...checked];
                        temp[index] = !checked[index];
                        setChecked(temp);
                        handleToggle(resource, index, temp[index]);
                      }}
                      type="checkbox"
                    />
                  ),
                };
              }),
            }}
            headCheckboxID="id6"
            bodyCheckboxID="checkboxes6"
          />
        ) : (
          <Spinner animation="border" />
        )}
      </Container>
    </>
  );
};

export default CanRunSurveyList;
