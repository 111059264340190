export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const RESET_PASSWORD = 'reset_password';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_PASSWORD = 'update_user_password';

export const CREATE_SURVEY = 'create_survey';
export const GET_SURVEYS = 'get_surveys';
export const GET_SURVEY_QUESTIONS = 'get_survey_questions';
export const EDIT_SURVEY = 'edit_survey';
export const DELETE_SURVEY = 'delete_survey';
export const INACTIVATE_SURVEY = 'inactivate_survey';
export const ACTIVATE_SURVEY = 'activate_survey';
export const GET_ACTIVE_SURVEY = 'get_active_survey';
export const CHOOSE_SURVEY_TO_USE = 'choose_servey_to_use';
export const SET_ACTIVE_SURVEY = 'set_active_survey';
export const SURVEY_PROGRESS = 'survey_progress';
export const REMOVE_SURVEY_FROM_PROJECT = 'remove_survey_from_project';
export const ADD_PROJECT_TO_SERVEY = 'add_project_to_servey';

export const POST_RESPONSE = 'post_response';
export const GET_RESPONSE = 'get_response';
export const DELETE_RESPONSE = 'delete_response';
export const EDIT_RESPONSE = 'edit_response';

export const ADD_PROJECT = 'add_project';
export const GET_PROJECTS = 'get_projects';
export const DELETE_PROJECT = 'delete_project';
export const EDIT_PROJECT = 'edit_project';
export const ACTIVATE_PROJECT = 'activate_project';
export const INACTIVATE_PROJECT = 'inactivate_project';

export const ADD_QUESTION = 'add_question';
export const GET_QUESTIONS = 'get_questions';
export const DELETE_QUESTION = 'delete_question';
export const EDIT_QUESTION = 'edit_question';
export const INACTIVATE_QUESTION = 'inactivate_question';
export const ACTIVATE_QUESTION = 'activate_question';
export const ADD_QUESTION_TO_SURVEYS = 'add_question_to_surveys';
export const REMOVE_QUESTION_FROM_SURVEY = 'remove_question_from_survey';

export const ADD_PROJECT_MANAGER = 'add_project_manager';
export const ADD_RESOURCE = 'add_resource';
export const GET_RESOURCES = 'get_resources';
export const GET_USERS = 'get_users';
export const DELETE_RESOURCE = 'delete_resource';
export const EDIT_RESOURCE = 'edit_resource';
export const ACTIVATE_RESOURCE = 'activate_resource';
export const INACTIVATE_RESOURCE = 'inactivate_resource';
export const ADD_RESOURCE_TO_PROJECT = 'add_resource_to_project';
export const REMOVE_RESOURCE_TO_PROJECT = 'remove_resource_to_project';
export const REMOVE_RESOURCE_FROM_OPERATION = 'remove_resource_from_operation';
export const ADD_RESOURCE_TO_OPERATION = 'add_resource_to_operation';
export const INACTIVATE_ACCOUNT = 'inactivate_account';
export const ACTIVATE_ACCOUNT = 'activate_account';
export const DELETE_ACCOUNT = 'delete_account';
export const EDIT_ACCOUNT = 'edit_account';
export const CREATE_ACCOUNT = 'create_account';
export const GET_REPORTERS = 'get_reporters';
export const GET_ACCOUNTS = 'get_accounts';

export const ADD_MANAGED_PROJECT = 'add_managed_project';
export const GET_MANAGED_PROJECTS = 'get_managed_projects';
export const GET_MY_MANAGED_PROJECTS = 'get_my_managed_projects';
export const DELETE_MANAGED_PROJECT = 'delete_managed_project';
export const EDIT_MANAGED_PROJECT = 'edit_managed_project';

export const ADD_OPERATION = 'add_operation';
export const GET_OPERATION = 'get_operation';
export const ACTIVATE_OPERATION = 'activate_operation';
export const INACTIVATE_OPERATION = 'inactivate_operation';
export const EDIT_OPERATION = 'edit_operation';
export const DELETE_OPERATION = 'delete_operation';

export const ADD_CUSTOMER = 'add_customer';
export const GET_CUSTOMER = 'get_customer';
export const EDIT_CUSTOMER = 'edit_customer';
export const DELETE_CUSTOMER = 'delete_customer';
export const ACTIVATE_CUSTOMER = 'activate_customer';
export const INACTIVATE_CUSTOMER = 'inactivate_customer';

export const SET_ANALYS_DATA = 'set_analys_data';
export const SET_ANALYS_DATA_DEFAULT = 'set_analys_data_default';
export const SET_ANALYS_DATA_TIME = 'set_analys_data_time';
export const SET_ANALYS_DATA_PROJECT_CUSTOMER =
  'set_analys_data_project_customer';
export const SET_ANALYS_DATA_REPORTER = 'set_analys_data_reporter';
export const SET_ANALYS_DATA_QUESTION = 'set_analys_data_question';

export const SET_OVERVIEW_DATA_SELECTED_QUESTIONS =
  'set_overviewd_data_selected_questions';
export const SET_OVERVIEW_DATA_SELECTED_CUSTOMERS =
  'set_overviewd_data_selected_customers';

export const SET_OVERVIEW_DATA_ACTIVE_QUESTIONS =
  'set_overviewd_data_active_questions';
export const SET_OVERVIEW_DATA_ACTIVE_CUSTOMERS =
  'set_overviewd_data_active_customers';
export const SET_OVERVIEW_DATA_SELECTED_DATE = 'set_overviewd_data_dates';
export const SET_SELECTED_DATA_FROM_FILTER = 'set_selected_data_from_filter';
