import React from 'react';

import AddModal from '../../../../components/add-modal';
import AddResourceForm from './AddResourceForm';

const AddResourceModal = ({ handleClose, show, mobile }) => {
  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        submitForm={'AddResourceForm'}
        header="Skapa resurs"
      >
        <AddResourceForm handleClose={handleClose} />
      </AddModal>
    </>
  );
};

export default AddResourceModal;
