import React from 'react';

import AddModal from '../../../../components/add-modal';
import AddOperationForm from './AddOperationForm';

const AddOperationModal = ({ handleClose, show, mobile }) => {
  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={mobile ? true : false}
        submitForm={'AddOperationForm'}
        header="Skapa avdelning"
      >
        <AddOperationForm handleClose={handleClose} />
      </AddModal>
    </>
  );
};

export default AddOperationModal;
