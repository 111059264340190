import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';

const FAB = ({ icon, onClick }) => {
  const [currentWindowHeight, setCurrentWindowHeight] = useState(
    window.innerHeight
  );

  const pixelBreakpoint = 730;
  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MDBBtn
      floating
      tag="a"
      style={{
        zIndex: 100,
        position: 'fixed',
        right: '30px',
        top: currentWindowHeight < pixelBreakpoint ? '75vh' : '80vh',
      }}
      size="lg"
      onClick={onClick}
    >
      <MDBIcon fas icon={icon} size="lg" color="white" />
    </MDBBtn>
  );
};

export default FAB;
