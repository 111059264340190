import { supabase } from '../utils/supabase';
import { sendToast } from '../components/toast';
import moment from 'moment';

const addQuestion = async (
  { question, description, status, surveys },
  digitualsClientID
) => {
  try {
    const { data, error } = await supabase.from('question').insert([
      {
        question: question,
        question_description: description,
        digituals_client_id: digitualsClientID,
        is_active: status,
      },
    ]);

    if (data) {
      sendToast(`${question} lades till som fråga`, 1);

      if (surveys.length > 0) {
        const { question_id } = data[0];

        const questionnaireSetup = surveys.map((value) => ({
          question_id: question_id,
          questionnaire_id: value.questionnaire_id,
          digituals_client_id: digitualsClientID,
          question_sort_order: value.question_sort_order,
        }));

        const { data: questionnaireSetupData, error: questionnaireSetupError } =
          await supabase.from('questionnaire_setup').insert(questionnaireSetup);

        if (questionnaireSetupData) {
          sendToast(`Fråga ${question} lades till i valda formulär`, 1);
        }
        if (questionnaireSetupError) {
          console.log('questionnaireSetupError:', questionnaireSetupError);
        }
      }
    }
    if (error) {
      console.log('addQuestion error: ', error);
    }
  } catch (error) {
    console.log('addQuestion error', error, error.message);
  }
};

const getQuestions = async (digitualsClientID) => {
  try {
    let { data: question, error } = await supabase
      .from('question')
      .select(
        `
         *,
        questionnaire_setup (
          *,
          questionnaire (
            *
          )
        )
         `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (question) {
      return question;
    }
    if (error) {
      console.log('getQuestions error: ', error);
    }
  } catch (error) {
    console.log('getQuestions error', error, error.message);
  }
};
const editQuestion = async (q, digitualsClientID) => {
  const { question, question_id, description, status } = q;

  try {
    if (status === false) {
      const { data: questionnaireSetupData, error: questionnaireSetupError } =
        await supabase
          .from('questionnaire_setup')
          .delete()
          .eq('question_id', question_id)
          .eq('digituals_client_id', digitualsClientID);

      if (questionnaireSetupData) {
      }
      if (questionnaireSetupError) {
        console.log('editQuestion error: ', questionnaireSetupError);
      }

      const { data, error } = await supabase
        .from('question')
        .update({
          question: question,
          question_description: description,
          question_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          is_active: status,
        })
        .eq('question_id', question_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
        sendToast(`${question} redigerad!`, 1);
      }
      if (error) {
        console.log('editQuestion error: ', error);
      }
    } else {
      const { data, error } = await supabase
        .from('question')
        .update({
          question: question,
          question_description: description,
          question_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          is_active: status,
        })
        .eq('question_id', question_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
        sendToast(`${question} redigerad!`, 1);
      }
      if (error) {
        console.log('editQuestion error: ', error);
      }
    }
  } catch (error) {
    console.log('editQuestion error', error, error.message);
  }
};

const activateQuestion = async (q, digitualsClientID) => {
  const { question, question_id } = q;

  try {
    const { data, error } = await supabase
      .from('question')
      .update({ is_active: true })
      .eq('question_id', question_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`${question} aktiverad`, 1);
    }
    if (error) {
      console.log('editQuestion error: ', error);
    }
  } catch (error) {
    console.log('editQuestion error', error, error.message);
  }
};

const inactivateQuestion = async (q, digitualsClientID) => {
  const { question, question_id } = q;

  try {
    const { data, error } = await supabase
      .from('question')
      .update({ is_active: false })
      .eq('question_id', question_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      sendToast(`${question} inaktiverad`, 1);
    }
    if (error) {
      console.log('editQuestion error: ', error);
    }
  } catch (error) {
    console.log('editQuestion error', error, error.message);
  }
};
const deleteQuestion = async (q, digitualsClientID) => {
  const { question, question_id } = q;
  try {
    let { data: responseDetailsData, error: responseDetailsError } =
      await supabase
        .from('response_details')
        .select('*')
        .eq('question_id', question_id)
        .eq('digituals_client_id', digitualsClientID);

    if (responseDetailsError) {
      console.log('responseDetailsError: ', responseDetailsError);
    }

    if (responseDetailsData.length > 0) {
      return { connected: true };
    } else {
      const { data: questionnaireSetupData, error: questionnaireSetupError } =
        await supabase
          .from('questionnaire_setup')
          .delete()
          .eq('question_id', question_id)
          .eq('digituals_client_id', digitualsClientID);

      const { data, error } = await supabase
        .from('question')
        .delete()
        .eq('question_id', question_id)
        .eq('digituals_client_id', digitualsClientID);

      if (questionnaireSetupData) {
        console.log('questionnaireSetupData: ', questionnaireSetupData);
      }
      if (questionnaireSetupError) {
        console.log('editQuestion error: ', questionnaireSetupError);
      }
      if (error) {
        console.log('error error: ', error);
      }

      if (data) {
        sendToast(`${question} borttagen`, 4);
      }
    }
  } catch (error) {
    console.log('deleteQuestion error', error, error.message);
  }
};

const addQuestionToSurveys = async (q, surveys, digitualsClientID) => {
  const { question, question_id } = q;
  const questionsToPost = [];

  try {
    if (question && surveys) {
      const { data: questionnaireSetupData, error: questionnaireSetupError } =
        await supabase
          .from('questionnaire_setup')
          .delete()
          .eq('question_id', question_id)
          .eq('digituals_client_id', digitualsClientID);

      if (questionnaireSetupData) {
      }
      if (questionnaireSetupError) {
        console.log('editQuestion error: ', questionnaireSetupError);
      }

      surveys.forEach(async (s) => {
        const { questionnaire_id } = s;

        questionsToPost.push({
          question_id: question_id,
          questionnaire_id: questionnaire_id,
          digituals_client_id: digitualsClientID,
        });
      });
    }
    await supabase.from('questionnaire_setup').insert(questionsToPost);

    sendToast(`${question} lades till i valda formulär!`, 1);
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const removeQuestionFromSurvey = async (q, survey, digitualsClientID) => {
  const { question, question_id } = q;
  const { questionnaire_name, questionnaire_id } = survey;

  try {
    if (question && survey) {
      const { data, error } = await supabase
        .from('questionnaire_setup')
        .delete()
        .eq('questionnaire_id', questionnaire_id)
        .eq('question_id', question_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
        sendToast(
          `${question} borttagen från formulär ${questionnaire_name}!`,
          4
        );
      }
      if (error) {
        console.log('editQuestion error: ', error);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

export {
  addQuestion,
  getQuestions,
  editQuestion,
  deleteQuestion,
  activateQuestion,
  inactivateQuestion,
  addQuestionToSurveys,
  removeQuestionFromSurvey,
};
