import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import AddFromModalButton from '../../../../components/add-from-modal-button';

import AddModal from '../../../../components/add-modal';
import AddAccountForm from './AddAccountForm';
import { icons } from '../../../../constants';
import ConnectResourceModal from '../connect-resource-modal';
import Select from 'react-select';
import ModalIcon from '../../../../components/modal-icon';

const AddAccountModal = ({ handleClose, show, mobile }) => {
  const [connectResourceModal, setConnectResourceModal] = useState(false);
  const [resourceConnected, setResourceConnected] = useState(false);

  const [connectedResource, setConnectedResource] = useState('');

  const handleOpenResorceModal = () => {
    setConnectResourceModal(true);
  };

  const handleCloseResorceModal = () => {
    setConnectResourceModal(false);
  };

  const handleSetConnectedResource = (resourse) => {
    if (resourse) {
      setConnectedResource(resourse);
      setResourceConnected(true);
    }
  };

  const handleGoBack = () => {
    setConnectedResource('');
    handleClose();
  };

  const handleRemoveResource = () => {
    setConnectedResource('');
    setResourceConnected(false);
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleGoBack}
        fullscreen={mobile ? true : false}
        submitForm={
          resourceConnected && connectedResource ? 'AddAccountForm' : null
        }
        header="Skapa konto"
      >
        <ConnectResourceModal
          connectResource={handleSetConnectedResource}
          show={connectResourceModal}
          handleClose={handleCloseResorceModal}
          fullscreen={mobile ? true : false}
        />

        <ModalIcon icon={icons.ACCOUNT} />

        {resourceConnected && connectedResource ? (
          <>
            <AddAccountForm
              handleClose={handleClose}
              disabled={resourceConnected}
              connectedResource={connectedResource}
              handleRemoveResource={handleRemoveResource}
            />
          </>
        ) : (
          <>
            <Row className=" mb-4">
              <Col className="d-flex align-items-center  justify-content-start ">
                <AddFromModalButton
                  label={'Koppla resurs'}
                  onClick={handleOpenResorceModal}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">
                  E-post för konto
                </Form.Label>
                <Form.Control
                  readOnly
                  type="email"
                  placeholder="E-post..."
                  name="email"
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Användartyp</Form.Label>
                <Select placeholder="Användartyp..." isDisabled />
              </Form.Group>
            </Row>
          </>
        )}
      </AddModal>
    </>
  );
};

export default AddAccountModal;
