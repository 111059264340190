/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ModalList from '../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';

const ReporterList = ({ selectedReporters, removeReporter, add }) => {
  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resource',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  return (
    <>
      <ModalList
        add={add}
        columns={columns}
        rows={selectedReporters.map((reporter) => {
          const {
            resource_firstname,
            resource_lastname,
            resource_email,
            resource_id,
          } = reporter;
          return {
            resource: `${resource_firstname} ${resource_lastname}`,
            email: resource_email,
            remove: (
              <MDBIcon
                key={resource_id}
                style={{
                  cursor: 'pointer',
                  float: 'right',
                  marginRight: '13px',
                }}
                icon="minus-circle"
                className="text-primary"
                size="lg"
                onClick={() => {
                  removeReporter(resource_id);
                }}
              />
            ),
          };
        })}
      />
    </>
  );
};

export default ReporterList;
