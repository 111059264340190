/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProjectsToSurvey,
  getProjects,
} from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddSurveyToProjectModal = ({
  availableProjects,
  currentProjects,
  surveyToEdit,
  handleClose,
  show,
  fullscreen,
  done,
}) => {
  const [checked, setChecked] = useState([]);
  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Projekt</p>,
      field: 'project',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Projektnummer</p>,
      field: 'projectNumber',
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.projects);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!projects && user) {
      dispatch(getProjects(user.digituals_client_id));
    }
  }, []);

  // useEffect(() => {
  //   if (projects) {
  //     createRowsForList();
  //   }
  // }, [projects, currentProjects]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // const createRowsForList = () => {
  //   const availableP = [];

  //   projects.forEach((p) => {
  //     const { is_active, project_id } = p;
  //     if (is_active === true) {
  //       let addToList = true;
  //       currentProjects.forEach((project) => {
  //         if (project_id === project) {
  //           addToList = false;
  //         }
  //       });

  //       if (addToList) {
  //         availableP.push(p);
  //       } else {
  //         return;
  //       }
  //     }
  //   });

  //   setAvailableProjects(availableP);
  // };

  const handleAddProjectsToSurvey = () => {
    const projToAdd = projects.filter(
      (x) =>
        checked.includes(x.project_id) || currentProjects.includes(x.project_id)
    );

    dispatch(
      addProjectsToSurvey(projToAdd, surveyToEdit, user.digituals_client_id)
    );
    setChecked([]);
    handleClose();
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleAddProjectsToSurvey}
        centered={true}
        header="Lägg till projekt"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_MAX_WIDTH }}>
          {availableProjects.length > 0 ? (
            <AddList
              listKey={'AddSurveyToProjectModal'}
              columns={columns}
              rows={availableProjects.map((proj) => {
                const { project_name, project_number, project_id } = proj;

                return {
                  project: project_name,
                  projectNumber: project_number,
                  add: (
                    <MDBCheckbox
                      key={project_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={project_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddSurveyToProjectModal;
