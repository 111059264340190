import React from 'react';
import Select from 'react-select';

export const PositionSelect = ({
  placeholder,
  options,
  defaultValue,
  setValue,
  value,
}) => {
  const customStyles = {
    container: (base, state) => ({
      ...base,
      width: '75px',
    }),
    control: (base, state) => ({
      ...base,
      borderColor: '#FFF',
      boxShadow: '',
    }),
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? 'red' : 'blue',
      '&:hover': {
        background: state.isSelected ? '' : '#f3f8f9',
      },
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   color: state.isSelected ? 'red' : 'blue',
    // }),
  };
  // border-color: #d62518;
  //   box-shadow: 0 0 0 0.25rem rgb(214 37 24 / 25%);

  const onChange = (option) => {
    setValue(option.value);
  };

  // const getValue = (value) => {
  //   return value;
  // };

  return (
    <Select
      defaultValue={defaultValue}
      isSearchable={false}
      styles={customStyles}
      value={{ value: value, label: value }}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#6ba0ac',
        },
      })}
    />

    // <Form.Select
    //   style={{
    //     marginLeft: '0px',
    //     border: 'none',
    //     fontSize: 13,
    //     padding: 4,
    //     paddingLeft: 5,
    //     width: '50px',

    //     background: '',
    //   }}
    // >
    //   <option value="1">1</option>
    //   <option value="2">2</option>
    //   <option value="3">3</option>
    // </Form.Select>
  );
};

export default PositionSelect;
