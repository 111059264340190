import { analysIcons } from '../../../constants';

export const analysIconsList = [
  {
    name: 'Medelrespons per \n Kund',
    icon: analysIcons.CHART_BAR,
    number: 1,
  },
  {
    name: 'Medelrespons per \n Kund/Vecka',
    icon: analysIcons.TABLE,
    number: 2,
  },
  {
    name: 'Medelrespons per \n Kund/Fråga/Månad',
    icon: analysIcons.TABLE,
    number: 3,
  },
  {
    name: 'Medelrespons per \n Kund/Fråga/Vecka',
    icon: analysIcons.TABLE,
    number: 4,
  },
  {
    name: 'Medelrespons per \n Kund/Projektvecka',
    icon: analysIcons.TABLE,
    number: 5,
  },
  {
    name: 'Medelrespons per \n  Kund/Fråga/Projektvecka',
    icon: analysIcons.TABLE,
    number: 6,
  },
  {
    name: 'Medelrespons per \n Projekt',
    icon: analysIcons.CHART_BAR,
    number: 7,
  },
  {
    name: 'Medelrespons per \n Kund/Projekt/Vecka',
    icon: analysIcons.TABLE,
    number: 8,
  },
  {
    name: 'Medelrespons per \n  Projekt/Fråga/Månad',
    icon: analysIcons.TABLE,
    number: 9,
  },
  {
    name: 'Medelrespons per \n Projekt/Fråga/Vecka',
    icon: analysIcons.TABLE,
    number: 10,
  },
  {
    name: 'Medelrespons per \n  Kund/Projekt/Projektvecka',
    icon: analysIcons.TABLE,
    number: 11,
  },
  {
    name: 'Medelrespons per \n Projekt/Fråga/Projektvecka',
    icon: analysIcons.TABLE,
    number: 12,
  },
  {
    name: 'Medelrespons per \n  Rapportör',
    icon: analysIcons.CHART_BAR,
    number: 13,
  },
  {
    name: 'Medelrespons per \n Projekt/Rapportör/Vecka',
    icon: analysIcons.TABLE,
    number: 14,
  },
  {
    name: 'Medelrespons per \n Projekt/Rapportör/Fråga/Månad',
    icon: analysIcons.TABLE,
    number: 15,
  },
  {
    name: 'Medelrespons per \n Projekt/Rapportör/Fråga/Vecka',
    icon: analysIcons.TABLE,
    number: 16,
  },
  {
    name: 'Medelrespons per \n  Projekt/Rapportör/Projektvecka',
    icon: analysIcons.TABLE,
    number: 17,
  },
  {
    name: 'Medelrespons per \n  Projekt/Rapportör/Fråga/Projektvecka',
    icon: analysIcons.TABLE,
    number: 18,
  },
  {
    name: 'Medelrespons per \n Fråga',
    icon: analysIcons.CHART_BAR,
    number: 19,
  },
  {
    name: 'Medelrespons per \n Fråga/Vecka',
    icon: analysIcons.TABLE,
    number: 20,
  },
  {
    name: 'Medelrespons per \n Fråga/Vecka',
    icon: analysIcons.CHART_LINE,
    number: 21,
  },
  {
    name: 'Medelrespons per \n Fråga/Månad',
    icon: analysIcons.TABLE,
    number: 22,
  },
  {
    name: 'Medelrespons per \n Fråga/Månad',
    icon: analysIcons.CHART_LINE,
    number: 23,
  },
  {
    name: 'Medelrespons per \n  Fråga/Projektvecka',
    icon: analysIcons.TABLE,
    number: 24,
  },
  {
    name: 'Medelrespons per \n  Fråga/Projektvecka',
    icon: analysIcons.CHART_LINE,
    number: 25,
  },
  {
    name: 'Antal responser per \n Responsvärde',
    icon: analysIcons.CHART_BAR,
    number: 26,
  },
  {
    name: 'Antal responser per \n Responsvärde/Konto/Rapportör',
    icon: analysIcons.TABLE,
    number: 27,
  },
  {
    name: 'Antal responser per \n Responsvärde/Projekt',
    icon: analysIcons.TABLE,
    number: 28,
  },
  {
    name: 'Andel av respektive \n  responsvärde \n per Vecka',
    icon: analysIcons.CHART_AREA,
    number: 29,
  },
  {
    name: 'Antal responser per \n Responsvärde/Vecka',
    icon: analysIcons.TABLE,
    number: 30,
  },
  {
    name: 'Medelrespons per \n Vecka',
    icon: analysIcons.CHART_LINE,
    number: 31,
  },
  {
    name: 'Andel av respektive \n  responsvärde per \n Projektvecka',
    icon: analysIcons.CHART_AREA,
    number: 32,
  },
  {
    name: 'Antal responser per \n Responsvärde/Projektvecka',
    icon: analysIcons.TABLE,
    number: 33,
  },
  {
    name: 'Medelrespons per \n Projektvecka',
    icon: analysIcons.CHART_LINE,
    number: 34,
  },
];
export const analysIconsListSorted = [
  {
    name: 'Medelrespons per \n Kund/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 1,
  },
  {
    name: 'Medelrespons per Kund/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 2,
  },
  {
    name: 'Medelrespons \n Kund/Projekt/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 3,
  },
  {
    name: 'Medelrespons Kund/Projekt/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 4,
  },
  {
    name: 'Medelrespons per \n Projekt',
    icon: analysIcons.CHART_BAR,
    number: 7,
  },
  {
    name: 'Medelrespons \n Projekt/Fråga/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 9,
  },
  {
    name: 'Medelrespons Projekt/Fråga/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 10,
  },

  {
    name: 'Medelrespons per \n Rapportör',
    icon: analysIcons.CHART_BAR,
    number: 11,
  },
  {
    name: 'Medelrespons per \n Rapportör/ÅrMånad',
    icon: analysIcons.TABLE,
    number: 12,
  },
  {
    name: 'Medelrespons per \n Projekt/Rapportör/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 13,
  },
  {
    name: 'Medelrespons per Projekt/Rapportör/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 14,
  },
  {
    name: 'Medelrespons per \n Fråga/Projekt/Rapportör',
    icon: analysIcons.TABLE,
    number: 18,
  },
  {
    name: 'Medelrespons per \n Fråga',
    icon: analysIcons.CHART_BAR,
    number: 19,
  },
  {
    name: 'Medelrespons per Fråga/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 20,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrMånad',
    icon: analysIcons.TABLE,
    number: 21,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 22,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrMånad',
    icon: analysIcons.CHART_LINE,
    number: 23,
  },

  {
    name: 'Antal Responsvärden över Kalenderveckor',
    icon: analysIcons.TABLE,
    number: 25,
  },
  {
    name: 'Andel av respektive Responsvärde per \n Vecka från Projektstart',
    icon: analysIcons.CHART_AREA,
    number: 26,
  },
  {
    name: 'Medelrespons över \n Kalenderveckor',
    icon: analysIcons.CHART_LINE,
    number: 27,
  },
  {
    name: 'Medelrespons per Vecka \n från Projektstart',
    icon: analysIcons.CHART_LINE,
    number: 28,
  },
  {
    name: 'Antal Responser per \n Responsvärde',
    icon: analysIcons.CHART_BAR,
    number: 29,
  },

  {
    name: 'Medelrespons per \n Kund/Projekt/Rapportör',
    icon: analysIcons.TABLE,
    number: 30,
  },
  {
    name: 'Antal Respons per \n Responsvärde/Konto/Rapportör',
    icon: analysIcons.TABLE,
    number: 31,
  },

  {
    name: 'Antal Respons per \n Responsvärde/Projekt',
    icon: analysIcons.TABLE,
    number: 32,
  },
  {
    name: 'Andel av respektive Responsvärde \n över Kalenderveckor',
    icon: analysIcons.CHART_AREA,
    number: 33,
  },
  {
    name: 'Antal Responsvärden per \n Vecka från Projektstart',
    icon: analysIcons.TABLE,
    number: 34,
  },
];
export const analysIconsListCopy = [
  {
    name: 'Antal Responser per \n Responsvärde',
    icon: analysIcons.CHART_BAR,
    number: 1,
  },

  {
    name: 'Medelrespons per \n Kund/Projekt/Rapportör',
    icon: analysIcons.TABLE,
    number: 3,
  },
  {
    name: 'Antal Respons per \n Responsvärde/Konto/Rapportör',
    icon: analysIcons.TABLE,
    number: 4,
  },
  {
    name: 'Antal Respons per \n Responsvärde/Projekt',
    icon: analysIcons.TABLE,
    number: 5,
  },
  {
    name: 'Andel av respektive Responsvärde \n över Kalenderveckor',
    icon: analysIcons.CHART_AREA,
    number: 6,
  },
  {
    name: 'Antal Responsvärden över Kalenderveckor',
    icon: analysIcons.TABLE,
    number: 7,
  },
  {
    name: 'Andel av respektive Responsvärde per \n Vecka från Projektstart',
    icon: analysIcons.CHART_AREA,
    number: 8,
  },
  {
    name: 'Antal Responsvärden per \n Vecka från Projektstart',
    icon: analysIcons.TABLE,
    number: 9,
  },
  {
    name: 'Medelrespons över \n Kalenderveckor',
    icon: analysIcons.CHART_LINE,
    number: 10,
  },
  {
    name: 'Medelrespons per Vecka \n från Projektstart',
    icon: analysIcons.CHART_LINE,
    number: 11,
  },
  {
    name: 'Medelrespons per \n Projekt',
    icon: analysIcons.CHART_BAR,
    number: 12,
  },
  {
    name: 'Medelrespons per \n Projekt/Fråga',
    icon: analysIcons.CHART_BAR,
    number: 13,
  },
  {
    name: 'Medelrespons per \n Fråga/Kund',
    icon: analysIcons.CHART_BAR,
    number: 14,
  },
  {
    name: 'Medelrespons per \n Fråga/Projekt',
    icon: analysIcons.TABLE,
    number: 15,
  },
  {
    name: 'Medelrespons per \n Kund/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 16,
  },
  {
    name: 'Medelrespons per Kund/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 17,
  },
  {
    name: 'Medelrespons \n Kund/Projekt/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 18,
  },
  {
    name: 'Medelrespons Kund/Projekt/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 19,
  },
  {
    name: 'Medelrespons \n Kund/Projekt/Vecka',
    icon: analysIcons.TABLE,
    number: 20,
  },
  {
    name: 'Medelrespons Kund/Projekt/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 21,
  },
  {
    name: 'Medelrespons \n Projekt/Fråga/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 22,
  },
  {
    name: 'Medelrespons Projekt/Fråga/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 23,
  },
  {
    name: 'Medelrespons per \n Fråga/Projekt/Rapportör',
    icon: analysIcons.CHART_BAR,
    number: 24,
  },
  {
    name: 'Medelrespons per \n Fråga/Projekt/Rapportör',
    icon: analysIcons.TABLE,
    number: 25,
  },
  {
    name: 'Medelrespons per \n Rapportör',
    icon: analysIcons.CHART_BAR,
    number: 26,
  },
  {
    name: 'Medelrespons per \n Rapportör/ÅrMånad',
    icon: analysIcons.TABLE,
    number: 27,
  },
  {
    name: 'Medelrespons per \n Rapportör/Projekt/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 28,
  },
  {
    name: 'Medelrespons per Rapportör/Projekt/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 29,
  },
  {
    name: 'Medelrespons per \n Fråga',
    icon: analysIcons.CHART_BAR,
    number: 30,
  },
  {
    name: 'Medelrespons per Fråga/Vecka \n från projektstart',
    icon: analysIcons.TABLE,
    number: 31,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrMånad',
    icon: analysIcons.TABLE,
    number: 32,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrVecka',
    icon: analysIcons.TABLE,
    number: 33,
  },
  {
    name: 'Medelrespons per \n Fråga/ÅrMånad',
    icon: analysIcons.CHART_LINE,
    number: 34,
  },
  {
    name: 'Medelrespons per \n Fråga/Projekt',
    icon: analysIcons.CHART_BAR,
    number: 35,
  },
  {
    name: 'Senaste Rapporteringsdatum',
    icon: analysIcons.TABLE,
    number: 2,
  },
];
