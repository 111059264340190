/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {
  getOperation,
  getSurveys,
  getResources,
  getCustomer,
  addProject,
  getProjects,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { icons, status } from '../../../../constants';
import moment from 'moment';
import AddFromModalButton from '../../../../components/add-from-modal-button';
import AddReporterToProjectModal from '../add-reporter-to-project-modal';
import ReporterList from '../ReporterList';
import AddTeammemberToProjectModal from '../add-teammember-to-project-modal';
import TeammemberList from '../TeammemberList';
import ModalIcon from '../../../../components/modal-icon';
import CollapseSection from '../../../../components/collapse-section';

const AddProjectForm = ({ handleClose, mobile }) => {
  const [showAddReporterList, setShowAddReporterList] = useState(false);
  const [showAddTeammemberList, setShowAddTeammemberList] = useState(false);

  const [enableValidate, setEnableValidate] = useState(false);

  const [showReporterList, setShowReporterList] = useState(false);
  const [showTeammemberList, setShowTeammemberList] = useState(false);

  const [reporters, setReporters] = useState('');
  const [teammembers, setTeammembers] = useState('');

  const [pLeader, setPLeader] = useState([]);

  const [activeSurveys, setActiveSurveys] = useState('');
  const [activeCustomers, setActiveCustomers] = useState('');

  const initialValues = {
    projectLeaders: '',
    resources: '',
    reporters: '',
    projectName: '',
    projectNumber: '',
    projectStart: moment().format('YYYY-MM-DD'),
    customer: '',
    status: status.ACTIVE,
    survey: '',
  };

  const schema = Yup.object().shape({
    projectName: Yup.string()
      .max(25, 'Max 25 tecken!')
      .required('Ange projektnamn!'),
    projectNumber: Yup.string().required('Ange projektnummer!'),
    customer: Yup.string().required('Välj kund!'),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];
  const dispatch = useDispatch();

  const { operations } = useSelector((state) => state.operation);
  const { surveys } = useSelector((state) => state.survey);
  const { resources } = useSelector((state) => state.resources);
  const { customers } = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
    if (!surveys && user) {
      dispatch(getSurveys(user.digituals_client_id));
    }
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
    if (!customers && user) {
      dispatch(getCustomer(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (surveys) {
      setActiveSurveys(surveys.filter((s) => s.is_active === true));
    }
  }, [surveys]);
  useEffect(() => {
    if (customers) {
      setActiveCustomers(customers.filter((c) => c.is_active === true));
    }
  }, [customers]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = async (values, resetForm) => {
    const projectToAdd = {
      ...values,
      status: values.status === status.ACTIVE ? true : false,
      reporters: reporters,
      teammembers: teammembers
        ? teammembers.map((value) => {
            let teamM = {
              ...value,
              projectLeader: false,
            };

            pLeader.forEach((projectLeader) => {
              if (projectLeader.resource_id === value.resource_id) {
                teamM.projectLeader = true;
              }
            });

            return teamM;
          })
        : '',
    };

    await dispatch(addProject(projectToAdd, user.digituals_client_id)).then(
      () =>
        setTimeout(function () {
          dispatch(getProjects(user.digituals_client_id));
        }, 1000)
    );

    setShowReporterList(false);
    setShowTeammemberList(false);
    handleResetForm(resetForm);
    setTeammembers('');
    setPLeader([]);
    setReporters('');
    handleClose();
  };

  const handleOpenAddReporterList = () => {
    setShowAddReporterList(true);
  };
  const handleOpenAddTeammemberList = () => {
    setShowAddTeammemberList(true);
  };

  const handleConnectReporters = (selectedReporters) => {
    setReporters(selectedReporters);
  };

  const handleConnectTeammembers = (selectedTeammembers) => {
    setTeammembers(selectedTeammembers);
  };

  const handleConnectProjectLeaders = (selectedProjectLeaders) => {
    setPLeader(selectedProjectLeaders);
  };

  const handleRemoveReporter = (resourceID) => {
    const newReporterList = reporters.filter(
      (r) => r.resource_id !== resourceID
    );

    setReporters(newReporterList.length > 0 ? newReporterList : '');
  };

  const handleRemoveTeammember = (teammemberID) => {
    const newTeammemberList = teammembers.filter(
      (r) => r.resource_id !== teammemberID
    );

    setTeammembers(newTeammemberList.length > 0 ? newTeammemberList : '');
  };

  const toggleShowReporterList = () => setShowReporterList(!showReporterList);

  const toggleShowTeammemberList = () =>
    setShowTeammemberList(!showTeammemberList);

  const handleGoBack = (resetForm) => {
    setShowReporterList(false);
    setShowTeammemberList(false);
    setTeammembers('');
    setPLeader([]);
    setReporters('');
    resetForm();
    handleClose();
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        initialValues={initialValues}
        validateOnChange={enableValidate}
        validateOnBlur={enableValidate}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          resetForm,
        }) => {
          return (
            <Form
              noValidate
              onSubmit={(e) => {
                setEnableValidate(true);
                e.preventDefault();
                return handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                return handleGoBack(resetForm);
              }}
              id="AddProjectForm"
            >
              <ModalIcon icon={icons.PROJECT} style={{ marginTop: '0%' }} />

              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Projektnamn
                  </Form.Label>
                  <Form.Control
                    required
                    placeholder="Projektnamn..."
                    type="text"
                    name="projectName"
                    value={values.projectName}
                    onChange={handleChange}
                    isValid={touched.projectName && !errors.projectName}
                    isInvalid={!!errors.projectName}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.projectName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">
                    Projektnummer
                  </Form.Label>
                  <Form.Control
                    required
                    placeholder="Projektnummer..."
                    type="number"
                    name="projectNumber"
                    value={values.projectNumber}
                    onChange={handleChange}
                    isValid={touched.projectNumber && !errors.projectNumber}
                    isInvalid={!!errors.projectNumber}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.projectNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                {activeCustomers && (
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">
                      Välj kund
                    </Form.Label>

                    <Field
                      component={CustomSelect}
                      placeholder="Välj kund..."
                      name="customer"
                      options={activeCustomers.map((c) => {
                        return { value: c.customer_id, label: c.customer_name };
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.customer && !errors.customer}
                      isInvalid={!!errors.customer}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.customer}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Startdatum</Form.Label>
                  <Form.Control
                    type="date"
                    name="projectStart"
                    placeholder="Välj startdatum"
                    value={values.projectStart}
                    onChange={handleChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.projectStart}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col}>
                  <Form.Label className="text-secondary">Status</Form.Label>

                  {statusOptions && (
                    <Field
                      component={CustomSelect}
                      placeholder="Välj status..."
                      name="status"
                      options={statusOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.status && !errors.status}
                      isInvalid={!!errors.status}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                {activeSurveys && (
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">
                      Välj formulär
                    </Form.Label>

                    <Field
                      component={CustomSelect}
                      placeholder="Välj formulär..."
                      name="survey"
                      options={activeSurveys.map((s) => {
                        return {
                          value: s.questionnaire_id,
                          label: s.questionnaire_name,
                        };
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isValid={touched.survey && !errors.survey}
                      isInvalid={!!errors.survey}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.survey}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Row>

              <CollapseSection
                isOpen={showReporterList}
                label="Välj rapportörer"
                onClick={toggleShowReporterList}
              >
                {reporters ? (
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <ReporterList
                        selectedReporters={reporters}
                        removeReporter={handleRemoveReporter}
                        add={handleOpenAddReporterList}
                      />
                    </Form.Group>
                  </Row>
                ) : (
                  <Row className=" mb-4">
                    <Col className="d-flex align-items-center  justify-content-start ">
                      <AddFromModalButton
                        disabled={values.survey ? false : true}
                        label={'Välj rapportörer'}
                        onClick={handleOpenAddReporterList}
                      />
                    </Col>
                  </Row>
                )}
              </CollapseSection>

              <CollapseSection
                isOpen={showTeammemberList}
                label="Välj teammedlemmar"
                onClick={toggleShowTeammemberList}
              >
                {teammembers ? (
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <TeammemberList
                        selectedTeammembers={teammembers}
                        connectProjectLeaders={handleConnectProjectLeaders}
                        removeTeammember={handleRemoveTeammember}
                        add={handleOpenAddTeammemberList}
                      />
                    </Form.Group>
                  </Row>
                ) : (
                  <Row className=" mb-4">
                    <Col className="d-flex align-items-center  justify-content-start ">
                      <AddFromModalButton
                        label={'Välj teammedlemmar'}
                        onClick={handleOpenAddTeammemberList}
                      />
                    </Col>
                  </Row>
                )}
              </CollapseSection>
            </Form>
          );
        }}
      </Formik>

      <AddReporterToProjectModal
        show={showAddReporterList}
        handleClose={() => setShowAddReporterList(false)}
        fullscreen={mobile}
        connectReporters={handleConnectReporters}
        reporters={reporters}
      />
      <AddTeammemberToProjectModal
        show={showAddTeammemberList}
        handleClose={() => setShowAddTeammemberList(false)}
        fullscreen={mobile}
        connectTeammember={handleConnectTeammembers}
        teammembers={teammembers}
      />
    </>
  );
};

export default AddProjectForm;
