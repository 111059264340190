const activeOptions = [
  {
    label: 'Ja',
    isDisabled: false,
    is_active: true,
    value: 1,
    id: 1,
  },
  {
    label: 'Nej',
    isDisabled: false,
    is_active: true,
    value: 2,
    id: 2,
  },
];
const activeOptionsProjects = [
  {
    label: 'Aktiva Projekt',
    isDisabled: false,
    is_active: true,
    value: 1,
    id: 1,
  },
  {
    label: 'Inaktiva Projekt',
    isDisabled: false,
    is_active: true,
    value: 2,
    id: 2,
  },
];
const activeOptionsReporters = [
  {
    label: 'Aktiva Rapportörer',
    isDisabled: false,
    is_active: true,
    value: 1,
    id: 1,
  },
  {
    label: 'Inaktiva Rapportörer',
    isDisabled: false,
    is_active: true,
    value: 2,
    id: 2,
  },
];
const activeOptionsQuestions = [
  {
    label: 'Aktiva Frågor',
    isDisabled: false,
    is_active: true,
    value: 1,
    id: 1,
  },
  {
    label: 'Inaktiva Frågor',
    isDisabled: false,
    is_active: true,
    value: 2,
    id: 2,
  },
];
const activeOptionsCustomers = [
  {
    label: 'Aktiva Kunder',
    isDisabled: false,
    is_active: true,
    value: 1,
    id: 1,
  },
  {
    label: 'Inaktiva Kunder',
    isDisabled: false,
    is_active: true,
    value: 2,
    id: 2,
  },
];

export {
  activeOptionsProjects,
  activeOptionsReporters,
  activeOptionsQuestions,
  activeOptionsCustomers,
  activeOptions,
};
