/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import { getOperation, updateProfile } from '../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { icons } from '../../constants';
import ModalIcon from '../modal-icon';

const UserModalForm = ({ handleClose, currentUser }) => {
  // const [activeField, setActiveField] = useState('');

  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const initialValues = {
    email: currentUser.profile.profile_email,
    name: currentUser.resource_firstname,
    lastname: currentUser.resource_lastname,
    password: '',
  };

  const schema = Yup.object().shape({
    password: Yup.string().min(6, 'Minst 6 tecken!').notRequired(),
  });

  const dispatch = useDispatch();

  const { operations } = useSelector((state) => state.operation);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const userToUpdate = {
      ...values,
      ...currentUser,
    };

    dispatch(
      updateProfile(userToUpdate, isEmailChanged, user.digituals_client_id)
    );
    handleResetForm(resetForm);

    handleClose();
  };

  // const handleFocus = (e) => {
  //   setActiveField(e.target.name);
  // };

  // const toggleCollapse = () => {
  //   setCreateAccount(!createAccount);
  // };

  return (
    <>
      {initialValues !== '' && (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          // onSubmit={(values, { resetForm }) =>  handleSubmit(values, resetForm)}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} id="UserModalForm">
                <ModalIcon icon={icons.RESOURCE} />

                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">Förnamn</Form.Label>
                    <Form.Control
                      placeholder="Förnamn..."
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">
                      Efternamn
                    </Form.Label>
                    <Form.Control
                      placeholder="Efternamn..."
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      isValid={touched.lastname && !errors.lastname}
                      isInvalid={!!errors.lastname}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">E-post</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E-post..."
                      name="email"
                      value={values.email}
                      onChange={(value) => {
                        setIsEmailChanged(true);
                        handleChange(value);
                      }}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label className="text-secondary">Lösenord</Form.Label>
                    <Form.Control
                      placeholder="Lösenord..."
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                      autoComplete="new-password"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default UserModalForm;
