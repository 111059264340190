/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import img from '../../image/eqpuls_logo.png';

import { MDBIcon, MDBBtn } from 'mdbreact';

import { useLocation, Link } from 'react-router-dom';

import {
  ADMIN_HOME,
  PROJECTS,
  USERS,
  RESPONSE,
  FONT_SIZE_NAVBAR,
} from './constants';
import UserModal from '../user-modal';
import LogoutConfirmation from '../confirm-modal/LogoutConfirmation';

const DeskopSizeNav = () => {
  const location = useLocation();
  const [currentPathLocation, setCurrentPathLocation] = useState(
    location.pathname
  );
  const [showUserModal, setShowUserModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    setCurrentPathLocation(location.pathname);
  }, [location]);

  // const handleReloadApp = () => {
  //   window.location.reload();
  // };

  return (
    <>
      <LogoutConfirmation
        showModal={showLogoutModal}
        hideModal={() => setShowLogoutModal(false)}
      />
      <UserModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        logout={() => setShowLogoutModal(true)}
      />
      <Navbar
        fixed="top"
        className="w-100"
        bg="primary"
        variant="primary"
        style={{
          maxHeight: '75px',
        }}
      >
        <Container
          style={{
            marginLeft: 0,
            marginTop: 10,
          }}
        >
          <Navbar.Brand href="/admin-home">
            <img
              alt=""
              src={img}
              width="120"
              height="auto"
              className={
                currentPathLocation === ADMIN_HOME
                  ? 'd-inline-block align-top  '
                  : 'd-inline-block align-top'
              }
            />
          </Navbar.Brand>
        </Container>

        <Container className="d-flex align-items-center justify-content-end d-grid gap-5">
          <NavbarLink
            to={ADMIN_HOME}
            activeIcon="bi bi-house-fill"
            inactiveIcon="bi bi-house"
            text="Hem"
            currentPathLocation={currentPathLocation}
          />
          <NavbarLink
            to={RESPONSE}
            activeIcon="bi bi-bar-chart-fill"
            inactiveIcon="bi bi-bar-chart"
            text="Frågeformulär"
            currentPathLocation={currentPathLocation}
          />
          <NavbarLink
            to={PROJECTS}
            activeIcon="bi bi-calendar-week-fill"
            inactiveIcon="bi bi-calendar-week"
            text="Projekt"
            currentPathLocation={currentPathLocation}
          />
          <NavbarLink
            to={USERS}
            activeIcon="bi bi-people-fill"
            inactiveIcon="bi bi-people"
            text="Resurser"
            currentPathLocation={currentPathLocation}
          />

          <Link
            to={USERS}
            className={
              currentPathLocation === USERS ? '  text-white' : 'text-light'
            }
          >
            <MDBBtn
              floating
              className="mt-0 bg-white "
              style={{ marginRight: 19 }}
              onClick={(e) => {
                e.preventDefault();
                setShowUserModal(true);
              }}
            >
              <MDBIcon
                icon="user"
                className="text-primary"
                style={{ cursor: 'pointer' }}
                size="2x"
              />
            </MDBBtn>
          </Link>
        </Container>
      </Navbar>
    </>
  );
};

export default DeskopSizeNav;

export function NavbarLink({
  to,
  currentPathLocation,
  activeIcon,
  inactiveIcon,
  text,
}) {
  const isActive = to === currentPathLocation;

  return (
    <Link to={to} className={isActive ? '  text-white' : 'text-darkerLight'}>
      <div className="d-flex flex-column align-items-center justify-content-center mt-2">
        <MDBIcon
          icon={isActive ? activeIcon : inactiveIcon}
          size="lg"
          className="mt-3 mr-1"
        />
        <p className="mt-1" style={{ fontSize: FONT_SIZE_NAVBAR }}>
          {text}
        </p>
      </div>
    </Link>
  );
}
