/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ModalList from '../../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';
import AddResourceToOperationModal from '../add-resource-to-operation-modal';

import { useDispatch, useSelector } from 'react-redux';
import {
  getOperation,
  removeResourceFromOperation,
} from '../../../../redux/store/actions';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';

const ConnectedResourceList = ({ operationToEdit, mobile }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentResources, setCurrentResources] = useState('');
  const [rows, setRows] = useState('');

  const [resourcetoRemove, setResourcetoRemove] = useState('');

  const confirmDeleteMessage =
    operationToEdit && resourcetoRemove
      ? `Är du säker att du vill ta bort kopplingen mellan resurs  ${resourcetoRemove.resource_firstname} ${resourcetoRemove.resource_lastname} och avdelning ${operationToEdit.operation_name} ?`
      : '';

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resource',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {}, [operationToEdit]);

  useEffect(() => {
    if (operationToEdit) {
      createRowsForList(operationToEdit);
    }
  }, [operationToEdit]);

  const createRowsForList = (o) => {
    const { resource } = o;
    const resourceIDs = [];

    const activeRecources = resource.filter(
      (value) => value.is_active === true
    );

    const rows = activeRecources.map((r) => {
      const {
        resource_firstname,
        resource_lastname,
        resource_id,
        resource_email,
      } = r;
      resourceIDs.push(resource_id);

      return {
        resource: `${resource_firstname} ${resource_lastname}`,
        email: resource_email,
        remove: (
          <MDBIcon
            key={resource_id}
            style={{ cursor: 'pointer', float: 'right', marginRight: '13px' }}
            icon="minus-circle"
            className="text-primary"
            size="lg"
            onClick={() => {
              setShowDeleteModal(true);
              setResourcetoRemove(r);
            }}
          />
        ),
      };
    });

    setRows(rows);
    setCurrentResources(resourceIDs);
  };

  const handleRemoveResourceFromOperation = (operation, resource) => {
    dispatch(
      removeResourceFromOperation(operation, resource, user.digituals_client_id)
    ).then(() =>
      setTimeout(function () {
        dispatch(getOperation(user.digituals_client_id)).then(({ payload }) => {
          const findOperation = payload.filter(
            (o) => o.operation_id === operationToEdit.operation_id
          );

          if (findOperation[0]) {
            createRowsForList(findOperation[0]);
          }
        });
      }, 500)
    );

    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowModal(false);

    setTimeout(function () {
      dispatch(getOperation(user.digituals_client_id)).then(({ payload }) => {
        const findOperation = payload.filter(
          (o) => o.operation_id === operationToEdit.operation_id
        );

        if (findOperation[0]) {
          createRowsForList(findOperation[0]);
        }
      });
    }, 500);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleDone = () => {};

  return (
    <>
      <DeleteConfirmation
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() =>
          handleRemoveResourceFromOperation(operationToEdit, resourcetoRemove)
        }
        message={confirmDeleteMessage}
      />
      {currentResources && (
        <AddResourceToOperationModal
          currentResources={currentResources}
          operationToEdit={operationToEdit}
          show={showModal}
          handleClose={handleClose}
          done={handleDone}
          fullscreen={mobile ? true : false}
        />
      )}

      <ModalList
        listKey={'ConnectedResourceList'}
        columns={columns}
        rows={rows}
        add={handleOpen}
      />
    </>
  );
};

export default ConnectedResourceList;
