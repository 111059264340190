import React, { useEffect, useState } from 'react';

import ProjectStatusTable from './ProjectStatusTable';

import NavigationBar from '../../../components/navbar';
import TopTabNavsDesktop from '../../../components/top-tabs-navs-desktop';
import TopTabNavs from '../../../components/top-tab-navs';
import { supabase } from '../../../utils/supabase';

const AdminHome = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  const tabs = [
    {
      name: 'Status',
      content: (
        <ProjectStatusTable
          mobile={currentWindowSize < pixelBreakPoint ? true : false}
        />
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <NavigationBar />

      {currentWindowSize < pixelBreakPoint ? (
        <TopTabNavs tabs={tabs} />
      ) : (
        <TopTabNavsDesktop tabs={tabs} />
      )}

      {/* <div className="d-flex align-items-center  justify-content-center flex-column w-100">
        <br />
        <br />
        <br />
       
        <br />
        <br />

        <br />
       
        <br />
      
        <br />
        <br />
        <br />
        <br />
      </div> */}
    </>

    //    {/* <MinimalistChart /> */}
    // {/* <UserStatusTable /> */}
  );
};

export default AdminHome;
