import React, { useEffect, useState } from 'react';
import DeskopSizeNav from './DeskopSizeNav';
import MobileSizeNav from './MobileSizeNav';

const NavigationBar = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return currentWindowSize > pixelBreakPoint ? (
    <DeskopSizeNav />
  ) : (
    <MobileSizeNav />
  );
};

export default NavigationBar;
