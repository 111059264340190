export const resourceWithAccountColumns = [
  {
    label: 'namn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];

export const manageProjectColumns = [
  {
    label: 'projektnamn',
    field: 'projectName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'projektnummer',
    field: 'projectNumber',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'kund',
    field: 'customer',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'startdatum',
    field: 'projectStart',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'status',
    field: 'active',
    // width: 270,
  },
  {
    label: 'projektledare',
    field: 'projectLeader',
    // width: 270,
  },
  {
    label: 'team medlemmar',
    field: 'resources',
    // width: 270,
  },

  {
    label: 'redigera',
    field: 'edit',
    // width: 100,
  },
  {
    label: 'ta bort',
    field: 'delete',
    sort: 'disabled',
    // width: 150,
  },
];
