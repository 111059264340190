/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import img from '../../image/eqpuls_logo.png';

import { MDBIcon, MDBBtn } from 'mdbreact';

import { useLocation, Link } from 'react-router-dom';

import {
  OVERVIEW,
  ANALYS,
  USERS,
  EXPORT,
  STATUS,
  FONT_SIZE_NAVBAR,
} from './constants';
import UserModal from '../user-modal';
import LogoutConfirmation from '../confirm-modal/LogoutConfirmation';
import { NavbarLink } from './DeskopSizeNav';

const AnalysNavbar = () => {
  const location = useLocation();
  const [currentPathLocation, setCurrentPathLocation] = useState(
    location.pathname
  );
  const [showUserModal, setShowUserModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    setCurrentPathLocation(location.pathname);
  }, [location]);

  return (
    <>
      <LogoutConfirmation
        showModal={showLogoutModal}
        hideModal={() => setShowLogoutModal(false)}
      />
      <UserModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        logout={() => setShowLogoutModal(true)}
      />
      <Navbar
        fixed="top"
        className="w-100"
        bg="primary"
        variant="primary"
        style={{
          maxHeight: '75px',
        }}
      >
        <Container
          style={{
            marginLeft: 0,
            marginTop: 10,
          }}
        >
          <Navbar.Brand style={{ cursor: 'pointer' }}>
            <Link to={OVERVIEW}>
              <img
                alt=""
                src={img}
                width="120"
                height="auto"
                className="d-inline-block align-top"
              />
            </Link>
          </Navbar.Brand>
        </Container>

        <Container
          className="d-flex align-items-center justify-content-end d-grid gap-5"
          // style={{ marginLeft: 700 }}
        >
          <NavbarLink
            to={OVERVIEW}
            activeIcon="tachometer-alt"
            inactiveIcon="tachometer-alt"
            text="Översikt"
            currentPathLocation={currentPathLocation}
          />
          <NavbarLink
            to={STATUS}
            activeIcon="table"
            inactiveIcon="table"
            text="Rapporter"
            currentPathLocation={currentPathLocation}
          />
          <NavbarLink
            to={ANALYS}
            activeIcon="chart-line"
            inactiveIcon="chart-line"
            text="Analys"
            currentPathLocation={currentPathLocation}
          />

          <Link to={USERS} className="" style={{ marginRight: 19 }}>
            <MDBBtn
              floating
              className="mt-0 bg-white "
              //  style={{ marginRight: '5%', marginLeft: '100px' }}
              onClick={(e) => {
                e.preventDefault();
                setShowUserModal(true);
              }}
            >
              <MDBIcon
                icon="user"
                className="text-primary"
                style={{ cursor: 'pointer' }}
                size="2x"
              />
            </MDBBtn>
          </Link>
        </Container>
      </Navbar>
    </>
  );
};

export default AnalysNavbar;
