export const userStatusColumns = [
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'namn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'e-post',
    field: 'email',
    // width: 270,
  },

  {
    label: 'senast aktiv',
    field: 'lastActive',
    // width: 200,
  },
  {
    label: 'senast respons',
    field: 'date',
    // width: 200,
  },
  {
    label: 'på projekt',
    field: 'project',
    // width: 200,
  },
  {
    label: '',
    field: 'last',
    sort: 'disabled',
  },
];

export const projectStatusColumns = [
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'Projektnamn',
    field: 'projectName',
  },
  {
    label: 'Projektnummer',
    field: 'projectNumber',
  },
  {
    label: 'Rapportör',
    field: 'reporter',
  },
  {
    label: 'Dagar sen respons',
    field: 'daysFromLastResponse',
  },
  {
    label: 'Senast respons',
    field: 'time',
  },

  {
    label: 'Antal responser',
    field: 'numberOfResponses',
  },
];
export const projectStatusColumnsMobile = [
  {
    label: '',
    field: '',
  },
  {
    label: 'Projektnamn',
    field: 'projectName',
  },
  {
    label: 'Projektnummer',
    field: 'projectNumber',
  },
  {
    label: 'Rapportör',
    field: 'reporter',
  },
  {
    label: 'Dagar sen respons',
    field: 'daysFromLastResponse',
  },
];
