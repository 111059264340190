/* eslint-disable import/no-anonymous-default-export */
import {
  SET_ANALYS_DATA,
  SET_ANALYS_DATA_DEFAULT,
  SET_ANALYS_DATA_TIME,
  SET_ANALYS_DATA_PROJECT_CUSTOMER,
  SET_ANALYS_DATA_REPORTER,
  SET_ANALYS_DATA_QUESTION,
  SET_OVERVIEW_DATA_SELECTED_QUESTIONS,
  SET_OVERVIEW_DATA_ACTIVE_QUESTIONS,
  SET_OVERVIEW_DATA_SELECTED_CUSTOMERS,
  SET_OVERVIEW_DATA_ACTIVE_CUSTOMERS,
  SET_OVERVIEW_DATA_SELECTED_DATE,
  SET_SELECTED_DATA_FROM_FILTER,
} from '../types';

const INITIAL_STATE = {
  analysData: null,
  analysDataDefault: null,
  analysDataTime: null,
  analysDataProjectCustomer: null,
  analysDataReporter: null,
  analysDataQuestion: null,
  overviewDataSelectedQuestions: null,
  overviewDataSelectedCustomers: null,
  overviewDataActiveQuestions: '',
  overviewDataActiveCustomers: '',
  overviewDataSelectedDate: null,
  selectedDataFromFilter: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ANALYS_DATA:
      return { ...state, analysData: action.payload };
    case SET_ANALYS_DATA_DEFAULT:
      return { ...state, analysDataDefault: action.payload };
    case SET_ANALYS_DATA_TIME:
      return { ...state, analysDataTime: action.payload };
    case SET_ANALYS_DATA_PROJECT_CUSTOMER:
      return { ...state, analysDataProjectCustomer: action.payload };
    case SET_ANALYS_DATA_REPORTER:
      return { ...state, analysDataReporter: action.payload };
    case SET_ANALYS_DATA_QUESTION:
      return { ...state, analysDataQuestion: action.payload };
    case SET_OVERVIEW_DATA_SELECTED_QUESTIONS:
      return { ...state, overviewDataSelectedQuestions: action.payload };
    case SET_OVERVIEW_DATA_ACTIVE_QUESTIONS:
      return { ...state, overviewDataActiveQuestions: action.payload };
    case SET_OVERVIEW_DATA_SELECTED_CUSTOMERS:
      return { ...state, overviewDataSelectedCustomers: action.payload };
    case SET_OVERVIEW_DATA_ACTIVE_CUSTOMERS:
      return { ...state, overviewDataActiveCustomers: action.payload };
    case SET_OVERVIEW_DATA_SELECTED_DATE:
      return { ...state, overviewDataSelectedDate: action.payload };
    case SET_SELECTED_DATA_FROM_FILTER:
      return { ...state, selectedDataFromFilter: action.payload };
    default:
      return state;
  }
}
