import React, { useState, useEffect } from 'react';

import NavigationBar from '../../../components/navbar';

import AdminResponseMobile from './respons-mobile';

import TopTabNavsDesktop from '../../../components/top-tabs-navs-desktop';
import AdminQuestions from '../questions';
import AdminSurveyManagement from '../survey-management';

const AdminResponse = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  const tabs = [
    {
      name: 'Frågor',
      content: <AdminQuestions />,
    },
    {
      name: 'Frågeformulär',
      content: <AdminSurveyManagement />,
    },
    // {
    //   name: 'svar',
    //   content: <ResponseComponent />,
    // },
  ];

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <NavigationBar />

      {currentWindowSize < pixelBreakPoint ? (
        <AdminResponseMobile />
      ) : (
        <TopTabNavsDesktop tabs={tabs} />
      )}
    </>
  );
};

export default AdminResponse;
