/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  addQuestionToSurveys,
  getSurveys,
} from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddQuestionToSurveysModal = ({
  availableSurveys,
  currentSurveys,
  questionToEdit,
  handleClose,
  show,
  fullscreen,
}) => {
  const [checked, setChecked] = useState([]);

  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Fråga</p>,
      field: 'question',
    },

    {
      label: '',

      field: 'add',
      sort: 'disabled',
    },
  ];

  const dispatch = useDispatch();

  const { surveys } = useSelector((state) => state.survey);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!surveys && user) {
      dispatch(getSurveys(user.digituals_client_id));
    }
  }, []);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleAddQuestionToSurveys = () => {
    const surveysToAdd = surveys.filter(
      (x) =>
        checked.includes(x.questionnaire_id) ||
        currentSurveys.includes(x.questionnaire_id)
    );

    dispatch(
      addQuestionToSurveys(
        questionToEdit,
        surveysToAdd,
        user.digituals_client_id
      )
    );
    setChecked([]);
    handleClose();
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleAddQuestionToSurveys}
        centered={true}
        header="Välj formulär"
      >
        <MDBContainer
          style={{ maxWidth: pixels.MODAL_LIST_WIDTH, minWidth: '300px' }}
          className="d-flex align-items-center justify-content-center"
        >
          {availableSurveys.length > 0 ? (
            <AddList
              columns={columns}
              rows={availableSurveys.map((q) => {
                const { questionnaire_name, questionnaire_id } = q;

                return {
                  question: questionnaire_name,

                  add: (
                    <MDBCheckbox
                      key={questionnaire_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={questionnaire_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : (
            'Inga formulär...'
          )}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddQuestionToSurveysModal;
