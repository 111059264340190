import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import AddModal from '../../../../components/add-modal';
import AddCustomerForm from './AddCustomerForm';
import { pixels } from '../../../../constants';

const AddCustomerModal = ({ handleClose, show, mobile }) => {
  return (
    <AddModal
      show={show}
      handleClose={handleClose}
      submitForm={'AddCustomerForm'}
      fullscreen={mobile ? true : false}
      header="Skapa kund"
    >
      <MDBContainer
        className="mb-5 bg-transparent"
        style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
      >
        <AddCustomerForm handleClose={handleClose} />
      </MDBContainer>
    </AddModal>
  );
};

export default AddCustomerModal;
