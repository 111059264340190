import React from 'react';
import Select from 'react-select';
import { colors } from '../../constants';

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  isValid,
  isInvalid,
  isDisabled,
  isClearable,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: isInvalid
        ? colors.DANGER
        : isValid
        ? colors.SUCCESS
        : state.isFocused
        ? colors.PRIMARY
        : '#ddd',
      boxShadow:
        state.isFocused && isInvalid
          ? '0 0 0 0.25rem rgb(214 37 24 / 25%)'
          : state.isFocused && isValid
          ? '0 0 0 0.25rem rgb(62 141 99 / 25%)'
          : state.isFocused
          ? '0 0 0 0.25rem rgb(107 160 172 / 25%)'
          : '',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? colors.WHITE : colors.TEXT,
      '&:hover': {
        background: state.isSelected ? '' : colors.PRIMARY,
        color: state.isSelected ? '' : colors.WHITE,
      },
      // '&:hover': {
      //   background: state.isSelected ? '' : '#f3f8f9',
      // },
    }),
  };

  // border-color: #d62518;
  //   box-shadow: 0 0 0 0.25rem rgb(214 37 24 / 25%);

  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? option.map((item) => item.value)
        : option === null
        ? ''
        : option.value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  return (
    <Select
      isClearable={isClearable}
      isDisabled={isDisabled}
      styles={customStyles}
      className={className}
      name={field.name}
      value={getValue() || ''}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: colors.PRIMARY,
        },
      })}
    />
  );
};

export default CustomSelect;
