/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManagedProjects,
  deleteManagedProject,
} from '../../../redux/store/actions';

import EditManageProjectModal from './EditManageProjectModal';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';
import { manageProjectColumns } from './dataColumns';
import DeleteConfirmation from '../../../components/confirm-modal/DeleteConfirmation';

const ManagedProjectTable = ({ handleOpenProjectManagementModal }) => {
  const [showEditManagedProjectModal, setShowEditManagedProjectModal] =
    useState(false);
  const [managedProjectToEdit, setManagedProjectToEdit] = useState({
    project: {
      active: true,
      projectNumber: '',
      projectName: '',
      projectID: '',
      projectStart: '',
      customer: '',
    },
    projectLeader: '',
    canRunSurvey: [],
    resources: [],
    selectedProjectIndex: 0,
  });

  const [rowData, setRowData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [managedProjectToDelete, setManagedProjectToDelete] = useState('');
  // const [defaultSelectorValue, setDefaultSelectorValue] = useState(0);
  const confirmDeleteTitle = 'Ta bort hanterat projekt';
  const confirmDeleteMessage = managedProjectToDelete
    ? `Är du säker att du vill ta bort hanterat projekt:  ${managedProjectToDelete.project.projectName}  ?`
    : '';

  const dispatch = useDispatch();
  const { managedProjects } = useSelector((state) => state.managedProjects);

  useEffect(() => {
    if (!managedProjects) {
      dispatch(getManagedProjects());
    }
  }, []);

  useEffect(() => {
    if (managedProjects) {
      createRowsForDataset();
    }
  }, [managedProjects]);

  const handleDeleteManagedProject = (managedProjectID, projectName) => {
    dispatch(deleteManagedProject(managedProjectID, projectName));
    setShowDeleteModal(false);
  };

  const handleCloseEditModal = () => {
    setManagedProjectToEdit({
      project: {
        managedProjectID: '',
        projectNumber: '',
        projectName: '',
      },
      projectLeader: '',
      canRunSurvey: [],
      resources: [],
    });
    setShowEditManagedProjectModal(false);
  };

  const createRowsForDataset = () => {
    let rows = [];

    managedProjects.forEach((managedProject, index) => {
      const row = {
        projectName: managedProject.project.projectName,
        projectNumber: managedProject.project.projectNumber,
        customer: managedProject.project.customer,
        projectStart: managedProject.project.projectStart,
        active: managedProject.project.active ? (
          <MDBIcon icon="check-circle" className="green-text" size="lg" />
        ) : (
          <MDBIcon size="lg" icon="moon" className="yellow-text" />
        ),

        projectLeader: `${managedProject.projectLeader.value.name} ${managedProject.projectLeader.value.lastname}`,
        resources: managedProject.resources.map((resource) => {
          const name = resource.value.name;
          const lastname = resource.value.lastname;
          return `${name} ${lastname} ${' '} ${', '}`;
        }),
        edit: (
          <MDBIcon
            icon="edit"
            className="blue-text"
            size="lg"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setManagedProjectToEdit({
                ...managedProject,
                selectedProjectIndex: index,
              });
              setShowEditManagedProjectModal(true);
            }}
          />
        ),
        delete: (
          <MDBIcon
            icon="trash-alt"
            className="grey-text"
            size="lg"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setManagedProjectToDelete(managedProject);
              setShowDeleteModal(true);
            }}
          />
        ),
      };

      rows.push(row);
    });

    rows.push({
      projectName: (
        <MDBIcon
          style={{ cursor: 'pointer' }}
          icon="plus-circle"
          className="align-self-start blue-text"
          onClick={handleOpenProjectManagementModal}
          size="2x"
        />
      ),
    });

    setRowData(rows);
  };

  if (managedProjects) {
    managedProjects.sort(function (a, b) {
      var nameA = a.project.projectName.toUpperCase();
      var nameB = b.project.projectName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <div className="mb-5">
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() =>
          handleDeleteManagedProject(
            managedProjectToDelete.managedProjectID,
            managedProjectToDelete.project.projectName
          )
        }
        message={confirmDeleteMessage}
      />
      {managedProjects ? (
        <MDBDataTableV5
          barReverse
          searchTop
          searchBottom={false}
          responsive
          hover
          entriesOptions={[10, 20, 30]}
          entries={10}
          pagesAmount={4}
          data={{
            columns: manageProjectColumns,
            rows: rowData,
          }}
        />
      ) : (
        <Spinner animation="border" />
      )}

      <EditManageProjectModal
        // defaultSelectorValue={defaultSelectorValue}
        show={showEditManagedProjectModal}
        managedProjectToEdit={managedProjectToEdit}
        handleClose={() => handleCloseEditModal()}
      />
    </div>
  );
};

export default ManagedProjectTable;
