import React, { useState } from 'react';

import { Container } from 'react-bootstrap';

import NavigationBar from '../../../components/navbar';
import AddManagedProjectModal from './AddManagedProjectModal';
import ManagedProjectTable from './ManagedProjectTable';

import { MDBTypography } from 'mdbreact';
import glb from '../../../image/GLB_logo.png';

const AdminProjectManagement = () => {
  const [showAddManagedProjectModal, setShowAddManagedProjectModal] =
    useState(false);

  const handleOpenProjectManagementModal = () => {
    setShowAddManagedProjectModal(true);
  };

  return (
    <>
      <NavigationBar />
      <div className="d-flex align-items-center   justify-content-center flex-column mb-5">
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-between align-items-center mt-5">
          <MDBTypography tag="h2" variant="h2-responsive">
            projekthantering
          </MDBTypography>
          <img alt="" src={glb} width="120" height="auto" />
        </div>
        <AddManagedProjectModal
          handleClose={() => setShowAddManagedProjectModal(false)}
          show={showAddManagedProjectModal}
        />
        <ManagedProjectTable
          handleOpenProjectManagementModal={handleOpenProjectManagementModal}
        />
      </div>
    </>
  );
};

export default AdminProjectManagement;
