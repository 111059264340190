export const customerColumns = [
  {
    label: '',
    field: '',
  },
  {
    label: 'Kundnamn',
    field: 'customerName',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Beskrivning',
    field: 'description',
  },

  {
    label: 'Antal projekt',
    field: 'numberOfProjects',
  },
  {
    label: 'Skapad',
    field: 'createdAt',
  },
  {
    label: 'Uppdaterad',
    field: 'updatedAt',
  },
  {
    label: '',
    field: 'last',
  },
];
export const inactiveCustomerColumns = [
  {
    label: 'kundnamn',
    field: 'customerName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    sort: 'disabled',
    // width: 150,
  },
];
export const customerColumnsMobile = [
  {
    label: 'Kundnamn',
    field: 'customerName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
export const inactiveCustomerColumnsMobile = [
  {
    label: 'kundnamn',
    field: 'customerName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'aktivera',
    field: 'activate',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
export const questionColumnsMobile = [
  {
    label: 'fråga',
    field: 'question',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
