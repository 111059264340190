import React from 'react';
import { Container, Spinner } from 'react-bootstrap';

const LoadingPage = () => {
  return (
    <Container className="d-flex align-items-center vh-100  justify-content-center flex-column">
      <Spinner animation="border" />
    </Container>
  );
};

export default LoadingPage;
