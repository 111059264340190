import { sendToast } from '../components/toast';
import { supabase } from '../utils/supabase';
import moment from 'moment';

const createSurvey = async (
  { surveyName, description, status, questions },
  digitualsClientID
) => {
  try {
    const { data, error } = await supabase.from('questionnaire').insert([
      {
        is_active: status,
        questionnaire_name: surveyName,
        questionnaire_description: description,
        digituals_client_id: digitualsClientID,
      },
    ]);

    if (data) {
      const { questionnaire_id } = data[0];

      if (questions) {
        questions.forEach(async (question) => {
          const { question_id, question_sort_order } = question;

          await supabase.from('questionnaire_setup').insert([
            {
              question_id: question_id,
              questionnaire_id: questionnaire_id,
              digituals_client_id: digitualsClientID,
              question_sort_order: question_sort_order,
            },
          ]);
        });
      }
    }
    if (error) {
      console.log('addOperation error: ', error);
    }

    const toastText = `Formulär ${surveyName} skapad!`;

    sendToast(toastText, 1);
  } catch (error) {
    console.log('createSurvey error', error, error.message);
  }
};

const chooseSurveyToUse = async (surveyID) => {};

const getSurveys = async (digitualsClientID) => {
  try {
    let { data: questionnaire, error } = await supabase
      .from('questionnaire')
      .select(
        `
      *,
      questionnaire_setup (
        *,
        question (
          *
        )
      ),
      project_setup (
        *,
        project (
          *
          )
      )
    `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (questionnaire) {
      return questionnaire;
    }
    if (error) {
      console.log('getSurveys error: ', error);
    }
  } catch (error) {
    console.log('getSurvey error', error, error.message);
  }
};

const getSurveyQuestions = async (questionnaire, digitualsClientID) => {
  const { questionnaire_id } = questionnaire;
  try {
    let { data: questionnaire_setup, error } = await supabase
      .from('questionnaire_setup')
      .select(
        `
    *,
   question (
      *
    )
  `
      )
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (questionnaire_setup) {
      return questionnaire_setup;
    }
    if (error) {
      console.log('getSurveyQuestions error: ', error);
    }
  } catch (error) {
    console.log('getSurveyQuestions error', error, error.message);
  }
};

const getResponse = async (digitualsClientID) => {
  try {
    let { data: responseData, error: responseError } = await supabase
      .from('response')
      .select(
        `
         *,
      reporter (
        *,
        project_setup (
          *,
          project (
            *
          )
        ),
        profile (
          *,
          resource(
            *
          )
        )
      ),
      response_details (
        *
      ),
      project_team_at_response (
        *
      )
         
         `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (responseData) {
      return responseData;
    }
    if (responseError) {
      console.log('responseError: ', responseError);
    }
  } catch (error) {
    console.log('getResponse error', error, error.message);
  }
};

const getActiveSurvey = async () => {};

const postResponse = async (res, digitualsClientID) => {
  const { daysFromStartDate, project, response, createdAt } = res;
  const { reporter_id } = project;
  const { project_id, project_name } = project.project_setup.project;

  try {
    const { data, error } = await supabase.from('response').insert([
      {
        // response_created_at: createdAt,
        response_days_from_start: daysFromStartDate,
        reporter_id: reporter_id,
        digituals_client_id: digitualsClientID,
      },
    ]);

    if (data) {
      const { response_id } = data[0];

      const questionToPost = [];

      response.forEach((r) => {
        const { question, questionID, answer } = r;

        questionToPost.push({
          // response_details_created_at: createdAt,
          question_id: questionID,
          question_at_response: question,
          response: answer,
          response_id: response_id,
          digituals_client_id: digitualsClientID,
        });
      });

      await questionToPost;

      const { data: responseDetails, error: responseDetailsError } =
        await supabase.from('response_details').insert(questionToPost);

      if (responseDetails) {
      }
      if (responseDetailsError) {
        console.log('responseDetailsError:', responseDetailsError);
      }

      let { data: team, error: teamError } = await supabase
        .from('team')
        .select('*')
        .eq('project_id', project_id)
        .eq('digituals_client_id', digitualsClientID);

      if (team) {
        const projectTeamAtResponseToPost = [];

        team.forEach((t) => {
          const { resource_id, team_projectleader } = t;

          projectTeamAtResponseToPost.push({
            // project_team_at_response_created_at: createdAt,
            resource_id: resource_id,
            team_projectleader: team_projectleader,
            response_id: response_id,
            digituals_client_id: digitualsClientID,
          });
        });

        const {
          data: projectTeamAtResponse,
          error: projectTeamAtResponseError,
        } = await supabase
          .from('project_team_at_response')
          .insert(projectTeamAtResponseToPost);

        if (projectTeamAtResponse) {
        }
        if (projectTeamAtResponseError) {
          console.log(
            'projectTeamAtResponseError:',
            projectTeamAtResponseError
          );
        }
      }
      if (teamError) {
        console.log('teamError:', teamError);
      }

      const toastText = `Respons på projekt ${project_name} lämnat!`;

      sendToast(toastText, 1, 1500, true);
    }
    if (error) {
      console.log('responseError:', error);
    }
  } catch (error) {
    console.log('postResponse error', error, error.message);
  }
};

const deleteSurvey = async (survey, digitualsClientID) => {
  const { questionnaire_id, questionnaire_name } = survey;

  try {
    const { data: projectSetupData, error: projectSetupError } = await supabase
      .from('project_setup')
      .select('*')
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (projectSetupError) {
      console.log('projectSetupError: ', projectSetupError);
    }

    if (projectSetupData.length > 0) {
      return { connected: true };
    } else {
      const { error: questionnaireSetupError } = await supabase
        .from('questionnaire_setup')
        .delete()
        .eq('questionnaire_id', questionnaire_id)
        .eq('digituals_client_id', digitualsClientID);

      const { data: questionnaireData, error: questionnaireError } =
        await supabase
          .from('questionnaire')
          .delete()
          .eq('questionnaire_id', questionnaire_id)
          .eq('digituals_client_id', digitualsClientID);

      if (questionnaireError) {
        console.log('questionnaireError: ', questionnaireError);
      }
      if (questionnaireSetupError) {
        console.log('questionnaireSetupError: ', questionnaireSetupError);
      }

      if (questionnaireData) {
        const toastText = `Formulär ${questionnaire_name} bortagen!`;

        sendToast(toastText, 2);
      }
    }
  } catch (error) {
    console.log('deleteSurvey error', error, error.message);
  }
};

const deleteResponse = async (responseID) => {};

const editResponse = async (r, digitualsClientID) => {
  try {
    if (r) {
      r.forEach(async (res) => {
        const { response, response_details_id } = res;

        const { data, error } = await supabase
          .from('response_details')
          .update({ response: parseInt(response) })
          .eq('response_details_id', response_details_id)
          .eq('digituals_client_id', digitualsClientID);

        if (error) {
          console.log('editResponseError: ', error);
        }
        if (data) {
        }
      });
    }

    const toastText = `Respons uppdaterad!`;

    sendToast(toastText, 1);
  } catch (error) {
    console.log('editResponse error', error, error.message);
  }
};

const editSurvey = async (survey, selectedQuestions, digitualsClientID) => {
  const { surveyName, description, questionnaire_id, status } = survey;

  try {
    if (status === false) {
      const { data, error } = await supabase
        .from('questionnaire')
        .update({
          is_active: status,
          questionnaire_name: surveyName,
          questionnaire_description: description,
          questionnaire_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        })
        .eq('questionnaire_id', questionnaire_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
      }
      if (error) {
        console.log('editOperation error: ', error);
      }

      const { data: projectSetupData, error: projectSetupError } =
        await supabase
          .from('project_setup')
          .select('*')
          .eq('questionnaire_id', questionnaire_id)
          .eq('digituals_client_id', digitualsClientID);

      if (projectSetupError) {
        console.log('projectSetupError: ', projectSetupError);
      }
      if (projectSetupData) {
        if (projectSetupData.length > 0) {
          projectSetupData.forEach(async (pSetup) => {
            const { data: d, error: projectSetupUpdateError } = await supabase
              .from('project_setup')
              .update({
                is_active: false,
              })
              .eq('project_setup_id', pSetup.project_setup_id)
              .eq('questionnaire_id', questionnaire_id)
              .eq('digituals_client_id', digitualsClientID);

            const { data: reporterData, error: reporterError } = await supabase
              .from('reporter')
              .update({
                is_active: false,
              })
              .eq('project_setup_id', pSetup.project_setup_id)
              .eq('digituals_client_id', digitualsClientID);

            if (reporterData) {
            }

            if (reporterError) {
              console.log('reporterError: ', reporterError);
            }

            if (d) {
              console.log('d ', d);
            }

            if (projectSetupUpdateError) {
              console.log('projectSetupError: ', projectSetupUpdateError);
            }
          });
        }

        const toastText = `${surveyName} uppdaterad!`;

        sendToast(toastText, 1);
      }
    } else {
      const { data, error } = await supabase
        .from('questionnaire')
        .update({
          is_active: status,
          questionnaire_name: surveyName,
          questionnaire_description: description,
          questionnaire_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        })
        .eq('questionnaire_id', questionnaire_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
        const toastText = `${surveyName} uppdaterad!`;

        sendToast(toastText, 1);
      }
      if (error) {
        console.log('editOperation error: ', error);
      }

      const { data: projectSetupData, error: projectSetupError } =
        await supabase
          .from('project_setup')
          .select('*')
          .eq('questionnaire_id', questionnaire_id)
          .eq('digituals_client_id', digitualsClientID);

      if (projectSetupError) {
        console.log('projectSetupError: ', projectSetupError);
      }
      if (projectSetupData) {
        // if (projectSetupData.length > 0) {
        //   projectSetupData.forEach(async (pSetup) => {
        //     const { data: d, error: projectSetupUpdateError } = await supabase
        //       .from('project_setup')
        //       .update({
        //         is_active: true,
        //       })
        //       .eq('project_setup_id', pSetup.project_setup_id)
        //       .eq('questionnaire_id', questionnaire_id)
        //       .eq('digituals_client_id', digitualsClientID);
        //     if (d) {
        //       console.log('d ', d);
        //     }
        //     if (projectSetupUpdateError) {
        //       console.log('projectSetupError: ', projectSetupUpdateError);
        //     }
        //   });
        // }
      }
    }

    await supabase
      .from('questionnaire_setup')
      .delete()
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (selectedQuestions) {
      const questionsToPost = [];

      selectedQuestions.forEach(async (question) => {
        const { question_id, question_sort_order } = question;

        questionsToPost.push({
          question_id: question_id,
          questionnaire_id: questionnaire_id,
          digituals_client_id: digitualsClientID,
          question_sort_order: question_sort_order,
        });
      });

      const { data: questionnaireSetupData, error: questionnaireSetupError } =
        await supabase.from('questionnaire_setup').insert(questionsToPost);

      if (questionnaireSetupData) {
      }
      if (questionnaireSetupError) {
        console.log('questionnaireSetupData: ', questionnaireSetupError);
      }
    }
  } catch (error) {
    console.log('editSurvey error', error, error.message);
  }
};

const activateSurvey = async (survey, digitualsClientID) => {
  const { questionnaire_id, questionnaire_name } = survey;

  try {
    const { data, error } = await supabase
      .from('questionnaire')
      .update({ is_active: true })
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      const toastText = `${questionnaire_name} akiverad!`;

      sendToast(toastText, 1);
    }
    if (error) {
      console.log('activateSurvey error: ', error);
    }
  } catch (error) {
    console.log('activateSurvey error', error, error.message);
  }
};
const inactivateSurvey = async (survey, digitualsClientID) => {
  const { questionnaire_id, questionnaire_name } = survey;

  try {
    const { data, error } = await supabase
      .from('questionnaire')
      .update({
        is_active: false,

        questionnaire_updated_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      })
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
    }
    if (error) {
      console.log('editOperation error: ', error);
    }

    const { data: projectSetupData, error: projectSetupError } = await supabase
      .from('project_setup')
      .select('*')
      .eq('questionnaire_id', questionnaire_id)
      .eq('digituals_client_id', digitualsClientID);

    if (projectSetupError) {
      console.log('projectSetupError: ', projectSetupError);
    }
    if (projectSetupData) {
      if (projectSetupData.length > 0) {
        projectSetupData.forEach(async (pSetup) => {
          const { data: d, error: projectSetupUpdateError } = await supabase
            .from('project_setup')
            .update({
              is_active: false,
            })
            .eq('project_setup_id', pSetup.project_setup_id)
            .eq('questionnaire_id', questionnaire_id)
            .eq('digituals_client_id', digitualsClientID);

          const { data: reporterData, error: reporterError } = await supabase
            .from('reporter')
            .update({
              is_active: false,
            })
            .eq('project_setup_id', pSetup.project_setup_id)
            .eq('digituals_client_id', digitualsClientID);

          if (reporterData) {
          }

          if (reporterError) {
            console.log('reporterError: ', reporterError);
          }

          if (d) {
          }

          if (projectSetupUpdateError) {
            console.log('projectSetupError: ', projectSetupUpdateError);
          }
        });
      }

      const toastText = `${questionnaire_name} inaktiverad!`;

      sendToast(toastText, 1);
    }

    // const { data, error } = await supabase
    //   .from('questionnaire')
    //   .update({ is_active: false })
    //   .eq('questionnaire_id', questionnaire_id)
    //   .eq('digituals_client_id', digitualsClientID);

    // if (data) {
    //   console.log('editOperation: ', questionnaire_name);

    //   const toastText = `${questionnaire_name} inakiverad!`;

    //   sendToast(toastText, 2);
    // }
    // if (error) {
    //   console.log('editOperation error: ', error);
    // }
  } catch (error) {
    console.log('editSurvey error', error, error.message);
  }
};

const removeSurveyFromProject = async (project, survey, digitualsClientID) => {
  const { questionnaire_name } = survey;
  const { project_id, project_name } = project;

  try {
    if (project && survey) {
      const { data: projectSetupData, error: projectSetupError } =
        await supabase
          .from('project_setup')
          .update({
            is_active: false,
          })
          .eq('project_id', project_id)
          .eq('digituals_client_id', digitualsClientID);

      if (projectSetupData) {
        sendToast(
          `Formulär ${questionnaire_name} har tagits bort från projekt ${project_name}!`,
          4
        );
      }
      if (projectSetupError) {
        console.log('teamError: ', projectSetupError);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const addProjectsToSurvey = async (projects, survey, digitualsClientID) => {
  const { questionnaire_name, questionnaire_id } = survey;

  try {
    if (projects && survey) {
      projects.forEach(async (p) => {
        const { project_id, project_name } = p;

        const { data: checkProjectSetupData, error: checkProjectSetupError } =
          await supabase
            .from('project_setup')
            .select('*')
            .eq('project_id', project_id)
            .eq('digituals_client_id', digitualsClientID);

        if (checkProjectSetupError) {
          console.log('checkProjectSetupError: ', checkProjectSetupError);
        }

        if (checkProjectSetupData) {
          const { data: projectSetupData, error: projectSetupError } =
            await supabase
              .from('project_setup')
              .update({
                is_active: true,
                questionnaire_id: questionnaire_id,
              })
              .eq('project_id', project_id)
              .eq('digituals_client_id', digitualsClientID);

          if (projectSetupData) {
          }
          if (projectSetupError) {
            console.log('teamError: ', projectSetupError);
          }
        } else {
          const { data: projectSetupData, error: projectSetupError } =
            await supabase.from('project_setup').insert([
              {
                questionnaire_id: questionnaire_id,
                project_id: project_id,
                digituals_client_id: digitualsClientID,
              },
            ]);

          if (projectSetupData) {
            sendToast(
              `Formulär ${questionnaire_name} har lagts till på projekt ${project_name}!`,
              1
            );
          }
          if (projectSetupError) {
            console.log('addOperation error: ', projectSetupError);
          }
        }
      });

      sendToast(
        `formulär ${questionnaire_name} har lagts till på valda projekt!`,
        1
      );
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const setActiveSurvey = async (surveyID) => {};

export {
  createSurvey,
  getSurveys,
  postResponse,
  chooseSurveyToUse,
  getActiveSurvey,
  deleteSurvey,
  editSurvey,
  setActiveSurvey,
  getResponse,
  deleteResponse,
  editResponse,
  getSurveyQuestions,
  inactivateSurvey,
  activateSurvey,
  removeSurveyFromProject,
  addProjectsToSurvey,
};
