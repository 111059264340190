import {
  //======= auth
  AUTH_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  //======= analys
  SET_ANALYS_DATA,
  SET_ANALYS_DATA_DEFAULT,
  SET_ANALYS_DATA_TIME,
  SET_ANALYS_DATA_PROJECT_CUSTOMER,
  SET_ANALYS_DATA_REPORTER,
  SET_ANALYS_DATA_QUESTION,
  SET_OVERVIEW_DATA_SELECTED_QUESTIONS,
  SET_OVERVIEW_DATA_ACTIVE_QUESTIONS,
  SET_OVERVIEW_DATA_SELECTED_CUSTOMERS,
  SET_OVERVIEW_DATA_ACTIVE_CUSTOMERS,
  SET_OVERVIEW_DATA_SELECTED_DATE,
  SET_SELECTED_DATA_FROM_FILTER,
  //======= survey
  CREATE_SURVEY,
  GET_SURVEYS,
  GET_SURVEY_QUESTIONS,
  EDIT_SURVEY,
  DELETE_SURVEY,
  GET_ACTIVE_SURVEY,
  // CHOOSE_SURVEY_TO_USE,
  SET_ACTIVE_SURVEY,
  SURVEY_PROGRESS,
  INACTIVATE_SURVEY,
  ACTIVATE_SURVEY,
  REMOVE_SURVEY_FROM_PROJECT,
  ADD_PROJECT_TO_SERVEY,
  //======= response
  GET_RESPONSE,
  POST_RESPONSE,
  DELETE_RESPONSE,
  EDIT_RESPONSE,
  //======= project
  ADD_PROJECT,
  GET_PROJECTS,
  DELETE_PROJECT,
  EDIT_PROJECT,
  ACTIVATE_PROJECT,
  INACTIVATE_PROJECT,
  //======= questions
  ADD_QUESTION,
  GET_QUESTIONS,
  DELETE_QUESTION,
  EDIT_QUESTION,
  INACTIVATE_QUESTION,
  ACTIVATE_QUESTION,
  ADD_QUESTION_TO_SURVEYS,
  REMOVE_QUESTION_FROM_SURVEY,
  //======= resources
  ADD_RESOURCE,
  GET_RESOURCES,
  DELETE_RESOURCE,
  EDIT_RESOURCE,
  ACTIVATE_RESOURCE,
  INACTIVATE_RESOURCE,
  ADD_RESOURCE_TO_PROJECT,
  REMOVE_RESOURCE_TO_PROJECT,
  REMOVE_RESOURCE_FROM_OPERATION,
  ADD_RESOURCE_TO_OPERATION,
  INACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  CREATE_ACCOUNT,
  GET_REPORTERS,
  GET_ACCOUNTS,
  //======= managed projects
  ADD_MANAGED_PROJECT,
  GET_MANAGED_PROJECTS,
  GET_MY_MANAGED_PROJECTS,
  DELETE_MANAGED_PROJECT,
  //======= operation
  ADD_OPERATION,
  GET_OPERATION,
  ACTIVATE_OPERATION,
  INACTIVATE_OPERATION,
  EDIT_OPERATION,
  DELETE_OPERATION,
  //======= customer
  ADD_CUSTOMER,
  GET_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,
  ACTIVATE_CUSTOMER,
  INACTIVATE_CUSTOMER,
} from '../types';

import * as userApi from '../../../firebase-api/user';
import * as surveyApi from '../../../firebase-api/survey';
import * as projectApi from '../../../firebase-api/project';
import * as questionApi from '../../../firebase-api/questions';
import * as resourceApi from '../../../firebase-api/resource';
import * as managedProjectApi from '../../../firebase-api/projectManagement';
import * as operationApi from '../../../firebase-api/operation';
import * as customerApi from '../../../firebase-api/customer';

//======= auth

export const registerUser = (userData) => ({
  type: AUTH_USER,
  payload: userApi.registerUser(userData),
});

export const loginUser = (userData) => ({
  type: AUTH_USER,
  payload: userApi.loginUser(userData),
});

export const autoSignIn = () => ({
  type: AUTH_USER,
  payload: userApi.autoSignIn(),
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: userApi.logoutUser(),
});

export const updateProfile = (user, isEmailChanged, digitualsClientID) => ({
  type: UPDATE_USER,
  payload: userApi.updateProfile(user, isEmailChanged, digitualsClientID),
});

export const resetPassword = (email) => ({
  type: RESET_PASSWORD,
  payload: userApi.resetPassword(email),
});
export const updateUserPassword = (value) => ({
  type: UPDATE_USER_PASSWORD,
  payload: userApi.updateUserPassword(value),
});
//======= analys

export const setAnalysData = (analysData) => ({
  type: SET_ANALYS_DATA,
  payload: analysData,
});
export const setAnalysDataDefault = (analysData) => ({
  type: SET_ANALYS_DATA_DEFAULT,
  payload: analysData,
});

export const setAnalysDataTime = (analysData) => ({
  type: SET_ANALYS_DATA_TIME,
  payload: analysData,
});
export const setAnalysDataProjectCustomer = (analysData) => ({
  type: SET_ANALYS_DATA_PROJECT_CUSTOMER,
  payload: analysData,
});
export const setAnalysDataReporter = (analysData) => ({
  type: SET_ANALYS_DATA_REPORTER,
  payload: analysData,
});
export const setAnalysDataQuestion = (analysData) => ({
  type: SET_ANALYS_DATA_QUESTION,
  payload: analysData,
});
export const setOverviewDataSelectedQuestions = (overviewData) => ({
  type: SET_OVERVIEW_DATA_SELECTED_QUESTIONS,
  payload: overviewData,
});
export const setOverviewDataActiveQuestions = (overviewData) => ({
  type: SET_OVERVIEW_DATA_ACTIVE_QUESTIONS,
  payload: overviewData,
});

export const setOverviewDataSelectedCustomers = (overviewData) => ({
  type: SET_OVERVIEW_DATA_SELECTED_CUSTOMERS,
  payload: overviewData,
});
export const setOverviewDataActiveCustomers = (overviewData) => ({
  type: SET_OVERVIEW_DATA_ACTIVE_CUSTOMERS,
  payload: overviewData,
});
export const overviewDataSetSelectedDate = (overviewData) => ({
  type: SET_OVERVIEW_DATA_SELECTED_DATE,
  payload: overviewData,
});
export const setSelectedDataFromFilter = (overviewData) => ({
  type: SET_SELECTED_DATA_FROM_FILTER,
  payload: overviewData,
});

//======= survey

export const createSurvey = (surveyData, digitualsClientID) => ({
  type: CREATE_SURVEY,
  payload: surveyApi.createSurvey(surveyData, digitualsClientID),
});

export const getSurveys = (digitualsClientID) => ({
  type: GET_SURVEYS,
  payload: surveyApi.getSurveys(digitualsClientID),
});

export const getSurveyQuestions = (questionnaire, digitualsClientID) => ({
  type: GET_SURVEY_QUESTIONS,
  payload: surveyApi.getSurveyQuestions(questionnaire, digitualsClientID),
});

export const editSurvey = (survey, selectedQuestions, digitualsClientID) => ({
  type: EDIT_SURVEY,
  payload: surveyApi.editSurvey(survey, selectedQuestions, digitualsClientID),
});
export const activateSurvey = (survey, digitualsClientID) => ({
  type: ACTIVATE_SURVEY,
  payload: surveyApi.activateSurvey(survey, digitualsClientID),
});
export const inactivateSurvey = (survey, digitualsClientID) => ({
  type: INACTIVATE_SURVEY,
  payload: surveyApi.inactivateSurvey(survey, digitualsClientID),
});

export const deleteSurvey = (survey, digitualsClientID) => ({
  type: DELETE_SURVEY,
  payload: surveyApi.deleteSurvey(survey, digitualsClientID),
});

export const getActiveSurvey = () => ({
  type: GET_ACTIVE_SURVEY,
  payload: surveyApi.getActiveSurvey(),
});

export const setActiveSurvey = (surveyID) => ({
  type: SET_ACTIVE_SURVEY,
  payload: surveyApi.setActiveSurvey(surveyID),
});
export const removeSurveyFromProject = (
  project,
  servey,
  digitualsClientID
) => ({
  type: REMOVE_SURVEY_FROM_PROJECT,
  payload: surveyApi.removeSurveyFromProject(
    project,
    servey,
    digitualsClientID
  ),
});
export const addProjectsToSurvey = (projects, servey, digitualsClientID) => ({
  type: ADD_PROJECT_TO_SERVEY,
  payload: surveyApi.addProjectsToSurvey(projects, servey, digitualsClientID),
});

export const setSurveyProgress = (surveyProgress) => ({
  type: SURVEY_PROGRESS,
  payload: surveyProgress,
});

//======= response

export const postResponse = (response, digitualsClientID) => ({
  type: POST_RESPONSE,
  payload: surveyApi.postResponse(response, digitualsClientID),
});

export const getResponse = (digitualsClientID) => ({
  type: GET_RESPONSE,
  payload: surveyApi.getResponse(digitualsClientID),
});
export const deleteResponse = (responseID) => ({
  type: DELETE_RESPONSE,
  payload: surveyApi.deleteResponse(responseID),
});

export const editResponse = (response, digitualsClientID) => ({
  type: EDIT_RESPONSE,
  payload: surveyApi.editResponse(response, digitualsClientID),
});

//======= project

export const addProject = (project, digitualsClientID) => ({
  type: ADD_PROJECT,
  payload: projectApi.addProject(project, digitualsClientID),
});

export const getProjects = (digitualsClientID) => ({
  type: GET_PROJECTS,
  payload: projectApi.getProjects(digitualsClientID),
});

export const deleteProject = (projectToDelete, digitualsClientID) => ({
  type: DELETE_PROJECT,
  payload: projectApi.deleteProject(projectToDelete, digitualsClientID),
});

export const editProject = (project, digitualsClientID) => ({
  type: EDIT_PROJECT,
  payload: projectApi.editProject(project, digitualsClientID),
});

export const activateProject = (project, digitualsClientID) => ({
  type: ACTIVATE_PROJECT,
  payload: projectApi.activateProject(project, digitualsClientID),
});

export const inactivateProject = (project, digitualsClientID) => ({
  type: INACTIVATE_PROJECT,
  payload: projectApi.inactivateProject(project, digitualsClientID),
});
//======= questions

export const addQuestion = (question, digitualsClientID) => ({
  type: ADD_QUESTION,
  payload: questionApi.addQuestion(question, digitualsClientID),
});

export const getQuestions = (digitualsClientID) => ({
  type: GET_QUESTIONS,
  payload: questionApi.getQuestions(digitualsClientID),
});

export const deleteQuestion = (question, digitualsClientID) => ({
  type: DELETE_QUESTION,
  payload: questionApi.deleteQuestion(question, digitualsClientID),
});

export const editQuestion = (question, digitualsClientID) => ({
  type: EDIT_QUESTION,
  payload: questionApi.editQuestion(question, digitualsClientID),
});
export const activateQuestion = (question, digitualsClientID) => ({
  type: ACTIVATE_QUESTION,
  payload: questionApi.activateQuestion(question, digitualsClientID),
});
export const inactivateQuestion = (question, digitualsClientID) => ({
  type: INACTIVATE_QUESTION,
  payload: questionApi.inactivateQuestion(question, digitualsClientID),
});

export const addQuestionToSurveys = (question, surveys, digitualsClientID) => ({
  type: ADD_QUESTION_TO_SURVEYS,
  payload: questionApi.addQuestionToSurveys(
    question,
    surveys,
    digitualsClientID
  ),
});
export const removeQuestionFromSurvey = (
  question,
  survey,
  digitualsClientID
) => ({
  type: REMOVE_QUESTION_FROM_SURVEY,
  payload: questionApi.removeQuestionFromSurvey(
    question,
    survey,
    digitualsClientID
  ),
});

//======= resources

export const addResource = (resource, digitualsClientID) => ({
  type: ADD_RESOURCE,
  payload: resourceApi.addResource(resource, digitualsClientID),
});

export const getResources = (digitualsClientID) => ({
  type: GET_RESOURCES,
  payload: resourceApi.getResources(digitualsClientID),
});

export const getReporters = (digitualsClientID) => ({
  type: GET_REPORTERS,
  payload: resourceApi.getReporters(digitualsClientID),
});
export const getAccounts = () => ({
  type: GET_ACCOUNTS,
  payload: resourceApi.getAccounts(),
});

export const deleteResource = (resource, digitualsClientID) => ({
  type: DELETE_RESOURCE,
  payload: resourceApi.deleteResource(resource, digitualsClientID),
});
export const activateResource = (resource, digitualsClientID) => ({
  type: ACTIVATE_RESOURCE,
  payload: resourceApi.activateResource(resource, digitualsClientID),
});
export const inactivateResource = (resource, digitualsClientID) => ({
  type: INACTIVATE_RESOURCE,
  payload: resourceApi.inactivateResource(resource, digitualsClientID),
});
export const addResourceToProject = (
  projects,
  resource,
  digitualsClientID
) => ({
  type: ADD_RESOURCE_TO_PROJECT,
  payload: resourceApi.addResourceToProject(
    projects,
    resource,
    digitualsClientID
  ),
});
export const addResourceToOperation = (
  operation,
  resources,
  digitualsClientID
) => ({
  type: ADD_RESOURCE_TO_OPERATION,
  payload: resourceApi.addResourceToOperation(
    operation,
    resources,
    digitualsClientID
  ),
});
export const removeResourceToProject = (
  project,
  resource,
  digitualsClientID
) => ({
  type: REMOVE_RESOURCE_TO_PROJECT,
  payload: resourceApi.removeResourceToProject(
    project,
    resource,
    digitualsClientID
  ),
});
export const removeResourceFromOperation = (
  operation,
  resource,
  digitualsClientID
) => ({
  type: REMOVE_RESOURCE_FROM_OPERATION,
  payload: resourceApi.removeResourceFromOperation(
    operation,
    resource,
    digitualsClientID
  ),
});
export const deleteAccount = (resource) => ({
  type: DELETE_ACCOUNT,
  payload: resourceApi.deleteAccount(resource),
});
export const activateAccount = (resource, digitualsClientID) => ({
  type: ACTIVATE_ACCOUNT,
  payload: resourceApi.activateAccount(resource, digitualsClientID),
});
export const inactivateAccount = (resource, digitualsClientID) => ({
  type: INACTIVATE_ACCOUNT,
  payload: resourceApi.inactivateAccount(resource, digitualsClientID),
});
export const editAccount = (resource, digitualsClientID) => ({
  type: EDIT_ACCOUNT,
  payload: resourceApi.editAccount(resource, digitualsClientID),
});
export const createAccount = (resource, digitualsClientID) => ({
  type: CREATE_ACCOUNT,
  payload: resourceApi.createAccount(resource, digitualsClientID),
});

export const editResource = (resource, digitualsClientID) => ({
  type: EDIT_RESOURCE,
  payload: resourceApi.editResource(resource, digitualsClientID),
});

// export const addresource = (projectManager) => ({
//   type: ADD_PROJECT_MANAGER,
//   payload: resourceApi.addresource(projectManager),
// });

//======= managed projects

export const addManagedProject = (managedProject) => ({
  type: ADD_MANAGED_PROJECT,
  payload: managedProjectApi.addManagedProject(managedProject),
});

export const getManagedProjects = () => ({
  type: GET_MANAGED_PROJECTS,
  payload: managedProjectApi.getManagedProjects(),
});

export const getMyManagedProjects = (userID, digitualsClientID) => ({
  type: GET_MY_MANAGED_PROJECTS,
  payload: managedProjectApi.getMyManagedProjects(userID, digitualsClientID),
});

export const deleteManagedProject = (managedProjectID, projectName) => ({
  type: DELETE_MANAGED_PROJECT,
  payload: managedProjectApi.deleteManagedProject(
    managedProjectID,
    projectName
  ),
});

// export const editManagedProject = (managedProject) => ({
//   type: EDIT_MANAGED_PROJECT,
//   payload: managedProjectApi.editManagedProject(managedProject),
// });

//======= operation

export const addOperation = (operation, digitualsClientID) => ({
  type: ADD_OPERATION,
  payload: operationApi.addOperation(operation, digitualsClientID),
});

export const getOperation = (digitualsClientID) => ({
  type: GET_OPERATION,
  payload: operationApi.getOperation(digitualsClientID),
});
export const activateOperation = (operation, digitualsClientID) => ({
  type: ACTIVATE_OPERATION,
  payload: operationApi.activateOperation(operation, digitualsClientID),
});

export const inactivateOperation = (operation, digitualsClientID) => ({
  type: INACTIVATE_OPERATION,
  payload: operationApi.inactivateOperation(operation, digitualsClientID),
});

export const editOperation = (operation, digitualsClientID) => ({
  type: EDIT_OPERATION,
  payload: operationApi.editOperation(operation, digitualsClientID),
});
export const deleteOperation = (operation, digitualsClientID) => ({
  type: DELETE_OPERATION,
  payload: operationApi.deleteOperation(operation, digitualsClientID),
});

//======= customer

export const addCustomer = (customer, digitualsClientID) => ({
  type: ADD_CUSTOMER,
  payload: customerApi.addCustomer(customer, digitualsClientID),
});

export const getCustomer = (digitualsClientID) => ({
  type: GET_CUSTOMER,
  payload: customerApi.getCustomer(digitualsClientID),
});

export const editCustomer = (customer, digitualsClientID) => ({
  type: EDIT_CUSTOMER,
  payload: customerApi.editCustomer(customer, digitualsClientID),
});
export const deleteCustomer = (customer, digitualsClientID) => ({
  type: DELETE_CUSTOMER,
  payload: customerApi.deleteCustomer(customer, digitualsClientID),
});
export const activateCustomer = (customer, digitualsClientID) => ({
  type: ACTIVATE_CUSTOMER,
  payload: customerApi.activateCustomer(customer, digitualsClientID),
});
export const inactivateCustomer = (customer, digitualsClientID) => ({
  type: INACTIVATE_CUSTOMER,
  payload: customerApi.inactivateCustomer(customer, digitualsClientID),
});
