import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ModalIcon = ({ icon, style }) => {
  return (
    <Row className=" mb-4" style={style}>
      <Col className="d-flex align-items-center  justify-content-center ">
        <img
          style={{ maxHeight: '150px', maxWidth: '150px' }}
          src={icon}
          alt=""
          // className="rounded-circle "
        />
      </Col>
    </Row>
  );
};

export default ModalIcon;
