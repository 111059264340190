import React, { useState, useEffect } from 'react';

import NavigationBar from '../../../components/navbar';
import ProjectsTable from './ProjectsTable';
import CustomerTable from '../customer/CustomerTable';

import AdminProjectMobile from './project-mobile';

import TopTabNavsDesktop from '../../../components/top-tabs-navs-desktop';

const AdminProject = () => {
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  const pixelBreakPoint = 770;

  const tabs = [
    {
      name: 'Projekt',
      content: <ProjectsTable />,
    },
    {
      name: 'Kunder',
      content: <CustomerTable />,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <NavigationBar />

      {currentWindowSize < pixelBreakPoint ? (
        <AdminProjectMobile />
      ) : (
        <TopTabNavsDesktop tabs={tabs} />
      )}
    </>
  );
};

export default AdminProject;
