/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ModalList from '../../../components/modal-list';
import { MDBIcon } from 'mdb-react-ui-kit';
import PositionSelect from '../../../components/position-select';

const QuestionList = ({
  selectedQuestions,
  removeQuestion,
  add,
  setPostionValue,
  fixedPosition,
}) => {
  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Fråga</p>,
      field: 'question',
    },
    {
      label: (
        <p style={{ cursor: 'pointer', float: 'right', marginRight: '5px' }}>
          Position
        </p>
      ),
      field: 'position',
    },

    {
      label: <p style={{ cursor: 'pointer', float: 'right' }}>Ta bort</p>,
      field: 'remove',
      sort: 'disabled',
    },
  ];

  const positionOptions = selectedQuestions.map((_, index) => ({
    value: index + 1,
    label: index + 1,
  }));

  const positionOptionsQuestion = [];

  for (let index = 1; index < 10; index++) {
    positionOptionsQuestion.push(index);
  }
  const handleSetPostionValue = (value, index) => {
    let selectedQuestionsCopy = [...selectedQuestions];

    selectedQuestionsCopy[index].question_sort_order = value;
    setPostionValue(selectedQuestionsCopy);
  };

  if (selectedQuestions) {
    selectedQuestions.sort(function (a, b) {
      var numA = a.question_sort_order;
      var numB = b.question_sort_order;
      if (numA < numB) {
        return -1;
      }
      if (numA > numB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <>
      <ModalList
        add={add}
        listKey={'QuestionList'}
        columns={columns}
        rows={selectedQuestions.map((value, index) => {
          const { question_sort_order, question_id, question } = value;

          return {
            question: question,

            position: (
              <div style={{ float: 'right' }}>
                <PositionSelect
                  options={
                    fixedPosition ? positionOptionsQuestion : positionOptions
                  }
                  placeholder="-"
                  value={question_sort_order ? question_sort_order : '-'}
                  // defaultValue={question_sort_order ? question_sort_order : '-'}
                  setValue={(value) => {
                    return handleSetPostionValue(value, index);
                  }}
                />
              </div>
            ),
            remove: (
              <MDBIcon
                key={question_id}
                style={{
                  cursor: 'pointer',
                  float: 'right',
                  marginRight: '13px',
                }}
                icon="minus-circle"
                className="text-primary"
                size="lg"
                onClick={() => {
                  removeQuestion(question_id);
                }}
              />
            ),
          };
        })}
      />
    </>
  );
};

export default QuestionList;
