import React from 'react';
import { Button } from 'react-bootstrap';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBContainer,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import UserModalForm from './UserModalForm';
import { pixels } from '../../constants';

const UserModal = ({ handleClose, show, fullscreen, logout }) => {
  const { user } = useSelector((state) => state.auth);

  // const handleUpdateUser = () => {
  //   dispatch(
  //     updateProfile(currentUser, isEmailChanged, user.digituals_client_id)
  //   );
  // };

  const handleClickLogout = () => {
    logout();
    handleClose();
    // setShowLogout(true);
    // logout('Är du säker att du vill logga ut?');
  };

  return (
    <MDBModal show={show} animationDirection="right" staticBackdrop closeOnEsc>
      <MDBModalDialog size={fullscreen ? 'fullscreen' : 'lg'} centered>
        <MDBModalContent className="bg-light">
          <MDBModalHeader className="border-0">
            <div
              // className="d-flex flex-column align-items-start justify-content-center bg-dark"
              style={{
                width: '30%',
                marginLeft: 20,
                background: 'transparent',
              }}
              // style={{ width: '30%', marginLeft: fullscreen ? '0%' : 0 }}
            >
              <Button
                style={{ background: 'transparent' }}
                color="transparent"
                className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                onClick={handleClose}
              >
                <MDBIcon
                  fas
                  icon="chevron-left "
                  size="sm"
                  color="secondary"
                  style={{ marginLeft: -25 }}
                />

                <p
                  className=" text-secondary"
                  style={{ marginLeft: 5, fontSize: 16, marginTop: 15 }}
                >
                  Tillbaka
                </p>
              </Button>
            </div>

            {/* <p
              className="mt-3 text-secondary"
              style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 14 }}
            >
              konto
            </p> */}
            <div>
              {/* {user && (
                <MDBTypography variant="h5" style={{ marginTop: 10 }}>
                  {user._digituals_client.digituals_client_name
                    ? user._digituals_client.digituals_client_name
                    : 'Användare'}
                </MDBTypography>
              )} */}
            </div>
            <div
              // style={{ width: '30%', marginRight: fullscreen ? '0%' : 0 }}
              style={{
                width: '30%',
                marginRight: 20,
                background: 'transparent',
              }}
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                style={{ background: 'transparent' }}
                color="transparent"
                // className=" align-items-center  text-muted d-flex flex-row"
                className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-end"
                type="submit"
                form="UserModalForm"
              >
                <p
                  className=" text-secondary"
                  style={{ fontSize: 16, marginTop: 15, marginRight: -25 }}
                >
                  Spara
                </p>
              </Button>
            </div>
          </MDBModalHeader>
          <MDBModalBody className="d-flex flex-column align-items-center justify-content-start ">
            <MDBContainer
              className="mb-5 bg-transparent"
              style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
            >
              {user && (
                <UserModalForm currentUser={user} handleClose={handleClose} />
              )}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter className="d-flex flex-column align-items-start justify-content-center ">
            <Button
              style={{ background: 'transparent', marginLeft: 20 }}
              color="transparent"
              // className=" align-items-center  text-muted d-flex flex-row"
              className="mt-0   text-muted d-flex flex-row  align-items-center justify-content-center"
              onClick={handleClickLogout}
            >
              <p
                style={{
                  marginLeft: -25,
                  fontSize: 16,
                }}
                className=" text-secondary"
              >
                Logga ut
              </p>
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default UserModal;
