/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import { useReactToPrint } from 'react-to-print';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import { colors, pixels } from '../../constants';
import { getUniqueRespons } from '../../pages/analys/calculate-functions';
import ChoosenFilters from '../print-components/ChoosenFilters';
import FloatingHoverButton from '../floating-hover-button';
import { ComponentToPrint } from '../component-to-print';
import DateUserTitle from '../print-components/DateUserTitle';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyAreaChart = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  style = { marginLeft: '31px' },
  dtick = 1,
  tickangle = 0,
  usePrint = false,
}) => {
  const [filtredData, setFiltredData] = useState([]);
  const [colorWay, setColorWay] = useState([
    colors.areachart.RED,
    colors.areachart.LIGHT_RED,
    colors.areachart.LIGHT_GREEN,
    colors.areachart.GREEN,
  ]);

  const { analysDataDefault } = useSelector((state) => state.analys);
  const { user } = useSelector((state) => state.auth);

  const sortedData = analysDataDefault.sort(function (a, b) {
    const date1 = new Date(a.Responsdatum);
    const date2 = new Date(b.Responsdatum);

    return date1 - date2;
  });

  const extraWidth = analysDataDefault.length * 2.5;

  const width = 1600;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (data) {
      if (data.valueFilter) {
        filterData(analysDataDefault, data.valueFilter);
      }
    }
  }, [data]);

  function filterData(data, valueFilter) {
    let filtredData = data.slice();

    const filterKeys = Object.keys(valueFilter);
    filterKeys.forEach((key) => {
      const filterValue = Object.keys(valueFilter[key]);
      filtredData = filtredData.filter(
        (item) => !filterValue.includes(item[key].toString())
      );
    });

    const colorScheme = changeColorWay(getUniqueRespons(filtredData));
    if (colorScheme) {
      setColorWay(colorScheme);
    }

    setFiltredData(filtredData);
  }

  return (
    <>
      <div style={style}>
        <ComponentToPrint ref={componentRef}>
          <div className="justify-content-center flex-column align-items-center  rounded">
            <div className="hide-when-printing">
              <PivotTableTitle title={title} />
              <div style={{ overflow: 'auto' }}>
                <ReactPivotTable
                  data={analysDataDefault}
                  rows={preSelectedRows}
                  cols={preSelectedCols}
                  aggregatorName={aggregatorName}
                  vals={vals}
                  rendererName="Area Chart"
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                  plotlyOptions={{
                    width: pixels.ANALYS_PLOTLY_WIDTH,
                    title: '',
                    showlegend: true,
                    yaxis: {
                      tickformat: 'p',
                      tickmode: 'linear',
                      tick0: 0.1,
                      dtick: 0.15,
                      gridcolor: 'transparent',
                    },
                    colorway: colorWay,
                    xaxis: {
                      gridcolor: 'transparent',
                      autorange: true,
                      rangeslider: {
                        range: [1, 2],
                        bgcolor: colors.DASHBOARD,
                        thickness: 0.02,
                        borderwidth: 2,
                        bordercolor: colors.PRIMARY,
                      },
                      type: 'category',
                    },
                  }}
                  plotlyConfig={{ responsive: true }}
                  valueFilter={data ? data.valueFilter : {}}
                />
              </div>
            </div>
            <div className="show-when-printing">
              <DateUserTitle user={user} title={title} />
              <div style={{ overflow: 'auto' }}>
                <ReactPivotTable
                  data={analysDataDefault}
                  rows={preSelectedRows}
                  cols={preSelectedCols}
                  aggregatorName={aggregatorName}
                  vals={vals}
                  rendererName="Area Chart"
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                  plotlyOptions={{
                    width: pixels.ANALYS_PLOTLY_WIDTH,
                    title: '',
                    showlegend: true,
                    yaxis: {
                      title: '',
                      tickformat: '.0%',
                      gridcolor: 'transparent',
                    },
                    colorway: colorWay,
                    xaxis: {
                      autorange: true,
                      gridcolor: 'transparent',
                    },
                  }}
                  plotlyConfig={{ responsive: true }}
                  valueFilter={data ? data.valueFilter : {}}
                />
              </div>
            </div>
          </div>
          <div className="page-break" />
          <div className="show-when-printing d-felx align-items-center justify-content-center">
            <ChoosenFilters
              data={data ? data.valueFilter : {}}
              defaultData={analysDataDefault}
            />
          </div>
        </ComponentToPrint>
      </div>
      {usePrint && (
        <>
          <FloatingHoverButton
            hoverText="Skriv ut graf"
            icon="print"
            onClick={() => handlePrint()}
            top="82vh"
          />
        </>
      )}
    </>
  );
};

export default PlotlyAreaChart;

function changeColorWay(responseValues) {
  responseValues.sort(function (a, b) {
    return a - b;
  });

  if (responseValues.length === 4) {
    return [
      colors.areachart.RED,
      colors.areachart.LIGHT_RED,
      colors.areachart.LIGHT_GREEN,
      colors.areachart.GREEN,
    ];
  }
  if (responseValues.length === 3) {
    let arr = [];

    responseValues.forEach((item) => {
      if (item === 1) {
        arr.push(colors.areachart.RED);
      }
      if (item === 2) {
        arr.push(colors.areachart.LIGHT_RED);
      }
      if (item === 3) {
        arr.push(colors.areachart.LIGHT_GREEN);
      }
      if (item === 4) {
        arr.push(colors.areachart.GREEN);
      }
    });

    return arr;
  }
  if (responseValues.length === 2) {
    let arr = [];

    responseValues.forEach((item) => {
      if (item === 1) {
        arr.push(colors.areachart.RED);
      }
      if (item === 2) {
        arr.push(colors.areachart.LIGHT_RED);
      }
      if (item === 3) {
        arr.push(colors.areachart.LIGHT_GREEN);
      }
      if (item === 4) {
        arr.push(colors.areachart.GREEN);
      }
    });

    return arr;
  }
  if (responseValues.length === 1) {
    let arr = [];

    responseValues.forEach((item) => {
      if (item === 1) {
        arr.push(colors.areachart.RED);
      }
      if (item === 2) {
        arr.push(colors.areachart.LIGHT_RED);
      }
      if (item === 3) {
        arr.push(colors.areachart.LIGHT_GREEN);
      }
      if (item === 4) {
        arr.push(colors.areachart.GREEN);
      }
    });

    return arr;
  }
}
