import React, { useEffect, useState } from 'react';
import { MDBDataTableV5, MDBIcon, MDBBtn } from 'mdbreact';

const ModalList = ({ columns, rows, add, noAddBtn, listKey, mobile }) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const pixelBr = 992;

  useEffect(() => {
    const handleResize = () => {
      setCurrentWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <MDBDataTableV5
        key={`datatable-${listKey}`}
        btn
        small
        info={false}
        searching={false}
        displayEntries={false}
        searchLabel="sök..."
        entriesLabel="per sida"
        barReverse
        searchTop
        searchBottom={false}
        responsive={currentWidth < pixelBr || mobile ? true : false}
        hover
        entriesOptions={[10, 20, 30]}
        entries={100}
        pagesAmount={4}
        data={{
          columns: columns,
          rows: rows,
        }}
      />

      <div
        style={{ cursor: 'pointer', marginLeft: 22, marginTop: -55 }}
        key={`div-${listKey}`}
      >
        {noAddBtn ? null : (
          <MDBBtn
            floating
            tag="a"
            size="md"
            onClick={add}
            className="bg-primary"
          >
            <MDBIcon
              fas
              icon="plus"
              size="lg"
              color="light"
              className="text-white"
            />
          </MDBBtn>
        )}
      </div>
    </>
  );
};

export default ModalList;
