const s = {
  fontSize: 14,
  marginTop: 18,
  fontWeight: 'normal',
};

export const columnsExport = [
  {
    label: <p style={s}>Kund</p>,
    field: 'customer',
    width: 'auto',
    sort: true,
  },
  {
    label: <p style={s}>Projekt</p>,
    field: 'project',
    width: 'auto',
    sort: true,
  },
  {
    label: <p style={s}>Fråga</p>,
    field: 'question',
    width: 'auto',
    sort: true,
  },

  {
    label: <p style={s}>Respons</p>,
    field: 'response',
    width: 10,
    sort: true,
  },
  {
    label: <p style={s}>Respons skapad</p>,
    field: 'responseCreated',
    width: 'auto',
    sort: true,
  },
  {
    label: <p style={s}>Rapportör</p>,
    field: 'reporter',
  },
  {
    label: <p style={s}>Rapportör e-post</p>,
    field: 'reporterEmail',
    width: 'auto',
    sort: true,
  },
  {
    label: <p style={s}>Rapportör aktiv</p>,
    field: 'reporterActive',
    width: 'auto',
    sort: true,
  },

  {
    label: <p style={s}>Projekt aktiv</p>,
    field: 'projectActive',
    width: 'auto',
    sort: true,
  },
];
