import { supabase } from '../utils/supabase';

const addManagedProject = async (managedProject) => {};

const getManagedProjects = async () => {};

const getMyManagedProjects = async (profile_id, digitualsClientID) => {
  try {
    let { data: reporter, error: reporterError } = await supabase
      .from('reporter')
      .select(
        `
       *,
       response (
         *
       )
       ,
       project_setup (
       * ,
       questionnaire (
         * 
         
         ),
       project (
         * ,
        customer (
          *
        )
         )
       )
       
       `
      )
      .eq('is_active', true)
      .eq('profile_id', profile_id)
      .eq('digituals_client_id', digitualsClientID);

    if (reporter) {
      return reporter;
    }

    if (reporterError) {
      console.log('teamError error: ', reporterError);
    }
  } catch (error) {
    console.log('getMyManagedProjects error', error, error.message);
  }
};

const deleteManagedProject = async (managedProjectID, projectName) => {};

export {
  addManagedProject,
  getManagedProjects,
  deleteManagedProject,
  getMyManagedProjects,
};
