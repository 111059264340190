/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_PROJECT,
  GET_PROJECTS,
  EDIT_PROJECT,
  DELETE_PROJECT,
  ACTIVATE_PROJECT,
  INACTIVATE_PROJECT,
} from '../types';

const INITIAL_STATE = {
  projects: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PROJECT:
      return { ...state };
    case GET_PROJECTS:
      return { ...state, projects: action.payload };
    case EDIT_PROJECT:
      return { ...state };
    case ACTIVATE_PROJECT:
      return { ...state };
    case INACTIVATE_PROJECT:
      return { ...state };
    case DELETE_PROJECT:
      return { ...state };
    default:
      return state;
  }
}
