import { MDBBtn } from 'mdb-react-ui-kit';
import React from 'react';

const ShowOrHideInactiveButton = ({ onClick, text }) => {
  return (
    <div className="d-flex justify-content-end align-items-center ">
      <div style={{ marginBottom: '-40px' }}>
        <MDBBtn
          style={{ fontSize: 16 }}
          color="transparent"
          onClick={onClick}
          className="d-flex justify-self-center align-self-center "
        >
          {text}
        </MDBBtn>
      </div>
    </div>
  );
};

export default ShowOrHideInactiveButton;
