import React from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyTable = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  style = { marginLeft: '29px', overflow: 'auto' },
}) => {
  const { analysDataDefault } = useSelector((state) => state.analys);
  // const tableData = {
  //   ...data,
  //   rendererName: 'Table',
  //   rows: preSelectedRows,
  //   cols: preSelectedCols,
  //   aggregatorName: aggregatorName,
  //   vals: vals,
  // };

  return (
    <div style={style}>
      <div style={{ marginLeft: '2px' }}>
        <PivotTableTitle title={title} />
      </div>
      <ReactPivotTable
        data={analysDataDefault}
        rows={preSelectedRows}
        cols={preSelectedCols}
        aggregatorName={aggregatorName}
        vals={vals}
        rendererName="Table"
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        valueFilter={data ? data.valueFilter : {}}
      />
    </div>
  );
};

export default PlotlyTable;
