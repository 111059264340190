import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomer,
  getCustomer,
  inactivateCustomer,
} from '../../../../redux/store/actions';

import { MDBContainer } from 'mdb-react-ui-kit';

import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditModal from '../../../../components/edit-modal';
import EditCustomerForm from './EditCustomerForm';
import { pixels } from '../../../../constants';

const EditCustomerModal = ({ handleClose, show, customerToEdit, mobile }) => {
  const [customer, setCustomer] = useState('');
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [fromDelete, setFromDelete] = useState(false);

  const [customerToDeleteOrActivate, setCustomerToDeleteOrActivate] =
    useState('');

  const confirmDeleteTitle = 'ta bort fråga';
  const confirmDeleteMessage = customerToDeleteOrActivate
    ? `Är du säker att du vill ta bort kund:  ${customerToDeleteOrActivate.customer_name}  ?`
    : '';
  const confirmInactivationTitle = 'inaktivera fråga';
  const confirmInactivationMessage = customerToDeleteOrActivate
    ? `Är du säker att du vill inaktivera kund:  ${customerToDeleteOrActivate.customer_name}  ?`
    : '';
  const confirmInactivationMessageFromDelete = customerToDeleteOrActivate
    ? `Kund ${customerToDeleteOrActivate.customer_name} har kopplingar till annan data och går inte att ta bort, vill du inaktivera frågan istället?`
    : '';

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setCustomer(customerToEdit);
  }, [customerToEdit]);

  const handleDeleteCustomer = (c) => {
    dispatch(deleteCustomer(c, user.digituals_client_id)).then(
      ({ payload }) => {
        dispatch(getCustomer(user.digituals_client_id));
        if (!payload) return;

        const { connected } = payload;
        if (connected) {
          setFromDelete(true);

          setShowInactivateModal(true);
        }
      }
    );
    setShowDeleteModal(false);
  };
  const handleInactivateCustomer = (c) => {
    dispatch(inactivateCustomer(c, user.digituals_client_id)).then(() =>
      dispatch(getCustomer(user.digituals_client_id))
    );
    setShowInactivateModal(false);
  };

  const handleResetAndClose = () => {
    handleClose();
  };

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteCustomer(customerToDeleteOrActivate)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() =>
          handleInactivateCustomer(customerToDeleteOrActivate)
        }
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />

      <EditModal
        show={show}
        handleClose={handleResetAndClose}
        fullscreen={mobile ? true : false}
        submitForm={'EditCustomerForm'}
        inactivate={() => {
          setCustomerToDeleteOrActivate(customer);
          setFromDelete(false);

          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setCustomerToDeleteOrActivate(customer);
          setShowDeleteModal(true);
        }}
        header="Redigera kund"
      >
        <MDBContainer
          className="mb-5 bg-transparent"
          style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}
        >
          {customer && (
            <EditCustomerForm
              handleClose={handleResetAndClose}
              customerToEdit={customer}
            />
          )}
        </MDBContainer>
      </EditModal>
    </>
  );
};

export default EditCustomerModal;
