/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustumDropdown from '../../../components/custom-dropdown';
import Listbox from '../../../components/listbox';
import ListboxRadio from '../../../components/listbox-radio';
import MultiSelectCheckboxes from '../../../components/multi-select-checkboxes';
import CustomDatePicker from '../../../components/custom-date-picker';

import {
  setOverviewDataSelectedQuestions,
  setOverviewDataActiveQuestions,
  setOverviewDataSelectedCustomers,
  setOverviewDataActiveCustomers,
  overviewDataSetSelectedDate,
  setSelectedDataFromFilter,
} from '../../../redux/store/actions';
import FloatingHoverButton from '../../../components/floating-hover-button';

const DashboardControls = ({
  projects,
  reporters,
  questions,
  customers,
  dates,
  activeOptionsCustomers,
  activeOptionsProjects,
  activeOptionsReporters,
  activeOptionsQuestions,
  handleSetValuesProject,
  handleSetValuesRepoters,
  handleSetActiveProject,
  handleSetActiveReporter,
  marginTop = '124px',
  handleResetFilter,
}) => {
  const [choosenFilter, setChoosenFilter] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (choosenFilter) {
      dispatch(setSelectedDataFromFilter(choosenFilter));
    }
  }, [choosenFilter]);

  const handleSetSelectedQuestions = (value) => {
    const filtredQuestions = value.filter((o) => o.value !== '*');

    setChoosenFilter((prev) => ({
      ...prev,
      Frågor: filtredQuestions.map((q) => q.label),
    }));

    const selectedQuestions = filtredQuestions.map(
      (question) => question.value
    );

    dispatch(setOverviewDataSelectedQuestions(selectedQuestions));
  };

  const handleSetActiveQuestions = (value) => {
    setChoosenFilter((prev) => ({
      ...prev,
      'Status Fråga': value,
    }));
    dispatch(setOverviewDataActiveQuestions(value));
  };

  const handleSetSelectedCustomers = (value) => {
    const filtredCustomers = value.filter((o) => o.value !== '*');

    setChoosenFilter((prev) => ({
      ...prev,
      Kund: filtredCustomers.map((customer) => customer.label),
    }));

    const selectedCustomers = filtredCustomers.map(
      (customer) => customer.value
    );
    dispatch(setOverviewDataSelectedCustomers(selectedCustomers));
  };

  const handleSetActiveCustomers = (value) => {
    setChoosenFilter((prev) => ({
      ...prev,
      'Status Kund': value,
    }));

    dispatch(setOverviewDataActiveCustomers(value));
  };

  function setValueProjects(value) {
    const filtredProjects = value.filter((o) => o.value !== '*');

    setChoosenFilter((prev) => ({
      ...prev,
      Projekt: filtredProjects.map((project) => project.label),
    }));

    handleSetValuesProject(value);
  }
  function setValueActiveProjects(value) {
    setChoosenFilter((prev) => ({
      ...prev,
      'Status Projekt': value,
    }));

    handleSetActiveProject(value);
  }

  function setValueReporters(value) {
    const filtredReporters = value.filter((o) => o.value !== '*');

    setChoosenFilter((prev) => ({
      ...prev,
      Rapportör: filtredReporters.map((reporter) => reporter.label),
    }));

    handleSetValuesRepoters(value);
  }
  function setValueActiveResporters(value) {
    setChoosenFilter((prev) => ({
      ...prev,
      'Status Rapportör': value,
    }));

    handleSetActiveReporter(value);
  }

  return (
    <div
      className="d-flex align-items-center justify-content-start flex-row bg-white fixed-top flex-wrap"
      style={{ marginTop: marginTop, marginLeft: 17 }}
    >
      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Kund'}
          options={customers}
          setValue={handleSetSelectedCustomers}
          resetFilter={resetFilter}
        />
      </div>
      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Aktiv Kund'}
          options={activeOptionsCustomers}
          setValue={handleSetActiveCustomers}
          setActive={true}
          resetFilter={resetFilter}
        />
      </div>

      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Projekt'}
          options={projects}
          setValue={setValueProjects}
          resetFilter={resetFilter}
        />
      </div>
      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Aktivt Projekt'}
          options={activeOptionsProjects}
          setValue={setValueActiveProjects}
          setActive={true}
          resetFilter={resetFilter}
        />
      </div>

      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Rapportör'}
          options={reporters}
          setValue={setValueReporters}
          resetFilter={resetFilter}
        />
      </div>

      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Aktiv Rapportör'}
          options={activeOptionsReporters}
          setValue={setValueActiveResporters}
          setActive={true}
          resetFilter={resetFilter}
        />
      </div>

      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Fråga'}
          options={questions}
          setValue={handleSetSelectedQuestions}
          resetFilter={resetFilter}
          menuWidth={500}
        />
      </div>
      <div className="px-3">
        <MultiSelectCheckboxes
          buttonLabel={'Aktiv Fråga'}
          options={activeOptionsQuestions}
          setValue={handleSetActiveQuestions}
          setActive={true}
          resetFilter={resetFilter}
        />
      </div>

      <FloatingHoverButton
        hoverText="Återställ filter"
        icon="redo"
        onClick={() => {
          setResetFilter((prev) => !prev);
          setChoosenFilter(null);
          dispatch(setSelectedDataFromFilter(null));
          return handleResetFilter();
        }}
      />
    </div>
  );
};

export default DashboardControls;
