/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getOperation } from '../../../redux/store/actions';

import EditOperationModal from './edit-operation-modal/EditOperationModal';
import { operationColumns, operationColumnsMobile } from './dataColumns';
import AddOperationModal from './add-operation-modal/AddOperationModal';
import ActiveDatatable from '../../../components/datatables/active-datatable';
import moment from 'moment';
import ShowOrHideInactiveButton from '../../../components/show-or-hide-inactive-button';

const OperationTable = ({ mobile }) => {
  const [showEditOperationModal, setShowEditOperationModal] = useState(false);
  const [operationToEdit, setOperationToEdit] = useState('');

  const [showAddOperationModal, setShowAddOperationModal] = useState(false);

  const [rows, setRows] = useState([]);

  const [showInactiveOperation, setShowInactiveOperation] = useState(false);

  const dispatch = useDispatch();
  const { operations } = useSelector((state) => state.operation);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!operations && user) {
      dispatch(getOperation(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (operations) {
      createRowsForDatasets();
    }
  }, [operations, showInactiveOperation]);

  const createRowsForDatasets = () => {
    const filtredOperations = operations.filter(
      ({ is_active }) => is_active === true
    );

    const operationsToMap = !showInactiveOperation
      ? filtredOperations
      : operations;

    const rows = operationsToMap.map((operation) => {
      const {
        operation_name,
        is_active,
        resource,
        operation_created_at,
        operation_updated_at,
      } = operation;

      const activeRecources = resource.filter((r) => r.is_active === true);

      return {
        clickEvent: () => handleClickRow(operation),
        operationName: operation_name,
        status: is_active ? 'Aktiv' : 'Inaktiv',
        numberOfResources: activeRecources ? activeRecources.length : 0,
        createdAt: moment(operation_created_at).format('YYYY-MM-DD'),
        updated: moment(operation_updated_at).format('YYYY-MM-DD'),
      };
    });

    setRows(rows);
  };

  const handleClickRow = (o) => {
    setOperationToEdit(o);
    setShowEditOperationModal(true);
  };

  const handleToggleShowActive = () =>
    setShowInactiveOperation(!showInactiveOperation);

  if (operations) {
    operations.sort(function (a, b) {
      var nameA = a.operation_name.toUpperCase();
      var nameB = b.operation_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <div className="mt-0 mb-5">
      {rows ? (
        <>
          {!mobile && (
            <ShowOrHideInactiveButton
              onClick={handleToggleShowActive}
              text={
                showInactiveOperation
                  ? 'Göm inaktiva avdelningar'
                  : 'Visa inaktiva avdelningar'
              }
            />
          )}

          <ActiveDatatable
            listKey={'OperationTable'}
            mobile={mobile}
            columns={mobile ? operationColumnsMobile : operationColumns}
            rows={rows}
            add={() => setShowAddOperationModal(true)}
          />
        </>
      ) : (
        <Spinner animation="border" />
      )}

      <EditOperationModal
        mobile={mobile}
        show={showEditOperationModal}
        operationToEdit={operationToEdit}
        handleClose={() => {
          setShowEditOperationModal(false);
          setOperationToEdit('');
        }}
      />
      <AddOperationModal
        mobile={mobile}
        handleClose={() => setShowAddOperationModal(false)}
        show={showAddOperationModal}
      />
    </div>
  );
};

export default OperationTable;
