export const operationColumns = [
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'Avdelning',
    field: 'operationName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Antal resurser',
    field: 'numberOfResources',
  },
  {
    label: 'Skapad',
    field: 'createdAt',
  },
  {
    label: 'Uppdaterad',
    field: 'updated',
  },
];
export const inactiveOperationColumns = [
  {
    label: 'avdelning',
    field: 'operationName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    // width: 100,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
      'align-items': 'center',
      'justify-content': 'center',
    },
  },
];
export const operationColumnsMobile = [
  {
    label: 'Avdelning',
    field: 'operationName',
  },
  {
    label: 'Status',
    field: 'status',
  },
];
export const inactiveOperationColumnsMobile = [
  {
    label: 'avdelning',
    field: 'operationName',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'aktivera',
    field: 'activate',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
export const questionColumnsMobile = [
  {
    label: 'fråga',
    field: 'question',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
];
