/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import {
  createSurvey,
  getQuestions,
  getSurveys,
} from '../../../../redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../../../../components/custom-select';
import { status, icons } from '../../../../constants';
import ModalIcon from '../../../../components/modal-icon';
import CollapseSection from '../../../../components/collapse-section';
import AddFromModalButton from '../../../../components/add-from-modal-button';
import QuestionList from '../QuestionList';
import AddQuestionToSurveyModal from '../add-question-to-survey-modal';

const AddSurveyForm = ({ handleClose, mobile }) => {
  const [showAddQuestionList, setShowAddQuestionList] = useState(false);

  const [showAddQuestionToSurveyModal, setShowAddQuestionToSurveyModal] =
    useState(false);

  const [enableValidate, setEnableValidate] = useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState('');

  const [questionOptions, setQuestionOptions] = useState('');
  const initialValues = {
    surveyName: '',
    description: '',
    status: status.ACTIVE,
    options: [1, 2, 3, 4],
  };

  const schema = Yup.object().shape({
    surveyName: Yup.string().required('Ange formulärnamn!'),
  });

  const statusOptions = [
    {
      value: status.ACTIVE,
      label: 'Aktiv',
    },
    {
      value: status.INACTIVE,
      label: 'Inaktiv',
    },
  ];

  const dispatch = useDispatch();

  const { questions } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!questions && user) {
      dispatch(getQuestions(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (questions) {
      const qOptions = questions.map(({ question, question_id }) => {
        return { value: question_id, label: question };
      });

      setQuestionOptions(qOptions);
    }
  }, [questions]);

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    const v = {
      ...values,
      status: status.ACTIVE === values.status ? true : false,
      questions: selectedQuestions,
    };

    dispatch(createSurvey(v, user.digituals_client_id)).then(() =>
      setTimeout(function () {
        dispatch(getSurveys(user.digituals_client_id));
        dispatch(getQuestions(user.digituals_client_id));
      }, 1000)
    );
    handleResetForm(resetForm);
    setSelectedQuestions('');

    handleClose();
  };

  const toggleShowAddQuestionList = () =>
    setShowAddQuestionList(!showAddQuestionList);

  const handleConnectQuestions = (questionsToConnect) => {
    setSelectedQuestions(questionsToConnect);
  };

  const handleRemoveQuestion = (questionID) => {
    const newQuestionList = selectedQuestions.filter(
      (q) => q.question_id !== questionID
    );

    setSelectedQuestions(newQuestionList.length > 0 ? newQuestionList : '');
  };

  const handleSetPostionValue = (value) => {
    setSelectedQuestions(value);
  };

  const handleGoBack = (resetForm) => {
    setShowAddQuestionList(false);
    setSelectedQuestions('');
    resetForm();
    handleClose();
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        initialValues={initialValues}
        validateOnChange={enableValidate}
        validateOnBlur={enableValidate}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          resetForm,
        }) => (
          <Form
            onReset={(e) => {
              e.preventDefault();
              return handleGoBack(resetForm);
            }}
            noValidate
            onSubmit={(e) => {
              setEnableValidate(true);
              e.preventDefault();
              return handleSubmit();
            }}
            id="AddSurveyForm"
          >
            <ModalIcon icon={icons.QUESTIONNAIRE} style={{ marginTop: '0%' }} />

            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Formulärnamn</Form.Label>
                <Form.Control
                  placeholder="Formulärnamn..."
                  type="text"
                  name="surveyName"
                  value={values.surveyName}
                  onChange={handleChange}
                  isValid={touched.surveyName && !errors.surveyName}
                  isInvalid={!!errors.surveyName}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.surveyName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Beskrivning</Form.Label>
                <Form.Control
                  as={'textarea'}
                  style={{ height: '100px' }}
                  placeholder="Skriv beskrivning..."
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isValid={touched.description && !errors.description}
                  isInvalid={!!errors.description}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary">Status</Form.Label>

                {statusOptions && (
                  <Field
                    component={CustomSelect}
                    placeholder="Välj status..."
                    name="status"
                    options={statusOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isValid={touched.status && !errors.status}
                    isInvalid={!!errors.status}
                  />
                )}

                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <CollapseSection
              isOpen={showAddQuestionList}
              label="Välj frågor"
              onClick={toggleShowAddQuestionList}
            >
              {selectedQuestions ? (
                <Row className="mb-4">
                  <Form.Group as={Col}>
                    <QuestionList
                      add={() => setShowAddQuestionToSurveyModal(true)}
                      removeQuestion={handleRemoveQuestion}
                      selectedQuestions={selectedQuestions}
                      setPostionValue={handleSetPostionValue}
                    />
                  </Form.Group>
                </Row>
              ) : (
                <Row className=" mb-4">
                  <Col className="d-flex align-items-center  justify-content-start ">
                    <AddFromModalButton
                      label={'Välj frågor'}
                      onClick={() => setShowAddQuestionToSurveyModal(true)}
                    />
                  </Col>
                </Row>
              )}
            </CollapseSection>
          </Form>
        )}
      </Formik>

      <AddQuestionToSurveyModal
        show={showAddQuestionToSurveyModal}
        handleClose={() => setShowAddQuestionToSurveyModal(false)}
        fullscreen={mobile}
        connectQuestions={handleConnectQuestions}
        selectedQuestions={selectedQuestions}
      />
    </>
  );
};

export default AddSurveyForm;
