import { supabase } from '../utils/supabase';
import Parse from '../utils/parse';
import { sendToast } from '../components/toast';

const addResource = async (resource, digitualsClientID) => {
  const {
    email,
    name,
    lastname,
    operation,
    createAccount,
    createAccountByInvite,
    changeEmail,
    newEmail,
    password,
    internalResource,
  } = resource;
  try {
    if (createAccount || createAccountByInvite) {
      const accountEmail = changeEmail ? newEmail : email;

      const { data: inviteData, error: inviteError } = createAccountByInvite
        ? await Parse.Cloud.run('invite', {
            email: accountEmail,
            SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
            SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
            redirectTo: 'http://localhost:3000/',
          })
        : await Parse.Cloud.run('createUser', {
            email: accountEmail,
            password: password,
            SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
            SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
          });

      console.log('inviteData', inviteData, 'inviteError: ', inviteError);

      if (inviteData) {
        const { id } = inviteData;

        sendToast(`Konto skapat för ${name} ${lastname}`, 1);

        const { error: profileError } = await supabase.from('profile').insert([
          {
            profile_id: id,
            profile_reporter: true,
            profile_role: 2,
            profile_email: changeEmail ? newEmail : email,
            profile_internal: internalResource,
            digituals_client_id: digitualsClientID,
          },
        ]);

        const { error: resourceError } = await supabase
          .from('resource')
          .insert([
            {
              resource_internal: internalResource,
              resource_firstname: name,
              resource_lastname: lastname,
              resource_email: email,
              resource_title: '',
              profile_id: id,
              operation_id: operation,
              digituals_client_id: digitualsClientID,
            },
          ]);

        console.log('resourceError', resourceError);

        if (profileError) {
          console.log('profileError: ', profileError);
        }
        if (resourceError) {
          console.log('resourceError: ', resourceError);
        }

        sendToast(`Konto skapat för ${name} ${lastname}!`, 1);

        sendToast(`${name} ${lastname} lades till som projektresurs`, 1);
      }

      if (inviteError) {
        const { status } = inviteError;

        if (status === 400) {
          sendToast(`${accountEmail} kontot finns redan!`, 2);
        }
        if (status === 422) {
          sendToast(`${accountEmail} invalid e-post adress eller lösenord!`, 2);
        }

        console.log('inviteError: ', inviteError);
      }
    } else {
      const { data, error } = await supabase.from('resource').insert([
        {
          resource_internal: internalResource,
          resource_firstname: name,
          resource_lastname: lastname,
          resource_email: email,
          resource_title: '',
          operation_id: operation,
          digituals_client_id: digitualsClientID,
        },
      ]);

      if (data) {
        sendToast(`${name} ${lastname} lades till som projektresurs`, 1);
      }
      if (error) {
        console.log('addresource error: ', error);
      }
    }
  } catch (error) {
    console.log('addresource error', error, error.message);
  }
};

const getResources = async (digitualsClientID) => {
  try {
    let { data: resource, error } = await supabase
      .from('resource')
      .select(
        `
    *,
    operation (
      * 
    ),
    team (
      * ,
      project (
        *
      )
    ),
    profile (
      *,
      reporter (
        *,
        project_setup (
          *,
          project (
            *
          )
        )
      )
    )
  `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (resource) {
      return resource;
    }
    if (error) {
      console.log('getresources error: ', error);
    }
  } catch (error) {
    console.log('getresources error', error, error.message);
  }
};
const getReporters = async (digitualsClientID) => {
  try {
    let { data: reporters, error } = await supabase
      .from('reporter')
      .select(
        `
    *,
        profile(
          *,
          resource (
            *
          )
        )
        ,
        response (
          *
        ),
        project_setup (
          *,
          project (
            *
          )
        )
  `
      )
      .eq('is_active', true)
      .eq('digituals_client_id', digitualsClientID);

    if (reporters) {
      return reporters;
    }
    if (error) {
      console.log('getReporters error: ', error);
    }
  } catch (error) {
    console.log('getReporters error', error, error.message);
  }
};

const editResource = async (resource, digitualsClientID) => {
  const {
    activateAccount,
    changeEmail,
    createAccount,
    email,
    inactivateAccount,
    lastname,
    name,
    newEmail,
    operation,
    createAccountByInvite,
    resource_id,
    profile_id,
    password,
    status,
    internalResource,
  } = resource;

  try {
    if (createAccount || createAccountByInvite) {
      const accountEmail = changeEmail ? newEmail : email;

      const { data: inviteData, error: inviteError } = createAccountByInvite
        ? await Parse.Cloud.run('invite', {
            email: accountEmail,
            SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
            SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
            redirectTo: 'http://localhost:3000/',
          })
        : await Parse.Cloud.run('createUser', {
            email: accountEmail,
            password: password,
            SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
            SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
          });

      if (inviteData) {
        const { id } = inviteData;

        sendToast(`Konto skapat för ${name} ${lastname}`, 1);

        const { error: profileError } = await supabase.from('profile').insert([
          {
            profile_id: id,
            profile_email: accountEmail,
            profile_reporter: true,
            profile_role: 2,
            profile_internal: internalResource,
            digituals_client_id: digitualsClientID,
          },
        ]);

        const { error: resourceError } = await supabase
          .from('resource')
          .update({
            resource_internal: internalResource,
            profile_id: id,
            resource_firstname: name,
            resource_lastname: lastname,
            resource_email: email,
            resource_title: '',
            operation_id: operation,
            is_active: status,
          })
          .eq('resource_id', resource_id)
          .eq('digituals_client_id', digitualsClientID);

        if (profileError) {
          console.log('profileError: ', profileError);
        }
        if (resourceError) {
          console.log('resourceError: ', resourceError);
        }
      }
      if (inviteError) {
        console.log('editresource error: ', inviteError);
        const { status } = inviteError;

        if (status === 422) {
          sendToast(`E-posten ogiltig, ange en annan e-post!`, 4);
        }

        if (status === 400) {
          sendToast(`Konto med e-post ${accountEmail} finns redan!`, 4);
        }
      }
    } else {
      if (inactivateAccount) {
        const { error: profileError } = await supabase
          .from('profile')
          .update({
            is_active: false,
          })
          .eq('profile_id', profile_id)
          .eq('digituals_client_id', digitualsClientID);

        if (profileError) {
          console.log('profileError:', profileError);
        }
      }

      if (activateAccount) {
        const { error: profileError } = await supabase
          .from('profile')
          .update({
            is_active: true,
          })
          .eq('profile_id', profile_id)
          .eq('digituals_client_id', digitualsClientID);

        if (profileError) {
          console.log('profileError:', profileError);
        }
      }

      const { data, error } = await supabase
        .from('resource')
        .update({
          resource_internal: internalResource,
          resource_firstname: name,
          resource_lastname: lastname,
          resource_email: email,
          resource_title: '',
          operation_id: operation,
          is_active: status,
        })
        .eq('resource_id', resource_id)
        .eq('digituals_client_id', digitualsClientID);

      if (data) {
        sendToast(`${name} ${lastname} har redigerats`, 1);
      }
      if (error) {
        console.log('editresource error: ', error);
      }
    }
  } catch (error) {
    console.log('editresource error', error, error.message);
  }
};
const activateResource = async (resource, digitualsClientID) => {
  const { resource_id, resource_firstname, resource_lastname, profile_id } =
    resource;
  try {
    const { data, error } = await supabase
      .from('resource')
      .update({
        is_active: true,
      })
      .eq('resource_id', resource_id)
      .eq('digituals_client_id', digitualsClientID);

    if (profile_id) {
      const { error: profileError } = await supabase
        .from('profile')
        .update({
          is_active: true,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      if (profileError) {
        console.log('profileError:', profileError);
      }
    }

    if (data) {
      sendToast(`${resource_firstname} ${resource_lastname}  aktiverad`, 1);
    }
    if (error) {
      console.log('activateresource Error:', error);
    }
  } catch (error) {
    console.log('activateresource error', error, error.message);
  }
};
const inactivateResource = async (resource, digitualsClientID) => {
  const { resource_id, resource_firstname, resource_lastname, profile_id } =
    resource;
  try {
    const { data, error } = await supabase
      .from('resource')
      .update({
        is_active: false,
      })
      .eq('resource_id', resource_id)
      .eq('digituals_client_id', digitualsClientID);

    if (profile_id) {
      const { error: profileError } = await supabase
        .from('profile')
        .update({
          is_active: false,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      if (profileError) {
        console.log('inactivateresource:', profileError);
      }
    }

    if (data) {
      sendToast(`${resource_firstname} ${resource_lastname}  inaktiverad`, 1);
    }
    if (error) {
      console.log('inactivateresource Error:', error);
    }
  } catch (error) {
    console.log('inactivateresource error', error, error.message);
  }
};

const deleteResource = async (resource, digitualsClientID) => {
  const {
    resource_firstname,
    resource_lastname,
    resource_id,
    profile_id,
    profile,
  } = resource;
  try {
    const { data: teamData, error: teamError } = await supabase
      .from('team')
      .select('*')
      .eq('resource_id', resource_id)
      .eq('digituals_client_id', digitualsClientID);

    const {
      data: projectTeamAtResponseData,
      error: projectTeamAtResponseError,
    } = await supabase
      .from('project_team_at_response')
      .select('*')
      .eq('resource_id', resource_id)
      .eq('digituals_client_id', digitualsClientID);

    let rData = [];

    if (profile_id) {
      const { data: reporterData, error: reporterError } = await supabase
        .from('reporter')
        .select('*')
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      if (reporterData) {
        rData = reporterData;
      }
      if (reporterError) {
        console.log('reporterError:', reporterError);
      }
    }

    if (
      teamData.length > 0 ||
      projectTeamAtResponseData.length > 0 ||
      rData.length > 0
    ) {
      // if (teamData || projectTeamAtResponseData || rData)
      return { connected: true };
    } else {
      const { error } = await supabase
        .from('resource')
        .delete()
        .eq('resource_id', resource_id)
        .eq('digituals_client_id', digitualsClientID);

      if (error) {
        console.log('deleteResource error: ', error);
      }

      if (profile_id) {
        const { error: profileError } = await supabase
          .from('profile')
          .delete()
          .eq('profile_id', profile_id)
          .eq('digituals_client_id', digitualsClientID);

        if (profileError) {
          console.log('profileError: ', profileError);
        }

        const { data: deleteUserData, error: deleteUserError } =
          await Parse.Cloud.run('deleteUser', {
            profile_id: profile_id,
            SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
            SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
          });

        if (deleteUserData) {
          sendToast(`Konto ${profile.profile_email}  borttaget!`, 2);
        }
        if (deleteUserError) {
          console.log('deleteUserError: ', deleteUserError);
        }
      }
    }

    if (projectTeamAtResponseError) {
      console.log(
        'deleteresource projectTeamAtResponseError:',
        projectTeamAtResponseError
      );
    }

    if (teamError) {
      console.log('deleteresource teamError:', teamError);
    }

    sendToast(`${resource_firstname} ${resource_lastname}  borttagen!`, 2);
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const deleteAccount = async (resource) => {
  const { resource_firstname, resource_lastname, profile_id } = resource;
  try {
    const { data: deleteUserData, error: deleteUserError } =
      await Parse.Cloud.run('deleteUser', {
        profile_id: profile_id,
        SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
      });

    if (deleteUserError) {
      console.log('deleteUserError: ', deleteUserError);

      const { status } = deleteUserError;

      if (status === 500) {
        return { connected: true };
        // sendToast(`Konto ${profile.profile_email} gick inte att ta bort!`, 2);
      }
    }
    if (deleteUserData) {
      sendToast(
        `Konto ${resource_firstname} ${resource_lastname}  borttaget!`,
        2
      );

      return { connected: false };
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const inactivateAccount = async (resource, digitualsClientID) => {
  const { resource_firstname, resource_lastname, profile_id } = resource;
  try {
    if (profile_id) {
      const { error: reporterError } = await supabase
        .from('reporter')
        .update({
          is_active: false,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      const { error: profileError } = await supabase
        .from('profile')
        .update({
          is_active: false,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      if (profileError) {
        console.log('profileError: ', profileError);
      }

      const { data: deleteUserData, error: deleteUserError } =
        await Parse.Cloud.run('deleteUser', {
          profile_id: profile_id,
          SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
          SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
        });

      if (deleteUserData) {
      }
      if (deleteUserError) {
        console.log('deleteUserError: ', deleteUserError);
      }

      if (reporterError) {
        console.log('deleteresource reporterError:', reporterError);
      }
    }

    sendToast(
      `${resource_firstname} ${resource_lastname}s konto inaktiverat`,
      2
    );
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};
const activateAccount = async (resource, digitualsClientID) => {
  const { resource_firstname, resource_lastname, profile_id } = resource;
  try {
    if (profile_id) {
      const { error: reporterError } = await supabase
        .from('reporter')
        .update({
          is_active: true,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      const { error: profileError } = await supabase
        .from('profile')
        .update({
          is_active: true,
        })
        .eq('profile_id', profile_id)
        .eq('digituals_client_id', digitualsClientID);

      if (profileError) {
        console.log('profileError: ', profileError);
      }

      if (reporterError) {
        console.log('deleteresource reporterError:', reporterError);
      }
    }

    sendToast(`${resource_firstname} ${resource_lastname}s konto aktiverat`, 1);
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const editAccount = async (resource, digitualsClientID) => {
  const { resource_firstname, resource_lastname, profile_id } =
    resource.resource;
  const { role } = resource.values;
  const { status } = resource;

  try {
    const { error: profileError } = await supabase
      .from('profile')
      .update({
        profile_role: role,
        is_active: status,
      })
      .eq('profile_id', profile_id)
      .eq('digituals_client_id', digitualsClientID);

    if (profileError) {
      console.log('profileError: ', profileError);
    }

    sendToast(
      `${resource_firstname} ${resource_lastname}s konto redigerats!`,
      1
    );
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const createAccount = async (r, digitualsClientID) => {
  const { email, password, role, resource } = r;

  const { resource_firstname, resource_lastname, resource_id } = resource;

  try {
    const { user: inviteData, error: inviteError } = await Parse.Cloud.run(
      'createUser',
      {
        email: email,
        password: password,
        SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
      }
    );

    // if (inviteData) {
    //   console.log('createUserData: ', inviteData);
    // }

    // if (inviteError) {
    //   console.log('createUserError: ', inviteError);
    // }

    // const { data: inviteData, error: inviteError } = await Parse.Cloud.run(
    //   'invite',
    //   { email: email }
    // );

    if (inviteError) {
      console.log('inviteError: ', inviteError);

      const { status } = inviteError;

      if (status === 422) {
        const toastText = 'Invalid e-post!';

        sendToast(toastText, 2);
      }
      if (status === 400) {
        const toastText = 'Användare redan registrerad!';

        sendToast(toastText, 2);
      }
    }

    if (inviteData) {
      const { id } = inviteData;

      sendToast(
        `konto skapat för ${resource_firstname} ${resource_lastname}`,
        1
      );

      const { error: profileError } = await supabase.from('profile').insert([
        {
          profile_id: id,
          profile_reporter: true,
          profile_role: role,

          profile_email: email,
          profile_internal: true,
          digituals_client_id: digitualsClientID,
        },
      ]);

      const { error: resourceError } = await supabase
        .from('resource')
        .update({
          profile_id: id,
        })
        .eq('resource_id', resource_id)
        .eq('digituals_client_id', digitualsClientID);

      console.log('resourceError', resourceError);

      if (profileError) {
        console.log('profileError: ', profileError);
      }
      if (resourceError) {
        console.log('resourceError: ', resourceError);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};
// const createAccount = async (r) => {
//   const { email, role, resource } = r;

//   const { resource_firstname, resource_lastname, resource_id } = resource;

//   try {

//     const { data: inviteData, error: inviteError } = await Parse.Cloud.run(
//       'invite',
//       { email: email }
//     );

//     if (inviteError) {
//       console.log('inviteError: ', inviteError);

//       const { status } = inviteError;

//       if (status === 422) {
//         const toastText = 'invalid e-post!';

//         sendToast(toastText, 2);
//       }
//     }

//     if (inviteData) {
//       const { id } = inviteData;

//       sendToast(
//         `konto skapat för ${resource_firstname} ${resource_lastname}`,
//         1
//       );

//       console.log('addresource: ', inviteData);

//       const { error: profileError } = await supabase.from('profile').insert([
//         {
//           profile_id: id,
//           profile_reporter: true,
//           profile_role: role,

//           profile_email: email,
//           profile_internal: true,
//           digituals_client_id: DIGITUALS_CLIENT_ID,
//         },
//       ]);

//       const { error: resourceError } = await supabase
//         .from('resource')
//         .update({
//           profile_id: id,
//         })
//         .eq('resource_id', resource_id)
//         .eq('digituals_client_id', DIGITUALS_CLIENT_ID);

//       console.log('resourceError', resourceError);

//       if (profileError) {
//         console.log('profileError: ', profileError);
//       }
//       if (resourceError) {
//         console.log('resourceError: ', resourceError);
//       }
//     }
//   } catch (error) {
//     console.log('deleteOperation error', error, error.message);
//   }
// };

const addResourceToProject = async (projects, resource, digitualsClientID) => {
  const { resource_firstname, resource_lastname, resource_id } = resource;

  try {
    if (projects) {
      const { error: tError } = await supabase
        .from('team')
        .delete()
        .eq('resource_id', resource_id)
        .eq('digituals_client_id', digitualsClientID);

      if (tError) {
        console.log('teamError: ', tError);
      }

      const teamsToCreate = [];

      projects.forEach((p) => {
        const { project_id } = p;

        const t = {
          resource_id: resource_id,
          team_projectleader: false,
          project_id: project_id,
          digituals_client_id: digitualsClientID,
        };

        teamsToCreate.push(t);
      });

      const { data: teamData, error: teamError } = await supabase
        .from('team')
        .insert(teamsToCreate);

      if (teamData) {
        sendToast(
          `${resource_firstname} ${resource_lastname}s har lagts till i valda projekt!`,
          1
        );
      }
      if (teamError) {
        console.log('teamError: ', teamError);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const removeResourceToProject = async (
  project,
  resource,
  digitualsClientID
) => {
  const { resource_firstname, resource_lastname, resource_id } = resource;
  const { project_id, project_name } = project;

  try {
    if (project && resource) {
      const { data: teamData, error: teamError } = await supabase
        .from('team')
        .delete()
        .eq('project_id', project_id)
        .eq('resource_id', resource_id)
        .eq('digituals_client_id', digitualsClientID);

      if (teamData) {
        sendToast(
          `${resource_firstname} ${resource_lastname}s har tagits bort från projekt ${project_name}!`,
          4
        );
      }
      if (teamError) {
        console.log('teamError: ', teamError);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};
const removeResourceFromOperation = async (
  operation,
  resource,
  digitualsClientID
) => {
  const { resource_firstname, resource_lastname, resource_id } = resource;
  const { operation_name } = operation;

  try {
    if (operation && resource) {
      const { data: resourceData, error: resourceError } = await supabase
        .from('resource')
        .update({
          operation_id: '5cb601b0-e1b1-4812-94d2-fa7fcc1687c7',
        })
        .eq('resource_id', resource_id);
      // .eq('digituals_client_id', digitualsClientID);

      if (resourceData) {
        sendToast(
          `${resource_firstname} ${resource_lastname}s har tagits bort från avdelning ${operation_name}!`,
          4
        );
      }
      if (resourceError) {
        console.log('teamError: ', resourceError);
      }
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const addResourceToOperation = async (
  operation,
  resources,
  digitualsClientID
) => {
  const { operation_name, operation_id } = operation;

  try {
    if (operation && resources) {
      resources.forEach(async (resource) => {
        const { resource_id } = resource;

        const { data: resourceData, error: resourceError } = await supabase
          .from('resource')
          .update({
            operation_id: operation_id,
          })
          .eq('resource_id', resource_id)
          .eq('digituals_client_id', digitualsClientID);

        if (resourceData) {
        }
        if (resourceError) {
          console.log('teamError: ', resourceError);
        }
      });

      sendToast(
        `Valda resurser har lagts till avdelning ${operation_name}!`,
        1
      );
    }
  } catch (error) {
    console.log('deleteOperation error', error, error.message);
  }
};

const getAccounts = async () => {
  try {
    const { data: getUserData, error: getUserError } = await Parse.Cloud.run(
      'getUsers',
      {
        SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        SUPABASE_SERVICE_KEY: process.env.REACT_APP_SUPABASE_SERVICE_KEY,
      }
    );

    if (getUserData) {
      return getUserData;
    }

    if (getUserError) {
      console.log('getUserError: ', getUserError);
    }
  } catch (error) {
    console.log('getAccountsError', error);
  }
};

export {
  addResource,
  getResources,
  editResource,
  deleteResource,
  activateResource,
  inactivateResource,
  deleteAccount,
  inactivateAccount,
  activateAccount,
  editAccount,
  addResourceToProject,
  removeResourceToProject,
  removeResourceFromOperation,
  addResourceToOperation,
  createAccount,
  getReporters,
  getAccounts,
};
