/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCheckbox } from 'mdb-react-ui-kit';
import AddList from '../../../../components/add-list';
import AddModal from '../../../../components/add-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  addResourceToOperation,
  getResources,
} from '../../../../redux/store/actions';
import { pixels } from '../../../../constants';

const AddResourceToOperationModal = ({
  currentResources,
  operationToEdit,
  handleClose,
  show,
  fullscreen,
  done,
}) => {
  // const [selectedProjects, setSelectedProjects] = useState([]);
  // const [availableProjects, setAvailableProjects] = useState([]);
  const [availableResources, setAvailableResources] = useState([]);

  const [checked, setChecked] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const columns = [
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>Resurs</p>,
      field: 'resource',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: <p style={{ cursor: 'pointer', float: 'left' }}>E-post</p>,
      field: 'email',
      // width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: '',

      field: 'add',
      sort: 'disabled',

      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { resources } = useSelector((state) => state.resources);

  useEffect(() => {
    if (!resources && user) {
      dispatch(getResources(user.digituals_client_id));
    }
  }, []);

  useEffect(() => {
    if (resources) {
      createRowsForList();
    }
  }, [resources, currentResources]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // const selects = () => {
  //   var selec = document.getElementsByName('selectedProjects');
  //   for (var i = 0; i < selec.length; i++) {
  //     if (selec[i].type === 'checkbox') selec[i].checked = true;
  //   }

  //   setSelectedProjects(availableProjects);
  // };
  // const deSelect = () => {
  //   var selec = document.getElementsByName('selectedProjects');
  //   for (var i = 0; i < selec.length; i++) {
  //     if (selec[i].type === 'checkbox') selec[i].checked = false;
  //   }
  //   setSelectedProjects([]);
  // };

  const createRowsForList = () => {
    const availableR = [];
    resources.forEach((value) => {
      const { is_active, resource_id } = value;
      if (is_active === true) {
        let addToList = true;
        currentResources.forEach((resource) => {
          if (resource_id === resource) {
            addToList = false;
          }
        });
        if (addToList) {
          availableR.push(value);
        } else {
          return;
        }
      }
    });
    setAvailableResources(availableR);
  };

  const handleAddResourceToProject = () => {
    const resourcesToAdd = resources.filter((x) =>
      checked.includes(x.resource_id)
    );

    dispatch(
      addResourceToOperation(
        operationToEdit,
        resourcesToAdd,
        user.digituals_client_id
      )
    );
    handleClose();
  };

  return (
    <>
      <AddModal
        show={show}
        handleClose={handleClose}
        fullscreen={fullscreen}
        done={handleAddResourceToProject}
        centered={true}
        header="Lägg till resurs"
      >
        <MDBContainer style={{ maxWidth: pixels.MODAL_LIST_WIDTH }}>
          {availableResources.length > 0 ? (
            <AddList
              listKey={'AddResourceToOperationModal'}
              columns={columns}
              rows={availableResources.map((res) => {
                const {
                  resource_firstname,
                  resource_lastname,
                  resource_id,
                  resource_email,
                } = res;

                return {
                  resource: `${resource_firstname} ${resource_lastname}`,
                  email: resource_email,
                  add: (
                    <MDBCheckbox
                      key={resource_id}
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginRight: 8,
                      }}
                      name="selectedProjects"
                      value={resource_id}
                      className="text-primary"
                      onChange={handleCheck}
                    />
                  ),
                };
              })}
            />
          ) : null}
        </MDBContainer>
      </AddModal>
    </>
  );
};

export default AddResourceToOperationModal;
