import React from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import { pixels } from '../../constants';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyGroupedColumnChart = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  showlegend,
  fixedRange,
  style = { marginLeft: '31px' },
}) => {
  const { analysDataDefault } = useSelector((state) => state.analys);

  return (
    <>
      <div style={style}>
        <div className="justify-content-center flex-column align-items-center  rounded ">
          <PivotTableTitle title={title} />
          <div style={{ overflow: 'auto' }}>
            <ReactPivotTable
              data={analysDataDefault}
              rows={preSelectedRows}
              cols={preSelectedCols}
              aggregatorName={aggregatorName}
              vals={vals}
              rendererName="Grouped Column Chart"
              renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
              plotlyOptions={{
                width: pixels.ANALYS_PLOTLY_WIDTH,
                // height: 500,
                // bargap: 1.01,
                title: '',
                showlegend: showlegend ? true : false,

                // paper_bgcolor: '#dfe6e7ad',
                // plot_bgcolor: '#dfe6e7ad',

                xaxis: {
                  zeroline: true,
                  range: fixedRange ? [0.2, 4.8] : '',
                  fixedrange: fixedRange ? true : false,

                  dtick: 1,
                },
                yaxis: {
                  title: '',
                  // color: 'rgb(142,124,195)',
                  // tickformat: '%',
                },
                colorway: [
                  '#6ba0ac',
                  '#79a9b4',
                  '#88b3bc',
                  '#97bcc4',
                  '#a6c6cd',
                  '#b5cfd5',
                  '#c3d9dd',
                  '#d2e2e6',
                  '#e1ecee',
                  '#f0f5f6',
                ],
              }}
              plotlyConfig={{ responsive: true }}
              valueFilter={data ? data.valueFilter : {}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlotlyGroupedColumnChart;
