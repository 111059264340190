import React, { useRef } from 'react';
import ReactPivotTable from 'react-pivottable/PivotTable';
import { useReactToPrint } from 'react-to-print';
import 'react-pivottable/pivottable.css';

import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { useSelector } from 'react-redux';
import PivotTableTitle from '../pivot-table-title';
import ChoosenFilters from '../print-components/ChoosenFilters';
import { ComponentToPrint } from '../component-to-print';
import DateUserTitle from '../print-components/DateUserTitle';
import FloatingHoverButton from '../floating-hover-button';
import { colors, pixels } from '../../constants';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const PlotlyGroupedBarChart = ({
  data,
  preSelectedCols,
  preSelectedRows,
  aggregatorName,
  vals,
  title,
  showlegend,
  fixedrange,
  range,
  usePrint = false,
  style = { marginLeft: '31px' },
}) => {
  const { analysDataDefault } = useSelector((state) => state.analys);
  const { user } = useSelector((state) => state.auth);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={style}>
        <ComponentToPrint ref={componentRef}>
          <div className="justify-content-center flex-column align-items-center p-0 rounded m-0">
            <div className="hide-when-printing">
              <PivotTableTitle title={title} />
            </div>
            <div className="show-when-printing">
              <DateUserTitle user={user} title={title} />
            </div>
            <div style={{ overflow: 'auto' }}>
              <ReactPivotTable
                data={analysDataDefault}
                rows={preSelectedRows}
                cols={preSelectedCols}
                aggregatorName={aggregatorName}
                vals={vals}
                rendererName="Grouped Bar Chart"
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                rowOrder="value_a_to_z"
                plotlyOptions={{
                  width: pixels.ANALYS_PLOTLY_WIDTH,
                  // height: 'auto',
                  colorway: [colors.PRIMARY],
                  hoverlabel: {
                    bgcolor: '#FFF',
                    namelength: 100,
                    font: {
                      family: 'Roboto',
                      color: '#000',
                    },
                  },
                  title: '',
                  showlegend: showlegend ? true : false,
                  xaxis: {
                    title: '',
                    zeroline: true,
                    range: fixedrange ? range : null,
                    fixedrange: fixedrange ? true : false,
                    dtick: 1,
                  },
                }}
                plotlyConfig={{ responsive: true }}
                valueFilter={data ? data.valueFilter : {}}
              />
            </div>
          </div>
          <div className="page-break" />
          <div className="show-when-printing d-felx align-items-center justify-content-center">
            <ChoosenFilters
              data={data ? data.valueFilter : {}}
              defaultData={analysDataDefault}
            />
          </div>
        </ComponentToPrint>
      </div>
      {usePrint && (
        <>
          <FloatingHoverButton
            hoverText="Skriv ut graf"
            icon="print"
            onClick={() => handlePrint()}
            top="82vh"
          />
        </>
      )}
    </>
  );
};

export default PlotlyGroupedBarChart;
