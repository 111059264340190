/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { colors, fontFamily } from '../../constants';
import {
  calculateAvrageResponseFromProjectsOverWeeks,
  getUniqueProjects,
} from '../../pages/analys/calculate-functions';

function LineChartOverview({
  lineChartData,
  width = 800,
  height = 550,
  title = '',
  style,
}) {
  const [data, setData] = useState([]);
  let { xData, yData } =
    calculateAvrageResponseFromProjectsOverWeeks(lineChartData);

  useEffect(() => {
    setData([
      {
        x: xData.length > 0 ? xData : [1],
        y: yData.length > 0 ? yData : [1],
        name: '',
        type: 'scatter',
        mode: 'lines',
        marker: { color: colors.PRIMARY },
        line: { shape: 'spline', width: 3 },
      },
      {
        x: [xData[0], xData[xData.length - 1]],
        y: [yData[0], yData[yData.length - 1]],
        name: '',
        type: 'scatter',
        mode: 'markers',
        marker: { color: colors.PRIMARY, size: 12 },
      },
      // {
      //   x: xData,
      //   y: yDataResponse,
      //   name: 'Respons',
      //   type: 'scatter',
      //   mode: 'lines',
      //   marker: { color: colors.DASHBOARD },
      //   line: { shape: 'spline', width: 3 },
      // },
      // {
      //   x: [xData[0], xData[xData.length - 1]],
      //   y: [yDataResponse[0], yDataResponse[yDataResponse.length - 1]],
      //   name: 'Respons',
      //   type: 'scatter',
      //   mode: 'markers',
      //   marker: { color: colors.DASHBOARD, size: 12 },
      // },
    ]);
  }, [lineChartData]);

  var layout = {
    showlegend: false,
    height: height,
    width: width,

    xaxis: {
      fixedrange: true,
      showline: true,
      showgrid: false,
      showticklabels: true,
      linecolor: colors.DASHBOARD,
      linewidth: 2,
      autotick: false,
      ticks: 'outside',
      tickcolor: colors.DASHBOARD,
      tickwidth: 2,
      ticklen: 5,
      tickfont: {
        family: fontFamily.PRIMARY,
        size: 12,
        color: colors.SECONDARY,
      },
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      zeroline: false,
      showline: false,
      showticklabels: false,
    },
    autosize: false,
    margin: {
      autoexpand: false,
      l: 100,
      r: 20,
      t: 100,
    },
    annotations: [
      {
        xref: 'paper',
        yref: 'paper',
        x: xData.length > 0 ? 0.331 : 0.45,
        y: 1.08,
        xanchor: 'left',
        yanchor: 'bottom',
        text: xData.length > 0 ? title : 'Ingen data...',
        font: {
          family: fontFamily.PRIMARY,
          size: 14,
          color: 'rgb(37,37,37)',
        },
        showarrow: false,
      },

      {
        xref: 'paper',
        x: 0.05,
        y: yData[0],
        xanchor: 'right',
        yanchor: 'right',
        text: yData[0] ? yData[0].toFixed(2) : '',
        // text: 'Projekt' + ' ' + yData[0],
        showarrow: false,
        font: {
          family: fontFamily.PRIMARY,
          size: 14,
          color: 'black',
        },
      },
      {
        xref: 'paper',
        x: 0.95,
        y: yData[yData.length - 1],
        xanchor: 'left',
        yanchor: 'middle',
        text: yData[yData.length - 1] ? yData[yData.length - 1].toFixed(2) : '',
        font: {
          family: fontFamily.PRIMARY,
          size: 14,
          color: 'black',
        },
        showarrow: false,
      },
      {
        xref: 'paper',
        yref: 'paper',
        x: 0.5,
        y: -0.1,
        xanchor: 'center',
        yanchor: 'top',
        text: 'Kalendervecka',
        showarrow: false,
        font: {
          family: fontFamily.PRIMARY,
          size: 12,
          color: 'rgb(150,150,150)',
        },
      },
      // {
      //   xref: 'paper',
      //   x: 0.05,
      //   y: yDataResponse[0],
      //   xanchor: 'right',
      //   yanchor: 'right',
      //   text: 'Respons' + ' ' + yDataResponse[0],
      //   showarrow: false,
      //   font: {
      //     family: fontFamily.PRIMARY,
      //     size: 16,
      //     color: 'black',
      //   },
      // },
      // {
      //   xref: 'paper',
      //   x: 0.95,
      //   y: yDataResponse[yDataResponse.length - 1],
      //   xanchor: 'left',
      //   yanchor: 'middle',
      //   text: yDataResponse[yDataResponse.length - 1],
      //   font: {
      //     family: fontFamily.PRIMARY,
      //     size: 16,
      //     color: 'black',
      //   },
      //   showarrow: false,
      // },
    ],
  };

  return (
    <div style={style}>
      <Plot
        data={data}
        layout={layout}
        config={{ displayModeBar: false, scrollZoom: false }}
      />
    </div>
  );
}

export default LineChartOverview;

//   var colors = [
//     '#60909a',
//     '#b5cfd5',
//     '#558089',
//     '#d2e2e6',
//     '#4a7078',

//     '#c3d9dd',
//     '#88b3bc',
//     '#406067',
//     '#a6c6cd',
//     '#355056',
//     '#97bcc4',

//     '#6ba0ac',
//     '#79a9b4',
//   ];
