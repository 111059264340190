/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';

import { useSelector } from 'react-redux';

import CanRunSurveyList from './CanRunSurveyList';

import Select from 'react-select';

const EditManageProjectModal = ({
  handleClose,
  show,
  managedProjectToEdit,
  // defaultSelectorValue,
}) => {
  const [resourcesWithAccount, setresourcesWithAccount] = useState([]);
  const [managedProject, setManagedProject] = useState(managedProjectToEdit);

  const { resources } = useSelector((state) => state.resources);
  const { projects } = useSelector((state) => state.projects);

  let projectLeaders;
  let workers;

  useEffect(() => {
    setManagedProject(managedProjectToEdit);
    countresourcesWithAccount();
  }, [managedProjectToEdit]);
  useEffect(() => {
    countresourcesWithAccount();
  }, [managedProject]);

  const handleEditManagedProject = () => {};

  const handleSetSelectedProjects = (selectedresourcesWithAccount) => {
    setManagedProject({
      ...managedProject,
      canRunSurvey: selectedresourcesWithAccount,
    });
  };

  const countresourcesWithAccount = () => {
    let temp = [];

    if (managedProject.projectLeader) {
      if (managedProject.projectLeader.value.haveAccount) {
        temp.push(managedProject.projectLeader.value);
      }
    }

    if (managedProject.resources[0]) {
      const withAccount = managedProject.resources.filter(
        (resource) => resource.value.haveAccount === true
      );

      withAccount.forEach((resource) => {
        temp.push(resource.value);
      });
    }

    setresourcesWithAccount(temp);
  };

  if (resources) {
    const withAccount = resources.filter(
      (resource) => resource.haveAccount === true && resource.role === 2
    );
    const withoutAccount = resources.filter(
      (resource) => resource.haveAccount !== true
    );

    projectLeaders = withAccount;
    workers = withoutAccount;
  }

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>redigera hanterat projekt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resources && projects ? (
          <Form>
            <Row>
              <Col xs={7}>
                <Form.Label className="text-secondary">projekt</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={managedProject.project.projectName}
                  aria-label="Disabled input example"
                  disabled
                  readOnly
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Form.Label className="text-secondary">
                välj projektledare
              </Form.Label>
              <Select
                placeholder="Välj projektledare..."
                // defaultValue={managedProject.projectLeader}
                value={
                  managedProject.projectLeader
                    ? {
                        value: managedProject.projectLeader.value,
                        label: managedProject.projectLeader.value.name,
                      }
                    : ''
                }
                onChange={(value) => {
                  setManagedProject({
                    ...managedProject,
                    projectLeader: value,
                  });
                }}
                name="colors"
                options={projectLeaders.map((projectLeader) => {
                  return { value: projectLeader, label: projectLeader.name };
                })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Row>

            <br />
            <Row>
              <Form.Label className="text-secondary">
                välj team medlemmar (valfritt)
              </Form.Label>
              <Select
                placeholder="Välj team medlemmar..."
                value={managedProject.resources}
                onChange={(value) => {
                  setManagedProject({ ...managedProject, resources: value });
                }}
                isMulti
                name="colors"
                options={workers.map((worker) => {
                  return {
                    value: worker,
                    label: worker.name,
                  };
                })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Row>
            <br />
            <br />
            {resourcesWithAccount[0] && (
              <Row>
                <Form.Label className="text-secondary">
                  ska lämna respons
                </Form.Label>
                <CanRunSurveyList
                  resourcesWithAccount={resourcesWithAccount}
                  selectedresourcesWithAccount={handleSetSelectedProjects}
                  checkedresources={managedProject.canRunSurvey}
                />
              </Row>
            )}
            <br />
            <br />

            <Button variant="primary" onClick={handleEditManagedProject}>
              ändra
            </Button>
          </Form>
        ) : (
          <Spinner animation="border" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManageProjectModal;
