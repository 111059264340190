export const resourceColumns = [
  {
    label: '',
    field: '',
    sort: 'disabled',
  },
  {
    label: 'Förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Avdelning',
    field: 'operation',
    // width: 270,
  },
  {
    label: 'E-post',
    field: 'email',
    // width: 270,
  },
  {
    label: 'Projekt',
    field: 'numberOfProjects',
    // width: 270,
  },
  {
    label: 'Intern/extern',
    field: 'internalResource',
    // width: 270,
  },
  {
    label: 'Status',
    field: 'status',
    // width: 270,
  },
  {
    label: 'Konto',
    field: 'haveAccount',
    sort: 'disabled',
    // width: 270,
  },

  // {
  //   label: 'redigera',
  //   field: 'edit',
  //   // width: 100,
  // },
  // {
  //   label: 'ta bort',
  //   field: 'delete',
  //   sort: 'disabled',
  //   // width: 150,
  // },
];

export const inactiveResourceColumns = [
  {
    label: 'förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'avdelning',
    field: 'operation',
    // width: 270,
  },
  {
    label: 'e-post',
    field: 'email',
    // width: 270,
  },

  {
    label: 'aktivera',
    field: 'activate',
    sort: 'disabled',
    // width: 150,
  },
];
export const inactiveResourceColumnsMobile = [
  {
    label: 'förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },

  {
    label: 'aktivera',
    field: 'activate',
    sort: 'disabled',
    // width: 150,
  },
];
export const resourceColumnsMobile = [
  {
    label: 'Förnamn',
    field: 'name',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Efternamn',
    field: 'lastname',
    // width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'Konto',
    field: 'haveAccount',
    // width: 270,
  },
];
