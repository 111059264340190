/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

export default function ChoosenFilters({ data, defaultData }) {
  const [dataUsedToFilter, setDataUsedToFilter] = useState([]);

  const filterKeys = Object.keys(data ? data : {});

  const style = {
    title: {
      fontSize: 12,
      minWidth: 130,
      fontWeight: 'bold',
    },
    li: {
      fontSize: 12,
    },
  };

  useEffect(() => {
    if (data && defaultData) {
      setDataUsedToFilter(filterData(data, defaultData));
    }
  }, [data, defaultData]);

  function filterData(data, defaultData) {
    let sortedData = {};

    filterKeys.forEach((key) => {
      const titleKeys = Object.keys(data[key] ? data[key] : {});
      const uniqueVal = getUniqueValues(defaultData, key);
      let dataToPush = [];

      uniqueVal.forEach((val) => {
        if (!titleKeys.includes(val)) {
          dataToPush.push(val);
        }
      });
      if (dataToPush.length > 0) {
        sortedData[key] = dataToPush;
      }
    });

    return sortedData;
  }

  function getUniqueValues(data, key) {
    let uniqueValues = [];
    data.forEach((item) => {
      if (!uniqueValues.includes(item[key])) {
        uniqueValues.push(item[key]);
      }
    });
    return uniqueValues;
  }

  return (
    <div className="d-flex align-items-center align-self-center flex-column mx-5">
      <div
        style={{
          marginTop: 0,
          marginBottom: 40,
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        Använda filter för grafen
      </div>
      <div className="d-flex flex-column align-self-center">
        {filterKeys.length > 0 && dataUsedToFilter && (
          <>
            {filterKeys.map((title) => {
              return (
                <div key={title} className="me-5 d-flex flex-row  ">
                  <p className="d-flex align-self-start" style={style.title}>
                    {title}:
                  </p>
                  <div className="d-flex flex-row flex-wrap">
                    {dataUsedToFilter[title]
                      ? dataUsedToFilter[title].map((item) => {
                          return (
                            <p
                              key={item}
                              style={style.li}
                              className="mx-3 d-flex align-self-center"
                            >
                              -{item}
                            </p>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
