/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {
  loginUser,
  resetPassword,
  addResource,
} from '../../redux/store/actions';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import * as Yup from 'yup';
import toast from 'react-hot-toast';

const LoginForm = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const initialValues = {
    password: '',
    email: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string().required('Ange e-post!'),
    // password: Yup.string().required('Ange lösenord!'),
  });

  const dispatch = useDispatch();

  const handleResetForm = (resetForm) => {
    resetForm({});
  };

  const handleSubmit = (values, resetForm) => {
    // const r = {
    //   email: 'superuser@glbtest.se',
    //   password: '@blg!Lulea1',
    //   name: 'superuser',
    //   lastname: 'test',
    //   role: 1,
    //   createAccount: true,
    //   operation: '4a328ae1-5ecd-4a4c-ac51-a56d49da681e',
    //   internalResource: true,
    // };
    // const f = {
    //   email: 'superuser@glbprod.se',
    //   password: '@blg!Lulea2',
    //   name: 'superuser',
    //   lastname: 'prod',
    //   role: 1,
    //   createAccount: true,
    //   operation: 'e2948760-5475-4882-bcb3-dea2591b41d2',
    //   internalResource: true,
    // };
    // const f = {
    //   email: 'karlssonr1989@gmail.com',
    //   password: 'test123',
    //   name: 'Robin',
    //   lastname: 'Karlsson',
    //   role: 2,
    //   createAccount: true,
    //   operation: '9ccc3f23-19ce-43b9-bda5-e425f8205bf3',
    //   internalResource: true,
    // };

    // const digitualsClientID = '628dce2a-aea5-4519-99ec-cd9a0ca30df9';
    // dispatch(addResource(f, digitualsClientID));

    if (forgotPassword) {
      dispatch(resetPassword(values.email));
    } else {
      dispatch(loginUser(values));
    }

    handleResetForm(resetForm);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit} id="LoginForm">
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label className="text-white">E-post</Form.Label>
              <Form.Control
                placeholder="E-post..."
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {forgotPassword ? null : (
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label className="text-white">Lösenord</Form.Label>
                <Form.Control
                  placeholder="Lösenord..."
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                  isInvalid={!!errors.password}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}

          <Form.Group className="mt-1 mb-3 text-white">
            <p style={{ fontSize: 12 }}>
              {forgotPassword
                ? 'Gå till login'
                : 'Glömt dina användaruppgifter?'}{' '}
              <span
                style={{ cursor: 'pointer' }}
                className="font-weight-bold text-white"
                onClick={() => {
                  setForgotPassword((prev) => !prev);
                }}
              >
                Klicka här
              </span>
            </p>
          </Form.Group>

          <Form.Group
            className="mb-3 d-flex d-flex justify-content-end"
            controlId="formBasicLastname"
          >
            <span
              className="font-weight-bold text-white"
              onClick={handleSubmit}
              style={{ cursor: 'pointer' }}
            >
              {forgotPassword ? 'Återställ lösenord' : 'Logga in'}
            </span>
            {/* <Button
              style={{ }}
              size="lg"
              variant="transparent"
              onClick={handleSubmit}
              className="d-flex d-flex justify-content-end text-white "
            >
              
            </Button> */}
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
