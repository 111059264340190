import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProject,
  getProjects,
  inactivateProject,
} from '../../../../redux/store/actions';
import DeleteConfirmation from '../../../../components/confirm-modal/DeleteConfirmation';
import InactivateConfirmation from '../../../../components/confirm-modal/InactivateConfirmation';
import EditModal from '../../../../components/edit-modal';
import EditProjectForm from './EditProjectForm';

const EditProjectModal = ({ handleClose, show, projectToEdit, mobile }) => {
  const [project, setProject] = useState('');
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [fromDelete, setFromDelete] = useState(false);

  const [projectToDeleteOrInactivate, setProjectToDeleteOrInactivate] =
    useState('');

  const confirmDeleteTitle = 'ta bort projekt';
  const confirmDeleteMessage = projectToDeleteOrInactivate
    ? `Är du säker att du vill ta bort kund:  ${projectToDeleteOrInactivate.project_name}  ?`
    : '';
  const confirmInactivationTitle = 'inaktivera projekt';
  const confirmInactivationMessage = projectToDeleteOrInactivate
    ? `Är du säker att du vill inaktivera projekt:  ${projectToDeleteOrInactivate.project_name}  ?`
    : '';
  const confirmInactivationMessageFromDelete = projectToDeleteOrInactivate
    ? `Projekt ${projectToDeleteOrInactivate.project_name} har kopplingar till annan data och går inte att ta bort, vill du inaktivera projektet istället?`
    : '';

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setProject(projectToEdit);
  }, [projectToEdit]);

  const handleDeleteProject = (p) => {
    dispatch(deleteProject(p, user.digituals_client_id)).then(({ payload }) => {
      dispatch(getProjects(user.digituals_client_id));

      if (!payload) return;
      const { connected } = payload;

      if (connected) {
        setFromDelete(true);

        setShowInactivateModal(true);
      }
    });
    setShowDeleteModal(false);
  };
  const handleInactivateProject = (p) => {
    dispatch(inactivateProject(p, user.digituals_client_id)).then(() =>
      dispatch(getProjects(user.digituals_client_id))
    );
    setShowInactivateModal(false);
  };

  return (
    <>
      <DeleteConfirmation
        title={confirmDeleteTitle}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        confirmModal={() => handleDeleteProject(projectToDeleteOrInactivate)}
        message={confirmDeleteMessage}
      />
      <InactivateConfirmation
        title={confirmInactivationTitle}
        showModal={showInactivateModal}
        hideModal={() => setShowInactivateModal(false)}
        confirmInactivate={() =>
          handleInactivateProject(projectToDeleteOrInactivate)
        }
        message={
          fromDelete
            ? confirmInactivationMessageFromDelete
            : confirmInactivationMessage
        }
      />
      <EditModal
        show={show}
        handleClose={() => {
          // setProject('');
          handleClose();
        }}
        fullscreen={mobile ? true : false}
        inactivate={() => {
          setProjectToDeleteOrInactivate(project);
          setFromDelete(false);
          setShowInactivateModal(true);
        }}
        deleteItem={() => {
          setProjectToDeleteOrInactivate(project);
          setShowDeleteModal(true);
        }}
        submitForm={'EditProjectForm'}
        header="Redigera projekt"
      >
        {project && (
          <EditProjectForm handleClose={handleClose} projectToEdit={project} />
        )}
      </EditModal>
    </>
  );
};

export default EditProjectModal;
