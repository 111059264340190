import moment from 'moment';
import React from 'react';
import Moment from '../moment';

export default function DateUserTitle({ user, title }) {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <div className="d-flex flex-row align-self-end">
        <p className="mx-5">Användare: {user.profile.profile_email}</p>
        <p>Datum: {Moment(moment(), 'YYYY-MM-DD HH:mm')}</p>
      </div>
      {title && (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{
            fontSize: 25,
            fontWeight: 'bold',
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
}
