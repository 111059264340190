import { supabase } from '../utils/supabase';
import { sendToast } from '../components/toast';

const addProject = async (p, digitualsClientID) => {
  const {
    teammembers,
    reporters,
    projectName,
    projectNumber,
    projectStart,
    customer,
    status,
    survey,
  } = p;

  try {
    const { data, error } = await supabase.from('project').insert([
      {
        project_name: projectName,
        project_number: projectNumber,
        project_start: projectStart,
        project_status: '',
        is_active: status,
        customer_id: customer,
        digituals_client_id: digitualsClientID,
      },
    ]);

    if (data) {
      const { project_id } = data[0];

      if (teammembers) {
        const teamToPost = [];

        teammembers.forEach((teammember) => {
          const { projectLeader, resource_id } = teammember;
          teamToPost.push({
            resource_id: resource_id,
            team_projectleader: projectLeader,
            project_id: project_id,
            digituals_client_id: digitualsClientID,
          });
        });

        const team = await supabase.from('team').insert(teamToPost);

        if (team.error) {
          console.log('team.error:', team.error, 'teamToPost: ', teamToPost);
        }
      }

      if (survey) {
        const { data: projectSetupData, error: projectSetupError } =
          await supabase.from('project_setup').insert([
            {
              questionnaire_id: survey,
              project_id: project_id,
              digituals_client_id: digitualsClientID,
            },
          ]);

        if (projectSetupData) {
          const { project_setup_id } = projectSetupData[0];

          if (reporters) {
            const reporterPostArr = [];

            reporters.forEach((reporter) => {
              const { profile_id } = reporter;

              if (profile_id) {
                reporterPostArr.push({
                  project_setup_id: project_setup_id,
                  profile_id: profile_id,
                  digituals_client_id: digitualsClientID,
                });
              }
            });

            if (reporterPostArr.length > 0) {
              const { error: reporterError } = await supabase
                .from('reporter')
                .insert(reporterPostArr);

              if (reporterError) {
                console.log('reporterError:', reporterError);
              }
            }
          } else {
            return;
          }
        }
        if (projectSetupError) {
          console.log('projectSetupError:', projectSetupError);
        }
      }

      const toastText = `${projectName} lades till i projekt!`;

      sendToast(toastText, 1);
    }
    if (error) {
      console.log('addProject error: ', error);
    }
  } catch (error) {
    console.log('addProject error', error, error.message);
  }
};

const getProjects = async (digitualsClientID) => {
  try {
    let { data: project, error: projectError } = await supabase
      .from('project')
      .select(
        `
    *,
    customer (
      * 
    ),
    project_setup (
      * ,
      reporter (
        *,
        profile (
          *,
          resource (
            *
          )
        )

      ),
      questionnaire (
        *,
        questionnaire_setup (
          question (
            *
          )
        )
      )
    ),
    team (
      * ,
      resource (
        * 
      )
    )
  
  
  `
      )
      .eq('digituals_client_id', digitualsClientID);

    if (project) {
      return project;
    }
    if (projectError) {
      console.log('projectError error: ', projectError);
    }
  } catch (error) {
    console.log('getProjects error', error, error.message);
  }
};

const editProject = async (p, digitualsClientID) => {
  const {
    teammembers,
    reporters,
    projectName,
    projectNumber,
    projectStart,
    customer,
    status,
    projectID,
    survey,
    projectSetupID,
  } = p;

  try {
    const { data: projectData, error: projectError } = await supabase
      .from('project')
      .update({
        project_name: projectName,
        project_number: projectNumber,
        project_start: projectStart,
        is_active: status,
        customer_id: customer,
      })
      .eq('project_id', projectID)
      .eq('digituals_client_id', digitualsClientID);

    if (projectData) {
      const { error: teamError } = await supabase
        .from('team')
        .delete()
        .eq('project_id', projectID)
        .eq('digituals_client_id', digitualsClientID);

      if (teamError) {
        console.log('teamError: ', teamError);
      }

      if (teammembers) {
        const pLeaderToInsert = teammembers.map((tmember) => ({
          resource_id: tmember.resource_id,
          team_projectleader: tmember.projectLeader,
          project_id: projectID,
          digituals_client_id: digitualsClientID,
        }));

        const { error: teamInsertError } = await supabase
          .from('team')
          .insert(pLeaderToInsert);

        if (teamInsertError) {
          console.log('teamInsertError: ', teamInsertError);
        }
      }

      if (survey) {
        if (projectSetupID) {
          const { error: projectSetupError } = await supabase
            .from('project_setup')
            .update([
              {
                is_active: true,
                questionnaire_id: survey,
              },
            ])
            .eq('project_id', projectID)
            .eq('digituals_client_id', digitualsClientID);

          if (projectSetupError) {
            console.log('projectSetupError: ', projectSetupError);
          }

          let { data: reporterData, error: reporterError } = await supabase
            .from('reporter')
            .select('*')
            .eq('project_setup_id', projectSetupID)
            .eq('digituals_client_id', digitualsClientID);

          if (reporterError) {
            console.log('reporterError: ', reporterError);
          }

          if (reporterData) {
            if (reporters) {
              // const reporterIDs = reporters.map((r) => r.profile_id)

              reporters.forEach(async (reporter) => {
                const { profile_id } = reporter;

                let createAsReporter = true;

                reporterData.forEach(async (r) => {
                  if (r.profile_id === profile_id) {
                    createAsReporter = false;

                    if (r.is_active === false) {
                      const { error: reporterUpdateError } = await supabase
                        .from('reporter')
                        .update({ is_active: true })
                        .eq('profile_id', profile_id)
                        .eq('project_setup_id', projectSetupID)
                        .eq('digituals_client_id', digitualsClientID);

                      if (reporterUpdateError) {
                        console.log(
                          'reporterUpdateError: ',
                          reporterUpdateError
                        );
                      }
                    }
                  }
                });

                if (createAsReporter) {
                  if (profile_id) {
                    const { error: rError } = await supabase
                      .from('reporter')
                      .insert([
                        {
                          is_active: true,
                          project_setup_id: projectSetupID,
                          profile_id: profile_id,
                          digituals_client_id: digitualsClientID,
                        },
                      ]);

                    if (rError) {
                      console.log('rError: ', rError);
                    }
                  }
                }
              });

              reporterData.forEach(async (rData) => {
                let inactivate = true;
                reporters.forEach((rep) => {
                  if (rep.profile_id === rData.profile_id) {
                    inactivate = false;
                  }
                });

                if (inactivate) {
                  if (rData.is_active === true) {
                    const { error: reporterUpdateError } = await supabase
                      .from('reporter')
                      .update({ is_active: false })
                      .eq('profile_id', rData.profile_id)
                      .eq('project_setup_id', projectSetupID)
                      .eq('digituals_client_id', digitualsClientID);

                    if (reporterUpdateError) {
                      console.log('reporterUpdateError: ', reporterUpdateError);
                    }
                  }
                }
              });
            } else {
              reporterData.forEach(async (r) => {
                const { error: reporterUpdateError } = await supabase
                  .from('reporter')
                  .update({ is_active: false })
                  .eq('profile_id', r.profile_id)
                  .eq('project_setup_id', projectSetupID)
                  .eq('digituals_client_id', digitualsClientID);

                if (reporterUpdateError) {
                  console.log('reporterUpdateError: ', reporterUpdateError);
                }
              });
            }
          }
        } else {
          const { data: projectSetupData, error: projectSetupError } =
            await supabase.from('project_setup').insert([
              {
                questionnaire_id: survey,
                project_id: projectID,
                digituals_client_id: digitualsClientID,
              },
            ]);

          if (projectSetupError) {
            console.log('projectSetupError: ', projectSetupError);
          }

          if (projectSetupData) {
            const { project_setup_id } = projectSetupData[0];

            if (reporters.length > 0) {
              reporters.forEach(async (reporter) => {
                const { profile_id } = reporter;

                let createAsReporter = true;

                let { data: reporterData, error: reporterError } =
                  await supabase
                    .from('reporter')
                    .select('*')
                    .eq('profile_id', profile_id)
                    .eq('project_setup_id', project_setup_id)
                    .eq('digituals_client_id', digitualsClientID);

                if (reporterData[0]) {
                  createAsReporter = false;

                  const { is_active } = reporterData[0];

                  if (is_active === false) {
                    const { error: reporterUpdateError } = await supabase
                      .from('reporter')
                      .update({ is_active: true })
                      .eq('profile_id', profile_id)
                      .eq('project_setup_id', project_setup_id)
                      .eq('digituals_client_id', digitualsClientID);

                    if (reporterUpdateError) {
                      console.log('reporterUpdateError: ', reporterUpdateError);
                    }
                  }
                }
                if (reporterError) {
                  console.log('reporterError: ', reporterError);
                }

                if (createAsReporter) {
                  if (profile_id) {
                    const { error: rError } = await supabase
                      .from('reporter')
                      .insert([
                        {
                          is_active: true,
                          project_setup_id: project_setup_id,
                          profile_id: profile_id,
                          digituals_client_id: digitualsClientID,
                        },
                      ]);

                    if (rError) {
                      console.log('rError: ', rError);
                    }
                  }
                }
              });
            }

            if (!reporters || reporters.length === 0) {
              const { error: rError } = await supabase
                .from('reporter')
                .update([
                  {
                    is_active: false,
                  },
                ])

                .eq('project_setup_id', projectSetupID)
                .eq('digituals_client_id', digitualsClientID);

              if (rError) {
                console.log('rError: ', rError);
              }
            }
          }
        }
      }
    }
    if (projectError) {
      console.log('projectError: ', projectError);
    }

    const toastText = `Projekt ${projectName} redigerad!`;

    sendToast(toastText, 1);
  } catch (error) {
    console.log('getProjects error', error, error.message);
  }
};

const deleteProject = async (projectToDelete, digitualsClientID) => {
  const { project_id, project_name } = projectToDelete;

  try {
    const { data: projectSetupData, error: projectSetupError } = await supabase
      .from('project_setup')
      .select('*')
      .eq('project_id', project_id)
      .eq('digituals_client_id', digitualsClientID);

    if (projectSetupError) {
      console.log('deleteProject: ', projectSetupError);
    }

    if (projectSetupData.length > 0) {
      const { project_setup_id } = projectSetupData[0];

      const { data: reporterData, error: reporterError } = await supabase
        .from('reporter')
        .delete()
        .eq('project_setup_id', project_setup_id)
        .eq('digituals_client_id', digitualsClientID);

      if (reporterError) {
        console.log('reporterError: ', reporterError);
        const { code } = reporterError;

        if (code === '23503') {
          return { connected: true };
        }
      }
      if (reporterData) {
        const { data: teamData, error: teamError } = await supabase
          .from('team')
          .delete()
          .eq('project_id', project_id)
          .eq('digituals_client_id', digitualsClientID);

        const { data: projectSetupData, error: projectSetupError } =
          await supabase
            .from('project_setup')
            .delete()
            .eq('project_id', project_id)
            .eq('digituals_client_id', digitualsClientID);

        const { data, error } = await supabase
          .from('project')
          .delete()
          .eq('project_id', project_id)
          .eq('digituals_client_id', digitualsClientID);

        if (data) {
          const toastText = `Projekt ${project_name} bortagen!`;

          sendToast(toastText, 4);
        }
        if (error) {
          console.log('deleteProject: ', error);
        }

        if (projectSetupError) {
          console.log('projectSetupError: ', projectSetupError);
        }
        if (projectSetupData) {
        }

        if (teamError) {
          console.log('teamError: ', teamError);
        }
        if (teamData) {
        }
      }
    } else {
      const { data: teamData, error: teamError } = await supabase
        .from('team')
        .delete()
        .eq('project_id', project_id)
        .eq('digituals_client_id', digitualsClientID);

      const { data, error } = await supabase
        .from('project')
        .delete()
        .eq('project_id', project_id)
        .eq('digituals_client_id', digitualsClientID);

      if (teamError) {
        console.log('teamError: ', teamError);
      }
      if (teamData) {
      }

      if (data) {
        const toastText = `Projekt ${project_name} bortagen!`;

        sendToast(toastText, 4);
      }
      if (error) {
        console.log('deleteProject: ', error);
      }
    }
  } catch (error) {
    console.log('getProjects error', error, error.message);
  }
};

const activateProject = async (projectToActivate, digitualsClientID) => {
  const { project_id, project_name } = projectToActivate;

  try {
    const { data, error } = await supabase
      .from('project')
      .update({ is_active: true })
      .eq('project_id', project_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      const toastText = `${project_name} aktiverad!`;

      sendToast(toastText, 1);
    }
    if (error) {
      console.log('activateProject: ', error);
    }
  } catch (error) {
    console.log('activateProject error', error, error.message);
  }
};

const inactivateProject = async (projectToActivate, digitualsClientID) => {
  const { project_id, project_name } = projectToActivate;

  try {
    const { data, error } = await supabase
      .from('project')
      .update({ is_active: false })
      .eq('project_id', project_id)
      .eq('digituals_client_id', digitualsClientID);

    if (data) {
      const toastText = `${project_name} inaktiverad!`;

      sendToast(toastText, 2);
    }
    if (error) {
      console.log('inactivateProject: ', error);
    }
  } catch (error) {
    console.log('inactivateProject error', error, error.message);
  }
};

export {
  addProject,
  getProjects,
  editProject,
  deleteProject,
  activateProject,
  inactivateProject,
};
